import { Platform } from "react-native";

export function savedIcon(savedFlag) {
  if (savedFlag) {
    if (Platform.OS === "ios") {
      return "ios-heart";
    } else {
      return "md-heart";
    }
  } else {
    if (Platform.OS === "ios") {
      return "ios-heart-outline";
    } else {
      return "md-heart-outline";
    }
  }
}

export function savedColor(savedFlag) {
  if (savedFlag) {
    return "#ff9999";
  } else {
    return "#999999";
  }
}

export function concatText(mean, meanEn) {
  let returnArr = [];
  if (mean) {
    returnArr.push(mean);
  }
  if (meanEn) {
    returnArr.push(meanEn);
  }
  return returnArr.join("\n");
}
