import * as Linking from "expo-linking";

export default {
  prefixes: ["https://app.quick-dictionary.com"],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              HomeScreen: "home",
            },
          },
          Browser: {
            screens: {
              BrowserScreen: "browser",
            },
          },
          Saved: {
            screens: {
              SavedScreen: "saved",
            },
          },
          Settings: {
            screens: {
              SettingsScreen: "settings",
            },
          },
        },
      },
      NotFound: "*",
    },
  },
};
