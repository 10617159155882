// import * as WebBrowser from "expo-web-browser";
import React, { useState, useEffect } from "react";
import {
  InteractionManager,
  FlatList,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Text, TextInput, View } from "../components/Themed";
import { Button, Tooltip } from "react-native-elements";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import * as Speech from "expo-speech";
import { requestTrackingPermissionsAsync } from "expo-tracking-transparency";
import { savedIcon, savedColor, concatText } from "../api/Utils";
import * as Query from "../api/Query";
import { nextKeysMap, nextKeysDefault } from "../api/NextKeyMap";
import * as SharedContainer from "../api/SharedContainer";
import * as Colors from "../constants/Colors";

import firebase from "firebase";
import "@firebase/firestore";
import { firebaseConfig } from "../api/Firestore";
import { firebaseLoginStatus, firebaseUserId } from "../api/SharedContainer";

import * as Analytics from "expo-firebase-analytics";
Analytics.setAnalyticsCollectionEnabled(false);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const firestoreDB = firebase.firestore();

export default function HomeScreen(props) {
  useEffect(() => {
    (async () => {
      const { status } = await requestTrackingPermissionsAsync();
      if (status === "granted") {
        Analytics.setAnalyticsCollectionEnabled(true);
        console.log("firebase analytics tracking enabled");
      }
    })();
  }, []);
  firebase.auth().onAuthStateChanged((user) => {
    if (firebaseLoginStatus()) {
      Analytics.setUserId(firebaseUserId());
    }
  });
  const [keyInput, setKeyInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [listUpdateFlg, setListUpdateFlg] = useState(true);
  let savedWordsContainer = SharedContainer.SavedWords.useContainer();
  const [nextKeys, setNextKeys] = useState(nextKeysDefault);
  const loadSavedWords = () => {
    if (firebaseLoginStatus() && listUpdateFlg) {
      setListUpdateFlg(false);
      SharedContainer.getWordsFromFirestore().then((words) => {
        savedWordsContainer.updateWords(words);
      });
    }
  };
  loadSavedWords();
  const autoSaveToHistory = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    InteractionManager.runAfterInteractions(() => {
      const timeoutId = setTimeout(() => {
        setKeyInput("");
        setSearchResult([]);
        setNextKeys(nextKeysDefault);
      }, 20000);
      setTimeoutId(timeoutId);
    });
  };
  const queryDict = (queryChar) => {
    if (queryChar !== "") {
      const userScore = Query.freqMedian(
        savedWordsContainer.savedWords.slice(0, 20)
      );
      const sql = Query.builder(queryChar, userScore);
      const res = Query.executeSQL(sql);
      let newSearchResult = [];
      let savedWordList = [];
      savedWordsContainer.savedWords.forEach((el) => {
        savedWordList.push(el.word);
      });
      res.forEach((el) => {
        el.restChars = el.word.slice(queryChar.length);
        if (savedWordList.includes(el.word)) {
          el.savedFlag = true;
        } else {
          el.savedFlag = false;
        }
        newSearchResult.push(el);
      });
      setSearchResult(newSearchResult);
    } else {
      setSearchResult([]);
    }
  };
  const syncToFirestore = (item) => {
    InteractionManager.runAfterInteractions(() => {
      const wordRef = firestoreDB
        .collection("users")
        .doc(firebaseUserId())
        .collection("words")
        .doc(item.word);
      wordRef.get().then((wordData) => {
        if (!wordData.exists) {
          const newData = {
            word: item.word,
            mean: item.mean,
            meanEn: item.mean_en,
            examples: item.examples,
            freq: item.freq,
            cocaSpok2: item.coca_spok2,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          };
          wordRef.set(newData).then(() => {
            SharedContainer.getWordsFromFirestore().then((words) => {
              savedWordsContainer.updateWords(words);
            });
          });
          Analytics.logEvent("home_save_word", { word: item.word });
        } else {
          wordRef.delete().then(() => {
            SharedContainer.getWordsFromFirestore().then((words) => {
              savedWordsContainer.updateWords(words);
            });
          });
          Analytics.logEvent("home_delete_word", { word: item.word });
        }
      });
    });
  };
  const saveOrDelete = (item) => {
    if (firebaseLoginStatus()) {
      let currentSavedWords = [];
      savedWordsContainer.savedWords.forEach((el) => {
        currentSavedWords.push(el.word);
      });
      let newSavedWords = [];
      let newSearchResult = [];
      if (currentSavedWords.includes(item.word)) {
        //delete
        savedWordsContainer.savedWords.forEach((el) => {
          if (el.word !== item.word) {
            newSavedWords.push(el);
          }
        });
        searchResult.forEach((el) => {
          if (el.word === item.word) {
            el.savedFlag = false;
          }
          newSearchResult.push(el);
        });
        setSearchResult(newSearchResult);
      } else {
        //add
        newSavedWords = savedWordsContainer.savedWords;
        newSavedWords.unshift(item);
        searchResult.forEach((el) => {
          if (el.word === item.word) {
            el.savedFlag = true;
          }
          newSearchResult.push(el);
        });
        setSearchResult(newSearchResult);
      }
      syncToFirestore(item);
    }
  };
  const saveModule = (item) => {
    if (firebaseLoginStatus()) {
      return (
        <Ionicons
          name={savedIcon(item.savedFlag)}
          size={20}
          color={savedColor(item.savedFlag)}
          onPress={() => {
            saveOrDelete(item);
          }}
        />
      );
    } else {
      if (Platform.OS === "web") {
        <Ionicons
          name={savedIcon(item.savedFlag)}
          size={20}
          color={savedColor(item.savedFlag)}
        />;
      } else {
        return (
          <Tooltip
            height={80}
            backgroundColor={"#555"}
            popover={
              <Text style={{ color: "#eee" }}>
                Please sign in from setting tab↓
              </Text>
            }
          >
            <Ionicons
              name={savedIcon(item.savedFlag)}
              size={20}
              color={savedColor(item.savedFlag)}
            />
          </Tooltip>
        );
      }
    }
  };
  const wordList = () => {
    return (
      <FlatList
        data={searchResult}
        renderItem={(data) => (
          <TouchableOpacity
            style={Colors.homeScreenStyles.rowFrontContainer}
            activeOpacity={0.5}
            onPress={() => {
              saveOrDelete(data.item);
            }}
            onLongPress={() => {
              Speech.speak(data.item.word);
              Analytics.logEvent("home_speak_word", { word: data.item.word });
            }}
          >
            <View style={Colors.homeScreenStyles.wordTitleContainer}>
              <View style={{ width: "95%" }}>
                <View style={Colors.homeScreenStyles.wordTitleContainer}>
                  <Text style={Colors.homeScreenStyles.wordTitleInputted}>
                    {keyInput}
                  </Text>
                  <Text style={Colors.homeScreenStyles.wordTitle}>
                    {data.item.restChars}
                  </Text>
                </View>
                <Text>
                  {concatText(data.item.mean, data.item.mean_en)}
                  <Text style={Colors.homeScreenStyles.wordExample}>
                    {"\n"}
                    {data.item.examples}
                  </Text>
                  <Text style={Colors.homeScreenStyles.wordFreq}>
                    {" "}
                    ({data.item[Query.dbColumnForMedian]})
                  </Text>
                </Text>
              </View>
              <View style={{ justifyContent: "center" }}>
                {saveModule(data.item)}
              </View>
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={(data, index) => index.toString()}
      />
    );
  };
  const nextKeysMapSetter = (newInput) => {
    const currentNextKeys = nextKeysMap[newInput] || nextKeysDefault;
    setNextKeys(currentNextKeys);
  };
  const keyBoard = () => {
    return (
      <View style={Colors.homeScreenStyles.keyboardContainer}>
        {KEYS.map((line, lindex) => {
          return (
            <View
              key={lindex}
              style={Colors.homeScreenStyles.keyboardLineContainer}
            >
              {line.map((key, keyindex) => {
                if (key !== "backspace") {
                  if (nextKeys.includes(key)) {
                    return (
                      <Button
                        key={key}
                        type="outline"
                        title={key}
                        titleStyle={Colors.homeScreenStyles.keyboardKey}
                        containerStyle={
                          Colors.homeScreenStyles.keyboardKeyContainer
                        }
                        onPress={() => {
                          setKeyInput(keyInput + key);
                          queryDict(keyInput + key);
                          nextKeysMapSetter(keyInput + key);
                          autoSaveToHistory();
                          Analytics.logEvent("home_press_key", { key: key });
                        }}
                      />
                    );
                  } else {
                    return (
                      <Button
                        key={key}
                        type="outline"
                        title={key}
                        titleStyle={Colors.homeScreenStyles.keyboardKey}
                        containerStyle={
                          Colors.homeScreenStyles.keyboardKeyContainer
                        }
                        disabled={true}
                      />
                    );
                  }
                } else {
                  return (
                    <Button
                      key={key}
                      type="outline"
                      icon={
                        <MaterialIcons name={key} color={"#2098dc"} size={24} />
                      }
                      title=""
                      style={{ marginLeft: 10 }}
                      onPress={() => {
                        setKeyInput(keyInput.slice(0, -1));
                        queryDict(keyInput.slice(0, -1));
                        nextKeysMapSetter(keyInput.slice(0, -1));
                        autoSaveToHistory();
                        Analytics.logEvent("home_press_key", {
                          key: "backspace",
                        });
                      }}
                    />
                  );
                }
              })}
            </View>
          );
        })}
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Button
            icon={<MaterialIcons name="clear" color={"#2098dc"} size={24} />}
            title=""
            type="outline"
            style={{ margin: 1 }}
            containerStyle={{ width: "80%" }}
            onPress={() => {
              setKeyInput("");
              queryDict("");
              nextKeysMapSetter("");
              autoSaveToHistory();
              Analytics.logEvent("home_press_key", {
                key: "delete",
              });
            }}
          />
        </View>
      </View>
    );
  };
  return (
    <View style={Colors.homeScreenStyles.container}>
      <View>
        <TextInput
          style={Colors.homeScreenStyles.inputBox}
          onChangeText={(text) => {
            setKeyInput(text);
          }}
          editable={false}
          value={keyInput}
        />
      </View>
      <View style={Colors.homeScreenStyles.scrollViewContainer}>
        {wordList(searchResult)}
      </View>
      {keyBoard()}
    </View>
  );
}

HomeScreen.navigationOptions = {
  header: null,
};

const KEYS = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  ["z", "x", "c", "v", "b", "n", "m", "backspace"],
];
