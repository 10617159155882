import React, { useState } from "react";
import {
  Platform,
  FlatList,
  //  RefreshControl,
  TouchableOpacity,
} from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { Text, TextInput, View } from "../components/Themed";

import * as Speech from "expo-speech";
import { Ionicons } from "@expo/vector-icons";
import firebase from "firebase";
import "@firebase/firestore";
import { firebaseConfig } from "../api/Firestore";
import { firebaseLoginStatus, firebaseUserId } from "../api/SharedContainer";
import { concatText } from "../api/Utils";
import * as Query from "../api/Query";
import * as SharedContainer from "../api/SharedContainer";
import * as Colors from "../constants/Colors";
import * as Analytics from "expo-firebase-analytics";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const firestoreDB = firebase.firestore();

export default function SavedScreen() {
  const [listUpdateFlg, setListUpdateFlg] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  let savedWordsContainer = SharedContainer.SavedWords.useContainer();
  const loadSavedWords = () => {
    if (firebaseLoginStatus() && (listUpdateFlg || refreshing)) {
      setListUpdateFlg(false);
      setRefreshing(false);
      SharedContainer.getWordsFromFirestore().then((words) => {
        savedWordsContainer.updateWords(words);
      });
    }
  };
  loadSavedWords();
  const toggleRefresh = () => {
    if (refreshing === true) {
      setRefreshing(false);
    } else {
      setRefreshing(true);
    }
  };
  const deleteWord = (item) => {
    const wordRef = firestoreDB
      .collection("users")
      .doc(firebaseUserId())
      .collection("words")
      .doc(item.word);
    wordRef.delete();
    setListUpdateFlg(true);
    loadSavedWords();
    Analytics.logEvent("saved_delete_word", { word: item.word });
  };
  return (
    <View style={Colors.savedScreenStyles.container}>
      <FlatList
        data={savedWordsContainer.savedWords}
        renderItem={(data) => (
          <TouchableOpacity
            style={Colors.savedScreenStyles.rowFrontContainer}
            activeOpacity={0.5}
            onLongPress={() => {
              Speech.speak(data.item.word);
              Analytics.logEvent("saved_speak_word", { word: data.item.word });
            }}
          >
            <View style={Colors.savedScreenStyles.wordTitleContainer}>
              <View style={{ width: "95%" }}>
                <View style={Colors.savedScreenStyles.wordTitleContainer}>
                  <Text style={Colors.savedScreenStyles.wordTitle}>
                    {data.item.word}
                  </Text>
                </View>
                <Text>
                  {concatText(data.item.mean, data.item.meanEn)}
                  <Text style={Colors.savedScreenStyles.wordExample}>
                    {"\n"}
                    {data.item.examples}
                  </Text>
                  <Text style={Colors.savedScreenStyles.wordFreq}>
                    {" "}
                    ({data.item[Query.propForMedian]})
                  </Text>
                </Text>
              </View>
              <View style={{ justifyContent: "center" }}>
                <Ionicons
                  name={Platform.OS === "ios" ? "ios-trash" : "md-trash"}
                  size={20}
                  color={"#999"}
                  onPress={() => deleteWord(data.item)}
                />
              </View>
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={(data, index) => index.toString()}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={toggleRefresh} />
        }
        maxToRenderPerBatch={30}
      />
    </View>
  );
}

SavedScreen.navigationOptions = {
  title: "Saved",
};
