import React, { useState } from "react";
import { InteractionManager, Button, Platform } from "react-native";
import Clipboard from "@react-native-community/clipboard";
import { View, Input } from "../components/Themed";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { WebView } from "react-native-webview";
import { showMessage } from "react-native-flash-message";
import { isUri } from "valid-url";
import Constants from "expo-constants";
import * as SharedContainer from "../api/SharedContainer";
import Loading from "../components/Loading";
import useColorScheme from "../hooks/useColorScheme";

import * as Colors from "../constants/Colors";
const baseUrl =
  "https://quick-dictionary-249916.appspot.com/web/parse/article?url=";

import firebase from "firebase";
import "@firebase/firestore";
import { firebaseConfig } from "../api/Firestore";
import { firebaseLoginStatus, firebaseUserId } from "../api/SharedContainer";

import * as Analytics from "expo-firebase-analytics";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const firestoreDB = firebase.firestore();

export default function BrowserScreen() {
  const [webviewSourceUrl, setWebviewSourceUrl] = useState(baseUrl);
  const [sourceUrl, setSourceUrl] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [keyInput, setKeyInput] = useState("");
  let savedWordsContainer = SharedContainer.SavedWords.useContainer();
  const saveToFirestore = (item: {
    word: string | undefined;
    mean: string;
    mean_en: string;
    freq: number;
    coca_spok2: number;
    examples: string;
  }) => {
    if (firebaseLoginStatus()) {
      InteractionManager.runAfterInteractions(() => {
        const wordRef = firestoreDB
          .collection("users")
          .doc(firebaseUserId())
          .collection("words")
          .doc(item.word);
        showMessage({
          message: "saved",
          type: "info",
        });
        wordRef.get().then((wordData) => {
          const newData = {
            word: item.word,
            mean: item.mean,
            meanEn: item.mean_en,
            freq: item.freq,
            cocaSpok2: item.coca_spok2,
            examples: item.examples,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          };
          wordRef.set(newData).then(() => {
            SharedContainer.getWordsFromFirestore().then((words) => {
              savedWordsContainer.updateWords(words);
            });
            Analytics.logEvent("browser_save_word", { word: item.word });
          });
        });
      });
    } else {
      showMessage({
        message: "please sign in to save",
        type: "warning",
      });
    }
  };
  const saveUrlToFirestore = (item: { url: string; title: string }) => {
    if (firebaseLoginStatus()) {
      InteractionManager.runAfterInteractions(() => {
        const urlRef = firestoreDB
          .collection("users")
          .doc(firebaseUserId())
          .collection("urls")
          .doc(encodeURIComponent(item.url));
        urlRef.get().then(() => {
          const newData = {
            url: item.url,
            title: item.title,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          };
          urlRef.set(newData);
        });
      });
    }
  };
  return (
    <View style={Colors.browserScreenStyles.container}>
      <View>
        <Input
          placeholder="paste url here"
          clearButtonMode={"always"}
          onSubmitEditing={(text) => {
            const url = text.nativeEvent.text;
            if (!isUri(url)) {
              showMessage({
                message: "please input a valid url",
                type: "warning",
              });
              return;
            }
            if (webviewSourceUrl === baseUrl + encodeURIComponent(url)) {
              return;
            }
            setWebviewSourceUrl(
              baseUrl + encodeURIComponent(text.nativeEvent.text)
            );
            setSourceUrl(text.nativeEvent.text);
            if (Platform.OS !== "web") {
              setLoadingStatus(true);
            }
          }}
          rightIcon={
            <FontAwesome
              name="paste"
              solid={true}
              size={24}
              color={"#999"}
              onPress={() => {
                Clipboard.getString().then((text) => {
                  setKeyInput(text);
                  if (!isUri(text)) {
                    showMessage({
                      message: "please input a valid url",
                      type: "warning",
                    });
                    return;
                  }
                  setWebviewSourceUrl(baseUrl + encodeURIComponent(text));
                  setSourceUrl(text);
                  setLoadingStatus(true);
                });
              }}
            />
          }
          onChangeText={(text) => {
            setKeyInput(text);
          }}
          value={keyInput}
          autoCapitalize="none"
        />
      </View>
      <WebView
        source={{
          uri: webviewSourceUrl,
          headers: {
            "app-version": Constants.manifest.version,
            "color-scheme": useColorScheme(),
          },
        }}
        onLoadEnd={() => setLoadingStatus(false)}
        style={{ marginTop: 2 }}
        startInLoadingState={loadingStatus}
        renderLoading={() => <Loading />}
        onMessage={(event) => {
          const item = JSON.parse(event.nativeEvent.data);
          if (typeof item.mean !== "undefined") {
            saveToFirestore(item);
          }
          if (typeof item.title !== "undefined") {
            console.log(item.title);
            const urlData = {
              url: sourceUrl,
              title: item.title,
            };
            saveUrlToFirestore(urlData);
            Analytics.logEvent("browser_parse_url", {
              url: sourceUrl,
              title: item.title,
            });
          }
        }}
      />
      {loadingStatus && <Loading />}
    </View>
  );
}

BrowserScreen.navigationOptions = {
  header: null,
};
