export const nextKeysDefault = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const nextKeysMap = {
  a: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "i",
    "k",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  aa: ["a", "m", "r"],
  aar: ["d", "o"],
  ab: [
    "c",
    "m",
    "a",
    "b",
    "d",
    "e",
    "h",
    "i",
    "j",
    "l",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "y",
  ],
  aba: ["c", "f", "l", "n", "s", "t"],
  abac: ["i", "k", "u"],
  abas: ["e", "h"],
  abase: ["m"],
  abat: ["e", "i", "t"],
  abate: ["m"],
  abb: ["a", "e", "o", "r"],
  abbe: ["s", "y"],
  abbr: ["e"],
  abd: ["i", "o", "u"],
  abe: ["a", "c", "d", "l", "r", "t", "y"],
  abet: ["t"],
  abi: ["d", "l"],
  abid: ["e", "i"],
  abj: ["e", "u"],
  abl: ["a", "e", "o", "u", "y"],
  abla: ["t", "u", "z"],
  ablat: ["e", "i"],
  able: [],
  abn: ["e", "o"],
  abo: ["a", "d", "l", "m", "r", "u", "v"],
  abor: ["i", "n", "t"],
  abort: ["e", "i"],
  abou: ["n", "t"],
  about: [],
  abr: ["a", "e", "i", "o", "u"],
  abra: ["c", "d", "h", "s"],
  abro: ["a", "g"],
  abs: ["c", "e", "i", "o", "t", "u"],
  absc: ["e", "i", "o"],
  abso: ["l", "r"],
  absol: ["u", "v"],
  absor: ["b", "p"],
  abst: ["a", "e", "i", "r"],
  abste: ["m", "n"],
  abstr: ["a", "u"],
  abu: ["n", "s", "t", "z"],
  abus: ["e", "i"],
  abuse: ["r"],
  abut: ["m", "t"],
  ac: ["t", "a", "c", "e", "h", "i", "k", "m", "n", "o", "q", "r", "t", "u"],
  aca: ["c", "d", "n"],
  acad: ["e", "i"],
  acc: ["e", "i", "l", "o", "r", "t", "u"],
  acce: ["d", "l", "n", "p", "s"],
  accl: ["a", "i"],
  accla: ["i", "m"],
  accli: ["m", "v"],
  acco: ["l", "m", "r", "s", "u"],
  accom: ["m", "p"],
  accou: ["n", "t"],
  accr: ["e", "u"],
  accre: ["d", "t"],
  accru: ["a", "e"],
  accu: ["l", "m", "r", "s"],
  accur: ["a", "s"],
  accus: ["a", "e", "i", "t"],
  ace: ["p", "q", "r", "t"],
  acet: ["a", "i", "o", "y"],
  aceta: ["b", "l", "n", "t"],
  ach: ["e", "i", "o", "r"],
  ache: ["n"],
  achi: ["e", "l", "n"],
  ack: ["a", "n"],
  aco: ["l", "n", "r", "u"],
  acr: ["e", "i", "o", "y"],
  acre: ["a"],
  acri: ["d", "m"],
  acro: ["b", "l", "m", "n", "p", "s"],
  acrop: ["h", "o"],
  acros: ["s", "t"],
  act: ["h", "i", "o", "r", "s", "u"],
  acti: ["n", "o", "v"],
  actin: ["g", "i"],
  activ: ["a", "e", "i"],
  acu: ["i", "m", "p", "t"],
  acup: ["r", "u"],
  ad: [
    "a",
    "a",
    "d",
    "e",
    "h",
    "i",
    "j",
    "l",
    "m",
    "o",
    "r",
    "s",
    "u",
    "v",
    "z",
  ],
  ada: ["g", "m", "p"],
  adag: ["e", "i"],
  adam: ["a", "s"],
  add: ["e", "i", "l", "n", "r", "u"],
  adde: ["n", "r"],
  addi: ["c", "t"],
  addn: ["l"],
  ade: ["n", "p", "q"],
  aden: ["i", "o"],
  adeno: ["i", "m", "p"],
  adi: ["a", "e", "o", "p"],
  adj: ["a", "e", "o", "u"],
  adjo: ["i", "u"],
  adju: ["d", "n", "r", "s", "t", "v"],
  adjud: ["g", "i"],
  adm: ["a", "i", "o"],
  adma: ["n", "s"],
  admi: ["n", "r", "s", "t", "x"],
  admir: ["a", "e", "i"],
  admit: ["t"],
  admix: ["t"],
  ado: ["b", "l", "n", "p", "r"],
  ador: ["a", "e", "i", "n"],
  adora: ["b", "t"],
  adore: ["r"],
  adorn: ["m"],
  adr: ["e", "i", "o"],
  adri: ["a", "f"],
  adu: ["l", "m"],
  adul: ["a", "t"],
  adult: ["e", "h"],
  adv: ["a", "e", "i", "o", "t"],
  adve: ["n", "r"],
  adver: ["b", "s", "t"],
  advi: ["c", "s"],
  advis: ["a", "e", "o"],
  ae: ["c", "g", "n", "o", "r", "s", "t"],
  aeg: ["e", "i"],
  aeo: ["l", "n"],
  aer: ["a", "i", "o", "t", "y"],
  aeri: ["a", "e"],
  aero: ["b", "d", "f", "g", "l", "n", "p", "s"],
  aerob: ["a", "e", "i"],
  aerod: ["r", "y"],
  aeron: ["a"],
  aeros: ["o", "p", "t"],
  aes: ["c", "o", "t"],
  aest: ["h", "i"],
  aet: ["h", "i"],
  af: ["b", "c", "l", "a", "f", "g", "i", "l", "o", "r", "t"],
  aff: ["a", "e", "i", "l", "o", "r", "t"],
  affa: ["b", "i"],
  affab: ["i", "l"],
  affe: ["c", "r"],
  affi: ["a", "d", "l", "n", "r", "x"],
  affin: ["a", "e", "i"],
  affl: ["a", "i", "u"],
  affr: ["a", "i", "o"],
  affri: ["c", "g"],
  afi: ["c", "e", "r"],
  afl: ["c", "a", "o", "u"],
  afo: ["o", "r", "u"],
  afr: ["a", "e", "i", "o"],
  afri: ["c", "k"],
  afro: [],
  aft: ["e"],
  ag: ["a", "c", "e", "g", "h", "i", "l", "n", "o", "r", "t", "u"],
  aga: ["i", "p", "r", "t", "v"],
  age: ["d", "i", "l", "n", "r"],
  agei: ["n", "s"],
  agen: ["c", "d", "t"],
  agent: [],
  agg: ["l", "r"],
  aggl: ["o", "u"],
  aggr: ["a", "e", "i"],
  aggra: ["n", "v"],
  aggre: ["g", "s"],
  agi: ["l", "n", "t"],
  agil: ["e", "i"],
  agl: ["e", "i", "o"],
  agn: ["a", "o", "u"],
  ago: ["g", "n", "r"],
  agog: ["o"],
  agon: ["i", "y"],
  agoni: ["s", "z"],
  agony: [],
  agr: ["a", "e", "i", "o"],
  agri: ["b", "c"],
  agro: ["n", "u"],
  agu: ["a", "e"],
  ah: ["a", "c", "e", "o"],
  ahe: ["a", "m"],
  aho: ["l", "y"],
  ai: ["d", "h", "d", "l", "m", "n", "r", "s", "t"],
  aid: ["e"],
  aide: [],
  ail: ["a", "e", "m"],
  aim: ["l"],
  air: ["b", "c", "d", "e", "f", "h", "i", "l", "m", "p", "s", "t", "w", "y"],
  airb: ["a", "e", "l", "o", "r", "u"],
  airba: ["g", "s"],
  airbr: ["a", "i", "u"],
  airc: ["r", "u"],
  aircr: ["a", "e"],
  airf: ["i", "l", "o", "r"],
  airfr: ["a", "e"],
  airi: ["l", "n"],
  airin: ["e", "g"],
  airl: ["e", "i", "o"],
  airli: ["f", "n"],
  airm: ["a", "o"],
  airma: ["i", "n"],
  airp: ["l", "o"],
  airs: ["h", "i", "p", "t"],
  airsh: ["a", "i"],
  airsp: ["a", "e"],
  airw: ["a", "o"],
  airwa: ["v", "y"],
  airwo: ["m", "r"],
  ak: ["i"],
  aki: ["m", "n"],
  al: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  ala: ["b", "c", "m", "n", "r", "s"],
  alac: ["k", "r"],
  alar: ["m"],
  alarm: ["e", "i"],
  alas: ["k"],
  alb: ["a", "e", "i", "u"],
  alba: ["c", "n", "t"],
  alban: ["i", "y"],
  albe: ["d", "i"],
  albi: ["n", "o"],
  albin: ["i", "o"],
  alc: ["a", "h", "o"],
  alca: ["l", "z"],
  alco: ["h", "v"],
  ald: ["e"],
  alde: ["h", "r"],
  alder: ["m"],
  ale: ["a", "e", "h", "m", "r", "u", "w", "x"],
  alex: ["a", "i"],
  alf: ["a", "r"],
  alg: ["a", "e", "i", "o"],
  alga: ["e", "l"],
  alge: ["b", "r"],
  algo: ["n", "r"],
  ali: ["a", "b", "e", "g", "k", "m", "n", "q", "t", "v", "z"],
  alig: ["h", "n"],
  align: ["m"],
  alim: ["e", "o"],
  alk: ["a", "y"],
  all: ["a", "e", "h", "i", "o", "s", "u", "y"],
  alla: ["h", "y"],
  alle: ["g", "l", "r", "v", "y"],
  alleg: ["a", "e", "i", "o", "r"],
  allel: ["e", "u"],
  alley: ["w"],
  alli: ["a", "e", "g", "t", "u"],
  allo: ["c", "p", "t", "v", "w", "y"],
  allot: ["m", "r"],
  allow: ["a"],
  allu: ["d", "r", "s", "v"],
  allur: ["e", "i"],
  alm: ["a", "i", "o", "s"],
  alma: ["n"],
  alman: ["a", "d"],
  almo: ["n", "s"],
  almon: ["d", "e"],
  alms: ["g", "h"],
  alo: ["e", "f", "h", "n", "o", "p", "u"],
  alon: ["e", "g"],
  alone: ["n"],
  along: ["s"],
  alp: ["a", "e", "h", "i", "s"],
  alr: ["e", "i"],
  als: ["a", "o"],
  also: [],
  alt: ["a", "e", "h", "i", "o", "r"],
  alta: ["i", "r", "z"],
  altai: ["c"],
  altar: ["p"],
  alti: ["m", "t"],
  alto: ["g"],
  am: [
    "a",
    "v",
    "a",
    "b",
    "e",
    "h",
    "i",
    "m",
    "n",
    "o",
    "p",
    "s",
    "t",
    "u",
    "y",
  ],
  ama: ["h", "l", "n", "r", "s", "t", "z"],
  amar: ["a", "y"],
  amat: ["e", "i", "o"],
  amaz: ["e", "i", "o"],
  amaze: ["d", "m"],
  amb: ["a", "e", "i", "l", "r", "u"],
  ambi: ["d", "e", "g", "t", "v"],
  ambit: ["i"],
  ambiv: ["a", "e"],
  ambu: ["l", "s"],
  ame: ["b", "l", "n", "r", "t"],
  ameb: ["a", "i"],
  amen: ["a", "d", "i"],
  amend: ["a", "m", "s"],
  amer: ["a", "i"],
  ameri: ["c", "n"],
  ami: ["a", "c", "d", "g", "n", "r", "s", "t"],
  amid: ["e", "s"],
  amids: ["h", "t"],
  amin: ["e", "o"],
  amis: ["h", "s"],
  amm: ["a", "e", "o", "u"],
  ammo: ["n"],
  amn: ["e", "i"],
  amo: ["e", "k", "n", "r", "u"],
  amon: ["g", "t"],
  among: ["s"],
  amor: ["a", "o", "p", "t"],
  amou: ["n", "r"],
  amp: ["e", "h", "i", "l", "u"],
  amph: ["e", "i", "o"],
  amphi: ["b", "t"],
  ampl: ["e", "i", "y"],
  ampli: ["f", "t"],
  ampu: ["l", "t"],
  amput: ["a", "e"],
  amt: ["r"],
  amu: ["c", "l", "s"],
  amus: ["e", "i"],
  amuse: ["d", "m"],
  amy: ["g", "l"],
  amyl: ["a", "o"],
  an: [
    "a",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "k",
    "n",
    "o",
    "s",
    "t",
    "u",
    "v",
    "x",
    "y",
  ],
  ana: ["b", "c", "d", "e", "g", "l", "m", "p", "r", "s", "t"],
  anab: ["a", "o"],
  anac: ["h", "o"],
  anad: ["e", "r"],
  anae: ["m", "r", "s"],
  anag: ["n", "o", "r"],
  anal: ["g", "o", "y"],
  analy: ["s", "t", "z"],
  anap: ["a", "e", "h", "l"],
  anat: ["h", "o"],
  anc: ["e", "h", "i"],
  anci: ["e", "l"],
  and: ["a", "e", "i", "o", "r"],
  ande: ["a", "r", "s"],
  ane: ["c", "m", "n", "r", "s", "u", "w"],
  anec: ["d", "h"],
  anem: ["i", "o"],
  anemi: ["a", "c"],
  anemo: ["m", "n"],
  anen: ["c", "t"],
  ang: ["e", "i", "l", "o", "r", "s", "u"],
  ange: ["l", "r"],
  angel: ["f", "i", "u"],
  angi: ["n", "o"],
  angio: ["g", "s"],
  angl: ["e", "i", "o"],
  angle: ["d", "r", "s", "w"],
  angli: ["a", "c", "n"],
  anglo: ["m", "p"],
  ango: ["l", "r", "s"],
  angr: ["i", "y"],
  angu: ["i", "l", "s"],
  ani: ["l", "m", "o", "s"],
  anil: ["e", "i"],
  anim: ["a", "e", "i", "o", "u"],
  anima: ["d", "l", "t"],
  anis: ["e", "o"],
  anise: ["e", "t"],
  ank: ["a", "h", "l"],
  ann: ["a", "e", "i", "o", "u"],
  anne: ["a", "l", "x"],
  annex: ["a", "e"],
  anni: ["h", "v"],
  anno: ["t", "u", "y"],
  annoy: ["a", "i"],
  annu: ["a", "i", "l", "n"],
  annul: ["a", "m", "u"],
  ano: ["d", "i", "m", "n", "p", "r", "t"],
  anod: ["e", "i", "y"],
  anom: ["a", "i", "y"],
  anon: ["y"],
  anor: ["a", "e"],
  ant: ["a", "e", "h", "i", "l", "o", "r", "w"],
  anta: ["c", "g", "r"],
  ante: ["a", "b", "c", "d", "l", "m", "n", "p", "r"],
  antec: ["e", "h"],
  anted: ["a", "i"],
  anten: ["a", "n"],
  anter: ["i", "o"],
  anth: ["e", "i", "o", "r"],
  anthe: ["m", "r"],
  antho: ["c", "l"],
  anthr: ["a", "o"],
  anti: [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
  ],
  antib: ["a", "i", "o"],
  antic: ["h", "i", "l", "o", "y"],
  antid: ["e", "o"],
  antig: ["e", "r"],
  antih: ["e", "i", "y"],
  antim: ["a", "e", "i", "o"],
  antin: ["e", "o"],
  antip: ["a", "e", "h", "o", "r", "y"],
  antiq: ["u"],
  antis: ["e", "l", "o", "p"],
  antit: ["h", "o", "r", "y"],
  any: ["b", "h", "o", "p", "t", "w"],
  anyt: ["h", "i"],
  anyw: ["a", "h", "i"],
  ao: ["r", "u"],
  ap: [
    "b",
    "c",
    "o",
    "a",
    "e",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
  ],
  apa: ["c", "n", "r", "t"],
  apac: ["e", "h"],
  apat: ["h", "i"],
  apath: ["e", "y"],
  ape: ["a", "n", "r", "x"],
  aper: ["c", "i", "t"],
  aperi: ["e", "t"],
  aph: ["a", "e", "i", "o", "r"],
  aphi: ["d", "s"],
  apho: ["r", "t"],
  api: ["a", "c", "e", "s"],
  apic: ["a", "e", "u"],
  apl: ["a", "e", "o"],
  apn: ["e", "o"],
  apo: ["c", "g", "l", "p", "r", "s", "t"],
  apoc: ["a", "h", "r", "y"],
  apol: ["i", "l", "o", "u"],
  apop: ["h", "l"],
  app: ["a", "e", "i", "l", "o", "r", "t", "u"],
  appa: ["l", "n", "r"],
  appal: ["a", "l", "o"],
  appar: ["a", "e", "i"],
  appe: ["a", "l", "n", "r", "t"],
  appea: ["l", "r", "s"],
  apper: ["c", "t"],
  appet: ["e", "i"],
  appl: ["a", "e", "i", "y"],
  apple: ["c", "j", "s"],
  appli: ["a", "c", "e", "q"],
  appo: ["i", "m", "r", "s"],
  appos: ["e", "i"],
  appr: ["a", "e", "i", "o"],
  appre: ["c", "h", "n"],
  appro: ["a", "b", "p", "v", "x"],
  apr: ["e", "i", "o"],
  apri: ["c", "l"],
  april: [],
  apro: ["n", "p"],
  apron: ["e"],
  apt: ["i", "l", "n"],
  ar: [
    "c",
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "y",
  ],
  ara: ["b", "c", "g", "m"],
  arab: ["e", "i", "l"],
  arabi: ["a", "c", "s"],
  arb: ["a", "i", "o", "u"],
  arbo: ["r", "u"],
  arbor: ["e", "i"],
  arbu: ["s", "t"],
  arc: ["a", "h", "k", "t", "u"],
  arca: ["d", "n"],
  arcad: ["e", "i"],
  arch: ["a", "b", "d", "e", "f", "i", "l", "o", "w"],
  archa: ["e", "i", "n"],
  archd: ["e", "i", "u"],
  arche: ["d", "n", "o", "r", "s", "t"],
  archi: ["m", "n", "p", "t", "v"],
  arck: ["e", "i"],
  ard: ["e", "o", "u"],
  ardo: ["r", "u"],
  are: ["a", "n", "o", "s", "t"],
  area: ["l", "w"],
  aren: ["a"],
  arena: [],
  arg: ["e", "i", "o", "u", "y"],
  argo: ["n", "s", "t"],
  argon: ["a"],
  argu: ["a", "e", "m", "s"],
  argy: ["l"],
  ari: ["a", "d", "e", "g", "s", "t", "z"],
  aria: ["n"],
  arian: ["i"],
  arid: ["i"],
  aris: ["e", "t"],
  arise: ["n"],
  ariz: ["o"],
  ark: ["a"],
  arm: ["a", "b", "c", "e", "f", "h", "i", "l", "o", "p", "r", "s", "y"],
  arma: ["d", "g", "m", "t"],
  armad: ["a", "i"],
  arme: ["d", "n"],
  armed: [],
  armi: ["l", "n", "s"],
  armo: ["r", "u"],
  armor: ["e", "i", "y"],
  arms: [],
  army: ["w"],
  aro: ["m", "s", "u"],
  arou: ["n", "s"],
  arous: ["a", "e"],
  arr: ["a", "e", "h", "i", "o"],
  arra: ["c", "i", "n", "s", "y"],
  arran: ["g", "t"],
  arre: ["a", "s"],
  arro: ["g", "n", "w"],
  arrow: ["h", "r"],
  ars: ["e", "o"],
  arse: ["n"],
  arsen: ["a", "i"],
  art: ["e", "f", "h", "i", "l", "s", "y"],
  arte: ["f", "m", "r", "s"],
  arter: ["i", "y"],
  arth: ["r", "u"],
  arthr: ["i", "o"],
  arti: ["c", "f", "l", "s"],
  artic: ["h", "l", "u"],
  artif: ["a", "i"],
  artis: ["a", "t"],
  arts: ["y"],
  arty: [],
  as: ["b", "c", "e", "h", "i", "k", "l", "o", "p", "s", "t", "u", "y"],
  asc: ["e", "i", "o", "r"],
  asce: ["n", "r", "t"],
  ascen: ["d", "s", "t"],
  asco: ["r", "t"],
  ase: ["p", "x"],
  asep: ["s", "t"],
  ash: ["a", "b", "c", "e", "l", "o", "r", "t", "y"],
  ashe: ["n", "s"],
  asi: ["a", "d", "n"],
  asia: ["n", "t"],
  ask: ["a", "e", "i"],
  asl: ["a", "e"],
  asp: ["a", "e", "h", "i"],
  aspe: ["c", "n", "r"],
  asper: ["i", "s"],
  asph: ["a", "o", "y"],
  aspi: ["c", "r"],
  aspir: ["a", "e", "i"],
  ass: ["a", "e", "i", "n", "o", "t", "u", "y"],
  assa: ["i", "s", "u", "y"],
  assay: ["e", "i"],
  asse: ["m", "n", "r", "s", "t", "v"],
  asset: ["s"],
  assi: ["d", "g", "m", "s", "z"],
  asso: ["c", "n", "r"],
  assoc: ["i"],
  assu: ["a", "m", "r"],
  assum: ["e", "i", "p"],
  assur: ["a", "e", "i"],
  ast: ["a", "e", "h", "i", "o", "r", "u"],
  asti: ["g", "r"],
  asto: ["n", "u"],
  astr: ["a", "i", "o"],
  astra: ["d", "g", "k", "l", "y"],
  astri: ["d", "n"],
  astro: ["b", "d", "g", "l", "m", "n", "p"],
  asy: ["l", "m", "n"],
  asym: ["m", "p"],
  at: ["v", "a", "c", "e", "h", "i", "l", "m", "o", "r", "t", "w", "y"],
  ata: ["l", "v", "x"],
  ate: ["l"],
  ath: ["e", "i", "l", "w"],
  athe: ["i", "n", "r"],
  athen: ["a", "e", "i", "s"],
  ati: ["l", "s"],
  atl: ["a"],
  atla: ["n", "s"],
  atm: ["a", "o"],
  ato: ["l", "m", "n", "p"],
  atom: ["i"],
  atomi: ["c", "s", "z"],
  aton: ["a", "e", "y"],
  atone: ["m"],
  atr: ["e", "i", "o"],
  atri: ["a", "u"],
  atro: ["c", "p"],
  atrop: ["h", "i", "o"],
  att: ["a", "e", "i", "o", "r", "u", "y"],
  atta: ["b", "c", "i", "r"],
  attac: ["h", "k"],
  atte: ["m", "n", "s"],
  atten: ["d", "t", "u"],
  atti: ["c", "r", "t"],
  attic: ["a"],
  attr: ["a", "i"],
  attri: ["b", "t"],
  au: ["s", "b", "c", "d", "g", "k", "l", "n", "r", "s", "t", "x"],
  aub: ["e", "r", "u"],
  auc: ["o", "t"],
  aud: ["a", "i"],
  audi: ["b", "e", "o", "t"],
  audib: ["i", "l"],
  audio: ["l", "m", "p", "t", "v"],
  audit: ["i", "o"],
  aug: ["e", "h", "m", "u"],
  augu: ["r", "s"],
  augur: ["y"],
  aur: ["a", "e", "i", "o"],
  aura: ["l"],
  aure: ["a", "o"],
  aureo: ["l", "m"],
  auri: ["c", "f"],
  auric: ["l", "u"],
  auro: ["c", "r"],
  aus: ["c", "p", "s", "t"],
  aust: ["e", "r"],
  austr: ["a", "i", "o"],
  aut: ["a", "h", "i", "o", "u"],
  auth: ["e", "o"],
  auto: ["b", "c", "d", "e", "g", "h", "i", "m", "n", "p", "s", "t"],
  autob: ["a", "i"],
  autoc: ["e", "h", "l", "r"],
  autog: ["e", "i", "r", "y"],
  autoh: ["a", "y"],
  autom: ["a", "o"],
  autop: ["i", "s"],
  autos: ["t", "u"],
  aux: ["i"],
  auxi: ["l", "n"],
  av: ["a", "d", "e", "g", "i", "o", "u"],
  ava: ["i", "l", "n", "r", "s", "t"],
  ave: ["n", "r"],
  aven: ["g", "u"],
  aver: ["a", "s", "t"],
  avers: ["e", "i"],
  avert: ["e"],
  avi: ["a", "d", "f", "o", "t"],
  avia: ["n", "r", "t"],
  aviat: ["i", "o", "r"],
  avid: ["i"],
  avo: ["c", "i", "n", "u", "w"],
  avoc: ["a", "e"],
  avoca: ["d", "t"],
  avoi: ["d", "r"],
  avoid: ["a"],
  avow: ["a", "e"],
  avu: ["l", "n"],
  aw: ["a", "e", "f", "h", "k", "l", "n", "o", "r"],
  awa: ["i", "k", "r", "s", "y"],
  awar: ["d", "e"],
  aware: ["n"],
  awe: ["a", "i", "s"],
  awes: ["o", "t"],
  ax: ["e", "i", "l", "o"],
  axe: ["s"],
  axi: ["a", "l", "o", "s"],
  axo: ["l", "n"],
  axon: ["e"],
  ay: ["a", "e", "r"],
  aye: [],
  az: ["a", "i", "o", "t", "u"],
  azo: ["r", "v"],
  b: [
    "b",
    "c",
    "l",
    "m",
    "t",
    "w",
    "a",
    "b",
    "d",
    "e",
    "h",
    "i",
    "k",
    "l",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
    "x",
    "y",
  ],
  ba: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  baa: ["l"],
  bab: ["a", "b", "e", "o", "u", "y"],
  babb: ["i", "l"],
  babe: ["l"],
  baby: ["h", "i", "l", "s"],
  bac: ["c", "h", "i", "k", "o", "t"],
  bacc: ["a", "h", "y"],
  bacca: ["l", "r"],
  bacch: ["a", "u"],
  bach: ["e"],
  back: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
    "y",
  ],
  backb: ["e", "i", "o", "r", "u"],
  backc: ["h", "l", "o"],
  backd: ["a", "o", "r"],
  backe: ["d", "r"],
  backg: ["a", "r"],
  backl: ["a", "e", "o"],
  backp: ["a", "e"],
  backr: ["e", "o"],
  backs: ["a", "e", "i", "l", "p", "t", "w"],
  backt: ["a", "r"],
  backw: ["a", "o"],
  bact: ["e", "r"],
  bad: ["d", "e", "g", "i", "l", "m", "n"],
  badd: ["e", "i", "y"],
  badl: ["a", "y"],
  badly: [],
  badm: ["a", "i", "o"],
  bag: ["a", "d", "e", "f", "g", "h", "m", "p", "s", "u"],
  baga: ["s", "t"],
  bagg: ["a", "i", "y"],
  bah: ["a"],
  baha: ["i", "m"],
  bai: ["k", "l", "r", "t", "z"],
  bail: ["e", "i", "o", "s"],
  baile: ["r", "y"],
  baili: ["f", "w"],
  bak: ["e", "i", "l", "s"],
  bake: ["d", "l", "r"],
  baker: ["s", "y"],
  bal: ["a", "b", "c", "d", "e", "i", "k", "l", "m", "o", "s", "t", "u", "z"],
  bala: ["c", "k", "l", "n"],
  balb: ["o", "r"],
  bald: ["a", "e", "h", "i", "l", "n", "p", "r"],
  bale: ["e", "f"],
  balk: ["a", "y"],
  ball: ["a", "c", "e", "i", "o", "p", "r", "u", "y"],
  balla: ["d", "s"],
  balle: ["r", "t"],
  ballo: ["o", "t"],
  ballp: ["a", "l", "o"],
  bally: ["h"],
  balm: ["y"],
  bam: ["b"],
  bamb: ["i", "o"],
  ban: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "j",
    "k",
    "n",
    "q",
    "s",
    "t",
    "y",
    "z",
  ],
  bana: ["l", "n"],
  banal: ["i"],
  band: ["a", "b", "e", "i", "m", "o", "s", "w", "y"],
  banda: ["g", "n"],
  bande: ["a", "r"],
  bandi: ["c", "t"],
  bands: ["m", "t"],
  bandy: [],
  bane: ["f"],
  bang: ["e", "i", "k", "l"],
  bangl: ["a", "e"],
  bani: ["a", "s"],
  banis: ["h", "t"],
  bank: ["a", "b", "c", "e", "i", "n", "r", "s"],
  bankr: ["o", "u"],
  bann: ["e", "i", "o", "s"],
  bant: ["a", "e", "l", "u"],
  bap: ["t"],
  bapt: ["i"],
  bapti: ["s", "z"],
  bar: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "q",
    "r",
    "s",
    "t",
  ],
  barb: ["a", "e", "i"],
  barba: ["d", "r"],
  barbe: ["c", "d", "l", "r"],
  barbi: ["c", "t"],
  barc: ["a", "e"],
  bard: ["i"],
  bare: ["b", "f", "h", "l", "n"],
  baref: ["a", "o"],
  bareh: ["a", "e"],
  barel: ["e", "y"],
  barg: ["a", "e"],
  barge: ["m", "p"],
  bari: ["t", "u"],
  bark: ["e"],
  barke: ["e", "n", "r"],
  barm: ["a", "y"],
  barma: ["i", "n"],
  barn: ["a", "s", "y"],
  baro: ["g", "m", "n", "q", "u"],
  baron: ["e", "i", "y"],
  barq: ["e", "u"],
  barr: ["a", "e", "i", "o"],
  barra: ["c", "g", "t"],
  barre: ["d", "l", "n", "t"],
  barri: ["c", "e", "n", "o", "s"],
  barro: ["o", "w"],
  bart: ["e", "l"],
  barte: ["n", "r"],
  bas: ["a", "e", "h", "i", "k", "q", "s", "t"],
  base: ["b", "d", "l", "m", "n", "s"],
  baseb: ["a", "o"],
  basel: ["e", "i"],
  basem: ["a", "e"],
  basen: ["e", "j"],
  bash: ["f"],
  basi: ["c", "l", "n", "s"],
  basic: ["a", "s"],
  basil: ["a", "i"],
  bask: ["e"],
  bass: ["e", "i", "o", "w"],
  bassi: ["n", "s"],
  basso: ["o"],
  bast: ["a", "e", "i"],
  basti: ["l", "n", "o"],
  bat: ["c", "e", "h", "i", "m", "o", "r", "s", "t"],
  bate: ["a", "d"],
  bath: ["e", "h", "i", "m", "o", "r", "t", "y"],
  bathe: ["r", "t"],
  bathy: ["m", "s"],
  bati: ["k", "s"],
  bats: ["m"],
  batt: ["a", "e", "i", "l", "y"],
  batte: ["n", "r"],
  bau: ["b", "l", "x"],
  baw: ["d", "l"],
  bawd: ["i", "y"],
  bay: ["b", "e", "m", "o", "s"],
  bayo: ["n", "u"],
  baz: ["a", "o"],
  baza: ["a", "r"],
  bb: ["b", "c", "l"],
  bd: ["r"],
  be: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "l",
    "m",
    "n",
    "q",
    "r",
    "s",
    "t",
    "v",
    "w",
    "y",
    "z",
  ],
  bea: ["c", "d", "g", "k", "m", "n", "r", "s", "t", "u", "v"],
  beac: ["h", "o"],
  beach: ["c", "e", "h", "w", "y"],
  bead: ["i", "l", "w", "y"],
  beak: ["e"],
  beake: ["d", "r"],
  beam: ["e", "i", "y"],
  bean: ["b", "e", "i", "o", "s"],
  bear: ["a", "b", "d", "e", "i", "s"],
  beard: ["e", "l"],
  beari: ["n", "s"],
  beat: ["e", "i", "n"],
  beate: ["n", "r"],
  beati: ["f", "n", "t"],
  beau: ["f", "g", "t", "x"],
  beaut: ["e", "i", "y"],
  beaux: [],
  bec: ["a", "h", "k", "l", "o"],
  beca: ["l", "m", "u"],
  beck: ["o"],
  bed: ["a", "b", "c", "d", "e", "f", "i", "l", "o", "p", "r", "s", "t", "u"],
  beda: ["u", "z"],
  bedc: ["h", "l"],
  bedd: ["e", "i"],
  bede: ["c", "v", "w"],
  bedf: ["a", "e", "o"],
  bedi: ["m", "z"],
  bedp: ["a", "o"],
  bedr: ["a", "i", "o"],
  bedro: ["c", "l", "o"],
  beds: ["i", "o", "p", "t"],
  bee: ["b", "c", "f", "h", "k", "l", "n", "p", "r", "s", "t", "v"],
  beef: ["c", "e", "s", "y"],
  beel: ["i", "z"],
  beep: ["e"],
  beer: ["h", "y"],
  beet: ["h", "l", "r"],
  beetl: ["e", "i"],
  bef: ["a", "i", "o", "r", "u"],
  befo: ["g", "r", "u"],
  beg: ["a", "e", "g", "i", "o", "r", "u"],
  begg: ["a", "i"],
  bego: ["n", "t"],
  begon: ["e", "i"],
  begot: ["t"],
  begr: ["i", "u"],
  begu: ["i", "n"],
  begui: ["l", "n"],
  beh: ["a", "e", "i", "o"],
  beha: ["l", "v"],
  behav: ["e", "i"],
  behe: ["a", "l", "m", "s"],
  beho: ["l", "o", "v"],
  behoo: ["f", "v"],
  bei: ["g", "n", "r"],
  bel: ["a", "c", "d", "e", "f", "g", "i", "l", "o", "t", "u", "v"],
  bela: ["b", "t", "y"],
  belay: ["i"],
  belf: ["a", "r"],
  belg: ["i", "r"],
  belgi: ["a", "u"],
  beli: ["a", "e", "k", "s", "t"],
  belie: ["f", "v"],
  bell: ["a", "b", "e", "f", "h", "i", "m", "o", "s", "w", "y"],
  belle: ["d", "s"],
  belli: ["c", "e", "g"],
  belly: ["a", "b", "f"],
  belo: ["n", "v", "w"],
  belt: ["e", "i", "w"],
  bem: ["a", "i", "o", "u"],
  ben: ["c", "d", "e", "g", "i", "j", "t", "u", "z"],
  bend: ["a", "e", "i", "y"],
  bende: ["d", "r"],
  bene: ["a", "d", "f", "l", "v"],
  benef: ["a", "i"],
  beni: ["g", "n", "s"],
  benig: ["h", "n"],
  bent: ["h", "w"],
  benz: ["e", "i", "o"],
  benze: ["d", "n"],
  benzo: ["a", "i", "l"],
  ber: ["a", "b", "e", "g", "i", "k", "l", "m", "n", "r", "s", "t", "y"],
  bere: ["a", "f", "t"],
  berg: ["a"],
  beri: ["b", "n"],
  berk: ["e"],
  berm: ["u"],
  bern: ["e"],
  bes: ["e", "i", "m", "o", "p", "t"],
  bese: ["e", "t"],
  besee: ["c", "m"],
  beset: ["t"],
  besi: ["d", "e"],
  besm: ["e", "i"],
  beso: ["m", "t", "u"],
  besp: ["a", "e", "o", "r"],
  bespa: ["n", "t"],
  bespe: ["a", "c"],
  bespr: ["e", "i"],
  best: ["i", "o", "r", "s"],
  besti: ["a", "r"],
  bestr: ["e", "i"],
  bet: ["a", "e", "h", "i", "o", "r", "t", "w"],
  beta: ["k"],
  beth: ["e", "i", "l", "o"],
  beti: ["d", "m"],
  beto: ["k", "o"],
  betr: ["a", "o"],
  bett: ["e", "o"],
  betw: ["e", "i"],
  bev: ["e", "y"],
  beve: ["l", "r"],
  bevel: ["e"],
  bew: ["a", "i"],
  bewa: ["i", "r"],
  bewi: ["g", "l", "t"],
  bey: ["o"],
  bez: ["e", "i"],
  bh: ["a", "u"],
  bi: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "w",
    "y",
    "z",
  ],
  bia: ["n", "s", "t", "x"],
  bias: [],
  bib: ["e", "l", "u"],
  bibl: ["e", "i"],
  bibli: ["c", "o"],
  bic: ["a", "e", "h", "k", "o", "u", "y"],
  bica: ["m", "r"],
  bice: ["n", "p"],
  bico: ["l", "n", "r"],
  bicon: ["c", "v"],
  bicu: ["l", "s"],
  bid: ["d", "e", "i"],
  bidd: ["a", "e", "i", "y"],
  bidde: ["n", "r"],
  bide: ["t"],
  bie: ["n", "r"],
  bif: ["f", "o", "u"],
  bifo: ["c", "l"],
  big: ["a", "e", "g", "h", "m", "n", "o", "w"],
  bigg: ["e", "i"],
  bigge: ["r", "s"],
  bigh: ["e", "o", "t"],
  bik: ["e", "i"],
  bike: ["w"],
  bil: ["a", "b", "e", "g", "i", "k", "l"],
  bila: ["b", "t"],
  bili: ["a", "n", "o", "r"],
  bilk: ["e"],
  bill: ["a", "b", "e", "f", "h", "i", "o", "p", "s", "y"],
  bille: ["d", "t"],
  billf: ["i", "o"],
  billh: ["e", "o"],
  billi: ["a", "n", "o"],
  billy: ["o"],
  bim: ["a", "e", "o"],
  bin: ["a", "d", "g", "n", "o"],
  bina: ["r", "u"],
  bind: ["e", "i", "w"],
  bing: ["e", "o"],
  bino: ["c", "m"],
  bio: ["a", "c", "d", "g", "l", "m", "n", "p", "s", "t"],
  bioc: ["h", "i", "l"],
  biod: ["e", "y"],
  biog: ["e", "r"],
  bioge: ["n", "o"],
  bios: ["a", "c", "p", "t"],
  biot: ["e", "i"],
  biote: ["c", "l"],
  bioti: ["c", "n", "t"],
  bip: ["a", "e", "l", "o"],
  bir: ["a", "c", "d", "e", "m", "o", "t"],
  bird: ["b", "c", "d", "e", "h", "i", "l", "m", "s"],
  birdb: ["a", "r"],
  birdi: ["e", "n"],
  bis: ["c", "e", "h", "m", "o", "q", "t"],
  bise: ["c", "r", "x"],
  bit: ["c", "e", "i", "t", "u"],
  bite: ["r"],
  bitt: ["e", "y"],
  bitte: ["n", "r"],
  biv: ["a", "o"],
  bk: ["t"],
  bl: ["t", "a", "d", "e", "i", "k", "o", "u", "v"],
  bla: ["b", "c", "d", "e", "i", "k", "m", "n", "r", "s", "t", "z"],
  blab: ["b"],
  blad: ["d", "e"],
  blade: ["d"],
  blan: ["c", "d", "k"],
  blanc: ["h", "m"],
  bland: ["i", "l", "n"],
  blank: ["e", "l", "n"],
  blar: ["e", "n"],
  blas: ["e", "p", "t"],
  blast: ["e", "i", "o"],
  blat: ["a", "h"],
  blaz: ["e", "i", "o"],
  blaze: ["r"],
  bld: ["g", "r"],
  ble: ["a", "d", "e", "m", "n", "p", "s", "w"],
  blea: ["c", "k", "r", "t"],
  blear: ["y"],
  bleat: ["i"],
  blee: ["d", "p"],
  bleed: ["e", "i"],
  blen: ["c", "d", "t"],
  blend: ["e", "i"],
  bles: ["s", "t"],
  bless: ["e", "i"],
  bli: ["g", "m", "n", "p", "s", "t", "z"],
  blim: ["e", "p"],
  blin: ["d", "k"],
  blind: ["e", "f", "i", "l", "m", "n"],
  blink: ["e", "i"],
  blis: ["s", "t"],
  bliss: ["f"],
  blit: ["h", "z"],
  blitz: ["k"],
  blo: ["a", "b", "c", "n", "o", "s", "t", "u", "w"],
  bloc: ["k"],
  block: ["a", "b", "h", "i"],
  bloo: ["d", "m", "p"],
  blood: ["b", "c", "e", "h", "i", "l", "m", "r", "s", "t", "y"],
  bloom: ["e", "i"],
  blot: ["c", "t"],
  blott: ["e", "i", "o"],
  blow: ["e", "f", "g", "h", "l", "n", "o", "p", "s", "t", "u", "y", "z"],
  blowh: ["a", "o"],
  blu: ["b", "c", "d", "e", "f", "i", "n", "r", "s"],
  blue: ["b", "c", "f", "g", "i", "j", "n", "p", "s", "t"],
  blueb: ["e", "i", "l", "o"],
  blueg: ["i", "r"],
  bluei: ["n", "s"],
  bluen: ["e", "o"],
  blui: ["n", "s"],
  blun: ["d", "t"],
  blunt: ["l", "n"],
  blur: ["b", "r", "t"],
  blus: ["h", "t"],
  blush: ["e", "i"],
  bm: ["r"],
  bn: ["a"],
  bo: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
  ],
  boa: ["r", "s", "t"],
  boar: ["d"],
  board: ["e", "i", "r", "w"],
  boat: ["e", "h", "i", "m", "s"],
  boate: ["l", "r"],
  bob: ["b", "c", "o", "s", "t", "w"],
  bobb: ["e", "i", "l", "y"],
  bobby: ["s"],
  boc: ["c", "k"],
  bocc: ["a", "e", "i"],
  bod: ["e", "h", "i", "k", "y"],
  bodi: ["c", "e", "l", "n"],
  bodil: ["e", "i", "y"],
  body: ["g", "l", "w"],
  bog: ["e", "g", "i", "o", "u", "y"],
  bogg: ["l", "y"],
  boi: ["l", "s"],
  boil: ["a", "e", "i"],
  boile: ["d", "r"],
  bois: ["e", "t"],
  bol: ["a", "d", "e", "i", "l", "o", "s", "t", "u"],
  bola: ["s"],
  bold: ["f", "l", "n"],
  bole: ["r"],
  boli: ["d", "v"],
  boliv: ["a", "i"],
  boll: ["a", "w"],
  bolo: ["g", "n"],
  bols: ["h", "t"],
  bolsh: ["e", "y"],
  bolt: ["i"],
  bom: ["b"],
  bomb: ["a", "e", "p", "s"],
  bomba: ["r", "s", "y"],
  bombs: ["h", "i"],
  bon: [
    "a",
    "b",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "n",
    "s",
    "u",
    "y",
    "z",
  ],
  bona: ["n"],
  bond: ["a", "e", "h", "s"],
  bone: ["b", "d", "f", "h", "l", "r", "s"],
  bones: ["e", "h"],
  bong: ["o"],
  bongo: [],
  boni: ["n", "t"],
  bonin: ["g"],
  bonn: ["e", "i", "y"],
  boo: ["b", "d", "g", "h", "k", "m", "n", "r", "s", "t", "z"],
  boob: ["o", "s", "y"],
  booby: [],
  book: ["a", "b", "c", "e", "i", "k", "l", "m", "p", "r", "s", "w"],
  booke: ["d", "n", "r"],
  booki: ["e", "n", "s"],
  bookm: ["a", "o"],
  books: ["e", "h", "t"],
  boom: ["e", "i", "t"],
  boon: ["d"],
  boor: ["i"],
  boot: ["b", "e", "h", "i", "j", "l", "m", "s", "y"],
  boote: ["d", "e", "r"],
  bootl: ["a", "e", "i"],
  boots: ["t"],
  booz: ["e", "y"],
  booze: ["r"],
  bor: ["a", "d", "e", "i", "n", "o", "r", "s", "t", "z"],
  bora: ["c", "g", "t", "x"],
  bore: ["a", "d", "r"],
  bori: ["c", "n"],
  boric: [],
  born: ["e"],
  borne: ["o"],
  boro: ["n", "u"],
  bors: ["c", "t"],
  bos: ["h", "k", "o", "p", "s", "t", "u"],
  boso: ["m", "n"],
  bosom: ["y"],
  boss: ["a", "e", "i", "y"],
  bossi: ["n", "s"],
  bot: ["a", "c", "f", "h", "s", "t", "u"],
  both: ["e"],
  bots: ["w"],
  bott: ["i", "l", "o"],
  bottl: ["e", "i"],
  bou: ["c", "d", "f", "g", "i", "l", "n", "q", "r", "t"],
  boug: ["a", "h"],
  bough: ["t"],
  boul: ["d", "e"],
  boule: ["v"],
  boun: ["c", "d", "t"],
  bounc: ["e", "i", "y"],
  bound: ["a", "e", "i", "l"],
  bount: ["e", "i", "y"],
  bour: ["b", "g", "n", "s"],
  bourn: ["e"],
  bout: ["i", "o"],
  bow: ["d", "e", "h", "i", "k", "l", "m", "s", "t", "w"],
  bowe: ["d", "l", "r"],
  bowel: [],
  bower: ["b"],
  bowi: ["e", "n"],
  bowl: ["d", "e", "f", "i", "s"],
  bowle: ["g", "r"],
  bows: ["e", "h", "p", "t"],
  box: ["c", "e", "f", "i", "l", "w", "y"],
  boy: ["a", "c", "f", "h", "i", "l", "s"],
  br: ["a", "e", "i", "o", "u", "y"],
  bra: [
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "k",
    "m",
    "n",
    "s",
    "t",
    "v",
    "w",
    "y",
    "z",
  ],
  brac: ["e", "h", "i", "k", "t"],
  brace: ["l", "r"],
  brack: ["e", "i"],
  brad: ["a"],
  brag: ["g"],
  brai: ["d", "l", "n", "s"],
  braid: ["i"],
  brain: ["c", "e", "i", "l", "p", "s", "t", "w", "y"],
  bran: ["c", "d", "t"],
  brand: ["e", "i", "y"],
  bras: ["h", "i", "s"],
  brasi: ["e", "l"],
  brass: ["e", "i", "y"],
  brav: ["a", "e", "i", "o", "u"],
  brave: ["l", "r"],
  braw: ["l", "n"],
  brawl: ["e", "i"],
  brawn: ["i", "y"],
  bray: ["e", "i"],
  braz: ["e", "i"],
  braze: ["n"],
  brazi: ["e", "l"],
  bre: ["a", "c", "d", "e", "m", "r", "t", "v", "w"],
  brea: ["c", "d", "k", "m", "s", "t"],
  bread: ["b", "c", "e", "f", "s", "t", "w"],
  break: ["a", "d", "e", "f", "i", "n", "o", "t", "u", "w"],
  bree: ["c", "d", "z"],
  breed: ["e", "i"],
  breez: ["e", "i", "y"],
  bret: ["h", "o"],
  brev: ["e", "i"],
  breve: ["t"],
  brevi: ["a", "t"],
  brew: ["e", "i"],
  bri: ["a", "b", "c", "d", "e", "g", "l", "m", "n", "o", "q", "s", "t"],
  bric: ["k"],
  brick: ["b", "f", "l", "w", "y"],
  brid: ["a", "e", "g", "l"],
  bride: ["g", "s"],
  bridg: ["e", "i"],
  brie: ["f", "r"],
  brief: ["c", "i", "l", "n"],
  brier: [],
  brig: ["a", "h"],
  briga: ["d", "n"],
  brim: ["f", "m", "s"],
  brimm: ["e", "i"],
  brin: ["d", "e", "g", "k", "y"],
  bring: ["e"],
  brink: ["m"],
  brio: ["c"],
  bris: ["b", "k", "l", "t"],
  brisk: ["e", "l", "n"],
  brist: ["l", "o"],
  brit: ["a", "c", "i", "o", "t"],
  brita: ["i", "n"],
  briti: ["c", "s"],
  britt: ["a", "l"],
  bro: ["a", "b", "c", "g", "i", "k", "m", "n", "o", "s", "t", "u", "w"],
  broa: ["c", "d"],
  broad: ["b", "c", "e", "l", "m", "n", "s", "t", "w"],
  broc: ["a", "c", "h"],
  broch: ["e", "u"],
  brog: ["a", "u"],
  brogu: ["e", "i"],
  broi: ["d", "l"],
  broil: ["e", "i"],
  brom: ["e", "i"],
  bromi: ["d", "n"],
  bron: ["c", "t", "x", "z"],
  bronc: ["h", "o"],
  bronx: [],
  bronz: ["e", "i", "y"],
  broo: ["c", "d", "k", "m"],
  brood: ["e", "y"],
  brook: ["l"],
  broom: ["s"],
  brou: ["g", "h"],
  brow: ["b", "n", "s"],
  brown: ["b", "i", "n", "o", "s"],
  brows: ["e", "i"],
  bru: ["i", "n", "s", "t"],
  brui: ["n", "s", "t"],
  bruis: ["e", "i"],
  brun: ["c", "e", "s", "t"],
  brus: ["h", "q", "s"],
  brush: ["i", "w", "y"],
  brut: ["a", "e", "i", "u"],
  bt: ["u", "w", "r", "u"],
  btu: [],
  bu: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "r",
    "s",
    "t",
    "x",
    "y",
    "z",
  ],
  bub: ["b", "o"],
  bubo: ["n"],
  buc: ["c", "h", "k", "o"],
  buck: ["b", "e", "h", "i", "l", "r", "s", "t", "w"],
  bucke: ["d", "t", "y"],
  bucki: ["n", "s"],
  buckl: ["e", "i"],
  bucks: ["a", "h", "k"],
  bud: ["a", "d", "g"],
  budd: ["h", "i", "y"],
  buddh: ["a", "i"],
  budg: ["e", "i"],
  budge: ["r", "t"],
  bug: ["a", "b", "g", "h", "l", "r", "s"],
  bugg: ["e", "y"],
  bul: ["b", "g", "i", "k", "l", "r", "w"],
  bulb: ["o", "u"],
  bulg: ["a", "e", "y"],
  bulk: ["h", "y"],
  bull: ["d", "e", "f", "h", "i", "n", "o", "p", "r", "s", "t", "y"],
  bulle: ["d", "t"],
  bullf: ["i", "r"],
  bullh: ["e", "o"],
  bulli: ["o", "s"],
  bully: ["b"],
  bum: ["b", "f", "m", "p"],
  bumb: ["l", "o"],
  bumbl: ["e", "i"],
  bump: ["e", "h", "k", "t", "y"],
  bun: ["c", "d", "g", "i", "k", "n", "s", "t", "y"],
  bunc: ["h", "o"],
  bunch: ["y"],
  bunco: ["m"],
  bund: ["l"],
  bung: ["a", "h", "l"],
  bungl: ["e", "i"],
  bunk: ["e", "h", "o", "u"],
  bunt: ["i"],
  bur: ["b", "d", "e", "g", "i", "l", "m", "n", "p", "r", "s", "t", "u", "y"],
  burb: ["e", "l", "o"],
  burd: ["e", "o"],
  bure: ["a", "t"],
  buret: ["t"],
  burg: ["e", "h", "l", "o", "u"],
  burge: ["o", "r", "s"],
  burgh: ["e"],
  burgl: ["a", "e"],
  burgo: ["m", "o"],
  buri: ["a", "n"],
  burl: ["a", "e", "y"],
  burle: ["s", "y"],
  burm: ["a", "e"],
  burn: ["a", "e", "i", "o", "t"],
  burne: ["d", "r", "t"],
  burni: ["n", "s"],
  burno: ["o", "u"],
  burnt: [],
  burp: [],
  burr: ["o"],
  burro: ["w"],
  burs: ["a", "i", "t"],
  burt: ["h", "o"],
  bus: ["b", "h", "i", "k", "m", "s", "t", "y"],
  busb: ["o", "y"],
  bush: ["e", "i", "m", "w", "y"],
  bushe: ["d", "l"],
  busi: ["l", "n"],
  busin: ["e", "g"],
  busk: ["e", "i"],
  buss: ["e"],
  bust: ["a", "e", "l"],
  bustl: ["e", "i"],
  busy: ["b", "w"],
  but: ["a", "c", "l", "t", "y"],
  butt: ["e", "i", "o", "r"],
  butte: ["r"],
  butto: ["c", "n"],
  buty: ["l", "r"],
  buy: ["e"],
  bw: ["a"],
  by: ["e", "g", "l", "p", "r", "s", "t", "w", "z"],
  bye: ["l"],
  byl: ["a", "i"],
  byp: ["a", "l", "r"],
  bypa: ["s", "t"],
  byr: ["e", "o"],
  byro: ["a", "n"],
  byron: ["i"],
  byw: ["a", "o"],
  c: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "j",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "w",
    "y",
    "z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
    "y",
    "z",
  ],
  ca: [
    "b",
    "f",
    "p",
    "t",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  cab: ["a", "b", "d", "e", "i", "l", "o", "r"],
  caba: ["l", "n", "r"],
  cabal: ["i", "l"],
  cabb: ["a", "i", "y"],
  cabl: ["e", "i"],
  cable: ["d", "g", "s"],
  cabo: ["c", "o"],
  caboo: ["d", "s"],
  cac: ["a", "c", "h", "k", "o", "t"],
  cach: ["a", "e", "o"],
  cache: ["p", "t"],
  cad: ["a", "d", "e", "g", "i", "m", "r", "u"],
  cada: ["s", "v"],
  cadd: ["i", "y"],
  caddi: ["e", "s"],
  cade: ["n", "t"],
  caden: ["c", "z"],
  cadet: ["t"],
  cae: ["c", "s"],
  caes: ["a", "i", "u"],
  caf: ["e", "f", "t"],
  cafe: ["t"],
  cag: ["e", "i", "y"],
  cage: ["d", "l", "r", "y"],
  cagi: ["l", "n"],
  cai: ["m", "n", "q", "r", "s", "t"],
  cair: ["n", "o"],
  caj: ["o", "u"],
  cal: ["a", "c", "d", "e", "f", "i", "k", "l", "m", "o", "u", "v", "y"],
  cala: ["b", "d", "i", "m"],
  calab: ["a", "o"],
  calc: ["a", "i", "u"],
  calci: ["c", "f", "m", "n", "t", "u"],
  calcu: ["l", "t"],
  cald: ["e", "r"],
  cale: ["d", "n"],
  calf: ["s"],
  cali: ["b", "c", "f", "p", "s"],
  calib: ["e", "r"],
  calif: ["a", "o"],
  calip: ["e", "h"],
  call: ["a", "b", "e", "i", "o", "u"],
  callb: ["a", "o"],
  calli: ["g", "n", "o", "p", "s"],
  callo: ["s", "u", "w"],
  calm: ["e", "l", "n"],
  calo: ["m", "r"],
  calor: ["g", "i"],
  calv: ["a", "e", "i"],
  calve: ["s"],
  calvi: ["n", "t"],
  caly: ["c", "p", "x"],
  cam: ["a", "b", "e", "i", "o", "p", "s"],
  camb: ["e", "i", "o", "r"],
  came: ["l", "m", "o", "r"],
  camel: ["b", "h", "l", "o", "s"],
  camer: ["a", "o"],
  cami: ["s"],
  camo: ["m", "u"],
  camp: ["a", "e", "f", "g", "h", "i", "o", "s", "u", "y"],
  campa: ["i", "n"],
  campi: ["n", "o"],
  camps: ["i", "t"],
  can: ["a", "b", "c", "d", "e", "i", "k", "n", "o", "s", "t", "v", "y"],
  cana: ["a", "d", "i", "l", "p", "r", "s"],
  canad: ["a", "i"],
  canal: ["i"],
  canar: ["d", "y"],
  canc: ["a", "e"],
  cance: ["l", "r"],
  cand: ["e", "i", "l", "o", "y"],
  cande: ["l", "s"],
  candi: ["d", "e"],
  cando: ["r", "u"],
  candy: ["t"],
  cane: ["b", "d"],
  cani: ["n", "s"],
  cann: ["a", "e", "i", "o", "u", "y"],
  canna: ["b"],
  canne: ["d", "l", "r"],
  canni: ["b", "l", "n"],
  canno: ["n", "t"],
  cano: ["e", "n", "o", "p"],
  canoe: ["i"],
  canon: ["i"],
  cant: ["a", "e", "h", "i", "l", "o", "r"],
  canta: ["b", "l", "n", "t"],
  cante: ["d", "e", "r"],
  canth: ["a", "u"],
  canti: ["c", "l", "n"],
  canto: ["n", "r"],
  cany: ["o"],
  cap: ["a", "e", "f", "i", "o", "p", "r", "s", "t", "u", "y"],
  capa: ["b", "c", "r"],
  capab: ["i", "l"],
  cape: ["l", "r", "s", "t"],
  capi: ["l", "t"],
  capit: ["a", "o", "u"],
  caps: ["a", "i", "t", "u"],
  capsi: ["c", "z"],
  capst: ["a", "o"],
  capt: ["a", "i", "o", "u"],
  capti: ["o", "v"],
  car: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "w",
    "y",
  ],
  cara: ["b", "c", "f", "m", "p", "t", "v", "w"],
  carac: ["a", "o", "u"],
  carav: ["a", "e"],
  carb: ["i", "o", "u"],
  carbi: ["d", "n"],
  carbo: ["h", "l", "n", "r", "y"],
  carbu: ["n", "r"],
  carc: ["a", "e", "i"],
  card: ["a", "b", "i", "o", "p", "s"],
  cardi: ["a", "g", "n", "o"],
  care: ["e", "f", "l", "s", "t", "w"],
  caree: ["n", "r"],
  caref: ["r", "u"],
  caret: ["a"],
  cari: ["b", "c", "e", "l", "n", "o"],
  carib: ["b", "o"],
  carm: ["a", "e", "i"],
  carn: ["a", "e", "i", "y"],
  carna: ["g", "l", "t"],
  caro: ["b", "l", "m", "t", "u"],
  carol: ["e", "i", "l"],
  carot: ["e", "i"],
  carp: ["a", "e", "i", "o", "u"],
  carpa: ["l", "t"],
  carpe: ["l", "n", "t"],
  carpo: ["o", "r"],
  carr: ["e", "i", "o", "y"],
  carri: ["a", "e", "o"],
  carro: ["m", "t", "u"],
  carry: ["a", "c", "i", "o"],
  cars: ["i", "o"],
  cart: ["a", "e", "h", "i", "l", "o", "r", "w"],
  carte: ["l", "r", "s"],
  carth: ["a", "o", "u"],
  carto: ["g", "n", "o"],
  carv: ["e", "i"],
  carve: ["r"],
  cas: ["a", "c", "e", "h", "i", "k", "p", "q", "s", "t", "u"],
  casa: ["b", "n"],
  casab: ["a", "l"],
  case: ["b", "h", "i", "l", "m", "w"],
  cash: ["b", "e", "i", "m"],
  cask: ["e"],
  cass: ["a", "e", "i", "o"],
  cassa: ["n", "v"],
  casse: ["r", "t"],
  cassi: ["a", "o", "t"],
  casso: ["c", "w"],
  cast: ["a", "e", "i", "l", "o", "r"],
  casta: ["n", "w"],
  caste: ["l", "r"],
  casti: ["g", "l", "n"],
  casto: ["f", "r"],
  casu: ["a", "i"],
  cat: [
    "v",
    "a",
    "b",
    "c",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "s",
    "t",
    "w",
  ],
  cata: ["b", "c", "d", "f", "l", "m", "p", "r", "s", "t", "w"],
  catac: ["h", "l", "o"],
  catal: ["a", "e", "o", "p", "y"],
  catam: ["a", "o"],
  catar: ["a", "r"],
  catb: ["i", "o"],
  catc: ["a", "h"],
  catch: ["a", "e", "i", "m", "p", "u", "w", "y"],
  cate: ["c", "g", "n", "r"],
  cater: ["c", "e", "p", "w"],
  cath: ["a", "e", "o"],
  cathe: ["d", "r", "t"],
  catho: ["d", "l"],
  catn: ["a", "i"],
  cats: ["u"],
  catt: ["a", "i", "l", "y"],
  catti: ["l", "n"],
  catty: ["c"],
  cau: ["c", "d", "g", "l", "s", "t"],
  cauc: ["a", "u"],
  caul: ["d", "i", "k"],
  caus: ["a", "e", "t"],
  causa: ["l", "t"],
  cause: ["l", "r", "w"],
  caut: ["e", "i"],
  cav: ["a", "e", "i", "o", "y"],
  cave: ["a", "m", "r"],
  cavi: ["a", "l", "t"],
  cavil: ["e"],
  cavit: ["a", "y"],
  cay: ["e", "m", "u"],
  cb: ["c"],
  cc: ["t", "w"],
  cd: ["r"],
  ce: ["o", "a", "c", "d", "i", "l", "m", "n", "p", "r", "s", "t", "y"],
  cec: ["r", "u"],
  ced: ["a", "e", "i"],
  cel: ["a", "e", "i", "l", "s", "t"],
  cela: ["d", "n"],
  cele: ["b", "r", "s"],
  celeb: ["e", "r"],
  celer: ["i", "y"],
  celi: ["a", "b"],
  cell: ["a", "b", "i", "o", "u"],
  cello: ["p"],
  celt: ["i"],
  cem: ["b", "e"],
  ceme: ["n", "t"],
  cen: ["o", "s", "t"],
  ceno: ["b", "t", "z"],
  cens: ["e", "o", "u"],
  censu: ["r", "s"],
  cent: ["a", "e", "i", "r", "u"],
  centa: ["u", "v"],
  cente: ["n", "r", "s"],
  centi: ["a", "g", "l", "m", "p"],
  centr: ["a", "e", "i", "o", "u"],
  cer: ["a", "b", "e", "i", "t", "u", "v"],
  cere: ["a", "b", "c", "m", "s", "u"],
  cereb: ["e", "r"],
  cerem: ["e", "o"],
  ceri: ["s", "u"],
  cert: ["a", "i"],
  certi: ["f", "t"],
  ceru: ["l", "m"],
  cerv: ["a", "i"],
  cervi: ["c", "x"],
  ces: ["a", "i", "s"],
  cess: ["a", "i", "p"],
  cessp: ["i", "o"],
  cf: [],
  cg: ["s", "m", "s"],
  ch: ["a", "e", "g", "i", "l", "m", "o", "r", "t", "u"],
  cha: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
  ],
  chaf: ["e", "f", "i"],
  chafe: ["r"],
  chaff: ["e", "i"],
  chai: ["n", "r", "s"],
  chain: ["s"],
  chair: ["l", "m", "p", "w"],
  chal: ["c", "d", "e", "i", "k", "l"],
  chalc: ["e", "o"],
  chalk: ["b", "i", "y"],
  chall: ["a", "e", "i"],
  cham: ["b", "e", "f", "o", "p"],
  chamb: ["e", "r"],
  chamo: ["i", "m"],
  champ: ["a", "i"],
  chan: ["c", "d", "g", "n", "s", "t", "u"],
  chanc: ["e", "y"],
  chand: ["e", "l"],
  chant: ["e", "i", "r", "y"],
  chao: ["s", "t"],
  chap: ["a", "b", "e", "f", "l", "m", "s", "t"],
  chape: ["a", "l", "r"],
  chapl: ["a", "e"],
  char: ["a", "b", "c", "d", "g", "i", "l", "m", "n", "o", "t", "w", "y"],
  chara: ["b", "c", "d"],
  chari: ["l", "o", "s", "t"],
  charl: ["a", "e", "o"],
  charm: ["e", "i", "l"],
  chart: ["e", "r"],
  chary: ["b"],
  chas: ["e", "i", "m", "s", "t", "u"],
  chase: ["r"],
  chast: ["e", "i"],
  chat: ["e", "t"],
  chate: ["a", "l"],
  chatt: ["e", "y"],
  chau: ["c", "f", "v"],
  che: ["a", "c", "d", "e", "f", "l", "m", "n", "q", "r", "s", "v", "w", "y"],
  chea: ["p", "t"],
  cheap: ["e", "j", "l", "n", "s"],
  cheat: ["e"],
  chee: ["k", "p", "r", "s", "t"],
  cheek: ["b", "i", "y"],
  cheer: ["f", "i", "l", "s", "y"],
  chees: ["e", "y"],
  chef: [],
  chem: ["i", "o", "u"],
  chemi: ["c", "s"],
  cher: ["i", "o", "r", "t", "u", "v"],
  chero: ["k", "o"],
  ches: ["h", "s", "t"],
  chess: ["b", "m"],
  chest: ["e", "n", "y"],
  chev: ["a", "i", "r", "v", "y"],
  chew: ["i", "y"],
  chi: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
  ],
  chia: ["n", "r", "s"],
  chic: ["a", "h", "k", "l", "o"],
  chica: ["g", "n"],
  chich: ["a", "i"],
  chick: ["a", "e", "p", "w"],
  chig: ["g", "n", "o"],
  chil: ["b", "d", "e", "i", "l"],
  child: ["b", "e", "h", "i", "l", "p", "r"],
  chile: ["a"],
  chili: [],
  chill: ["e", "i", "y"],
  chim: ["a", "e", "n", "p"],
  chime: ["r"],
  chimp: ["a"],
  chin: ["a", "c", "e", "k", "l", "o", "s", "t", "w"],
  china: ["m", "t", "w"],
  chine: ["s"],
  chino: ["i", "o"],
  chip: ["b", "m", "p", "s"],
  chipp: ["e", "i", "y"],
  chir: ["o", "p", "r"],
  chiro: ["g", "m", "p"],
  chirp: ["i", "y"],
  chirr: ["u"],
  chit: ["c", "i", "l", "t"],
  chiv: ["a", "e", "v", "y"],
  cho: ["c", "i", "k", "l", "m", "n", "o", "p", "r", "s", "w"],
  choc: ["k", "o"],
  chock: ["a", "f"],
  choi: ["c", "r"],
  choir: ["b", "m"],
  chok: ["e", "i", "y"],
  choke: ["c", "r", "y"],
  chol: ["e", "i"],
  chole: ["r", "s"],
  chop: ["f", "h", "i", "p", "s"],
  chopp: ["e", "i", "y"],
  chops: ["t"],
  chor: ["a", "d", "e", "i", "t", "u"],
  chord: ["a"],
  chore: ["a", "o"],
  chori: ["n", "s"],
  chow: ["d", "m"],
  chr: ["i", "o", "y"],
  chro: ["m", "n"],
  chrom: ["a", "e", "i", "o"],
  chron: ["i", "o"],
  chu: ["b", "c", "g", "k", "m", "n", "r", "t"],
  chub: ["b"],
  chubb: ["i", "y"],
  chum: ["m", "p"],
  chur: ["c", "l", "n", "r"],
  churl: ["i"],
  churn: ["i"],
  chut: ["e", "n", "z"],
  ci: [
    "a",
    "d",
    "f",
    "o",
    "p",
    "a",
    "c",
    "d",
    "g",
    "l",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
  ],
  cia: ["o"],
  cic: ["a", "e"],
  cica: ["d", "t"],
  cid: ["e"],
  cin: ["c", "c", "d", "e", "n", "q"],
  cinc: ["h", "i", "t"],
  cinch: ["o"],
  cine: ["m", "r"],
  cio: ["n"],
  cip: ["h"],
  cir: ["c", "q", "r"],
  circ: ["a", "l", "u"],
  circa: ["d", "s"],
  circu: ["i", "l", "m", "s"],
  cirr: ["h", "o", "u"],
  cirro: ["c", "s"],
  cis: ["l", "s", "t"],
  cit: ["a", "e", "i", "r", "y"],
  cita: ["d", "t"],
  citi: ["f", "z"],
  citif: ["i", "y"],
  citr: ["a", "i", "o", "u"],
  citro: ["n", "u"],
  city: [],
  civ: ["e", "i", "v"],
  civi: ["c", "l"],
  civic: ["s"],
  civil: ["i", "l"],
  civv: ["i", "y"],
  cl: ["a", "d", "e", "i", "k", "o", "u"],
  cla: [
    "b",
    "c",
    "d",
    "i",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  clai: ["m", "r"],
  claim: ["a"],
  clam: ["b", "m", "o", "p", "s"],
  clamb: ["a", "e"],
  clamm: ["i", "y"],
  clamo: ["r", "u"],
  clamp: ["d"],
  clan: ["d", "g", "k", "n", "s"],
  clang: ["e", "o"],
  clans: ["m", "w"],
  clap: ["b", "p", "s", "t"],
  clar: ["e", "i", "t"],
  clari: ["f", "n", "o", "t"],
  clas: ["h", "p", "s", "t"],
  clasp: [],
  class: ["i", "l", "m", "r", "y"],
  clat: ["h", "t"],
  clau: ["d", "s"],
  claus: ["e", "t"],
  clav: ["e", "i"],
  clavi: ["c", "e"],
  claw: ["b", "e"],
  clay: ["e", "m"],
  cle: ["a", "f", "m", "n", "o", "p", "r", "v", "w"],
  clea: ["n", "r", "t", "v"],
  clean: ["e", "i", "l", "n", "s", "u"],
  clear: ["a", "e", "h", "i", "l", "n", "w"],
  cleav: ["a", "e"],
  clef: ["t"],
  cleft: [],
  clem: ["a", "e"],
  cler: ["e", "g", "i", "k"],
  cleri: ["c", "h"],
  clerk: ["s"],
  clev: ["e", "i"],
  cleve: ["l", "r"],
  cli: ["c", "e", "f", "m", "n", "p", "q", "t"],
  clic: ["h", "k"],
  click: ["e"],
  clim: ["a", "b", "e"],
  clima: ["c", "t", "x"],
  climb: ["e", "i"],
  clin: ["c", "e", "g", "i", "k", "o"],
  cling: ["i", "y"],
  clini: ["c", "q"],
  clink: ["e"],
  clip: ["b", "p", "s"],
  clipp: ["e", "i"],
  clo: [
    "a",
    "b",
    "c",
    "d",
    "g",
    "i",
    "m",
    "n",
    "p",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  cloa: ["c", "k"],
  cloak: ["i", "r"],
  cloc: ["h", "k"],
  clock: ["w"],
  clod: ["d", "h"],
  clog: ["d", "g"],
  clogg: ["i", "y"],
  clos: ["e", "i", "u"],
  close: ["d", "f", "l", "m", "n", "o", "r", "t"],
  clot: ["h", "t", "u"],
  cloth: ["e", "i"],
  clou: ["d", "t"],
  cloud: ["b", "i", "l", "y"],
  clu: ["b", "c", "e", "m", "n", "s", "t"],
  club: ["b", "f", "h", "r"],
  clubb: ["a", "e"],
  clue: ["l"],
  clum: ["p", "s"],
  clump: ["s", "y"],
  clums: ["i", "y"],
  clun: ["g", "k"],
  clut: ["c", "t"],
  cm: ["d", "l"],
  co: [
    "b",
    "l",
    "r",
    "v",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  coa: ["c", "d", "g", "l", "r", "s", "t", "u", "x"],
  coal: ["b", "e", "f", "i"],
  coale: ["r", "s"],
  coat: ["i", "l", "r", "t"],
  coati: ["n"],
  coax: ["i"],
  coaxi: ["a", "n"],
  cob: ["o", "a", "b", "r", "w"],
  coc: ["a", "c", "h", "k", "o"],
  coca: ["i"],
  cocc: ["u", "y"],
  coch: ["i", "l"],
  cock: ["a", "c", "e", "f", "h", "i", "l", "n", "p", "r", "s", "t", "y"],
  cocka: ["d", "l", "m", "t"],
  cocke: ["d", "r", "y"],
  cocki: ["l", "n"],
  cocks: ["c", "u", "w"],
  cocky: ["l"],
  coco: ["a", "b", "n", "o"],
  cocoa: ["n"],
  cod: ["a", "d", "e", "f", "g", "i", "l", "p"],
  code: ["f", "i", "x"],
  codi: ["c", "f"],
  codif: ["i", "y"],
  coe: ["d", "f", "l", "q", "r", "t", "v", "x"],
  coed: ["u"],
  coel: ["a", "e"],
  coex: ["i", "t"],
  cof: ["e", "f"],
  coff: ["e", "i"],
  coffe: ["e", "r"],
  cog: ["e", "i", "n", "w"],
  cogn: ["a", "i", "o"],
  cogna: ["c", "t"],
  cogni: ["t", "z"],
  cogno: ["m", "s"],
  coh: ["a", "e", "o"],
  cohe: ["i", "r", "s"],
  coho: ["r", "s"],
  coi: ["f", "l", "n", "r", "t"],
  coif: ["e", "f"],
  coiff: ["e", "u"],
  coin: ["a", "c", "e", "s"],
  coit: ["a", "u"],
  col: ["a", "c", "d", "e", "i", "l", "o", "p", "t", "u"],
  cola: ["n"],
  cold: ["h", "l", "n"],
  cole: ["s", "u"],
  coli: ["c", "s", "t"],
  colic: ["k"],
  coll: ["a", "e", "i", "o", "u", "y"],
  colla: ["b", "g", "p", "r", "t"],
  colle: ["a", "c", "e", "g"],
  colli: ["d", "e", "m", "n", "s"],
  collo: ["c", "d", "i", "q"],
  collu: ["d", "s"],
  colo: ["g", "m", "n", "p", "r", "s", "u"],
  colon: ["e", "i", "n", "y"],
  color: ["a", "c", "e", "f", "i", "l"],
  colos: ["s", "t"],
  colt: ["e", "i"],
  com: ["a", "b", "d", "e", "f", "i", "l", "m", "p", "r", "s"],
  coma: ["n", "t"],
  comb: ["a", "e", "i", "o", "u"],
  comd: ["g", "r", "t"],
  come: ["b", "d", "l", "r", "s", "t", "u"],
  comed: ["i", "o", "y"],
  comel: ["i", "y"],
  comes: ["t"],
  comet: ["a"],
  comf: ["o", "r", "y"],
  comi: ["c", "n", "t"],
  comic: ["a"],
  comin: ["f", "g", "t"],
  comm: ["a", "e", "i", "o", "u"],
  comma: ["n"],
  comme: ["m", "n", "r"],
  commi: ["e", "n", "s", "t"],
  commo: ["d", "n", "t"],
  commu: ["n", "t"],
  comp: ["a", "e", "i", "l", "o", "r", "t", "u"],
  compa: ["c", "n", "r", "s", "t"],
  compe: ["e", "l", "n", "r", "t"],
  compl: ["a", "e", "i", "y"],
  compo: ["n", "r", "s", "t", "u"],
  compr: ["e", "i", "o"],
  compt: ["o", "r"],
  compu: ["l", "n", "t"],
  comr: ["a"],
  coms: ["a"],
  con: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "j",
    "k",
    "n",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "y",
  ],
  conc: ["a", "e", "h", "i", "l", "o", "r", "u"],
  conca: ["t", "v"],
  conce: ["a", "d", "i", "l", "n", "p", "r", "s"],
  conch: ["o"],
  conci: ["e", "l", "s"],
  concl: ["a", "u"],
  conco: ["c", "m", "r", "u"],
  concu: ["b", "p", "r", "s"],
  cond: ["e", "i", "o", "u", "y"],
  conde: ["m", "n", "s"],
  condi: ["g", "m", "t"],
  condo: ["l", "m", "n", "r"],
  condu: ["c", "i"],
  cone: ["f", "s", "y"],
  coney: [],
  conf: ["a", "e", "i", "l", "o", "r", "u"],
  confe: ["c", "d", "r", "s", "t"],
  confi: ["d", "g", "n", "r", "s", "t"],
  confl: ["a", "i", "u"],
  confo: ["c", "r", "u"],
  confr: ["a", "e", "o"],
  confu: ["c", "s", "t"],
  cong: ["a", "e", "l", "o", "r"],
  conge: ["a", "e", "n", "r", "s"],
  congr: ["a", "e", "u"],
  coni: ["c", "f"],
  conic: ["a"],
  conj: ["e", "o", "u"],
  conju: ["g", "n", "r"],
  conk: ["e"],
  conke: ["d", "r"],
  conn: ["e", "i", "o", "u"],
  conne: ["c", "x"],
  conni: ["n", "p", "v"],
  conno: ["i", "t"],
  cons: ["a", "c", "e", "i", "o", "p", "t", "u"],
  consc: ["i", "r"],
  conse: ["c", "n", "q", "r"],
  consi: ["d", "g", "l", "s"],
  conso: ["c", "l", "m", "n", "r"],
  consp: ["e", "i"],
  const: ["a", "e", "i", "r"],
  consu: ["b", "l", "m"],
  cont: ["a", "d", "e", "g", "i", "o", "r", "u"],
  conta: ["c", "g", "i", "m"],
  conte: ["m", "n", "r", "s", "x"],
  conti: ["g", "n"],
  conto: ["r", "u"],
  contr: ["a", "e", "i", "o"],
  contu: ["m", "s"],
  conu: ["n", "r"],
  conv: ["a", "e", "i", "o", "u"],
  conve: ["c", "n", "r", "x", "y"],
  convi: ["c", "n", "v"],
  convo: ["c", "k", "l", "y"],
  coo: ["k", "l", "n", "p", "r", "t"],
  cook: ["b", "e", "h", "i", "o", "y"],
  cooke: ["d", "r"],
  cooki: ["e", "n"],
  cool: ["a", "e", "h", "i", "l", "n", "y"],
  cooli: ["e", "n"],
  coon: ["h", "s"],
  coop: ["e", "t"],
  coopt: ["a"],
  coot: ["e", "i"],
  cop: ["a", "e", "i", "o", "p", "r", "s", "t", "u", "y"],
  copa: ["l", "r"],
  cope: ["c", "d", "n", "p", "r"],
  copi: ["e", "l", "n", "o"],
  copo: ["l", "u"],
  copp: ["e", "i"],
  coppe: ["d", "r"],
  cops: ["e"],
  copt: ["e", "i"],
  copy: ["b", "c", "d", "e", "i", "r", "w"],
  copyi: ["n", "s"],
  copyr: ["e", "i"],
  cor: [
    "e",
    "a",
    "b",
    "d",
    "e",
    "g",
    "i",
    "k",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "y",
  ],
  cora: ["c", "l"],
  coral: [],
  cord: ["a", "e", "i", "l", "o", "u"],
  cordi: ["a", "l", "t"],
  cordo: ["b", "n", "v"],
  core: ["l", "r", "s"],
  cori: ["a", "n"],
  cork: ["a", "e", "s", "y"],
  corke: ["d", "r"],
  corm: ["o"],
  corn: ["b", "c", "e", "f", "h", "i", "m", "p", "s", "u", "w", "y"],
  cornb: ["a", "r"],
  cornc: ["o", "r"],
  corne: ["a", "d", "l", "o", "r", "t"],
  cornf: ["i", "l"],
  corni: ["c", "s"],
  corns: ["t"],
  coro: ["l", "n"],
  coron: ["a", "e"],
  corp: ["o", "s", "u"],
  corps: ["e", "m"],
  corpu: ["l", "s"],
  corr: ["a", "e", "i", "o", "u"],
  corre: ["c", "l", "s"],
  corri: ["d", "e", "g"],
  corro: ["b", "d", "s"],
  corru: ["g", "p"],
  cors: ["a", "e", "i"],
  corsa: ["g", "i"],
  corse: ["l", "t"],
  cort: ["e", "i"],
  corte: ["g", "x"],
  corti: ["c", "s"],
  coru: ["n", "s"],
  cos: ["h", "i", "m", "p", "s", "t", "y"],
  cosi: ["g", "l", "n"],
  cosm: ["e", "i", "o"],
  cosmo: ["c", "g", "l", "n", "p", "s"],
  coss: ["a", "e"],
  cost: ["a", "e", "l", "u"],
  costa: ["l", "r"],
  costl: ["e", "y"],
  cot: ["a", "e", "i", "t", "y"],
  cote: ["r"],
  coter: ["i", "m"],
  cott: ["a", "e", "o"],
  cotta: ["g", "r"],
  cou: ["c", "g", "l", "m", "n", "p", "r", "s", "t"],
  coug: ["a", "h"],
  cough: [],
  coul: ["d", "e", "o", "t"],
  could: ["n", "s"],
  coulo: ["i", "m"],
  coun: ["c", "s", "t"],
  count: ["a", "d", "e", "i", "l", "r", "y"],
  coup: ["e", "l", "o"],
  coupl: ["e", "i"],
  cour: ["a", "i", "s", "t"],
  cours: ["e", "i"],
  court: ["e", "h", "i", "l", "r", "s", "y"],
  cov: ["i", "a", "e"],
  cove: ["n", "r", "t", "y"],
  coven: ["a", "t"],
  cover: ["a", "e", "i", "l", "t"],
  covet: ["a", "o"],
  cow: ["a", "b", "c", "e", "g", "h", "l", "m", "o", "p", "r", "s"],
  cowb: ["e", "i", "o"],
  cowh: ["a", "e", "i"],
  cowl: ["e", "i"],
  cowli: ["c", "k", "n"],
  cowp: ["a", "e", "o", "u"],
  cowpo: ["k", "n", "x"],
  cowr: ["i", "y"],
  cows: ["h", "l"],
  cox: ["c", "s"],
  coy: ["l", "n", "o", "p"],
  coz: ["e", "i", "y"],
  cozi: ["e", "l", "n"],
  cp: ["i", "o", "d", "l", "s"],
  cr: ["c", "a", "e", "i", "o", "u", "y"],
  cra: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "k",
    "m",
    "n",
    "p",
    "s",
    "t",
    "v",
    "w",
    "y",
    "z",
  ],
  crab: ["a", "b", "g", "w"],
  crabb: ["e", "i", "y"],
  crag: ["g"],
  cram: ["m", "p"],
  cramp: ["e", "o", "y"],
  cran: ["b", "e", "i", "k", "n"],
  crane: [],
  crani: ["a", "o", "u"],
  crank: ["c", "e", "i", "s", "y"],
  crap: ["e", "p", "s"],
  crapp: ["i", "y"],
  craps: ["h"],
  cras: ["h", "s"],
  crash: ["i"],
  crass: ["n"],
  crat: ["e", "o"],
  crate: ["r"],
  crav: ["a", "e", "i"],
  crave: ["n", "r"],
  craw: ["f", "l"],
  crawl: ["e", "s", "y"],
  cray: ["f", "o"],
  craz: ["e", "i", "y"],
  crazi: ["l", "n"],
  crazy: [],
  cre: ["a", "c", "d", "e", "m", "n", "o", "p", "s", "t", "v", "w"],
  crea: ["k", "m", "s", "t"],
  creak: ["i", "y"],
  cream: ["e", "i", "p", "y"],
  creas: ["e", "i"],
  creat: ["e", "i", "o", "u"],
  cred: ["e", "i", "o", "u"],
  credi: ["b", "t"],
  cree: ["d", "k", "l", "p"],
  creek: ["s"],
  creep: ["e", "i", "y"],
  crem: ["a", "e"],
  creo: ["l", "s"],
  crep: ["e", "t", "u"],
  crepe: [],
  cres: ["c", "o", "s", "t"],
  cress: ["e"],
  crest: ["e", "f", "i"],
  cret: ["a", "e", "i", "o"],
  creta: ["c", "n"],
  crev: ["a", "i"],
  crew: ["e", "m", "n"],
  cri: ["b", "c", "e", "k", "m", "n", "p", "s", "t"],
  crib: ["b", "r"],
  cribb: ["a", "i"],
  cric: ["k", "o"],
  crick: ["e"],
  crie: ["d", "r", "s"],
  crim: ["e", "i", "p", "s"],
  crime: ["a"],
  crimp: ["y"],
  crin: ["e", "g", "k", "o"],
  crino: ["i", "l"],
  crip: ["e", "p"],
  cris: ["i", "p", "s"],
  crisp: ["e", "i", "l", "n", "y"],
  crit: ["e", "i"],
  criti: ["c", "q"],
  cro: [
    "a",
    "c",
    "f",
    "i",
    "k",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "w",
    "z",
  ],
  croa: ["k", "t"],
  croak: ["e"],
  croc: ["h", "k", "o", "u"],
  crock: ["e"],
  crom: ["l", "w"],
  cron: ["e", "y"],
  croo: ["k", "n"],
  crook: ["e", "n"],
  crop: ["l", "p"],
  cros: ["i", "s"],
  cross: ["b", "c", "e", "f", "h", "i", "l", "n", "o", "p", "r", "t", "w"],
  crot: ["c", "o"],
  crou: ["c", "p", "t"],
  croup: ["i", "y"],
  crow: ["b", "d", "f", "n", "s"],
  crowd: ["e"],
  crown: ["e", "i"],
  cru: ["c", "d", "e", "i", "l", "m", "n", "p", "s", "t", "x", "z"],
  crud: ["e", "i"],
  crude: ["l", "n"],
  crue: ["l", "t"],
  cruel: ["l", "t"],
  cruet: [],
  crum: ["b", "m", "p"],
  crumb: ["l"],
  crump: ["e", "l"],
  crus: ["a", "e", "h", "t"],
  crush: ["e", "i"],
  crust: ["a", "e", "y"],
  cry: ["b", "i", "o", "p", "s"],
  cryo: ["g", "n", "s"],
  cs: ["t"],
  ct: ["f", "g", "n", "r", "s"],
  ctg: ["e"],
  cu: ["b", "c", "d", "e", "f", "i", "l", "m", "n", "p", "r", "s", "t"],
  cub: ["a", "b", "e", "i", "s"],
  cuba: ["n"],
  cube: [],
  cubi: ["c", "s", "t"],
  cubic: ["a", "l"],
  cubis: ["m", "t"],
  cuc: ["k", "u"],
  cud: ["d", "g"],
  cudd: ["l", "y"],
  cuddl: ["e", "y"],
  cue: ["s"],
  cui: ["r", "s"],
  cul: ["e", "i", "l", "m", "o", "p", "t", "v"],
  cull: ["e", "i", "s"],
  culle: ["n", "t"],
  culp: ["a", "r"],
  cult: ["i", "u"],
  culti: ["c", "s", "v"],
  cum: ["b", "i", "m", "u"],
  cumb: ["e", "r"],
  cun: ["e", "n", "t"],
  cup: ["b", "c", "f", "i", "o", "p", "r"],
  cupb: ["e", "o"],
  cupp: ["a", "i"],
  cupr: ["i", "o"],
  cur: ["a", "b", "c", "d", "e", "f", "i", "l", "m", "r", "s", "t", "v"],
  cura: ["b", "c", "r", "t"],
  curac: ["a", "o", "y"],
  curat: ["e", "i", "o"],
  curb: ["i", "s"],
  curd: ["l"],
  cure: ["l", "t"],
  curi: ["a", "e", "n", "o", "u"],
  curia: ["l"],
  curio: ["s", "u"],
  curl: ["e", "i", "y"],
  curle: ["d", "r", "w"],
  curli: ["c", "n"],
  curly: ["c"],
  curr: ["a", "e", "i", "y"],
  curri: ["c", "e", "s"],
  curry: ["c"],
  curs: ["e", "i", "o", "t"],
  curse: ["d", "r"],
  curt: ["a", "l", "s"],
  curts: ["e", "y"],
  curv: ["a", "e", "i", "y"],
  curva: ["c", "t"],
  curve: ["d", "t"],
  cus: ["h", "p", "s", "t"],
  cush: ["i", "y"],
  cusp: ["i"],
  cuss: ["e"],
  cust: ["a", "o"],
  custo: ["d", "m"],
  cut: ["a", "b", "e", "i", "l", "o", "p", "t", "u", "w"],
  cuta: ["n", "w"],
  cute: ["n"],
  cutl: ["a", "e"],
  cutle: ["r", "t"],
  cuto: ["f", "u"],
  cutt: ["e", "h", "i", "l"],
  cw: ["o", "t"],
  cy: ["o", "a", "b", "c", "d", "g", "l", "m", "n", "p", "r", "s", "t"],
  cyc: ["a", "l"],
  cycl: ["a", "e", "i", "o"],
  cycla: ["m", "z"],
  cycli: ["c", "n", "s"],
  cyclo: ["i", "m", "n", "p", "r", "t"],
  cyl: ["i"],
  cym: ["b", "e", "o"],
  cyn: ["i", "o"],
  cyp: ["h", "r"],
  cypr: ["e", "i", "u"],
  cz: ["a", "e"],
  d: [
    "b",
    "c",
    "d",
    "e",
    "i",
    "m",
    "n",
    "o",
    "p",
    "s",
    "u",
    "x",
    "a",
    "b",
    "c",
    "e",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "u",
    "w",
    "y",
    "z",
  ],
  da: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  dab: ["b", "c", "s"],
  dac: ["e", "h", "r", "t"],
  dach: ["a", "s"],
  dad: ["a", "d", "o"],
  dae: ["d", "m"],
  daf: ["f", "t"],
  daff: ["o", "y"],
  dag: ["g", "o", "u"],
  dah: ["l", "o"],
  dai: ["l", "n", "q", "r", "s"],
  dail: ["i", "y"],
  daily: [],
  dais: ["y"],
  dal: ["e", "l", "m"],
  dall: ["a", "i", "y"],
  dam: ["a", "e", "n", "o", "p", "s"],
  dama: ["g", "s"],
  damas: ["c", "k"],
  dame: [],
  damn: ["a", "e", "i"],
  damna: ["b", "t"],
  damp: ["e", "i", "n"],
  dampe: ["n", "r"],
  dams: ["e", "o"],
  dan: ["c", "d", "e", "g", "i", "k", "s", "t", "u"],
  danc: ["e", "i"],
  dance: ["r"],
  dand: ["e", "i", "l", "r", "y"],
  dande: ["l", "r"],
  dang: ["e", "l"],
  dani: ["e", "s"],
  dap: ["h", "p"],
  dapp: ["e", "l"],
  dar: ["b", "d", "e", "i", "k", "l", "n", "t", "w"],
  dare: ["d", "n", "s"],
  dark: ["e", "i", "l", "n", "r", "s", "y"],
  darkl: ["i", "y"],
  darn: ["i"],
  dart: ["b", "e"],
  das: ["h", "t"],
  dash: ["b", "e", "i", "p"],
  dashe: ["d", "r"],
  dat: ["a", "e", "i", "u"],
  data: ["b"],
  datab: ["a", "l"],
  date: ["d", "l"],
  datel: ["e", "i"],
  datu: ["m", "r"],
  dau: ["b", "g", "n", "p"],
  daub: ["e", "i"],
  dav: ["e", "i", "y"],
  davi: ["d", "t"],
  david: ["i"],
  daw: ["d", "n"],
  day: ["b", "c", "d", "f", "l", "r", "s", "t"],
  dayb: ["o", "r"],
  daybo: ["o", "y"],
  daz: ["e", "z"],
  daze: ["d"],
  dd: ["t"],
  de: [
    "w",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
  ],
  dea: ["c", "d", "f", "l", "n", "r", "t"],
  deac: ["o", "t"],
  dead: ["b", "e", "h", "l", "m", "n", "p", "w"],
  deadl: ["i", "o", "y"],
  deadw: ["e", "o"],
  deaf: ["e", "n"],
  deal: ["e", "i", "t"],
  dean: ["e"],
  dear: ["e", "i", "l", "n", "t", "y"],
  deb: ["a", "e", "i", "o", "r", "t", "u"],
  deba: ["c", "r", "s", "t", "u"],
  debar: ["k", "m"],
  debat: ["a", "e", "i"],
  debi: ["l", "t"],
  debo: ["n", "u"],
  debon: ["a", "e"],
  debt: ["o"],
  debu: ["g", "n", "s", "t"],
  debut: ["a"],
  dec: ["a", "e", "i", "k", "l", "o", "r", "u"],
  deca: ["d", "f", "g", "h", "l", "m", "n", "p", "s", "t", "y"],
  decad: ["a", "e"],
  decag: ["o", "r"],
  decal: ["c", "i", "o"],
  decam: ["e", "p"],
  decay: ["e"],
  dece: ["a", "d", "i", "l", "m", "n", "p"],
  decei: ["t", "v"],
  decen: ["c", "n", "t"],
  deci: ["b", "d", "g", "l", "m", "p", "s"],
  decid: ["a", "e", "u"],
  decim: ["a", "e"],
  decis: ["i", "t"],
  deck: ["c", "e", "h", "l"],
  decl: ["a", "e", "i", "u"],
  decla: ["i", "m", "r", "s"],
  decli: ["n", "v"],
  deco: ["c", "d", "l", "m", "n", "r", "u", "y"],
  decol: ["l", "o"],
  decom: ["m", "p"],
  decon: ["g", "t"],
  decor: ["a", "o", "u"],
  decr: ["e", "i", "y"],
  decre: ["a", "e", "m", "p", "s"],
  ded: ["i", "u"],
  dee: ["d", "j", "m", "p", "r", "s"],
  deed: [],
  deem: ["p"],
  deep: ["e", "f", "l", "n"],
  deer: ["f", "s"],
  deers: ["k", "t"],
  def: ["a", "e", "i", "l", "o", "r", "s", "t", "u", "y"],
  defa: ["c", "l", "m", "u"],
  defam: ["a", "e"],
  defe: ["a", "c", "n", "r"],
  defec: ["a", "t"],
  defen: ["c", "d", "s"],
  defer: ["e", "m"],
  defi: ["a", "c", "l", "n"],
  defin: ["a", "e", "i"],
  defl: ["a", "e", "o"],
  defla: ["g", "t"],
  deflo: ["r", "w"],
  defo: ["e", "g", "l", "r"],
  defor: ["e", "m"],
  defr: ["a", "o"],
  defra: ["u", "y"],
  defro: ["c", "s"],
  deft: ["l", "n"],
  defu: ["n", "s", "z"],
  deg: ["a", "e", "l", "r", "u"],
  dega: ["s", "u"],
  degr: ["a", "e"],
  deh: ["i", "o", "u", "y"],
  dei: ["c", "f", "g", "o", "s", "t"],
  deic: ["e", "i", "t"],
  deif: ["i", "y"],
  deifi: ["c", "e"],
  deis: ["m", "t"],
  deist: ["i"],
  dej: ["a", "e"],
  deje: ["c", "u"],
  dek: ["a", "k"],
  deka: ["g", "l", "m"],
  del: ["a", "e", "f", "h", "i", "l", "o", "p", "t", "u", "v"],
  dela: ["m", "w", "y"],
  dele: ["c", "g", "t"],
  delet: ["e", "i"],
  deli: ["b", "c", "g", "m", "n", "q", "r", "v"],
  delic: ["a", "i"],
  delin: ["e", "q"],
  delt: ["a", "o"],
  delta: [],
  delu: ["d", "g", "s", "x"],
  dem: ["a", "e", "i", "o", "u", "y"],
  dema: ["g", "n", "r", "t"],
  demag: ["n", "o"],
  deme: ["a", "n", "r", "s", "t"],
  demi: ["g", "j", "l", "m", "n", "s", "t", "u"],
  demis: ["e", "t"],
  demo: ["b", "c", "d", "g", "i", "l", "n", "r", "s", "t"],
  demod: ["e", "u"],
  demon: ["e", "i", "o", "s"],
  demot: ["e", "i"],
  demu: ["l", "r"],
  demur: ["e", "r"],
  den: ["a", "d", "g", "i", "m", "o", "s", "t", "u", "v", "y"],
  dena: ["r", "t"],
  denat: ["i", "u"],
  deni: ["a", "c", "e", "g", "m", "t", "z"],
  denia: ["b", "l"],
  deno: ["m", "t", "u"],
  denot: ["a", "e"],
  denou: ["e", "n"],
  dens: ["e", "i"],
  dense: ["l"],
  dent: ["a", "e", "i", "u"],
  denta: ["l", "t"],
  denti: ["f", "n", "s", "t"],
  denu: ["c", "d", "n"],
  deo: ["d", "n", "x"],
  deod: ["a", "o"],
  deox: ["i", "y"],
  dep: ["a", "e", "i", "l", "o", "r", "t", "u"],
  depe: ["n", "r"],
  depi: ["c", "l"],
  depl: ["a", "e", "o"],
  deplo: ["r", "y"],
  depo: ["l", "n", "p", "r", "s", "t"],
  depol: ["a", "i"],
  depos: ["e", "i"],
  depr: ["a", "e", "i"],
  depre: ["c", "d", "s"],
  dept: ["h"],
  depth: ["l"],
  der: ["a", "b", "e", "i", "m", "o", "r", "v"],
  dera: ["i", "n"],
  dere: ["g", "l"],
  deri: ["d", "s", "v"],
  deris: ["i", "o"],
  deriv: ["a", "e"],
  derm: ["a", "i"],
  derma: ["l", "t"],
  derv: ["i"],
  des: ["a", "c", "e", "h", "i", "k", "o", "p", "s", "t", "u"],
  desc: ["a", "e", "r"],
  desca: ["l", "n", "r"],
  descr: ["i", "y"],
  dese: ["c", "g", "l", "n", "r"],
  deser: ["t", "v"],
  desi: ["c", "d", "g", "r", "s"],
  desir: ["a", "e", "o"],
  desk: ["w"],
  desp: ["a", "e", "i", "o"],
  despa: ["i", "t"],
  despi: ["c", "s", "t"],
  despo: ["i", "l", "n", "t"],
  dest: ["i", "r"],
  desti: ["n", "t"],
  destr: ["o", "u"],
  desu: ["e", "l"],
  det: ["a", "e", "h", "o", "r"],
  deta: ["c", "i"],
  detai: ["l", "n"],
  dete: ["c", "n", "r", "s"],
  deter: ["g", "i", "m", "r"],
  deto: ["n", "u", "x"],
  detr: ["a", "i", "o"],
  detra: ["c", "i"],
  detri: ["m", "t"],
  deu: ["c", "t"],
  deut: ["e", "s"],
  dev: ["a", "e", "i", "o"],
  deva: ["l", "s"],
  devi: ["a", "c", "l", "o", "s", "t"],
  devia: ["n", "t"],
  devil: ["e", "f", "i", "m", "r", "t"],
  devo: ["i", "l", "n", "t", "u"],
  devoi: ["d", "r"],
  devol: ["u", "v"],
  devot: ["e", "i"],
  devou: ["r", "t"],
  dew: ["b", "c", "d", "i", "l", "y"],
  dewi: ["l", "n"],
  di: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "x",
    "z",
  ],
  dia: ["b", "c", "d", "e", "g", "l", "m", "n", "p", "r", "s", "t"],
  diab: ["e", "o"],
  diac: ["o", "r"],
  diag: ["n", "o", "r"],
  dial: ["e", "i", "l", "o", "y"],
  diam: ["a", "e", "o"],
  dian: ["a", "t"],
  diap: ["a", "e", "h"],
  diaph: ["a", "o", "r"],
  diar: ["i", "r", "y"],
  dias: ["p", "t"],
  diast: ["o", "r"],
  diat: ["h", "o", "r"],
  diato: ["m", "n"],
  dic: ["e", "h", "i", "k", "o", "t"],
  dice: ["y"],
  dich: ["o", "r"],
  dick: ["e", "y"],
  dicke: ["n", "r", "y"],
  dicky: ["b"],
  dict: ["a", "i", "u"],
  dicta: ["p", "t"],
  did: ["a", "d", "n", "o", "s"],
  die: ["g", "h", "l", "r", "s", "t"],
  diet: ["a", "e", "i"],
  dieti: ["c", "t"],
  dig: ["a", "e", "g", "i", "n", "r"],
  digg: ["e", "i"],
  digr: ["a", "e"],
  dil: ["a", "d", "e", "i", "l", "u"],
  dila: ["p", "t"],
  dilat: ["a", "e", "i", "o"],
  dile: ["m", "t"],
  dill: ["y"],
  dilly: ["d"],
  dilu: ["e", "t"],
  dilut: ["e", "i"],
  dim: ["e", "i", "l", "m", "n", "o", "p", "w"],
  dime: ["n", "t"],
  dimi: ["n", "t"],
  dimin: ["i", "u"],
  din: ["a", "e", "g", "i", "k", "n", "o", "t"],
  dine: ["r", "t"],
  ding: ["a", "d", "e", "h", "i", "l", "o", "u", "y"],
  dingi: ["l", "n"],
  dink: ["u", "y"],
  dio: ["c", "d", "e", "n", "p", "r", "x"],
  dip: ["h", "l", "o", "p", "s", "t"],
  diph: ["e", "t"],
  dipl: ["o"],
  diplo: ["i", "m", "p"],
  dipp: ["e", "i", "y"],
  dips: ["o", "t", "w"],
  dipt: ["e", "y"],
  dir: ["e", "g", "i", "k", "n", "t"],
  dire: ["c", "f", "l"],
  dirt: ["i", "y"],
  dirti: ["l", "n"],
  dirty: [],
  dis: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "y",
  ],
  disa: ["b", "d", "f", "g", "l", "p", "r", "s", "v"],
  disab: ["i", "l", "u"],
  disag: ["g", "r"],
  disar: ["m", "r"],
  disas: ["s", "t"],
  disb: ["a", "e", "u"],
  disba: ["n", "r"],
  disc: ["a", "e", "h", "i", "l", "o", "r", "u"],
  discl: ["a", "o"],
  disco: ["i", "l", "m", "n", "r", "t", "u", "v"],
  discr: ["e", "i"],
  discu: ["r", "s"],
  dise: ["a", "m", "n", "q", "s"],
  disem: ["b", "p"],
  disen: ["c", "d", "f", "g", "t"],
  disf: ["a", "i", "o", "r"],
  disfr: ["a", "o"],
  disg: ["o", "r", "u"],
  disgr: ["a", "u"],
  disgu: ["i", "s"],
  dish: ["a", "c", "e", "f", "i", "o", "p", "r", "t", "w", "y"],
  disha: ["b", "r"],
  dishe: ["a", "d", "v"],
  disi: ["l", "n"],
  disin: ["c", "f", "g", "h", "t"],
  disj: ["o", "u"],
  disk: [],
  disl: ["i", "o"],
  dislo: ["c", "d", "y"],
  dism: ["a", "e", "i", "o"],
  disma: ["l", "n", "s", "y"],
  diso: ["b", "r", "w"],
  disob: ["e", "l"],
  disor: ["d", "g", "i"],
  disp: ["a", "e", "i", "l", "o", "r", "u"],
  dispa: ["r", "s", "t"],
  dispe: ["l", "n", "r"],
  displ: ["a", "e"],
  dispo: ["r", "s"],
  dispr: ["a", "o"],
  disr: ["e", "o", "u"],
  disre: ["g", "l", "m", "p", "s"],
  diss: ["a", "e", "i", "o", "u"],
  disse: ["c", "m", "n", "r", "v"],
  dissi: ["d", "m", "p"],
  disso: ["c", "l", "n"],
  dist: ["a", "e", "i", "o", "r", "u"],
  dista: ["f", "i", "l", "n", "s"],
  diste: ["m", "n"],
  disti: ["c", "l", "n"],
  distr: ["a", "e", "i", "u"],
  disu: ["n", "s"],
  dit: ["c", "h", "t"],
  ditt: ["o", "y"],
  div: ["a", "e", "i", "o", "u", "v"],
  diva: ["g", "l", "n"],
  dive: ["b", "r", "s"],
  diver: ["g", "s", "t"],
  dives: ["t"],
  divi: ["d", "n", "s"],
  divid: ["e", "i"],
  divin: ["a", "e", "g", "i"],
  divis: ["i", "o"],
  divo: ["r", "t"],
  dj: ["a", "i"],
  dl: ["r", "v"],
  dm: ["z"],
  dn: ["a", "i"],
  do: [
    "d",
    "e",
    "t",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  dob: ["b", "e"],
  doc: ["e", "i", "k", "t", "u"],
  dock: ["a", "e", "y"],
  docke: ["r", "t"],
  doct: ["o", "r"],
  dod: ["d", "e", "g", "o"],
  dodd: ["e", "l"],
  dodg: ["e", "y"],
  dodge: ["m", "r"],
  doe: ["r", "s"],
  does: ["k", "n"],
  dog: ["b", "c", "e", "f", "g", "h", "i", "l", "m", "p", "s", "t", "w"],
  dogg: ["e", "i", "o", "y"],
  dogge: ["d", "r"],
  doggo: ["n"],
  doggy: [],
  dogt: ["o", "r"],
  doi: ["l", "n", "t"],
  dol: ["d", "e", "l", "m", "o", "p", "t"],
  dole: ["f"],
  doll: ["a", "o", "y"],
  dolly: [],
  dolm: ["a", "e"],
  dolo: ["m", "r", "u"],
  dolor: ["o"],
  dolt: ["i"],
  dom: ["a", "e", "i"],
  dome: ["d", "s"],
  domes: ["d", "t"],
  domi: ["c", "n"],
  domin: ["a", "e", "i", "o", "u"],
  don: ["a", "e", "j", "k", "n", "o", "u"],
  dona: ["t"],
  donat: ["e", "i"],
  donn: ["a", "i", "y"],
  doo: ["d", "m", "r"],
  dood: ["a", "l"],
  doom: ["s"],
  door: ["b", "f", "j", "k", "l", "m", "n", "p", "s", "w", "y"],
  doork: ["e", "n"],
  doorp: ["l", "o"],
  doors: ["c", "i", "t"],
  dop: ["a", "e", "p", "y"],
  dope: ["y"],
  dor: ["i", "m", "s", "y"],
  dorm: ["a", "e", "i", "o"],
  dors: ["a", "u"],
  dos: ["a", "e", "i", "s", "t"],
  doss: ["e", "i"],
  dost: ["o"],
  dot: ["a", "e", "h", "i", "t"],
  dota: ["g", "r"],
  dote: ["d"],
  dott: ["e", "l", "y"],
  dou: ["a", "b", "c", "g", "r", "s"],
  doub: ["l", "t"],
  doubl: ["e", "i", "o", "y"],
  doubt: ["e", "f", "i", "l"],
  doug: ["h", "l"],
  dough: ["b", "n", "t", "y"],
  dov: ["e", "i"],
  dove: ["c", "r", "t"],
  dow: ["a", "d", "e", "i", "n", "r", "s"],
  dowe: ["l", "r"],
  down: ["b", "c", "d", "e", "f", "g", "h", "i", "p", "r", "s", "t", "w", "y"],
  downc: ["a", "o"],
  downh: ["e", "i"],
  downr: ["a", "i"],
  downs: ["h", "t", "w"],
  downt: ["o", "r", "u"],
  downw: ["a", "i"],
  downy: [],
  dows: ["e", "i"],
  dowse: ["r"],
  doy: ["e", "l"],
  doyl: ["e", "y"],
  doz: ["e", "y"],
  doze: ["n", "r"],
  dozen: ["t"],
  dp: ["h", "t"],
  dr: ["a", "e", "i", "o", "u", "y"],
  dra: ["b", "c", "f", "g", "i", "k", "m", "n", "p", "s", "t", "u", "w", "y"],
  drab: ["s"],
  drac: ["a", "h", "o"],
  drag: ["g", "n", "o"],
  dragg: ["l", "y"],
  drago: ["m", "n", "o"],
  dram: ["a"],
  drama: ["m", "t"],
  draw: ["b", "e", "i", "l", "n", "s"],
  drawb: ["a", "r"],
  drawl: ["i"],
  drawn: [],
  dray: ["m"],
  dre: ["a", "d", "g", "n", "s", "w"],
  drea: ["d", "m", "r"],
  dread: ["f", "n"],
  dream: ["b", "e", "i", "l", "t", "y"],
  drear: ["i", "y"],
  dres: ["d", "s"],
  dress: ["a", "e", "i", "m", "y"],
  dri: ["b", "e", "f", "l", "n", "p", "v", "z"],
  drib: ["b", "l", "s"],
  drie: ["d", "r", "s"],
  dried: [],
  dril: ["l", "y"],
  drill: ["e", "i", "m"],
  drip: ["p"],
  driv: ["e", "i"],
  drive: ["l", "n", "r", "w"],
  dro: ["g", "l", "m", "n", "o", "p", "s", "u", "v", "w"],
  dron: ["e", "g"],
  droo: ["l", "p"],
  droop: ["y"],
  drop: ["f", "k", "l", "o", "p", "s"],
  dropp: ["e", "i"],
  drops: ["i", "y"],
  drow: ["n", "s"],
  drown: ["e"],
  drows: ["e", "i", "y"],
  dru: ["b", "d", "g", "i", "m", "n", "p"],
  drug: ["g", "s"],
  drugg: ["e", "i"],
  drum: ["b", "f", "h", "l", "m", "s"],
  drumm: ["e", "i"],
  dry: ["a", "c", "e", "i", "l", "n", "w"],
  du: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
  ],
  dub: ["b", "i", "l"],
  dubi: ["e", "o"],
  duc: ["a", "h", "k", "t"],
  duca: ["l", "t"],
  duch: ["e", "y"],
  duck: ["b", "i", "l", "p", "s", "w", "y"],
  duckb: ["i", "o"],
  duct: ["i", "l"],
  dud: ["e", "g", "s"],
  dude: [],
  due: ["l", "n", "t"],
  duel: ["e", "i", "l"],
  dueli: ["n", "s"],
  duell: ["e", "i"],
  dug: ["o"],
  dugo: ["n", "u"],
  dul: ["c", "l", "y"],
  dulc: ["e", "i"],
  dull: ["a", "e", "n", "y"],
  dum: ["b", "d", "f", "m", "p"],
  dumb: ["b", "l", "n", "w"],
  dump: ["e", "i", "l", "s", "y"],
  dun: ["c", "d", "e", "g", "k"],
  dune: [],
  dung: ["a", "e", "h"],
  dunk: ["e"],
  duo: ["d", "l"],
  dup: ["e", "l"],
  dupl: ["e", "i"],
  duple: ["x"],
  dur: ["a", "b", "e", "i", "s", "u"],
  dura: ["b", "l", "n", "t"],
  durab: ["i", "l"],
  duri: ["a", "n"],
  dus: ["k", "t"],
  dusk: ["i", "y"],
  dust: ["b", "c", "e", "l", "m", "p", "s", "u", "y"],
  dustb: ["i", "o"],
  dustc: ["a", "o"],
  dusty: [],
  dut: ["c", "e", "i", "y"],
  duti: ["a", "f"],
  duty: [],
  dw: ["a", "e", "i", "t"],
  dy: ["a", "b", "e", "i", "k", "n", "s"],
  dya: ["d", "r"],
  dyad: ["i"],
  dye: ["d", "i", "r", "s"],
  dyn: ["a", "e"],
  dyna: ["m", "s"],
  dynam: ["i", "o"],
  dys: ["e", "f", "l", "p", "t"],
  dysp: ["e", "h", "n", "r"],
  dysph: ["a", "o"],
  dyspn: ["e", "o"],
  e: [
    "c",
    "d",
    "e",
    "h",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
    "w",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  ea: ["c", "g", "r", "s", "t", "u", "v"],
  eag: ["e", "l"],
  ear: ["a", "d", "e", "f", "l", "m", "n", "p", "r", "s", "t", "w"],
  earf: ["l", "u"],
  earl: ["a", "d", "e", "i", "o", "y"],
  early: [],
  earm: ["a", "u"],
  earn: ["e", "i"],
  earp: ["h", "i", "l"],
  ears: ["h", "p"],
  earw: ["a", "i"],
  eas: ["e", "i", "t", "y"],
  ease: ["f", "l", "m"],
  easi: ["l", "n"],
  east: ["b", "e", "o", "w"],
  easy: ["g"],
  eat: ["a", "e", "i"],
  eate: ["n", "r"],
  eater: ["y"],
  eb: ["b", "o", "u"],
  ebb: [],
  ec: ["g", "c", "h", "l", "o", "r", "s", "t", "u", "z"],
  ecc: ["e", "l"],
  ech: ["e", "o"],
  echo: ["l"],
  ecl: ["a", "e", "i", "o"],
  ecla: ["i", "m", "t"],
  eco: ["l", "n", "s"],
  ecol: ["o"],
  econ: ["o"],
  ecu: ["a", "m"],
  ed: ["p", "t", "a", "d", "e", "g", "i", "u"],
  ede: ["l", "m", "n"],
  eden: ["i"],
  edg: ["e", "i", "y"],
  edge: ["l", "w"],
  edgew: ["a", "i"],
  edi: ["b", "c", "f", "n", "s", "t"],
  edib: ["i", "l"],
  edif: ["i", "y"],
  edify: ["i"],
  edit: ["i", "o"],
  ee: ["c", "l", "r"],
  eel: ["g"],
  eer: ["i", "y"],
  eeri: ["e", "l", "n"],
  eg: ["a", "g", "i", "l", "o", "r", "y"],
  ega: ["d", "l"],
  egg: ["b", "c", "h", "n", "p", "s"],
  ego: ["c", "i", "t"],
  eh: ["f"],
  ei: ["d", "f", "g", "n", "r", "s", "t"],
  eid: ["e", "o"],
  eis: ["e", "t"],
  ej: ["a", "e"],
  ek: ["e", "i"],
  eki: ["n", "s"],
  el: [
    "a",
    "b",
    "d",
    "e",
    "f",
    "h",
    "i",
    "k",
    "l",
    "m",
    "o",
    "s",
    "u",
    "v",
    "y",
  ],
  ela: ["b", "n", "p", "s", "t"],
  elan: ["d"],
  elat: ["e", "i"],
  elate: ["d"],
  elb: ["e", "o"],
  eld: ["e"],
  elde: ["r", "s"],
  elder: ["b", "f", "l"],
  ele: ["c", "e", "g", "m", "p", "v"],
  elec: ["t"],
  elect: ["i", "o", "r"],
  eleg: ["a", "i", "y"],
  elem: ["e"],
  elev: ["a", "e"],
  elf: ["i"],
  elfi: ["n", "s"],
  eli: ["c", "d", "g", "j", "m", "t", "x", "z"],
  elit: ["e", "i"],
  elk: ["h"],
  ell: ["a", "i"],
  elo: ["c", "n", "p", "q"],
  elu: ["c", "d", "s"],
  elv: ["e", "i"],
  elve: ["r", "s"],
  em: ["u", "a", "b", "c", "e", "i", "m", "o", "p", "u"],
  ema: ["c", "n", "s"],
  eman: ["a", "c"],
  emb: ["a", "e", "i", "l", "o", "r"],
  emba: ["l", "n", "r", "s", "t", "y"],
  embar: ["g", "k", "r"],
  embe: ["d", "l", "r", "z"],
  ember: [],
  embl: ["a", "e"],
  embo: ["d", "l", "n", "s", "u", "w"],
  embod: ["i", "y"],
  embol: ["d", "i"],
  embos: ["o", "s"],
  embr: ["a", "o", "y"],
  embra: ["c", "s"],
  embro: ["c", "i"],
  eme: ["n", "r", "t"],
  emer: ["a", "g", "i", "s", "y"],
  emi: ["g", "n", "r", "s", "t"],
  emir: ["a"],
  emit: ["t"],
  emo: ["l", "t"],
  emol: ["l", "u"],
  emot: ["e", "i"],
  emoti: ["o", "v"],
  emp: ["a", "e", "h", "i", "l", "o", "r", "t", "u", "y"],
  empa: ["n", "t"],
  empe: ["n", "r"],
  emph: ["a", "y"],
  empha: ["s", "t"],
  empl: ["a", "o"],
  empla: ["c", "n"],
  empo: ["r", "w"],
  empt: ["i", "y"],
  empti: ["e", "l", "n"],
  empty: ["i"],
  emu: ["l"],
  emul: ["a", "o", "s"],
  en: [
    "e",
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "z",
  ],
  ena: ["b", "c", "m"],
  enam: ["e", "o"],
  enamo: ["r", "u"],
  enc: ["a", "e", "h", "i", "l", "o", "r", "u", "y"],
  enca: ["m", "p", "s", "u"],
  ench: ["a", "i"],
  encha: ["i", "n"],
  enci: ["p", "r"],
  encl: ["a", "o"],
  enco: ["d", "m", "r", "u"],
  encom: ["i", "p"],
  encou: ["n", "r"],
  encr: ["o", "u"],
  ency: ["c", "s"],
  encyc: ["l"],
  end: ["a", "e", "g", "i", "l", "m", "o", "u", "w"],
  ende: ["a", "m", "r"],
  endea: ["r", "v"],
  endi: ["n", "v"],
  endo: ["c", "g", "l", "m", "r", "s", "t", "w"],
  endoc: ["a", "r"],
  endog: ["a", "e"],
  endos: ["c", "k", "p"],
  endow: ["m"],
  endu: ["e", "r"],
  endur: ["a", "e", "i", "o"],
  endw: ["a", "i"],
  ene: ["m", "r"],
  enem: ["a", "y"],
  ener: ["g", "v"],
  energ: ["e", "i", "y"],
  enf: ["a", "e", "i", "o", "r"],
  enfo: ["l", "r"],
  eng: ["a", "e", "i", "l", "o", "r", "u"],
  engl: ["a", "i"],
  engr: ["a", "o"],
  engra: ["f", "m", "v"],
  enj: ["a", "o"],
  enjo: ["i", "y"],
  enjoy: ["a", "m"],
  enl: ["a", "i"],
  enli: ["g", "s", "v"],
  enm: ["e", "i"],
  enn: ["o", "u"],
  eno: ["r", "u"],
  enr: ["a", "i", "o"],
  enra: ["g", "p"],
  ens: ["a", "c", "e", "h", "i", "l", "n", "u"],
  ensh: ["e", "r"],
  enshr: ["i", "o"],
  ensi: ["g", "l"],
  ensil: ["a", "e"],
  ensu: ["e", "r"],
  ent: ["a", "e", "h", "i", "o", "r", "w"],
  enta: ["b", "i", "n"],
  ente: ["l", "n", "r"],
  enter: ["i", "p", "t"],
  enth: ["r", "u"],
  enthr: ["a", "o"],
  enti: ["c", "r", "t"],
  entic: ["e", "i"],
  entit: ["a", "l", "y"],
  ento: ["m", "u"],
  entom: ["b", "o"],
  entr: ["a", "e", "o", "u", "y"],
  entra: ["c", "i", "n", "p"],
  entre: ["a", "e", "n", "p", "s"],
  entry: ["w"],
  enu: ["c", "m", "n", "r"],
  env: ["e", "i", "o", "y"],
  enve: ["l", "n"],
  envi: ["a", "o", "r", "s"],
  envis: ["a", "i"],
  envo: ["i", "y"],
  envy: ["i"],
  eo: ["c", "l", "n", "s"],
  ep: ["a", "a", "e", "h", "i", "l", "o", "s"],
  epa: ["u"],
  epe: ["e", "r"],
  epi: ["c", "d", "g", "l", "n", "p", "s", "t", "z"],
  epic: ["e", "u"],
  epid: ["e", "i"],
  epide: ["m", "r"],
  epig: ["a", "e", "l", "r"],
  epil: ["e", "o"],
  epis: ["c", "o", "t"],
  episc: ["o"],
  epist: ["a", "e", "l", "o"],
  epit: ["a", "h", "o"],
  epith: ["a", "e"],
  epo: ["c", "n", "x"],
  er: ["a", "a", "b", "e", "g", "i", "m", "o", "r", "s", "u", "y"],
  era: ["d", "s"],
  eras: ["a", "e", "m", "u"],
  erase: ["d", "r"],
  ere: ["c", "l", "m"],
  erg: ["o"],
  ergo: ["m", "n", "t"],
  eri: ["e", "n"],
  ero: ["d", "g", "s", "t"],
  eros: ["i"],
  erosi: ["o", "v"],
  err: ["a", "o"],
  erra: ["n", "t"],
  erran: ["d", "t"],
  errat: ["a", "i", "u"],
  erro: ["n", "r"],
  ers: ["a", "e", "t"],
  erst: ["w"],
  eru: ["c", "d", "p"],
  ery: ["s", "t"],
  es: ["e", "p", "t", "c", "k", "o", "p", "q", "s", "t"],
  esc: ["a", "h", "o", "r", "u"],
  esca: ["l", "p", "r"],
  escal: ["a", "l", "o"],
  escap: ["a", "e", "i", "o"],
  escar: ["g", "o", "p"],
  esch: ["a", "e"],
  escha: ["r", "t"],
  escr: ["i", "o"],
  escu: ["d", "t"],
  esk: ["e", "i"],
  eso: ["p", "t"],
  esp: ["a", "e", "i", "l", "o", "r", "y"],
  espa: ["d", "l"],
  espe: ["c", "r"],
  espec: ["i"],
  espr: ["e", "i"],
  esq: ["r", "u"],
  ess: ["a", "e"],
  est: ["a", "e", "h", "i", "o", "r", "u"],
  esta: ["b", "m", "t"],
  este: ["e", "r"],
  esti: ["m", "v"],
  estr: ["a", "o"],
  estro: ["g", "u"],
  et: ["a", "d", "v", "a", "c", "e", "h", "i", "o", "r", "u", "y"],
  etc: ["e", "h"],
  etch: ["e", "i"],
  eth: ["a", "e", "i", "m", "n", "o", "y"],
  ethi: ["c", "o"],
  ethic: ["a", "i", "s"],
  ethn: ["i", "o"],
  ethno: ["c", "g", "l"],
  etho: ["l", "s"],
  eti: ["o", "q"],
  eu: ["c", "g", "l", "n", "o", "p", "r", "s", "t"],
  euc: ["a", "h", "l"],
  euch: ["a", "r"],
  eul: ["a", "o"],
  eur: ["a", "e", "h", "i", "o"],
  euro: ["c", "d", "p"],
  europ: ["e", "i"],
  eut: ["h", "r"],
  euth: ["a", "e"],
  ev: ["a", "a", "e", "g", "i", "o", "z"],
  eva: ["c", "d", "l", "n", "p", "s"],
  evan: ["e", "g"],
  eve: ["n", "r"],
  even: ["h", "i", "l", "n", "s", "t"],
  evens: ["o"],
  event: ["f", "i", "u"],
  ever: ["b", "e", "g", "l", "m", "y"],
  everg: ["l", "r"],
  every: ["b", "d", "o", "p", "t", "w"],
  evi: ["c", "d", "l", "n", "s"],
  evil: ["d", "l"],
  evo: ["c", "k", "l"],
  evol: ["u", "v"],
  ew: ["e"],
  ewe: ["r"],
  ex: ["a", "c", "e", "f", "h", "i", "o", "p", "q", "t", "u"],
  exa: ["c", "g", "l", "m", "s"],
  exac: ["e", "t"],
  exact: ["e", "i", "l", "n"],
  exam: ["i", "p"],
  exc: ["a", "e", "h", "i", "l", "o", "r", "u"],
  exce: ["e", "l", "p", "r", "s"],
  excel: ["l", "s"],
  exch: ["a", "e"],
  exci: ["s", "t"],
  excis: ["e", "i"],
  excit: ["a", "e", "i"],
  excl: ["a", "u"],
  excla: ["i", "m", "v"],
  exclu: ["d", "s"],
  exco: ["g", "m", "r"],
  excr: ["e", "u"],
  excre: ["m", "s", "t"],
  excu: ["l", "r", "s"],
  excus: ["a", "e"],
  exe: ["c", "g", "m", "r", "u"],
  exec: ["r", "u"],
  exer: ["c", "t"],
  exert: ["i"],
  exh: ["a", "i", "o", "u"],
  exha: ["l", "u"],
  exhal: ["a", "e"],
  exhi: ["b", "l"],
  exi: ["g", "l", "s", "t"],
  exig: ["e", "u"],
  exil: ["e", "i"],
  exit: [],
  exo: ["b", "c", "d", "g", "n", "r", "s", "t"],
  exod: ["u"],
  exog: ["a", "e"],
  exor: ["b", "c", "d"],
  exos: ["k", "p"],
  exot: ["e", "h", "i"],
  exp: ["a", "e", "i", "l", "o", "r", "t", "u", "w"],
  expa: ["n", "t"],
  expan: ["d", "s"],
  expat: ["i", "r"],
  expe: ["c", "d", "l", "n", "r"],
  expen: ["d", "s"],
  exper: ["i", "t"],
  expi: ["a", "r"],
  expir: ["a", "e", "i", "y"],
  expl: ["a", "e", "i", "o"],
  expla: ["i", "n"],
  explo: ["d", "i", "r", "s"],
  expo: ["n", "r", "s", "u"],
  expos: ["e", "i", "t", "u"],
  expr: ["e", "o"],
  expt: ["l"],
  expu: ["l", "n", "r"],
  ext: ["a", "e", "i", "o", "r"],
  exte: ["m", "n", "r"],
  exten: ["d", "s", "t", "u"],
  exter: ["i", "m", "n"],
  exti: ["n", "r"],
  extin: ["c", "g"],
  exto: ["l", "r"],
  extol: ["l"],
  extr: ["a", "e", "i", "o", "u"],
  extra: ["c", "d", "g", "j", "l", "m", "n", "o", "p", "s", "t", "v"],
  extri: ["c", "n"],
  extru: ["d", "s"],
  exu: ["b", "d", "l", "r"],
  exud: ["a", "e"],
  ey: ["e", "o", "r"],
  eye: ["b", "c", "d", "f", "g", "l", "p", "s", "t", "w"],
  eyeb: ["a", "r"],
  eyed: ["r"],
  eyel: ["a", "e", "i"],
  eyele: ["s", "t"],
  eyeli: ["d", "n"],
  eyes: ["h", "i", "o", "t"],
  eyesh: ["a", "o"],
  eyew: ["a", "i"],
  eyr: ["i", "y"],
  ez: ["e", "r"],
  f: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "h",
    "i",
    "l",
    "m",
    "p",
    "r",
    "t",
    "w",
    "y",
    "a",
    "b",
    "c",
    "e",
    "f",
    "i",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "t",
    "u",
    "w",
  ],
  fa: [
    "a",
    "o",
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  fab: ["a", "i", "l", "r", "u"],
  fac: ["a", "e", "i", "s", "t", "u"],
  face: ["c", "d", "l", "t"],
  facel: ["e", "i"],
  facet: ["e", "i"],
  faci: ["a", "l", "n"],
  facil: ["e", "i"],
  fact: ["i", "o", "s", "u"],
  facti: ["o", "t"],
  facto: ["r", "t"],
  fad: ["d", "e", "i", "m"],
  fade: ["d", "l", "o"],
  fae: ["c", "r"],
  faec: ["a", "e"],
  faer: ["i", "y"],
  fag: ["g", "o"],
  fagg: ["e", "o"],
  fai: ["e", "l", "n", "r", "t"],
  fail: ["i", "l", "s", "u"],
  fain: ["t"],
  faint: ["h", "i", "l", "n", "s"],
  fair: ["g", "i", "l", "n", "w", "y"],
  fairy: ["l", "t"],
  fait: ["h"],
  faith: ["f", "l"],
  fak: ["e", "i"],
  fake: ["r"],
  faker: ["y"],
  fal: ["c", "l", "s", "t"],
  falc: ["h", "o"],
  fall: ["a", "e", "i", "o"],
  falle: ["n", "r"],
  falli: ["b", "n"],
  fallo: ["f", "p", "u", "w"],
  fals: ["e", "i"],
  false: ["h", "l", "n", "t"],
  falsi: ["e", "f", "t"],
  falt: ["b", "e"],
  fam: ["e", "i", "o"],
  fame: ["d"],
  fami: ["l", "n", "s"],
  famil: ["i", "y"],
  fan: ["a", "c", "d", "f", "g", "j", "l", "n", "t", "z"],
  fanc: ["i", "y"],
  fanci: ["e", "f", "l"],
  fancy: ["w"],
  fand: ["a", "o"],
  fang: ["e"],
  fann: ["e", "y"],
  far: ["a", "c", "e", "f", "i", "m", "o", "r", "s", "t"],
  fara: ["d", "w"],
  farad: ["a"],
  farc: ["e", "i"],
  fare: ["w"],
  farm: ["e", "h", "i", "l", "s", "y"],
  farmh: ["a", "o"],
  farr: ["a", "i", "o"],
  fars: ["e", "i"],
  fart: ["h"],
  farth: ["e", "i"],
  fas: ["c", "h", "t"],
  fast: ["b", "e", "i", "n"],
  faste: ["n", "r"],
  fat: ["a", "b", "e", "h", "i", "l", "n", "t", "u"],
  fate: ["d", "f"],
  fath: ["e", "o"],
  fathe: ["a", "r"],
  fatt: ["e", "y"],
  fatte: ["d", "n"],
  fatty: [],
  fatu: ["i", "o"],
  fau: ["b", "c", "l", "n", "s", "v", "x"],
  faun: ["a"],
  fauna: ["l"],
  faux: [],
  fb: ["i"],
  fc: ["c", "y"],
  fd: ["a", "i"],
  fe: [
    "p",
    "a",
    "b",
    "c",
    "d",
    "e",
    "i",
    "l",
    "m",
    "n",
    "o",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  fea: ["l", "r", "s", "t", "z"],
  fear: ["f", "l", "s"],
  feas: ["i", "t"],
  feat: ["h", "u"],
  feb: ["r"],
  febr: ["i", "u"],
  febri: ["f", "l"],
  fec: ["a", "e", "k", "u"],
  fed: ["a", "e", "n", "o"],
  fede: ["r", "x"],
  fee: ["b", "d", "l", "t"],
  feed: ["b", "e", "i", "l", "s"],
  feel: ["e", "i"],
  fei: ["g", "n", "s"],
  fel: ["d", "i", "l", "o", "s", "t", "u"],
  feli: ["c", "n"],
  fell: ["a", "e", "o"],
  fello: ["e", "w"],
  felt: [],
  fem: ["a", "i", "m", "o", "u"],
  fen: ["c", "d", "e", "g", "i", "n", "u"],
  fenc: ["e", "i"],
  fence: ["r"],
  fend: ["e"],
  fer: ["a", "m", "n", "o", "r", "t", "u", "v"],
  ferm: ["e", "i"],
  fern: ["e", "y"],
  ferr: ["e", "i", "o", "u", "y"],
  ferri: ["c", "s", "t"],
  ferro: ["c", "m", "t", "u"],
  ferru: ["g", "l"],
  ferry: ["b", "m"],
  ferv: ["e", "i", "o"],
  fervo: ["r", "u"],
  fes: ["c", "s", "t"],
  fess: [],
  fest: ["a", "e", "i", "o", "s"],
  fet: ["a", "c", "e", "i", "l", "t", "u"],
  feti: ["c", "d", "s"],
  fett: ["e", "l", "u"],
  feu: ["d"],
  feud: ["a"],
  feuda: ["l", "t"],
  ff: [],
  fi: [
    "o",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "j",
    "l",
    "n",
    "o",
    "r",
    "s",
    "t",
    "v",
    "x",
    "z",
  ],
  fia: ["n", "s", "t"],
  fiat: [],
  fib: ["e", "r", "u"],
  fibr: ["e", "i", "o"],
  fibre: ["b", "g"],
  fibri: ["l", "n"],
  fibro: ["i", "m", "s", "u"],
  fic: ["e", "h", "k", "t", "u"],
  fich: ["e", "u"],
  fid: ["d", "e", "g", "u"],
  fie: ["f", "l", "n", "r", "s"],
  fier: ["c", "i", "y"],
  fieri: ["l", "n"],
  fif: ["e", "t"],
  fift: ["e", "h", "i", "y"],
  fifth: [],
  fifty: [],
  fig: ["h", "m", "u"],
  fil: ["a", "b", "c", "e", "i", "l", "m", "t"],
  fila: ["m", "r"],
  filar: ["i"],
  file: ["r", "t"],
  filet: [],
  fili: ["a", "b", "g", "n", "o", "p"],
  filia: ["l", "t"],
  fill: ["e", "i", "y"],
  fille: ["d", "r", "t"],
  filli: ["n", "p"],
  film: ["a", "d", "g", "s", "y"],
  filt: ["e", "h", "r"],
  filth: ["i", "y"],
  fin: ["a", "c", "d", "e", "f", "g", "i", "k", "l", "n"],
  fina: ["b", "g", "l", "n"],
  final: ["e", "i", "l"],
  find: ["a", "e", "i"],
  fine: ["a", "l", "n", "r", "s"],
  finer: ["y"],
  fines: ["s"],
  fini: ["a", "c", "n", "s", "t"],
  finic: ["a", "k"],
  finis: ["h"],
  finit: ["e", "u"],
  finn: ["a", "e", "i", "y"],
  fio: ["r"],
  fir: ["e", "i", "k", "m", "s", "t"],
  fire: ["a", "b", "c", "d", "f", "g", "h", "l", "m", "p", "s", "t", "w"],
  fireb: ["a", "i", "o", "r", "u"],
  firec: ["l", "r"],
  fired: ["a", "o"],
  firef: ["i", "l"],
  firel: ["e", "i"],
  firep: ["l", "o", "r"],
  fires: ["i", "t"],
  firew: ["a", "e", "o"],
  firm: ["a", "l", "n", "s"],
  fis: ["c", "h", "s", "t"],
  fish: ["b", "c", "e", "g", "h", "i", "l", "m", "p", "w", "y"],
  fishe: ["r", "y"],
  fiss: ["i", "u"],
  fissi: ["l", "o"],
  fist: ["f", "i", "u"],
  fit: ["f", "l", "m", "n", "t"],
  fitt: ["e", "i"],
  fitte: ["d", "r"],
  fix: ["a", "e", "i", "t"],
  fixa: ["b", "t"],
  fixe: ["d", "r"],
  fixed: ["l"],
  fixi: ["n", "t"],
  fl: ["a", "e", "i", "o", "u", "y"],
  fla: [
    "b",
    "c",
    "g",
    "i",
    "k",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
  ],
  flab: ["b"],
  flabb: ["e", "y"],
  flac: ["c", "k", "o"],
  flag: ["e", "g", "i", "m", "o", "p", "r", "s"],
  flage: ["l", "o"],
  flags: ["h", "t"],
  flai: ["l", "r"],
  flak: ["e", "i", "y"],
  flam: ["b", "e", "i", "m"],
  flamb: ["e", "o"],
  flame: ["n", "o", "t"],
  flan: ["d", "g", "k", "n"],
  flank: ["e"],
  flap: ["j", "p"],
  flapp: ["a", "e"],
  flar: ["e", "i"],
  flare: ["d", "s"],
  flas: ["h", "k"],
  flash: ["b", "c", "e", "f", "g", "i", "l", "p", "y"],
  flat: ["b", "c", "f", "h", "i", "l", "n", "t", "u", "w"],
  flatb: ["e", "o"],
  flatf: ["i", "o"],
  flatl: ["e", "y"],
  flatt: ["e", "i", "o"],
  flatu: ["l", "s"],
  flatw: ["a", "o"],
  flau: ["n", "t"],
  flaw: ["l"],
  flax: ["e", "s"],
  fle: ["a", "c", "d", "e", "m", "s", "u", "w", "x"],
  flea: ["b", "p"],
  fleab: ["a", "i"],
  fled: ["g"],
  fledg: ["e", "l"],
  flee: ["c", "r", "t"],
  fleec: ["e", "y"],
  fleet: ["i"],
  flem: ["i"],
  flemi: ["n", "s"],
  flex: ["i", "o", "u"],
  flexi: ["b", "o"],
  fli: ["b", "c", "e", "g", "m", "n", "p", "r", "t", "v"],
  flie: ["d", "r"],
  flim: ["f", "s"],
  flims: ["i", "y"],
  flin: ["c", "d", "g", "t"],
  flint: ["l", "y"],
  flip: ["f", "p", "s"],
  flipp: ["a", "e", "i"],
  flit: ["c", "t"],
  flitt: ["e", "i"],
  flo: ["a", "c", "e", "g", "o", "p", "r", "s", "t", "u", "w"],
  floc: ["c", "k"],
  flog: ["g"],
  floo: ["d", "r", "s", "z"],
  flood: ["g", "i", "l", "p", "t"],
  floor: ["b", "i", "l", "s", "w"],
  flop: ["h", "o", "p"],
  flor: ["a", "e", "i"],
  flora: ["l"],
  flore: ["n", "s", "t"],
  flori: ["c", "d", "f", "n", "s"],
  flot: ["a", "i", "s"],
  flou: ["n", "r", "t"],
  floun: ["c", "d"],
  flour: ["e", "i", "m", "y"],
  flow: ["e", "i", "n"],
  flu: ["b", "c", "e", "f", "i", "k", "m", "n", "o", "r", "s", "t", "v", "x"],
  flue: ["n"],
  fluen: ["c", "t"],
  fluk: ["e", "y"],
  fluke: ["y"],
  flum: ["e", "m"],
  flumm: ["e", "o"],
  flun: ["g", "k"],
  flunk: ["e", "y"],
  flus: ["h", "t"],
  flush: ["e", "i"],
  flut: ["e", "i", "t"],
  flute: ["d"],
  fluti: ["n", "s"],
  fly: ["a", "b", "c", "e", "i", "l", "o", "p", "s", "t", "w"],
  flyb: ["l", "y"],
  flys: ["h", "p", "w"],
  flyw: ["a", "e", "h"],
  flywh: ["e", "i"],
  fm: [],
  fo: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "u",
    "v",
    "w",
    "x",
    "y",
  ],
  foa: ["l", "m"],
  foam: ["y"],
  fob: [],
  foc: ["a", "i", "s", "u"],
  foe: ["h", "m", "t"],
  foet: ["a", "u"],
  fog: ["b", "e", "g", "h", "y"],
  fogb: ["a", "o"],
  fogg: ["i", "y"],
  foggi: ["l", "n"],
  fogy: ["i"],
  foi: ["b", "e", "l", "s"],
  foil: ["i"],
  fol: ["d", "i", "k", "l"],
  fold: ["a", "b", "e", "i", "o"],
  foli: ["a", "c", "o"],
  folia: ["g", "r", "t"],
  folk: ["d", "l", "s", "t", "w"],
  folks: ["i", "o", "y"],
  foll: ["i", "o", "y"],
  fon: ["d", "t"],
  fond: ["a", "l", "n", "u"],
  fondl: ["e", "i", "y"],
  fondu: ["e"],
  font: ["i"],
  foo: ["d", "l", "t"],
  food: ["s"],
  fool: ["e", "h", "i", "p", "s"],
  foot: ["a", "b", "e", "f", "h", "i", "l", "m", "n", "p", "r", "s", "w"],
  footb: ["a", "o", "r"],
  foote: ["d", "r"],
  footh: ["i", "o"],
  footl: ["e", "i", "o"],
  footp: ["a", "l", "r"],
  footr: ["a", "e", "o"],
  foots: ["l", "o", "t", "u"],
  footw: ["e", "o"],
  fop: ["p"],
  for: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "r",
    "s",
    "t",
    "u",
    "w",
  ],
  fora: ["g", "m", "s", "y"],
  forb: ["a", "e", "i", "o"],
  forc: ["e", "i"],
  force: ["d", "f", "m", "p"],
  forci: ["b", "n"],
  fore: [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
    "w",
  ],
  foreb: ["e", "o", "r"],
  forec: ["a", "l", "o"],
  fored: ["e", "o"],
  foref: ["a", "e", "i", "o", "r"],
  foreg: ["a", "o", "r", "u"],
  foreh: ["a", "e"],
  forel: ["a", "e", "i", "o"],
  forem: ["a", "o"],
  foren: ["a", "o", "s"],
  forep: ["a", "l"],
  fores: ["a", "e", "h", "i", "k", "t", "w"],
  foret: ["a", "e", "h", "o"],
  forew: ["a", "i", "o"],
  forg: ["a", "e", "i", "o"],
  forga: ["t", "v"],
  forge: ["r", "t"],
  forgi: ["n", "v"],
  forgo: ["t"],
  fork: ["e", "f", "l"],
  form: ["a", "b", "e", "f", "i", "l", "o", "u"],
  forma: ["l", "t"],
  forme: ["d", "r"],
  formi: ["c", "d", "n"],
  fors: ["a", "o", "w", "y"],
  forsw: ["e", "o"],
  fort: ["e", "h", "i", "n", "r", "u", "y"],
  forth: ["c", "r", "w"],
  forti: ["e", "f", "s", "t"],
  fortu: ["i", "n"],
  forty: [],
  forw: ["a", "e"],
  fos: ["s", "t"],
  foss: ["a", "e", "i"],
  fou: ["g", "l", "n", "r"],
  foul: ["a", "i", "m", "n"],
  foun: ["d", "t"],
  found: ["a", "e", "i", "l", "r"],
  fount: ["a"],
  four: ["f", "p", "s", "t"],
  fours: ["c", "o", "q"],
  fourt: ["e", "h"],
  fox: ["e", "f", "g", "h", "t", "y"],
  foxe: ["d", "s"],
  foxh: ["o", "u"],
  foxho: ["l", "u"],
  foxt: ["a", "r"],
  fp: ["o", "m", "s"],
  fr: ["s", "a", "e", "i", "o", "t", "u", "w", "y"],
  fra: ["c", "g", "i", "m", "n", "p", "t", "u", "y", "z"],
  frac: ["a", "t"],
  fract: ["i", "u"],
  frag: ["i", "m", "r"],
  fram: ["e", "i"],
  frame: ["r", "w"],
  fran: ["c", "g", "k", "t"],
  franc: ["e", "h", "i", "o"],
  frank: ["e", "f", "i", "l", "n"],
  frat: ["e", "r"],
  frau: ["d", "g", "l"],
  fraud: ["u"],
  fray: ["i"],
  fre: ["a", "c", "d", "e", "i", "n", "q", "s", "t", "u"],
  free: ["b", "d", "f", "h", "l", "m", "p", "r", "s", "t", "w", "z"],
  freeb: ["e", "i", "o"],
  freed: ["m", "o"],
  freeh: ["a", "o"],
  freel: ["a", "o", "y"],
  frees: ["i", "t"],
  freew: ["a", "h", "i"],
  freez: ["e", "i"],
  fren: ["c", "e", "z"],
  frenz: ["i", "y"],
  fres: ["c", "h"],
  fresh: ["e", "l", "m", "n", "w"],
  fret: ["f", "s", "t", "w"],
  fri: ["a", "c", "d", "e", "g", "l", "n", "p", "s", "t", "v", "z"],
  fria: ["b", "r"],
  friar: ["y"],
  fric: ["a", "t"],
  frica: ["s", "t"],
  frid: ["a", "g"],
  frie: ["d", "n", "r", "z"],
  fried: [],
  frig: ["a", "h", "i"],
  fris: ["b", "i", "k"],
  frisk: ["e", "i", "y"],
  friz: ["z"],
  frizz: ["l", "y"],
  fro: ["c", "g", "l", "m", "n", "s", "t", "u", "w", "z"],
  frog: ["g", "m", "s"],
  frogg: ["e", "y"],
  fron: ["d", "t"],
  front: ["a", "b", "e", "i", "l", "r"],
  frow: ["a", "n", "s", "z"],
  frown: ["i"],
  frows: ["t", "y"],
  fru: ["c", "g", "i", "m", "s"],
  fry: ["e", "i"],
  ft: ["c"],
  fu: ["c", "d", "e", "g", "h", "l", "m", "n", "r", "s", "t", "z"],
  fuc: ["h", "k"],
  fuck: ["e", "i"],
  fud: ["d", "g"],
  fudd: ["l", "y"],
  fue: ["h", "l"],
  fuel: [],
  fug: ["i", "u"],
  ful: ["c", "f", "l", "m", "n", "s"],
  full: ["b", "e", "n", "t", "y"],
  fully: [],
  fulm: ["a", "i"],
  fum: ["a", "b", "e", "i", "y"],
  fumi: ["g", "n"],
  fun: ["c", "d", "e", "f", "g", "i", "k", "n"],
  fund: ["a", "e", "i", "u"],
  fung: ["a", "i", "o", "u"],
  fungi: ["c"],
  fungo: ["i", "u"],
  funk: ["y"],
  funn: ["e", "i", "y"],
  funni: ["e", "l"],
  funny: [],
  fur: ["b", "c", "i", "l", "n", "o", "r", "t", "y", "z"],
  furb: ["e", "i"],
  furi: ["e", "o"],
  furl: ["o"],
  furlo: ["n", "u"],
  furn: ["a", "i"],
  furni: ["s", "t"],
  furr: ["i", "o", "y"],
  furri: ["e", "n"],
  furt: ["h", "i"],
  fus: ["e", "i", "s", "t"],
  fuse: ["d", "e", "l"],
  fusel: ["a"],
  fusi: ["l", "o"],
  fusil: ["i", "l"],
  fuss: ["b", "i", "p", "y"],
  fussi: ["l", "n"],
  fust: ["i", "y"],
  fusti: ["a", "n"],
  fut: ["i", "u"],
  fuz: ["e", "z"],
  fuze: ["e"],
  fuzz: ["y"],
  fw: ["d", "d"],
  fwd: [],
  fy: ["i", "r"],
  g: [
    "a",
    "c",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "a",
    "d",
    "e",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "t",
    "u",
    "y",
  ],
  ga: [
    "t",
    "b",
    "d",
    "e",
    "f",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  gab: ["a", "b", "e", "f", "l", "o", "r"],
  gabb: ["l", "r", "y"],
  gad: ["a", "f", "g", "o", "w"],
  gag: ["a", "e", "g"],
  gai: ["e", "l", "n", "t"],
  gain: ["e", "f", "s"],
  gait: ["e"],
  gal: ["a", "e", "i", "l", "o", "s", "u", "v"],
  gala: ["c", "h", "n", "x"],
  galan: ["g", "t"],
  gale: ["n"],
  gall: ["a", "b", "e", "i", "o", "s", "u"],
  galle: ["o", "r", "y"],
  galli: ["c", "m", "n", "p", "u", "v"],
  gallo: ["n", "p", "w"],
  gallu: ["p", "s"],
  galo: ["o", "r", "s"],
  gam: ["a", "b", "e", "i", "m", "p", "u", "y"],
  gamb: ["i", "l", "o", "r"],
  gambi: ["a", "t"],
  gambl: ["e", "i"],
  gambo: ["g", "l"],
  game: ["c", "k", "l", "s", "t", "y"],
  games: ["m", "o", "t"],
  gamet: ["e", "o"],
  gamm: ["a", "e", "o", "y"],
  gamma: [],
  gan: ["d", "g", "j", "n", "t"],
  gand: ["e", "h"],
  gang: ["e", "l", "p", "r", "s", "w"],
  gange: ["r", "s"],
  gangl: ["a", "i"],
  gant: ["l", "r"],
  gap: ["e"],
  gape: ["s"],
  gar: ["a", "b", "d", "f", "g", "i", "l", "m", "n", "r", "t"],
  garb: ["a", "e", "l"],
  garba: ["g", "n"],
  garg: ["a", "l", "o"],
  garl: ["a", "i"],
  garn: ["e", "i"],
  garne: ["r", "t"],
  garni: ["s", "t"],
  garr: ["e", "i", "o", "u"],
  gas: ["b", "e", "f", "h", "i", "k", "l", "m", "o", "p", "s", "t", "w"],
  gash: ["o"],
  gass: ["i", "y"],
  gat: ["t", "e", "h"],
  gate: ["a", "c", "d", "f", "h", "k", "p", "w"],
  gau: ["c", "d", "g", "l", "n", "s", "z"],
  gaud: ["i", "y"],
  gaudi: ["l", "n"],
  gauz: ["e", "y"],
  gav: ["a", "e", "o"],
  gave: ["l"],
  gaw: ["k", "p"],
  gawk: ["y"],
  gay: ["e", "l", "n"],
  gaz: ["e"],
  gaze: ["b", "l", "r", "t"],
  gc: ["a", "e"],
  gd: ["s"],
  ge: ["a", "c", "e", "f", "i", "l", "m", "n", "o", "r", "s", "t", "w", "y"],
  gee: ["s", "z"],
  gei: ["g", "s"],
  gel: ["a", "d", "i"],
  geld: ["i"],
  geli: ["d", "g"],
  gem: ["i", "m", "o", "s", "u"],
  gems: ["b", "t"],
  gen: ["d", "e", "g", "i", "l", "n", "o", "r", "s", "t", "u"],
  gend: ["a", "e"],
  gene: ["a", "r", "s", "t", "v"],
  gener: ["a", "i", "o"],
  genet: ["i"],
  geni: ["a", "c", "e", "i", "t", "u"],
  genit: ["a", "i", "o"],
  geno: ["a", "c", "e", "t"],
  gent: ["e", "i", "l", "o", "r", "s"],
  genti: ["a", "l"],
  gentl: ["e", "y"],
  genu: ["f", "i", "s"],
  geo: ["c", "d", "g", "l", "m", "p", "r", "s", "t"],
  geoc: ["e", "h"],
  geod: ["e", "u"],
  geode: ["s", "t"],
  geog: ["r"],
  geol: ["o"],
  geom: ["a", "e", "o"],
  geop: ["h", "o"],
  geos: ["c", "t"],
  ger: ["a", "b", "i", "m", "o", "r", "u"],
  gerb: ["e", "i"],
  germ: ["a", "i", "p"],
  germi: ["c", "n"],
  ges: ["s", "t", "u"],
  gest: ["a", "i", "u"],
  gesta: ["l", "p", "t"],
  get: ["a", "h", "t", "u"],
  geta: ["t", "w"],
  gett: ["e", "i", "y"],
  gh: ["q", "a", "e", "i", "o", "y"],
  gha: ["n", "r", "s", "t", "u"],
  ghe: ["e", "r", "t"],
  gho: ["s", "u"],
  gi: ["a", "b", "d", "f", "g", "l", "m", "n", "p", "r", "s", "t", "v", "z"],
  gib: ["b", "e", "l"],
  gibb: ["e", "o"],
  gibbe: ["r", "t"],
  gibbo: ["n", "u"],
  gig: ["a", "g", "o"],
  gigo: ["l", "t"],
  gil: ["a", "d", "l", "t"],
  gild: ["e", "i"],
  gilde: ["d", "r"],
  gill: ["i", "y"],
  gilt: [],
  gim: ["b", "c", "l", "m", "p"],
  gin: ["g", "k", "n", "s"],
  ging: ["e", "h", "i", "k"],
  ginn: ["e", "i"],
  gip: ["s"],
  gir: ["a", "d", "l", "o", "t"],
  gird: ["e", "i", "l"],
  girl: ["f", "h", "i", "y"],
  girli: ["e", "s"],
  girt: ["h"],
  gis: ["m", "t"],
  giv: ["e", "i"],
  give: ["a", "n", "r", "s"],
  given: [],
  giz: ["a", "m", "z"],
  gl: ["c", "a", "e", "i", "o", "u", "y"],
  gla: ["b", "c", "d", "m", "n", "r", "s", "u", "z"],
  glac: ["e", "i"],
  glaci: ["a", "e", "o"],
  glad: ["d", "e", "i", "l", "n", "s"],
  gladi: ["a", "o"],
  glan: ["c", "d", "s"],
  glanc: ["e", "i"],
  gland: ["u"],
  glar: ["e", "i"],
  glas: ["g", "s"],
  glass: ["b", "c", "f", "h", "m", "w", "y"],
  glaz: ["e", "i"],
  glaze: ["d"],
  glazi: ["e", "n"],
  gle: ["a", "b", "e", "n"],
  glea: ["m", "n"],
  glean: ["e", "i"],
  glee: ["f"],
  glen: ["g"],
  gli: ["b", "d", "m", "n", "o", "s", "t"],
  glib: ["l", "n"],
  glid: ["e", "i"],
  glide: ["r"],
  glim: ["m", "p"],
  glis: ["s", "t"],
  glit: ["c", "t"],
  glo: ["a", "b", "c", "o", "p", "r", "s", "t", "v", "w", "z"],
  gloa: ["m", "t"],
  glob: ["a", "e", "u"],
  globe: ["f", "t"],
  glor: ["i", "y"],
  glori: ["a", "e", "f", "o"],
  glory: [],
  glow: ["e", "i", "w"],
  glu: ["c", "e", "m", "t"],
  glue: ["y"],
  glum: ["l"],
  glut: ["e", "i", "t"],
  glute: ["a", "n", "u"],
  gly: ["c", "p"],
  glyc: ["e", "o"],
  glyco: ["g", "l"],
  gm: ["t", "c"],
  gmc: [],
  gn: ["p", "a", "e", "o", "u"],
  gna: ["r", "s", "t", "w"],
  gnaw: ["i"],
  gno: ["c", "m", "s"],
  gnom: ["e", "i", "o"],
  gnos: ["i", "t"],
  go: [
    "p",
    "a",
    "b",
    "d",
    "e",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
  ],
  goa: ["d", "l", "t"],
  goal: ["i", "k", "m"],
  goat: ["e", "h", "i", "s"],
  gob: ["b", "i", "l", "y"],
  gobb: ["e", "l"],
  gobl: ["e", "i"],
  god: ["c", "d", "f", "h", "l", "m", "o", "p", "s"],
  godd: ["a", "e"],
  godda: ["m", "u"],
  godf: ["a", "o"],
  godh: ["e", "o"],
  godl: ["e", "i", "y"],
  godli: ["k", "n"],
  gods: ["e", "o", "p"],
  goe: ["r", "s", "t"],
  goi: ["n", "t"],
  goit: ["e", "r"],
  gol: ["d", "f", "g", "i", "l", "o"],
  gold: ["b", "e", "f", "m", "s"],
  goldb: ["e", "r"],
  golf: ["e"],
  gon: ["a", "d", "e", "f", "g", "i", "n", "o"],
  gone: ["r"],
  gono: ["c", "r"],
  goo: ["b", "d", "e", "f", "k", "n", "s"],
  good: ["b", "h", "i", "l", "m", "n", "s", "w", "y"],
  goodn: ["e", "i"],
  goods: [],
  goody: [],
  goof: ["b", "y"],
  gop: ["h"],
  gor: ["d", "e", "g", "i", "m", "s", "y"],
  gorg: ["e", "o"],
  gorge: ["d", "o", "t"],
  gorm: ["a", "l"],
  gos: ["h", "l", "p", "s"],
  gosh: ["a"],
  goss: ["a", "i"],
  got: ["h", "t"],
  goth: ["i"],
  gott: ["a", "e"],
  gou: ["a", "d", "g", "l", "r", "t"],
  gour: ["d", "m"],
  gourd: ["e"],
  gourm: ["a", "e"],
  gout: ["y"],
  gov: ["e", "t"],
  gp: ["o"],
  gr: ["a", "e", "i", "o", "u", "y"],
  gra: [
    "b",
    "c",
    "d",
    "f",
    "h",
    "i",
    "m",
    "n",
    "p",
    "s",
    "t",
    "u",
    "v",
    "y",
    "z",
  ],
  grab: ["b", "e"],
  grac: ["e", "i", "k"],
  grace: ["d", "f", "l"],
  graci: ["l", "o"],
  grad: ["a", "e", "i", "u"],
  grade: ["r"],
  gradi: ["e", "n"],
  graf: ["f", "t"],
  graft: ["e", "i"],
  grai: ["l", "n"],
  grain: ["e", "s", "y"],
  gram: ["a", "m", "o", "p"],
  gramm: ["a", "e"],
  gran: ["a", "d", "g", "i", "n", "o", "t", "u"],
  grand: ["a", "c", "d", "e", "f", "i", "l", "m", "n", "p", "s", "u"],
  grann: ["i", "y"],
  grant: ["e", "o", "s"],
  grap: ["e", "h", "n", "p"],
  grape: ["f", "s", "v"],
  graph: ["i", "o"],
  gras: ["p", "s"],
  grasp: ["a", "i"],
  grass: ["h", "l", "r", "y"],
  grat: ["e", "i", "u"],
  grate: ["d", "f", "r"],
  grati: ["f", "n", "s", "t"],
  grav: ["a", "e", "i", "u", "y"],
  grave: ["d", "l", "n", "r", "s", "y"],
  gravi: ["d", "m", "n", "t"],
  gravy: [],
  gray: ["b", "i", "l", "n"],
  graz: ["e", "i"],
  graze: ["r"],
  gre: ["a", "b", "c", "e", "g", "m", "n", "w", "y"],
  grea: ["s", "t"],
  greas: ["e", "i", "y"],
  great: ["c", "e", "h", "l", "n"],
  grec: ["i", "o"],
  gree: ["c", "d", "k", "n", "t"],
  greed: ["i", "y"],
  greek: [],
  green: ["b", "e", "f", "g", "h", "i", "l", "n", "r", "s", "w"],
  greet: ["e", "i"],
  greg: ["a", "o"],
  grey: ["b", "h", "i"],
  gri: ["d", "e", "f", "l", "m", "n", "p", "s", "t", "z"],
  grid: ["d", "i"],
  grie: ["f", "v"],
  griev: ["a", "e", "i", "o"],
  gril: ["l", "s"],
  grill: ["e", "r"],
  grim: ["a", "e", "l", "m", "n", "y"],
  grin: ["d", "g"],
  grind: ["e", "i", "s"],
  grip: ["e", "p"],
  gripp: ["e", "i"],
  gris: ["a", "l", "t"],
  grist: ["l", "m"],
  grit: ["s", "t"],
  gritt: ["i", "y"],
  gro: ["a", "c", "g", "i", "m", "o", "p", "s", "t", "u", "v", "w"],
  groa: ["n", "t"],
  groat: ["s"],
  grog: ["g"],
  grogg: ["i", "y"],
  groo: ["m", "v"],
  groom: ["e", "s"],
  groov: ["e", "i", "y"],
  grop: ["e", "i"],
  gros: ["b", "c", "g", "s"],
  gross: ["e", "l", "n"],
  grot: ["e", "t"],
  grott: ["o", "y"],
  grou: ["c", "n", "p", "s", "t"],
  group: ["e", "i"],
  grout: ["i"],
  grow: ["e", "i", "l", "n", "t"],
  growl: ["e"],
  grown: ["u"],
  gru: ["b", "d", "e", "f", "m", "n", "y"],
  grub: ["b", "s"],
  grubb: ["i", "y"],
  grue: ["l", "s"],
  gruel: ["i", "l"],
  grum: ["b", "p"],
  grump: ["i", "y"],
  grun: ["d", "g", "i", "t"],
  gu: [
    "a",
    "b",
    "d",
    "e",
    "f",
    "i",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
    "y",
    "z",
  ],
  gua: ["c", "m", "n", "r", "t", "v"],
  guan: ["a", "g", "o"],
  guar: ["a", "d"],
  guard: ["e", "h", "i", "r", "s"],
  gue: ["r", "s"],
  guer: ["d", "i", "n", "r"],
  gues: ["s", "t"],
  guess: ["e", "w"],
  guest: ["h", "r"],
  gui: ["d", "l", "n", "p", "s", "t"],
  guid: ["a", "e", "o"],
  guide: ["b", "d", "l", "p", "r", "w"],
  guil: ["d", "e", "l", "t"],
  guild: ["e", "h"],
  guile: ["f", "l"],
  guill: ["e", "o"],
  guilt: ["i", "l", "y"],
  gul: ["c", "d", "f", "l", "p"],
  gulf: [],
  gull: ["e", "i", "y"],
  gulli: ["b", "v"],
  gum: ["b", "d", "m", "p", "s"],
  gun: ["b", "c", "d", "f", "g", "k", "l", "m", "n", "p", "r", "s", "w"],
  gung: ["e"],
  gunm: ["a", "e"],
  gunn: ["e", "i", "y"],
  gunne: ["l", "r"],
  gunny: ["s"],
  guns: ["h", "l", "m"],
  gur: ["g", "u"],
  gus: ["h", "s", "t"],
  gush: ["e", "i", "y"],
  gushi: ["l", "n"],
  guss: ["e", "y"],
  gust: ["a", "o", "y"],
  gut: ["l", "s", "t"],
  gutt: ["a", "e", "u", "y"],
  gutta: [],
  guv: ["n"],
  guy: ["a"],
  gy: ["s", "m", "n", "p", "r", "v"],
  gym: ["k", "n"],
  gymn: ["a", "o"],
  gyn: ["e"],
  gyp: ["s"],
  gyps: ["u", "y"],
  gypsy: [],
  gyr: ["a", "e", "f", "o", "u"],
  gyro: ["c", "s"],
  h: [
    "f",
    "g",
    "i",
    "p",
    "s",
    "u",
    "a",
    "b",
    "d",
    "e",
    "f",
    "g",
    "i",
    "m",
    "o",
    "p",
    "r",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  ha: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  hab: ["a", "e", "i"],
  habe: ["a", "r"],
  habi: ["l", "t"],
  habit: ["a", "u"],
  hac: ["i", "k"],
  hack: ["b", "e", "i", "l", "m", "n", "s", "w"],
  hacki: ["e", "n"],
  had: ["d", "e", "j", "n", "s"],
  haf: ["n", "t"],
  hag: ["f", "g", "i", "r", "u"],
  hagg: ["a", "i", "l"],
  hai: ["l", "r", "t"],
  hail: ["s"],
  hair: ["b", "c", "d", "e", "g", "i", "l", "n", "p", "s", "y"],
  hairb: ["a", "r"],
  hairc: ["l", "u"],
  haird: ["o", "r"],
  hairl: ["e", "i"],
  hairs: ["b", "p", "t"],
  hal: ["b", "c", "e", "f", "i", "l", "m", "o", "t", "v", "y"],
  half: ["b", "c", "h", "p", "t", "w"],
  halft: ["i", "o", "r"],
  halfw: ["a", "i"],
  hali: ["b", "t"],
  halit: ["e", "o"],
  hall: ["a", "e", "i", "m", "o", "s", "u", "w"],
  halle: ["l", "y"],
  hallo: ["o", "w"],
  halo: ["e", "g"],
  halt: ["e", "i"],
  ham: ["a", "b", "l", "m", "p", "s"],
  hamm: ["e", "o"],
  han: ["d", "g", "k", "o", "s", "u"],
  hand: [
    "b",
    "c",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "w",
    "y",
  ],
  handb: ["a", "i", "o", "r"],
  handc: ["a", "l", "r", "u"],
  hande: ["d", "l", "r"],
  handi: ["c", "l", "n", "w"],
  handl: ["e", "i", "o"],
  hands: ["a", "e", "h", "o", "p", "t"],
  handw: ["o", "r"],
  handy: ["m"],
  hang: ["a", "d", "e", "i", "m", "n", "o"],
  hango: ["u", "v"],
  hank: ["e", "i", "y"],
  hanky: [],
  hano: ["i", "v"],
  hans: ["a", "e", "o"],
  hap: ["h", "l", "o", "p"],
  hapl: ["e", "o", "y"],
  happ: ["e", "i", "y"],
  happi: ["l", "n"],
  happy: [],
  har: [
    "a",
    "b",
    "d",
    "e",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
  ],
  hara: ["n", "s"],
  harb: ["i", "o"],
  harbo: ["r", "u"],
  hard: ["b", "c", "e", "h", "i", "l", "n", "p", "s", "t", "w", "y"],
  hardb: ["a", "o"],
  harde: ["n", "r"],
  hardh: ["a", "e"],
  hardi: ["h", "n"],
  hardl: ["a", "i", "y"],
  hardn: ["e", "o"],
  hards: ["h", "t"],
  hardt: ["a", "o"],
  hardw: ["a", "e", "o"],
  hare: ["b", "l", "m"],
  hareb: ["e", "r"],
  hark: ["e"],
  harl: ["e", "o"],
  harle: ["m", "q", "y"],
  harm: ["f", "l", "o"],
  harp: ["i", "o", "s", "y"],
  harpi: ["n", "s"],
  harr: ["i", "o", "y"],
  harri: ["d", "e", "s"],
  hart: ["a", "e", "f"],
  harv: ["a", "e"],
  has: ["e", "h", "n", "p", "s", "t"],
  hash: ["e", "i"],
  hass: ["l", "o"],
  hast: ["a", "e", "i", "y"],
  haste: ["n"],
  hasti: ["l", "n"],
  hasty: [],
  hat: ["b", "c", "e", "h", "l", "p", "r", "t"],
  hatb: ["a", "o"],
  hate: ["f", "r"],
  hau: ["b", "g", "l", "n", "t"],
  haul: ["a", "e", "m", "s"],
  haun: ["c", "t"],
  haunt: ["e", "i"],
  haut: ["b", "e"],
  hav: ["a", "e", "i", "o"],
  have: ["n", "r"],
  haven: [],
  haver: ["s"],
  haw: ["a", "k", "s", "t"],
  hawk: ["e", "i"],
  hawke: ["d", "r", "y"],
  hay: ["c", "d", "f", "l", "m", "r", "s", "w"],
  hayf: ["i", "o"],
  haym: ["a", "o"],
  hays: ["e", "t"],
  haz: ["a", "e", "i", "y"],
  haze: ["l"],
  hazel: ["n"],
  hazi: ["l", "n"],
  hd: ["b", "k", "q", "w"],
  hdw: ["e"],
  he: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "j",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
    "x",
    "y",
  ],
  hea: ["d", "l", "p", "r", "t", "v"],
  head: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "w",
    "y",
  ],
  headb: ["a", "o"],
  heade: ["d", "r"],
  headl: ["a", "e", "i", "o"],
  headp: ["h", "i"],
  headr: ["e", "o"],
  heads: ["e", "h", "m", "t"],
  headw: ["a", "i", "o"],
  heal: ["e", "i", "t"],
  hear: ["d", "e", "i", "k", "n", "s", "t"],
  hears: ["a", "e"],
  heart: ["a", "b", "e", "f", "h", "i", "l", "r", "s", "t", "w", "y"],
  heat: ["e", "h", "i", "l", "s"],
  heate: ["d", "r"],
  heath: ["e"],
  heav: ["e", "i", "y"],
  heave: ["n", "s"],
  heavi: ["l", "n", "s"],
  heavy: ["s", "w"],
  heb: ["d", "r"],
  hebr: ["a", "e"],
  hec: ["a", "k", "t"],
  heck: ["l"],
  hect: ["a", "i", "o"],
  hecto: ["g", "l", "m", "r"],
  hed: ["g", "o"],
  hee: ["b", "d", "h", "l"],
  heed: ["f", "l"],
  heel: ["b", "e", "p"],
  heele: ["d", "r"],
  heg: ["e", "i"],
  hege: ["l", "m"],
  hei: ["f", "g", "n", "r", "s"],
  heir: ["e", "l"],
  hel: ["d", "e", "i", "l", "m", "o", "p", "s", "t", "v"],
  heli: ["c", "o", "p", "u", "x"],
  helic: ["a", "o"],
  helio: ["c", "g", "t"],
  helip: ["a", "o"],
  hell: ["c", "e", "g", "h", "i", "o", "u"],
  helle: ["b", "n"],
  helli: ["o", "s"],
  helm: ["e", "i", "s"],
  helme: ["d", "t"],
  help: ["e", "f", "i", "l", "m"],
  helpm: ["a", "e"],
  hem: ["a", "i", "l", "o", "p", "s"],
  hemi: ["n", "p", "s"],
  hemis: ["p", "t"],
  heml: ["i", "o"],
  hemo: ["g", "p", "r", "s"],
  hemop: ["h", "t"],
  hemp: ["e"],
  hen: ["b", "c", "h", "n", "p", "r"],
  henc: ["e", "h"],
  hence: ["f"],
  hep: ["a", "c", "t"],
  hepa: ["r", "t"],
  her: ["a", "b", "c", "d", "e", "i", "m", "n", "o", "p", "r", "s", "t"],
  hera: ["l"],
  herb: ["a", "i"],
  herba: ["c", "g", "l", "r"],
  herbi: ["c", "v"],
  herd: ["e", "s"],
  herds: ["m", "w"],
  here: ["a", "b", "d", "f", "i", "o", "s", "t", "u", "w"],
  herea: ["b", "f"],
  hereo: ["f", "n"],
  heret: ["i", "o"],
  hereu: ["n", "p"],
  herm: ["a", "e", "i"],
  herme: ["n", "s", "t"],
  hero: ["d", "i", "n"],
  herod: ["o"],
  heroi: ["c", "n", "s"],
  heron: ["r"],
  herr: ["i"],
  hers: ["e"],
  hes: ["i", "p", "s"],
  hew: ["e", "n"],
  hex: ["a"],
  hexa: ["g", "h", "m", "p", "v"],
  hexag: ["o", "r"],
  hey: ["d"],
  hf: [],
  hg: ["t", "w"],
  hi: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
  ],
  hib: ["a", "e", "i"],
  hic: ["c", "k"],
  hicc: ["o", "u"],
  hick: ["e", "o"],
  hid: ["a", "d", "e", "i"],
  hide: ["a", "b", "o", "r"],
  hie: ["r"],
  hier: ["a", "o"],
  hiera: ["r", "t"],
  hiero: ["g", "p"],
  hig: ["g", "h"],
  high: ["b", "c", "e", "f", "h", "j", "l", "n", "r", "t", "w"],
  highb: ["a", "o", "r"],
  highf: ["a", "l"],
  highl: ["a", "i", "y"],
  hij: ["a", "r"],
  hik: ["e", "i"],
  hike: ["r"],
  hil: ["a", "l", "t"],
  hill: ["b", "o", "s", "t", "y"],
  him: ["a", "s"],
  hin: ["c", "d", "g", "t"],
  hind: ["e", "i", "m", "q", "r", "s", "u"],
  hindu: ["i", "s"],
  hint: ["e"],
  hip: ["b", "f", "p", "s"],
  hipb: ["a", "o"],
  hipp: ["e", "i", "o", "y"],
  hippo: ["c", "d", "g", "p"],
  hir: ["e", "s"],
  hire: ["l", "s"],
  his: ["p", "s", "t"],
  hiss: ["i"],
  hist: ["a", "o", "r"],
  histo: ["g", "l", "r"],
  hit: ["c", "h", "l", "t"],
  hitt: ["e", "i"],
  ho: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  hoa: ["g", "r", "x"],
  hoag: ["i", "y"],
  hoar: ["d", "f", "h", "i", "s", "y"],
  hoard: ["e", "i"],
  hoax: ["e"],
  hob: ["b", "g", "n", "o", "s"],
  hobb: ["l", "y"],
  hobby: ["h"],
  hobn: ["a", "o"],
  hoc: ["k", "u"],
  hock: ["e", "s"],
  hod: ["g"],
  hodg: ["e", "k"],
  hoe: ["c", "d"],
  hog: ["a", "b", "g", "m", "s", "t", "w"],
  hogg: ["e", "i"],
  hoggi: ["n", "s"],
  hoi: ["s", "t"],
  hok: ["e", "u"],
  hol: ["d", "e", "i", "l", "m", "o", "s", "y"],
  hold: ["a", "b", "e", "i", "o", "u"],
  hole: [],
  holi: ["d", "e", "n", "s"],
  holin: ["e", "g"],
  holl: ["a", "e", "o", "y"],
  hollo: ["w"],
  holly: ["h", "w"],
  holm: ["e", "i"],
  holo: ["c", "g"],
  holoc: ["a", "e", "r"],
  holy: ["s"],
  hom: ["a", "b", "e", "i", "o", "u", "y"],
  home: ["b", "c", "g", "l", "m", "o", "r", "s", "t", "w", "y"],
  homeb: ["o", "r"],
  homel: ["a", "e", "i", "y"],
  homeo: ["p", "s"],
  homer: ["i", "o", "u"],
  homes: ["i", "p", "t"],
  homew: ["a", "o"],
  homi: ["c", "l", "n"],
  homil: ["e", "i", "y"],
  homin: ["g", "y"],
  homo: ["e", "g", "l", "n", "p", "s"],
  homog: ["e", "r"],
  hon: ["c", "d", "e", "g", "k", "o"],
  hone: ["s", "y"],
  honey: ["b", "c", "d", "e", "m", "s"],
  honk: ["i", "y"],
  honky: [],
  hono: ["l", "r", "u"],
  honor: ["a", "i"],
  hoo: ["c", "d", "e", "f", "k", "l", "p", "r", "s", "t", "v"],
  hood: ["e", "l", "o", "w", "y"],
  hoof: ["e"],
  hook: ["a", "e", "u", "w", "y"],
  hooke: ["d", "r"],
  hoop: ["l", "s"],
  hoos: ["e", "g", "i"],
  hoot: ["e"],
  hoote: ["n", "r"],
  hop: ["e", "h", "l", "p", "s"],
  hope: ["f", "l"],
  hopp: ["e", "i"],
  hoppe: ["d", "r"],
  hops: ["a", "c"],
  hor: ["a", "d", "e", "i", "m", "n", "o", "r", "s", "t"],
  hora: ["c", "t"],
  horn: ["b", "e", "l", "p", "y"],
  hornb: ["e", "i", "o"],
  horne: ["d", "t"],
  hornl: ["e", "i"],
  horo: ["l", "s"],
  horr: ["e", "i", "o"],
  horri: ["b", "d", "f"],
  hors: ["e", "y"],
  horse: ["b", "f", "h", "l", "m", "p", "r", "s", "t", "w", "y"],
  hort: ["a", "i"],
  hos: ["a", "e", "i", "p", "t"],
  hosp: ["i"],
  hospi: ["c", "t"],
  host: ["a", "e", "i", "l"],
  hoste: ["l", "s"],
  hosti: ["l", "n"],
  hot: ["b", "c", "d", "e", "f", "h", "l", "n", "p", "r", "s", "t"],
  hotb: ["e", "o"],
  hotc: ["a", "h"],
  hoth: ["e", "o"],
  hotl: ["i", "y"],
  hots: ["h", "p"],
  hou: ["n", "r", "s"],
  hour: ["g", "i", "l", "s"],
  hous: ["e", "i", "t"],
  house: ["b", "c", "d", "f", "h", "k", "l", "m", "r", "s", "t", "w"],
  how: ["b", "d", "e", "i", "l", "s"],
  howd: ["a", "y"],
  howe: ["v"],
  howl: ["e", "i"],
  hoy: ["d", "l"],
  hr: ["s"],
  ht: ["s"],
  hu: [
    "d",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "l",
    "m",
    "n",
    "r",
    "s",
    "t",
    "z",
  ],
  hub: ["b", "c", "r"],
  hubb: ["l", "u", "y"],
  hud: ["d", "s"],
  hue: ["d"],
  hug: ["e", "g", "o", "u"],
  hul: ["a", "k", "l"],
  hula: [],
  hulk: ["i"],
  hull: ["a", "e", "o"],
  hum: ["a", "b", "d", "e", "i", "m", "o", "p", "u"],
  humb: ["l", "u"],
  humbl: ["e", "y"],
  humd: ["i", "r"],
  humi: ["c", "d", "l"],
  humid: ["i", "o"],
  humm: ["e", "i", "o"],
  humo: ["r", "u"],
  humor: ["a", "i", "l", "o"],
  hump: ["b", "e", "h"],
  hun: ["c", "d", "g", "k", "n", "t"],
  hung: ["a", "e", "r"],
  hungr: ["i", "y"],
  hunk: ["e", "s", "y"],
  hunky: [],
  hunt: ["e", "i", "r", "s"],
  hur: ["d", "l", "o", "r", "t"],
  hurd: ["l", "y"],
  hurl: ["e", "i", "y"],
  hurr: ["a", "i", "y"],
  hurra: ["h", "y"],
  hurri: ["c", "e"],
  hurt: ["f", "l"],
  hus: ["b", "h", "k", "s", "t"],
  hush: ["i", "p"],
  husk: ["e", "i", "y"],
  huski: ["l", "n"],
  huss: ["a", "y"],
  hust: ["i", "l"],
  hut: ["c", "m", "z"],
  hy: ["a", "b", "d", "e", "g", "i", "m", "o", "p", "r", "s"],
  hya: ["c", "e", "l"],
  hyd: ["e", "r"],
  hydr: ["a", "i", "o"],
  hydra: ["n", "t", "u", "z"],
  hydri: ["c", "d"],
  hydro: ["c", "d", "e", "f", "g", "l", "m", "p", "q", "s", "t", "u", "x"],
  hyg: ["i", "r"],
  hym: ["e", "n"],
  hymn: ["a", "o"],
  hyp: ["e", "h", "n", "o"],
  hype: ["r"],
  hyper: ["a", "b", "c", "i", "k", "m", "o", "p", "s", "t"],
  hypn: ["a", "o"],
  hypno: ["s", "t"],
  hypo: ["c", "d", "g", "p", "s", "t"],
  hypoc: ["e", "h", "r"],
  hypot: ["e", "h"],
  hys: ["s", "t"],
  i: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "t",
    "u",
    "w",
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
  ],
  ia: ["m"],
  ib: ["e", "i"],
  ibe: ["r", "x"],
  ibi: ["d", "s"],
  ibid: ["e"],
  ic: ["b", "c", "j", "u", "e", "h", "i", "k", "o", "t", "y"],
  ice: ["b", "c", "d", "f", "h", "l", "m", "p"],
  iceb: ["e", "o", "r"],
  icebo: ["u", "x"],
  icel: ["a"],
  ich: ["n", "o", "t"],
  ici: ["c", "l", "n"],
  icin: ["e", "g"],
  ico: ["n", "s"],
  icon: ["o"],
  icono: ["c", "g"],
  id: ["a", "e", "i", "l", "o", "y"],
  ida: ["h"],
  ide: ["a", "m", "n", "o", "s"],
  idea: ["l", "t"],
  ideal: ["i", "l"],
  ideo: ["g", "l"],
  idl: ["e", "y"],
  idle: ["n", "r"],
  if: ["f"],
  ig: ["y", "l", "n", "u"],
  igl: ["o", "u"],
  ign: ["e", "i", "o"],
  igni: ["s", "t"],
  ignit: ["e", "i"],
  igno: ["b", "m", "r"],
  ignor: ["a", "e"],
  il: ["o", "s", "e", "i", "k", "l"],
  ile: ["i", "u", "x"],
  ili: ["a", "u"],
  ilia: ["c", "d"],
  ill: ["e", "i", "n", "o", "u"],
  illi: ["b", "c", "m", "n", "t"],
  illu: ["m", "s"],
  illus: ["i", "o", "t"],
  im: ["f", "a", "b", "i", "m", "p"],
  ima: ["g", "m"],
  imag: ["e", "i", "o"],
  image: ["r"],
  imagi: ["n", "s"],
  imb: ["a", "e", "i", "r", "u"],
  imbe: ["c", "d"],
  imbr: ["i", "o", "u"],
  imm: ["a", "e", "i", "o", "u"],
  imma: ["c", "n", "t"],
  immat: ["e", "u"],
  imme: ["a", "d", "m", "n", "r"],
  immi: ["g", "n", "s", "t"],
  immo: ["b", "d", "l", "r", "t", "v"],
  immor: ["a", "t"],
  immu: ["n", "r", "t"],
  immun: ["e", "i", "o"],
  imp: ["a", "e", "i", "l", "o", "r", "u"],
  impa: ["c", "i", "l", "n", "r", "s", "t"],
  impal: ["a", "e", "p"],
  impas: ["s", "t"],
  impe: ["a", "c", "d", "l", "n", "r", "t"],
  impea: ["c", "r"],
  impec: ["c", "u"],
  imped: ["a", "e", "i"],
  impel: ["l"],
  impen: ["d", "e", "i"],
  imper: ["a", "c", "f", "i", "m", "s", "t", "v"],
  impet: ["i", "u"],
  impi: ["e", "n", "o", "s"],
  impl: ["a", "e", "i", "o", "y"],
  impla: ["c", "n", "u"],
  impli: ["c", "e"],
  implo: ["d", "r", "s"],
  impo: ["l", "n", "r", "s", "t", "u", "v"],
  impos: ["e", "i", "s", "t"],
  impr: ["a", "e", "i", "o", "u"],
  impre: ["c", "g", "s"],
  impri: ["m", "n", "s"],
  impro: ["b", "m", "p", "v"],
  impu: ["d", "g", "i", "l", "n", "r", "t"],
  impur: ["e", "i"],
  imput: ["a", "e"],
  in: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
  ],
  ina: ["b", "c", "d", "l", "m", "n", "p", "r", "s", "t", "u"],
  inac: ["c", "t"],
  inacc: ["e", "u"],
  inad: ["e", "m", "v"],
  inadv: ["e", "i"],
  inan: ["e", "i"],
  inani: ["m", "t"],
  inap: ["p", "t"],
  inapp: ["l", "o", "r"],
  inapt: ["i"],
  inau: ["d", "g", "s"],
  inb: ["o", "r"],
  inbo: ["a", "r", "u"],
  inc: ["a", "e", "h", "i", "l", "o", "r", "u"],
  inca: ["l", "n", "p", "r", "s", "u"],
  incan: ["d", "t"],
  incar: ["c", "n"],
  ince: ["n", "p", "r", "s"],
  incen: ["d", "s", "t"],
  inces: ["s", "t"],
  inch: ["e", "o", "w"],
  inci: ["d", "n", "p", "s", "t", "v"],
  incis: ["e", "i", "o"],
  incl: ["e", "i", "o", "u"],
  inclu: ["d", "s"],
  inco: ["g", "h", "m", "n", "o", "r"],
  incog: ["n"],
  incom: ["b", "e", "i", "m", "p"],
  incon: ["c", "g", "s", "t", "v"],
  incor: ["p", "r"],
  incr: ["e", "i", "u"],
  incre: ["a", "d", "m"],
  incu: ["b", "l", "m", "r", "s"],
  incub: ["a", "u"],
  incul: ["c", "p"],
  incur: ["a", "i", "s", "v"],
  ind: ["e", "i", "o", "r", "u", "w"],
  inde: ["b", "c", "e", "f", "l", "m", "n", "p", "s", "t", "x"],
  indec: ["e", "i", "o"],
  indef: ["a", "e", "i"],
  indes: ["c", "t"],
  index: ["e", "i"],
  indi: ["a", "c", "e", "f", "g", "r", "s", "t", "u", "v"],
  india: ["n"],
  indic: ["a", "e", "i", "t"],
  indig: ["e", "n", "o"],
  indis: ["c", "p", "s", "t"],
  indo: ["c", "l", "m", "n", "o", "r"],
  indoc: ["h", "i", "t"],
  indu: ["b", "c", "e", "l", "r", "s"],
  induc: ["e", "t"],
  indus: ["t"],
  ine: ["b", "d", "f", "l", "p", "q", "r", "s", "v", "x"],
  ined: ["i", "u"],
  inel: ["a", "e", "i", "u"],
  iner: ["a", "r", "t"],
  inert: ["i", "n"],
  ines: ["c", "s", "t"],
  inex: ["a", "c", "h", "o", "p", "t"],
  inexp: ["e", "i", "l", "r"],
  inext: ["i", "r"],
  inf: ["a", "e", "i", "l", "o", "r", "u"],
  infa: ["l", "m", "n", "r", "t"],
  infam: ["o", "y"],
  infan: ["c", "t"],
  infe: ["a", "c", "l", "r", "s"],
  infer: ["e", "i", "n", "t"],
  infi: ["d", "e", "g", "l", "n", "r"],
  infin: ["i"],
  infl: ["a", "e", "i", "o", "u"],
  infla: ["m", "t"],
  infle: ["c", "x"],
  inflo: ["r", "w"],
  influ: ["e", "x"],
  info: ["l", "r"],
  infr: ["a", "e", "i"],
  infra: ["c", "r", "s", "t"],
  infu: ["r", "s"],
  infus: ["e", "i"],
  ing: ["a", "e", "l", "o", "r", "u"],
  inge: ["n", "s"],
  ingen: ["i", "u"],
  ingl: ["e", "o"],
  ingo: ["i", "t"],
  ingr: ["a", "e", "o"],
  ingra: ["f", "i", "t"],
  ingre: ["d", "s"],
  inh: ["a", "e", "i", "o", "u"],
  inha: ["b", "l", "r"],
  inhal: ["a", "e"],
  ini: ["m", "q", "t"],
  init: ["i"],
  inj: ["e", "u"],
  inju: ["d", "n", "r", "s"],
  injur: ["e", "i", "y"],
  ink: ["b", "i", "l", "p", "s", "w", "y"],
  inkb: ["l", "o"],
  inkp: ["a", "o"],
  inl: ["a", "e"],
  inla: ["i", "n", "y"],
  inm: ["a", "o"],
  inn: ["a", "e", "i", "k", "o", "s", "u"],
  inna: ["r", "t"],
  inno: ["c", "m", "v"],
  innoc: ["e", "u"],
  innu: ["e", "m"],
  ino: ["c", "f", "p", "r"],
  inop: ["e", "p"],
  inor: ["d", "g"],
  inp: ["a", "u"],
  inr: ["o", "u"],
  ins: ["a", "c", "e", "h", "i", "o", "p", "t", "u"],
  insa: ["l", "n", "t"],
  insan: ["e", "i"],
  inse: ["a", "c", "m", "n", "p", "r", "t"],
  insec: ["t", "u"],
  insen: ["s", "t"],
  insi: ["d", "g", "n", "p", "s"],
  insid: ["e", "i"],
  insig: ["h", "n"],
  insin: ["c", "u"],
  inso: ["l", "m", "u"],
  insol: ["a", "e", "u", "v"],
  insom: ["n", "u"],
  insp: ["e", "i"],
  inst: ["a", "e", "i", "r"],
  insta: ["b", "l", "n", "r", "t"],
  inste: ["a", "p"],
  insti: ["g", "l", "n", "t"],
  instr: ["u"],
  insu: ["b", "f", "l", "p", "r"],
  insub: ["o", "s"],
  insul: ["a", "i", "t"],
  insup: ["e", "p"],
  insur: ["a", "e", "g", "m", "r"],
  int: ["a", "e", "i", "l", "o", "r", "u"],
  inta: ["c", "g", "k", "n"],
  inte: ["g", "l", "m", "n", "r", "s"],
  integ: ["e", "r", "u"],
  inten: ["d", "s", "t"],
  inter: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
  ],
  into: ["l", "n", "x"],
  inton: ["a", "e"],
  intr: ["a", "e", "i", "o", "u"],
  intra: ["c", "m", "n", "s", "t", "u", "v"],
  intre: ["n", "p"],
  intri: ["c", "g", "n"],
  intro: ["d", "g", "s", "v"],
  intru: ["d", "s"],
  intu: ["b", "i", "m"],
  inu: ["l", "n", "r"],
  inv: ["a", "e", "i", "o", "u"],
  inva: ["d", "l", "r", "s"],
  inval: ["i", "u"],
  inve: ["c", "i", "n", "r", "s", "t"],
  inver: ["n", "s", "t"],
  invi: ["d", "g", "n", "o", "s", "t"],
  invig: ["i", "o"],
  invit: ["a", "e", "i"],
  invo: ["c", "i", "k", "l"],
  invol: ["u", "v"],
  inw: ["a", "r"],
  io: ["c", "u", "d", "n", "t", "w"],
  ion: ["i", "o"],
  ioni: ["a", "c", "s", "z"],
  ionia: ["n"],
  ionis: ["a", "e"],
  ioniz: ["a", "e"],
  ip: ["a", "e", "s"],
  ips: ["o"],
  ir: ["a", "b", "o", "a", "e", "i", "k", "o", "r"],
  ira: ["k", "n", "q", "s", "t"],
  iran: ["i"],
  iraq: ["i"],
  ire: ["f", "l", "n"],
  iri: ["d", "s"],
  irid: ["e", "i"],
  iris: ["h"],
  irish: ["m", "w"],
  irk: ["s"],
  iro: ["n", "q"],
  iron: ["b", "c", "i", "m", "s", "w", "y"],
  ironi: ["c", "n", "s"],
  ironw: ["a", "o"],
  irr: ["a", "e", "i", "u"],
  irra: ["d", "t"],
  irre: ["c", "d", "f", "g", "l", "m", "p", "s", "t", "v"],
  irrec: ["l", "o"],
  irred: ["e", "u"],
  irref: ["r", "u"],
  irreg: ["u"],
  irrel: ["e", "i"],
  irrem: ["e", "o"],
  irrep: ["a", "l", "r"],
  irres: ["i", "o", "p"],
  irrev: ["e", "o"],
  irri: ["g", "t"],
  is: ["a", "c", "i", "l", "m", "n", "o", "r", "s", "t"],
  isa: ["a", "i"],
  isi: ["n", "s"],
  isl: ["a", "e"],
  isla: ["m", "n"],
  islam: ["i"],
  isle: ["t"],
  iso: ["b", "l", "m", "p", "s", "t"],
  isom: ["e", "o"],
  isome: ["r", "t"],
  isos: ["c", "t"],
  isot: ["h", "o", "r"],
  isoto: ["n", "p"],
  isr: ["a"],
  ist: ["a", "h"],
  it: ["a", "v", "a", "c", "e", "i", "s", "t"],
  ita: ["l"],
  ital: ["i", "y"],
  itali: ["a", "c"],
  ite: ["m", "r"],
  item: ["i"],
  itemi: ["s", "z"],
  its: ["e", "y"],
  iv: ["i", "o", "y"],
  j: [
    "c",
    "d",
    "p",
    "v",
    "a",
    "c",
    "e",
    "g",
    "i",
    "n",
    "o",
    "p",
    "r",
    "u",
    "y",
  ],
  ja: [
    "b",
    "c",
    "d",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  jab: ["b"],
  jac: ["a", "k", "o", "q"],
  jack: ["a", "b", "d", "e", "h", "k", "l", "p", "r", "s"],
  jacka: ["l", "n", "r", "s"],
  jacke: ["r", "t"],
  jacks: ["c", "t"],
  jag: ["g", "u"],
  jai: ["a", "l", "n"],
  jail: ["b", "e", "o"],
  jailb: ["i", "r"],
  jal: ["a", "o"],
  jalo: ["p", "u"],
  jam: ["a", "b", "e", "m"],
  jamb: ["e", "o"],
  jan: ["g", "i", "u"],
  jani: ["s", "t"],
  janu: ["a", "s"],
  janus: [],
  jap: ["a", "e", "o"],
  jar: ["d", "g", "r"],
  jas: ["m", "o", "p"],
  jav: ["a", "e"],
  java: ["n"],
  jaw: ["b", "e", "i"],
  jawb: ["o", "r"],
  jay: ["b", "c", "v", "w"],
  jc: ["s", "t"],
  je: [
    "a",
    "e",
    "f",
    "h",
    "j",
    "l",
    "m",
    "n",
    "o",
    "r",
    "s",
    "t",
    "u",
    "w",
    "z",
  ],
  jea: ["l", "n"],
  jean: ["n"],
  jee: ["p", "r"],
  jeer: ["i", "s"],
  jer: ["b", "e", "i", "k", "o", "r", "s", "u"],
  jerk: ["i", "w", "y"],
  jerki: ["l", "n"],
  jes: ["s", "t", "u"],
  jess: ["a", "e"],
  jest: ["e", "i"],
  jesu: ["i", "s"],
  jet: ["l", "p", "s", "t"],
  jetl: ["a", "i"],
  jett: ["i", "y"],
  jew: ["e", "i", "r"],
  jewe: ["l", "s"],
  jewel: ["e", "l", "r", "w"],
  ji: ["b", "f", "g", "h", "l", "m", "n", "t", "v"],
  jib: ["e"],
  jig: ["g", "s"],
  jigg: ["e", "i", "l"],
  jiggl: ["e", "y"],
  jim: ["i", "j", "m", "s"],
  jin: ["g", "k", "n", "x"],
  jing: ["l", "o"],
  jingl: ["e", "i"],
  jingo: ["i"],
  jinn: ["i"],
  jit: ["n", "t"],
  jn: ["r", "t"],
  jo: [
    "a",
    "b",
    "c",
    "d",
    "g",
    "h",
    "i",
    "k",
    "l",
    "n",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  job: ["b", "h", "l"],
  jobb: ["e", "i"],
  joc: ["k", "o", "u"],
  jock: ["e", "s"],
  jocu: ["l", "n"],
  jog: ["g", "t"],
  jogg: ["e", "i", "l"],
  joh: ["a", "n"],
  john: ["n"],
  joi: ["n", "s"],
  join: ["e", "t"],
  joint: ["e", "l"],
  jok: ["e", "i"],
  joke: ["r"],
  jol: ["l", "t"],
  joll: ["i", "y"],
  jolli: ["f", "l", "t"],
  jolly: ["b"],
  jolt: ["y"],
  jon: ["a", "g", "q"],
  jona: ["h", "t"],
  jos: ["e", "h", "s", "t"],
  josh: ["u"],
  jot: ["t"],
  jott: ["e", "i"],
  jou: ["l", "n", "r", "s"],
  jour: ["n"],
  journ: ["a", "e"],
  jov: ["e", "i"],
  joy: ["a", "c", "f", "l", "o", "r", "s"],
  jp: ["n"],
  ju: [
    "b",
    "d",
    "g",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
    "x",
  ],
  jud: ["a", "d", "e", "g", "i"],
  juda: ["h", "i", "s"],
  judai: ["c", "s"],
  judas: [],
  judg: ["e", "m"],
  judge: ["m", "s"],
  jug: ["g", "o", "u"],
  jugg: ["e", "l"],
  juggl: ["e", "i"],
  jugo: ["s", "s"],
  juj: ["i", "u"],
  juju: ["b"],
  jul: ["e", "i", "y"],
  juli: ["a", "e"],
  jum: ["b", "p"],
  jumb: ["l", "o"],
  jump: ["e", "i", "s", "y"],
  jumpe: ["d", "r"],
  jumpi: ["l", "n"],
  jun: ["c", "e", "g", "i", "k", "o", "t"],
  junc: ["o", "t"],
  junct: ["i", "u"],
  june: ["a"],
  juni: ["o", "p"],
  junk: ["e", "i", "y"],
  junke: ["r", "t"],
  junky: ["a"],
  juno: ["e"],
  junt: ["a", "o"],
  jur: ["a", "i", "o", "y"],
  jura: ["l", "s"],
  juri: ["d", "s"],
  juris: ["d", "p", "t"],
  jury: ["m"],
  jut: ["e", "t"],
  juv: ["e"],
  k: [
    "d",
    "g",
    "h",
    "i",
    "k",
    "o",
    "p",
    "s",
    "w",
    "y",
    "a",
    "c",
    "e",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  ka: [
    "a",
    "b",
    "d",
    "f",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "y",
    "z",
  ],
  kab: ["o", "u"],
  kaf: ["f", "i", "t"],
  kaff: ["e", "i"],
  kai: ["l", "s"],
  kal: ["e", "i"],
  kale: ["i", "n"],
  kan: ["g", "s", "t"],
  kans: ["a"],
  kant: ["i"],
  kap: ["o", "p", "u"],
  kar: ["a", "m", "s", "t"],
  kara: ["c", "k", "t"],
  kay: ["a", "o"],
  kc: [],
  ke: ["a", "b", "d", "e", "f", "g", "l", "m", "n", "p", "r", "s", "t", "y"],
  keb: ["a", "o"],
  kee: ["l", "n", "p"],
  keel: ["e", "h", "s"],
  keen: ["e", "l", "n"],
  keep: ["e", "i", "s"],
  keg: ["l"],
  kel: ["l", "o", "p", "t", "v"],
  kelt: ["i"],
  ken: ["n", "o", "t", "y"],
  kep: ["i", "t"],
  kept: [],
  ker: ["a", "b", "c", "f", "n", "o", "s"],
  kerb: ["s"],
  kerf: ["u"],
  ket: ["c", "o", "t"],
  key: ["b", "e", "h", "l", "n", "p", "s", "w"],
  kg: ["b"],
  kh: ["a", "e", "z"],
  kha: ["k", "l", "n", "r", "z"],
  ki: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "w",
  ],
  kib: ["b", "i", "o"],
  kibb: ["l", "u"],
  kid: ["d", "n", "s", "v"],
  kidd: ["e", "i", "y"],
  kidn: ["a", "e"],
  kie: ["s", "v"],
  kil: ["i", "l", "n", "o", "t"],
  kill: ["d", "e", "i", "j"],
  killi: ["f", "n"],
  kiln: ["e"],
  kilo: ["c", "g", "h", "l", "m", "t", "w"],
  kilt: ["e"],
  kilte: ["d", "r"],
  kin: ["d", "e", "f", "g", "k", "s"],
  kind: ["e", "h", "l", "n", "r"],
  kindl: ["e", "i", "y"],
  kine: ["m", "s", "t"],
  kines: ["c", "i", "t"],
  kinet: ["i", "o"],
  king: ["b", "c", "d", "f", "l", "m", "p", "s"],
  kingb: ["i", "o"],
  kingl: ["e", "y"],
  kings: ["h"],
  kink: ["a", "y"],
  kins: ["f", "h", "m", "w"],
  kip: ["p"],
  kir: ["k", "s", "t"],
  kis: ["m", "s"],
  kiss: ["a", "e"],
  kit: ["b", "c", "e", "h", "s", "t"],
  kith: [],
  kitt: ["e", "i", "y"],
  kitty: [],
  kl: ["a", "e", "i"],
  kla: ["n", "x"],
  kle: ["e", "p"],
  kli: ["c", "e"],
  kn: ["a", "e", "i", "o", "t", "u"],
  kna: ["c", "p", "v"],
  knap: ["s", "w"],
  knav: ["e", "i"],
  knave: ["r"],
  kne: ["a", "e", "l", "w"],
  knee: ["c", "h", "l", "p"],
  kneel: ["e"],
  knel: ["l", "t"],
  kni: ["c", "f", "g", "s", "t", "v"],
  knit: ["s", "t", "w"],
  knitt: ["e", "i"],
  kno: ["b", "c", "l", "t", "u", "w"],
  knob: ["b", "k"],
  knobb: ["e", "l", "y"],
  knot: ["h", "t"],
  knott: ["e", "y"],
  know: ["a", "e", "i", "l", "n"],
  knu: ["c", "r"],
  ko: ["a", "b", "h", "l", "o", "p", "r", "s", "t", "w"],
  kol: ["a", "i"],
  kola: [],
  kop: ["e", "j", "p"],
  kope: ["c", "k"],
  kor: ["a", "e", "u"],
  kos: ["h", "m"],
  kp: ["h", "h"],
  kr: ["a", "e", "i", "o", "y"],
  kra: ["a", "i", "k", "u"],
  kt: [],
  ku: ["a", "c", "d", "l", "m", "n", "o", "r", "w"],
  kud: ["o", "u", "z"],
  kum: ["m", "q"],
  kur: ["d", "u"],
  kurd: ["i"],
  kw: ["a", "e", "h"],
  kwh: [],
  ky: ["r"],
  l: [
    "a",
    "d",
    "e",
    "f",
    "g",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "t",
    "z",
    "a",
    "b",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "o",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  la: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  lab: ["e", "i", "o", "r", "u", "y"],
  labi: ["a", "l", "u"],
  labia: ["l"],
  labil: ["e", "i"],
  labo: ["r", "u"],
  labor: ["a", "e", "i", "s"],
  labr: ["a", "u"],
  lac: ["e", "h", "i", "k", "o", "q", "r", "t", "u", "y"],
  lace: ["d", "r"],
  lack: ["a", "e", "i", "l"],
  lacr: ["i", "o"],
  lact: ["a", "e", "i", "o"],
  lacu: ["n", "s"],
  lad: ["d", "e", "i", "l", "y"],
  ladd: ["e", "i", "y"],
  lade: ["n"],
  ladi: ["e", "n"],
  ladin: ["g", "o"],
  lady: ["b", "f", "l", "s"],
  ladyb: ["i", "u"],
  ladyl: ["i", "o"],
  lag: ["e", "g", "n", "o"],
  lagg: ["a", "i"],
  lai: ["d", "n", "r", "s", "t"],
  laid: [],
  lair: ["d"],
  lam: ["a", "b", "e", "i", "m", "p"],
  lama: ["i", "r", "s", "z"],
  lamb: ["a", "d", "e", "k", "l", "s"],
  lame: ["b", "l", "n", "s"],
  lamel: ["l", "y"],
  lamen: ["e", "t"],
  lami: ["a", "n"],
  lamp: ["b", "l", "o", "p", "r", "s"],
  lan: ["a", "c", "d", "e", "g", "k", "o", "s", "t", "u", "y"],
  land: ["r", "a", "e", "f", "h", "i", "l", "m", "o", "s", "w"],
  lande: ["d", "r"],
  landf: ["a", "i"],
  landl: ["a", "e", "o", "u"],
  landm: ["a", "i"],
  lands: ["c", "l", "m"],
  lang: ["u"],
  langu: ["a", "i", "o"],
  lank: ["i", "y"],
  lanki: ["l", "n"],
  lant: ["a", "e", "h"],
  lao: ["s", "t"],
  lap: ["a", "b", "d", "e", "i", "l", "p", "s", "w"],
  lapi: ["d", "n", "s"],
  lapp: ["e", "i"],
  lar: ["b", "c", "d", "e", "g", "i", "k", "r", "v", "y"],
  larc: ["e", "h"],
  lard: ["e"],
  larg: ["e", "i", "o"],
  large: ["l", "n", "s"],
  lark: ["s"],
  las: ["a", "c", "e", "h", "s", "t"],
  lash: ["i"],
  lass: ["i", "o"],
  last: ["e", "i", "l"],
  lat: ["a", "c", "e", "h", "i", "r", "t", "v"],
  late: ["c", "e", "l", "n", "r", "s", "x"],
  laten: ["c", "e", "t"],
  later: ["a", "i"],
  lath: ["e"],
  lathe: ["r"],
  lati: ["n", "t"],
  latin: ["i", "o"],
  latt: ["e", "i"],
  lau: ["d", "g", "n", "r"],
  laud: ["a"],
  lauda: ["b", "n", "t"],
  laun: ["c", "d"],
  laund: ["e", "r"],
  lav: ["a", "e", "i"],
  lava: ["b", "g", "l", "t"],
  lave: ["n", "r"],
  law: ["b", "f", "g", "l", "m", "n", "r", "s", "y"],
  lawn: ["m"],
  lax: ["a", "i", "n"],
  lay: ["a", "e", "i", "m", "o", "w"],
  laya: ["b", "w"],
  laye: ["r", "t"],
  layer: ["i"],
  layo: ["f", "u", "v"],
  laz: ["a", "e", "i", "y"],
  lazi: ["l", "n"],
  lazy: ["b"],
  lb: [],
  ld: ["c", "g"],
  le: [
    "d",
    "m",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "k",
    "m",
    "n",
    "o",
    "p",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
  ],
  lea: ["c", "d", "f", "g", "k", "l", "n", "p", "r", "s", "t", "v"],
  lead: ["e", "i"],
  leade: ["d", "n", "r"],
  leaf: ["a", "e", "h", "l", "s", "y"],
  leak: ["a", "i", "p", "y"],
  lean: ["i", "n", "t"],
  leap: ["e", "f", "i", "t"],
  leas: ["e", "h", "i", "t"],
  lease: ["h"],
  least: ["w"],
  leav: ["e", "i"],
  leave: ["d", "n", "r", "s"],
  lec: ["h", "i", "t"],
  lech: ["e"],
  lect: ["e", "i", "o", "u"],
  led: ["g"],
  lee: ["c", "k", "r", "s", "w"],
  leer: ["i", "y"],
  leg: ["a", "e", "g", "h", "i", "l", "m", "r", "u", "w"],
  lega: ["c", "l", "t"],
  legal: ["e", "i", "l"],
  legat: ["e", "i", "o"],
  lege: ["n", "r"],
  leger: ["d"],
  legg: ["e", "i", "y"],
  legi: ["b", "o", "s", "t"],
  legib: ["i", "l"],
  legis: ["l"],
  legit: ["i"],
  lei: ["p", "s", "t"],
  lem: ["m", "o", "p", "u"],
  lemm: ["a", "i"],
  len: ["d", "g", "i", "s", "t"],
  lend: ["e", "i", "s"],
  leni: ["e", "n", "t"],
  lenin: ["g"],
  lenit: ["i", "y"],
  lent: ["e", "i", "o"],
  lenti: ["c", "l"],
  leo: ["n", "p", "t"],
  leon: ["a", "i"],
  lep: ["e", "i", "r"],
  lepr: ["e", "o"],
  lepro: ["s", "u"],
  les: ["b", "e", "i", "o", "s", "t"],
  less: ["e", "o"],
  lesse: ["e", "n", "r"],
  lesso: ["n", "r"],
  let: ["d", "h", "t", "u"],
  lett: ["e", "i", "u"],
  leu: ["c", "k"],
  leuk: ["a", "e", "o"],
  lev: ["a", "e", "i", "o", "y"],
  leva: ["n", "t"],
  leve: ["e", "l", "r"],
  level: ["e", "h", "i", "l"],
  lever: ["a", "e"],
  levi: ["a", "t"],
  levit: ["a", "e", "i", "y"],
  lg: [],
  li: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
    "z",
  ],
  lia: ["b", "i", "n", "r"],
  liab: ["i", "l"],
  lib: ["a", "b", "e", "i", "r", "y"],
  libe: ["l", "r"],
  libel: ["l", "o"],
  liber: ["a", "i", "t"],
  libr: ["a", "e"],
  libra: ["r", "t"],
  lic: ["e", "h", "i", "k", "o"],
  lice: ["n"],
  licen: ["c", "s", "t"],
  lich: ["e", "i"],
  liche: ["e", "n"],
  lick: ["e", "i"],
  lid: ["a", "d", "l", "o"],
  lie: ["c", "d", "f", "g", "n", "u"],
  lieu: ["t"],
  lieut: ["e"],
  lif: ["e", "t"],
  life: ["b", "c", "g", "j", "l", "r", "s", "t", "w"],
  lifeb: ["e", "l", "o", "u"],
  lifel: ["e", "i", "o"],
  lifes: ["a", "t"],
  lift: ["b", "e", "i", "m", "o"],
  lig: ["a", "e", "h", "n"],
  liga: ["m", "t"],
  ligat: ["e", "i", "u"],
  lign: ["e", "i", "u"],
  ligni: ["f", "n", "t"],
  lik: ["a", "e", "i"],
  like: ["a", "l", "m", "n", "w"],
  likel: ["i", "y"],
  liken: ["e"],
  lil: ["a", "l", "o", "t", "y"],
  lily: ["w"],
  lim: ["a", "b", "e", "i", "n", "o", "p", "y"],
  lima: [],
  limb: ["e", "l", "o", "u"],
  limbe: ["d", "r"],
  lime: ["a", "j", "k", "l", "r", "s", "y"],
  limn: ["i"],
  limo: ["n", "u"],
  limp: ["e", "i", "n"],
  lin: ["a", "c", "d", "e", "g", "i", "k", "n", "o", "s", "t", "y"],
  linc: ["h", "o"],
  lind: ["b", "e"],
  line: ["a", "b", "m", "n", "o", "r", "s", "u", "y"],
  linea: ["g", "l", "m", "r"],
  linen: [],
  liner: ["t"],
  lines: ["h", "m"],
  ling: ["e", "o", "u"],
  lingu: ["a", "i"],
  lini: ["m", "n"],
  link: ["a", "i", "m", "s", "u"],
  linn: ["a", "e"],
  linne: ["a", "t"],
  lino: ["c", "l", "t"],
  lint: ["e"],
  lip: ["a", "i", "l", "p", "s"],
  lips: ["t", "y"],
  liq: ["u"],
  liqu: ["e", "i", "o"],
  lique: ["f", "s", "u"],
  lis: ["b", "l", "p", "s", "t", "z"],
  lisp: ["i"],
  list: ["e", "i", "l", "s"],
  lit: ["a", "c", "e", "h", "i", "m", "o", "r", "t", "u"],
  lite: ["r"],
  liter: ["a"],
  lith: ["e", "i", "o", "u"],
  lithe: ["l"],
  lithi: ["c", "u"],
  litho: ["g", "l", "s", "t"],
  litt: ["e", "l", "o"],
  liv: ["a", "e", "i"],
  live: ["a", "d", "l", "n", "r", "s"],
  livel: ["i", "o", "y"],
  liver: ["i", "p", "w", "y"],
  lives: ["t"],
  livi: ["d", "n"],
  livid: ["i"],
  ll: ["a"],
  lla: ["m", "n"],
  lo: [
    "l",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  loa: ["d", "f", "m", "n", "t", "v"],
  load: ["e", "i", "s"],
  loade: ["d", "r"],
  loaf: ["e", "s"],
  loam: ["y"],
  loan: ["i", "w"],
  lob: ["b", "e", "l", "o", "s", "u"],
  lobe: ["d", "l"],
  loc: ["a", "h", "i", "k", "o", "u"],
  loca: ["l", "t"],
  local: ["e", "i", "l"],
  locat: ["e", "i", "o"],
  lock: ["e", "j", "k", "n", "o", "s", "u"],
  locke: ["r", "t"],
  locks: ["m", "t"],
  loco: ["m", "w"],
  locu: ["m", "s", "t"],
  locus: ["t"],
  lod: ["e", "g"],
  lode: ["n", "s"],
  lodg: ["e", "i", "m"],
  lodge: ["d", "m", "r"],
  log: ["a", "b", "e", "g", "i", "j", "o", "r", "w", "y"],
  loga: ["n", "r"],
  logg: ["e", "i"],
  logge: ["d", "r"],
  loggi: ["a", "n"],
  logi: ["c", "s"],
  logic: ["a", "i", "s"],
  logo: ["g", "s", "t"],
  loi: ["d", "n", "r", "t"],
  loin: ["c"],
  lol: ["l"],
  loll: ["i", "o", "y"],
  lom: ["a", "b"],
  lon: ["d", "e", "g"],
  lone: ["l", "s"],
  lonel: ["i", "y"],
  long: ["b", "e", "h", "i", "l", "s", "t", "w"],
  longe: ["r", "v"],
  longh: ["a", "e", "o"],
  longi: ["n", "s", "t"],
  longs: ["h", "t", "u"],
  longw: ["a", "e", "i"],
  loo: ["f", "k", "m", "n", "p", "s", "t"],
  look: ["e", "i", "o"],
  loom: ["i"],
  loon: ["y"],
  loony: [],
  loop: ["e", "h", "i"],
  loot: ["e"],
  lop: ["e", "p", "s"],
  loq: ["u"],
  lor: ["a", "d", "e", "g", "i", "n", "r"],
  lord: ["i", "l", "o", "s"],
  lordl: ["i", "y"],
  lore: ["l"],
  los: ["e", "i", "s", "t"],
  lose: ["r"],
  loss: ["l"],
  lost: [],
  lot: ["h", "i", "t", "u"],
  loth: ["a"],
  lott: ["e", "o"],
  lou: ["d", "g", "i", "n", "p", "r", "s", "t", "v"],
  loud: ["h", "l", "m", "n", "s"],
  lous: ["e", "y"],
  lout: ["i"],
  louv: ["e", "r"],
  lov: ["a", "e", "i"],
  lova: ["b", "g"],
  love: ["a", "b", "c", "l", "m", "r", "s", "y"],
  lovel: ["e", "i", "o", "y"],
  loves: ["e", "i"],
  low: ["b", "d", "e", "i", "l"],
  lowb: ["o", "r"],
  lowbo: ["r", "y"],
  lowbr: ["e", "o"],
  lowe: ["r", "s"],
  lower: ["c", "i", "m", "y"],
  lowl: ["a", "i", "y"],
  lp: ["g"],
  ls: ["d", "s", "d"],
  lt: ["l", "d"],
  lu: [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "x",
    "z",
  ],
  lua: ["u"],
  lub: ["b", "e", "r"],
  lubb: ["e", "o"],
  luc: ["e", "i", "k", "r", "u"],
  luce: ["n", "r"],
  luci: ["d", "f"],
  lucid: ["i", "l"],
  luck: ["i", "l", "y"],
  lucki: ["l", "n"],
  lucky: [],
  lucr: ["a", "e"],
  lud: ["d", "i", "o"],
  lug: ["g", "h", "s", "u", "w"],
  lugg: ["a", "e"],
  lum: ["b", "e", "i", "m", "p"],
  lumb: ["a", "e"],
  lumba: ["g", "r"],
  lumm: ["e", "o"],
  lump: ["i", "y"],
  lumpi: ["n", "s"],
  lun: ["a", "c", "e", "g", "k"],
  luna: ["c", "r", "t"],
  lunar: [],
  lunat: ["e", "i"],
  lung: ["e", "f", "p"],
  lunge: ["d"],
  lup: ["i", "u"],
  lur: ["c", "e", "g", "i", "k"],
  lurk: ["e"],
  lus: ["c", "h", "t"],
  lust: ["e", "f", "i", "r", "y"],
  lustr: ["a", "e", "o"],
  lut: ["a", "e", "h"],
  lute: ["i", "n", "t"],
  lux: ["e", "u"],
  ly: ["c", "d", "e", "i", "m", "n", "o", "r", "s"],
  lyc: ["a", "e", "h"],
  lyce: ["e", "u"],
  lych: ["e"],
  lyn: ["c", "x"],
  lynx: [],
  lyr: ["e", "i"],
  lyre: ["b"],
  lyri: ["c", "s"],
  lyric: ["a", "i"],
  lys: ["e", "i"],
  lysi: ["n", "s"],
  m: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "a",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "y",
  ],
  ma: [
    "s",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  mac: ["a", "b", "e", "h", "i", "k", "r", "u"],
  maca: ["b", "d", "o", "q", "r", "w"],
  mace: ["b", "d", "r"],
  mach: ["e", "i", "o"],
  machi: ["a", "c", "n", "s"],
  mack: ["e", "i"],
  macr: ["a", "o"],
  macro: ["b", "c", "n", "s"],
  mad: ["a", "c", "d", "e", "h", "i", "l", "m", "n", "o", "r", "w"],
  mada: ["g", "m"],
  madam: ["e"],
  madd: ["e", "i"],
  madde: ["n", "r"],
  made: ["i", "m"],
  madr: ["a", "i"],
  madri: ["d", "g"],
  mae: ["l", "n", "s", "t"],
  mag: ["a", "d", "e", "g", "i", "m", "n", "p", "u", "y"],
  mage: ["l", "n"],
  magi: ["c", "s"],
  magic: ["a", "i"],
  magn: ["a", "e", "i", "o", "u"],
  magna: ["n", "t"],
  magne: ["s", "t"],
  magni: ["f", "l", "t"],
  magu: ["e", "s"],
  mah: ["a", "i", "j", "l", "o"],
  maha: ["l", "r", "t"],
  maho: ["g", "m", "u"],
  mai: ["d", "l", "m", "n", "s", "t", "z"],
  maid: ["e", "s"],
  mail: ["b", "e", "g", "i", "l", "m", "w"],
  mailb: ["a", "o"],
  main: ["e", "f", "l", "m", "s", "t"],
  mainl: ["a", "i", "y"],
  mains: ["a", "p", "t"],
  maint: ["a", "e"],
  maj: ["g", "e", "o"],
  majo: ["l", "r"],
  major: ["c", "d", "e", "i"],
  mak: ["e", "i"],
  make: ["r", "s", "u"],
  mal: ["a", "c", "d", "e", "f", "i", "l", "m", "n", "o", "p", "t"],
  mala: ["c", "d", "g", "i", "m", "p", "r", "w", "y"],
  malac: ["c", "h"],
  malad: ["a", "j", "m", "r", "y"],
  malap: ["e", "r"],
  malar: ["i", "k"],
  malay: ["a", "s"],
  male: ["d", "f", "v"],
  malef: ["a", "i"],
  malf: ["e", "o", "u"],
  mali: ["c", "g", "n"],
  malic: ["e", "i"],
  mall: ["a", "e", "o"],
  malle: ["a", "o", "t", "u"],
  maln: ["o", "u"],
  malo: ["c", "d"],
  malt: ["a", "e", "h", "i", "o", "r", "s", "y"],
  malte: ["d", "s"],
  mam: ["a", "b", "m"],
  mamb: ["a", "o"],
  mamm: ["a", "o", "y"],
  mamma: ["l", "r"],
  mammo: ["g", "n", "t"],
  man: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "t",
    "u",
    "x",
    "y",
    "z",
  ],
  mana: ["c", "g", "n", "t"],
  manag: ["e", "i"],
  mand: ["a", "i", "o", "r"],
  manda: ["m", "r", "t"],
  mandr: ["a", "e", "i"],
  mane: ["g", "s", "u"],
  mang: ["a", "e", "l", "o", "r", "y"],
  mange: ["r"],
  mango: ["s"],
  manh: ["a", "o", "u"],
  manha: ["n", "t"],
  manho: ["l", "o"],
  mani: ["a", "c", "f", "k", "l", "o", "p", "t"],
  mania: ["c"],
  manic: ["h", "u"],
  manif: ["e", "o"],
  manil: ["a", "l"],
  manip: ["l", "u"],
  manl: ["i", "y"],
  manli: ["k", "n"],
  mann: ["a", "e", "i"],
  manne: ["d", "q", "r"],
  manni: ["k", "s", "t"],
  mano: ["e", "m", "r"],
  manor: ["i"],
  mans: ["a", "e", "i", "l", "u"],
  manse: ["r"],
  mant: ["a", "e", "i", "l", "r"],
  manti: ["l", "s"],
  manu: ["a", "f", "m", "r", "s"],
  manuf: ["a"],
  manus: ["c"],
  manx: [],
  many: [],
  mao: ["i", "r"],
  map: ["l", "p"],
  mar: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "j",
    "k",
    "l",
    "m",
    "o",
    "q",
    "r",
    "s",
    "t",
    "v",
    "x",
    "y",
    "z",
  ],
  mara: ["b", "c", "s", "t", "u"],
  maras: ["c", "m"],
  marc: ["a", "h", "o"],
  march: ["e", "i"],
  marco: ["n"],
  mare: [],
  marg: ["a", "e", "i", "r", "u"],
  mari: ["a", "c", "e", "g", "h", "j", "m", "n", "o", "t"],
  maria: ["c", "n"],
  marin: ["a", "e"],
  marit: ["a", "i"],
  mark: ["d", "e", "i", "k", "s", "u"],
  marke: ["d", "r", "t"],
  marks: ["m", "w"],
  marl: ["i"],
  marm: ["a", "o"],
  marmo: ["r", "s", "t"],
  maro: ["n", "o"],
  marr: ["i", "o", "y"],
  marri: ["a", "e"],
  mars: ["a", "e", "h", "u"],
  marsh: ["a", "m", "y"],
  mart: ["e", "i", "y"],
  marti: ["a", "n"],
  marx: ["i"],
  mary: ["l"],
  mas: ["h", "c", "e", "h", "k", "o", "q", "s", "t"],
  masc: ["a", "o", "u"],
  masco: ["n", "t"],
  mash: ["e", "i"],
  mask: ["e", "i"],
  maske: ["d", "r"],
  maso: ["c", "n"],
  mason: ["i", "r"],
  mass: ["a", "c", "e", "i", "l", "y"],
  massa: ["c", "g"],
  masse: ["u"],
  massi: ["f", "v"],
  mast: ["e", "h", "i", "o", "u"],
  maste: ["c", "r"],
  masti: ["c", "f", "t"],
  masto: ["d", "i"],
  mat: ["a", "c", "e", "h", "i", "r", "t", "u", "z"],
  mate: ["r", "y"],
  mater: ["i", "n"],
  math: ["e"],
  matr: ["i", "o"],
  matri: ["a", "c", "m", "x"],
  matt: ["e", "h", "i", "o", "r"],
  matte: ["d", "r"],
  matu: ["r", "t"],
  matur: ["a", "e", "i"],
  mau: ["d", "l", "n", "p", "r", "s", "v"],
  maul: ["i", "s"],
  mav: ["e", "i"],
  mave: ["n", "r"],
  maw: ["k"],
  may: ["a", "b", "d", "f", "h", "n", "o", "p", "s", "v"],
  maya: ["n"],
  mayo: ["n", "r"],
  mayor: ["a", "e", "s"],
  maz: ["e", "u", "y"],
  mc: ["c", "k"],
  mcc: ["a", "o"],
  md: ["n"],
  me: [
    "a",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "w",
    "x",
    "z",
  ],
  mea: ["d", "g", "l", "n", "s", "t"],
  mead: ["o"],
  meag: ["e", "r"],
  meal: ["t", "y"],
  mealy: [],
  mean: ["d", "i", "l", "n", "s", "t", "w"],
  means: [],
  meant: ["i"],
  meas: ["l", "u"],
  measl: ["e", "y"],
  meat: ["a", "b", "h", "i", "l", "p", "u", "y"],
  mec: ["c", "h", "o"],
  mech: ["a"],
  med: ["a", "d", "e", "i", "l", "u"],
  medi: ["a", "c", "e", "n", "o", "t", "u"],
  media: ["e", "l", "n", "s", "t"],
  medic: ["a", "i", "o", "s"],
  medit: ["a", "e"],
  medl: ["a", "e"],
  mee: ["d", "k", "r", "t"],
  meek: ["l", "n"],
  meer: ["k", "s"],
  meet: ["i"],
  meg: ["a", "i", "o", "r"],
  mega: ["c", "h", "l", "p", "t"],
  megal: ["i", "o"],
  mel: ["a", "b", "d", "e", "i", "l", "o", "t"],
  mela: ["m", "n"],
  melan: ["c", "e", "g", "i", "o"],
  melb: ["a", "o"],
  mell: ["i", "o"],
  melo: ["d", "n"],
  melod: ["i", "r", "y"],
  melt: ["i"],
  mem: ["b", "e", "o", "p"],
  memb: ["e", "r"],
  memo: ["i", "r"],
  memor: ["a", "i", "y"],
  men: ["a", "d", "e", "f", "h", "i", "n", "o", "s", "t", "u"],
  mena: ["c", "d", "g"],
  menac: ["e", "i"],
  mend: ["a", "e", "i", "s"],
  mende: ["l", "r"],
  mendi: ["c", "n"],
  meni: ["a", "n", "s"],
  meno: ["p", "r"],
  mens: ["c", "e", "t", "u", "w"],
  ment: ["a", "h", "i", "o"],
  mer: ["c", "e", "g", "i", "l", "m", "o", "r"],
  merc: ["a", "e", "h", "i", "u", "y"],
  merca: ["n", "t"],
  merce: ["n", "r"],
  merci: ["f", "l"],
  mercy: [],
  mere: ["l", "t"],
  merg: ["a", "e"],
  merge: ["r"],
  meri: ["d", "n", "s", "t"],
  merin: ["g", "o"],
  merit: ["o"],
  merl: ["e", "i"],
  merr: ["i", "y"],
  merri: ["l", "m"],
  merry: ["m"],
  mes: ["a", "c", "d", "e", "h", "m", "o", "q", "s", "t"],
  mesa: ["l"],
  mesd: ["a", "e"],
  mesh: ["e", "w"],
  meso: ["n", "p", "s", "z"],
  mess: ["a", "e", "i", "m", "r", "u", "y"],
  messe: ["i", "n"],
  messi: ["a", "e", "l"],
  met: ["a", "e", "h", "i", "o", "r", "t"],
  meta: ["b", "c", "g", "l", "m", "p", "s", "t"],
  metal: ["a", "e", "l", "w"],
  metat: ["a", "e", "h"],
  mete: ["m", "o", "r"],
  meter: [],
  meth: ["a", "i", "o", "u", "y"],
  metho: ["d", "u"],
  meti: ["c", "e"],
  metr: ["e", "i", "o"],
  metro: ["l", "n", "p"],
  mew: ["l", "s"],
  mex: ["i"],
  mez: ["c", "z"],
  mezz: ["a", "o"],
  mezzo: ["t"],
  mg: ["r", "t"],
  mi: [
    "a",
    "g",
    "r",
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "r",
    "s",
    "t",
    "x",
    "z",
  ],
  mia: ["m", "o", "s"],
  mic: ["a", "e", "h", "k", "r"],
  mich: ["a", "e", "i"],
  mick: ["e"],
  micr: ["a", "o"],
  micro: ["a", "b", "c", "e", "f", "g", "m", "n", "o", "p", "s", "w"],
  mid: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "w",
    "y",
  ],
  mida: ["i", "s"],
  midd: ["a", "e", "l", "y"],
  middl: ["e", "i"],
  middy: [],
  midr: ["a", "i"],
  midri: ["b", "f"],
  mids: ["h", "t", "u"],
  midst: ["r"],
  midt: ["e", "o"],
  midw: ["a", "e", "i"],
  midwe: ["e", "s"],
  midwi: ["c", "f", "n"],
  mig: ["h", "n", "r"],
  mik: ["a", "e"],
  mil: ["a", "c", "d", "e", "f", "i", "k", "l", "o", "q", "t", "w"],
  mila: ["d", "n"],
  milan: ["e"],
  mild: ["e", "l", "n"],
  mile: ["a", "o", "p", "r", "s"],
  mili: ["e", "t"],
  milit: ["a", "i"],
  milk: ["e", "i", "m", "s", "w", "y"],
  milks: ["h", "o"],
  milky: [],
  mill: ["a", "b", "d", "e", "i", "p", "r", "s", "w"],
  mille: ["d", "n", "p", "r", "t"],
  milli: ["a", "b", "g", "l", "m", "n", "o", "p", "s", "v"],
  millw: ["h", "o", "r"],
  milo: ["m", "r"],
  milt: ["o"],
  mim: ["e", "i", "o"],
  mime: ["o", "s", "t"],
  mimeo: ["g"],
  min: ["a", "c", "d", "e", "g", "i", "k", "n", "o", "s", "t", "u", "x", "y"],
  mina: ["r", "t"],
  minc: ["e", "i"],
  mince: ["m", "r"],
  mind: ["e", "f", "i", "l"],
  minde: ["d", "r"],
  mine: ["f", "l", "r", "s"],
  miner: ["a", "v"],
  mines: ["t", "w"],
  ming: ["l", "y"],
  mini: ["a", "b", "m", "n", "o", "s", "v"],
  minib: ["i", "u"],
  minim: ["a", "i", "u"],
  minis: ["c", "e", "k", "t"],
  minn: ["e", "o"],
  minne: ["a", "s"],
  mino: ["a", "r", "s", "t"],
  minor: ["i"],
  mint: ["a"],
  minu: ["e", "s", "t"],
  minue: ["n", "t"],
  minus: ["c"],
  minut: ["e", "i"],
  mir: ["v", "a", "e", "r", "t", "y"],
  mira: ["c", "g"],
  mirac: ["l", "u"],
  mis: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
  ],
  misa: ["d", "l", "n", "p"],
  misc: ["a", "e", "h", "i", "o", "r", "u"],
  misca: ["l", "r", "s"],
  misce: ["g", "l"],
  misch: ["a", "i"],
  misco: ["n", "u"],
  misd: ["a", "e", "i", "o"],
  misde: ["a", "e", "m"],
  misdo: ["i"],
  misf: ["e", "i", "o"],
  misfi: ["l", "r", "t"],
  misg: ["i", "o", "u"],
  mish: ["a", "e", "i", "m"],
  misha: ["n", "p"],
  misl: ["a", "e"],
  misla: ["b", "y"],
  misle: ["a", "d"],
  misn: ["a", "o"],
  misp: ["e", "l", "r"],
  mispr: ["i", "o"],
  misr: ["e", "u"],
  misre: ["a", "p"],
  miss: ["a", "h", "i", "o", "p", "t", "u", "y"],
  missi: ["l", "n", "o", "s", "v"],
  misst: ["a", "e"],
  mist: ["a", "e", "i", "l", "o", "r", "y"],
  misti: ["l", "m", "n"],
  mistr: ["a", "e", "i", "u"],
  misu: ["n", "s"],
  mit: ["e", "i", "o", "r", "t"],
  mite: ["r"],
  mito: ["s", "t"],
  mitr: ["a", "e"],
  mitt: ["e"],
  mix: ["e", "t"],
  mixe: ["d", "r"],
  mixed: [],
  mk: ["s", "t"],
  ml: ["l"],
  mm: ["e"],
  mn: ["e"],
  mo: [
    "a",
    "b",
    "c",
    "d",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "z",
  ],
  moa: ["n", "t"],
  moat: ["e"],
  mob: ["i", "s"],
  moc: ["c", "h", "k"],
  mock: ["e", "i", "u"],
  mod: ["e", "a", "c", "e", "i", "u"],
  mode: ["l", "r", "s"],
  model: ["e", "i"],
  moder: ["a", "n"],
  modi: ["c", "f", "s"],
  modif: ["i", "y"],
  modu: ["l", "s"],
  modul: ["a", "e", "u"],
  modus: [],
  mog: ["g", "u"],
  moh: ["a", "e", "i", "s"],
  moha: ["i", "m", "w"],
  moi: ["e", "l", "r", "s"],
  mol: ["a", "d", "e", "i", "l", "o", "t", "y"],
  mola: ["r", "s"],
  mold: ["a", "b", "e", "i", "y"],
  mole: ["c", "h", "s"],
  moles: ["k", "t"],
  moll: ["i", "u", "y"],
  molt: ["e", "o"],
  moly: ["b"],
  mom: ["e", "m"],
  momm: ["a", "y"],
  mon: ["a", "d", "e", "g", "i", "k", "o", "r", "s", "t", "u"],
  mona: ["c", "d", "r", "s", "u"],
  monad: ["i"],
  mone: ["t", "y"],
  monet: ["a", "i"],
  money: ["b", "c", "e", "g", "l", "m"],
  mong: ["e", "o", "r"],
  mongo: ["l", "o"],
  moni: ["e", "s", "t"],
  monis: ["m", "t"],
  monit: ["i", "o"],
  monk: ["e", "f", "i", "s"],
  monks: ["h"],
  mono: ["c", "d", "e", "g", "l", "m", "n", "p", "r", "s", "t", "x"],
  monoc: ["h", "l", "o", "u"],
  monog: ["a", "r"],
  monol: ["i", "o"],
  monom: ["a", "e", "i"],
  monop: ["h", "l", "o"],
  monot: ["h", "o", "y"],
  mons: ["e", "i", "o", "t"],
  monsi: ["e", "g"],
  monst: ["e", "r"],
  mont: ["a", "e", "g", "h", "p", "r"],
  monta: ["g", "n"],
  monte: ["v"],
  month: ["l"],
  moo: ["c", "d", "l", "n", "r", "s", "t"],
  mooc: ["h", "o"],
  mood: ["i", "y"],
  moodi: ["l", "n"],
  moon: ["b", "c", "e", "i", "l", "r", "s", "w", "y"],
  moonl: ["e", "i"],
  moons: ["c", "e", "h", "t"],
  moor: ["h", "i", "l"],
  moori: ["n", "s"],
  mop: ["e", "p"],
  mope: ["d"],
  mor: ["a", "b", "d", "e", "g", "i", "m", "n", "o", "p", "r", "s", "t"],
  mora: ["i", "l", "s", "t", "v", "y"],
  moral: ["e", "i", "l"],
  more: ["l", "o", "s"],
  morg: ["a", "u"],
  morn: ["i"],
  moro: ["c", "n", "s"],
  moron: ["i"],
  morr: ["i", "o"],
  morro: ["w"],
  mort: ["a", "g", "i", "u"],
  morta: ["l", "r"],
  morti: ["c", "f", "s"],
  mos: ["a", "c", "e", "l", "q", "s", "t"],
  mose: ["s", "y"],
  moss: ["b", "y"],
  most: ["l"],
  mot: ["e", "h", "i", "l", "o", "t"],
  mote: ["l", "t"],
  moth: ["b", "e", "p"],
  moti: ["f", "l", "o", "v"],
  motil: ["e", "i"],
  motiv: ["a", "e"],
  moto: ["c", "r"],
  motor: ["b", "c", "i", "m", "s", "t", "w"],
  mott: ["l", "o"],
  mou: ["f", "l", "n", "r", "s", "t"],
  moul: ["d", "t"],
  mould: ["e", "i", "y"],
  moun: ["d", "t"],
  mount: ["a", "e", "i", "y"],
  mous: ["e", "i", "s", "t", "y"],
  mouse: ["h", "r", "t"],
  mouss: ["a", "e"],
  mout: ["h", "o"],
  mouth: ["e", "f", "o", "p", "w", "y"],
  mov: ["a", "e", "i"],
  move: ["a", "m", "r"],
  movi: ["e", "n"],
  movie: [],
  mow: ["e", "i", "n"],
  mp: ["g", "h"],
  mr: ["a", "s"],
  mrs: [],
  ms: ["t", "e", "g"],
  msg: ["r"],
  mt: ["n", "s"],
  mu: [
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "r",
    "s",
    "t",
    "u",
    "z",
  ],
  muc: ["h", "i", "k", "o", "u"],
  muci: ["l", "n"],
  muck: ["h", "r", "y"],
  muco: ["p", "u"],
  mud: ["d", "f", "g", "h", "p", "s"],
  mudd: ["i", "l", "y"],
  muddi: ["l", "n"],
  mudp: ["a", "u"],
  mue: ["s", "z"],
  muf: ["f", "t"],
  muff: ["i", "l"],
  mug: ["g", "s", "w"],
  mugg: ["e", "i", "y"],
  mul: ["a", "b", "c", "e", "i", "l", "t"],
  mulc: ["h", "t"],
  mule: ["s", "t"],
  mull: ["a", "e", "i"],
  mulle: ["i", "n", "t"],
  mulli: ["g", "o"],
  mum: ["b", "m", "p"],
  mumb: ["l", "o"],
  mumbl: ["e", "i"],
  mumm: ["e", "i", "y"],
  mummi: ["f", "n"],
  mun: ["c", "d", "i"],
  muni: ["c", "f", "m", "t"],
  munic: ["h", "i"],
  mur: ["a", "d", "e", "i", "k", "m", "r"],
  muri: ["a", "n"],
  murk: ["i", "y"],
  murki: ["l", "n"],
  murr: ["a", "e"],
  mus: ["c", "e", "h", "i", "k", "l", "q", "s", "t"],
  musc: ["a", "l", "o", "u"],
  musca: ["d", "t"],
  muscl: ["e", "i"],
  muse: ["t", "u"],
  mush: ["r", "y"],
  musi: ["c", "n"],
  music: ["a", "i", "o"],
  musk: ["e", "i", "m", "r", "y"],
  muske: ["g", "l", "t"],
  muss: ["e", "o", "u"],
  must: ["a", "e", "i", "n", "y"],
  musta: ["c", "n", "r"],
  mut: ["a", "e", "i", "t", "u"],
  muta: ["b", "n", "t"],
  mutab: ["i", "l"],
  mutat: ["e", "i"],
  mute: ["l", "n"],
  muti: ["l", "n", "s"],
  mutin: ["e", "g", "o", "y"],
  mutt: ["e", "o"],
  my: ["a", "c", "e", "l", "n", "o", "r", "s", "t"],
  mya: ["l", "s"],
  myc: ["e", "o"],
  myo: ["c", "e", "p", "s"],
  myr: ["i", "m", "r", "t"],
  mys: ["e", "t"],
  myst: ["e", "i"],
  mysti: ["c", "f", "q"],
  n: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "l",
    "m",
    "n",
    "o",
    "r",
    "s",
    "t",
    "v",
    "w",
    "y",
    "a",
    "b",
    "d",
    "e",
    "f",
    "i",
    "o",
    "p",
    "t",
    "u",
    "v",
    "y",
  ],
  na: [
    "a",
    "s",
    "t",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "y",
    "z",
  ],
  nab: ["o"],
  nac: ["e", "r"],
  nag: ["g"],
  nai: ["a", "f", "l", "n", "r", "v"],
  nail: ["b", "e"],
  nam: ["b", "e"],
  name: ["d", "l", "p", "s"],
  namel: ["e", "y"],
  nan: ["k", "n", "o"],
  nank: ["e", "i"],
  nap: ["a", "e", "h", "k", "l", "o", "p"],
  nape: ["r"],
  napp: ["i", "y"],
  nar: ["c", "d", "e", "k", "r", "t", "w"],
  narc: ["i", "o"],
  narco: ["s", "t"],
  nark: ["y"],
  narr: ["a", "o"],
  nas: ["a", "a", "c", "h", "o", "t"],
  nasa: ["l"],
  nasal: ["i", "l"],
  nast: ["i", "u", "y"],
  nasti: ["l", "n"],
  nat: ["o", "a", "c", "e", "i", "l", "r", "t", "u"],
  nata: ["l", "t"],
  natal: ["i"],
  nati: ["o", "v"],
  nativ: ["e", "i"],
  natt: ["e", "i", "y"],
  natti: ["l", "n"],
  nau: ["g", "s", "t"],
  naut: ["c", "i"],
  nauti: ["c", "l"],
  nav: ["a", "e", "i", "v", "y"],
  nava: ["h", "l"],
  nave: ["l"],
  navel: [],
  navi: ["c", "g"],
  navy: [],
  naz: ["a", "i"],
  nazi: ["i", "s"],
  nb: ["s"],
  nc: ["o"],
  ne: [
    "a",
    "b",
    "t",
    "a",
    "b",
    "c",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
  ],
  nea: ["n", "p", "r", "t"],
  neap: ["o"],
  near: ["b", "l", "n", "s"],
  neat: ["h", "l", "n"],
  neb: ["b", "r", "u"],
  nebr: ["a"],
  nec: ["e", "k", "r", "t"],
  neck: ["b", "e", "i", "l", "t", "w"],
  necke: ["d", "r"],
  neckl: ["a", "e", "i"],
  nee: ["d"],
  need: ["f", "i", "l", "n", "s", "y"],
  neg: ["a", "l", "o", "r", "u"],
  negl: ["e", "i"],
  negr: ["e", "i", "o"],
  negro: ["i"],
  nei: ["g", "t"],
  nem: ["a", "e"],
  neo: ["c", "d", "l", "n", "p", "t"],
  neoc: ["l", "o"],
  neol: ["i", "o"],
  neon: ["a"],
  neop: ["h", "l"],
  nep: ["a", "e", "h", "l", "o", "t"],
  neph: ["e", "r"],
  ner: ["e", "o", "v"],
  nero: ["l"],
  nerv: ["e", "o", "y"],
  nerve: ["d", "l"],
  nes: ["s", "t"],
  nest: ["i", "l", "o"],
  nestl: ["e", "i"],
  net: ["b", "h", "t", "w"],
  neth: ["e"],
  nett: ["i", "l"],
  neu: ["r", "t"],
  neur: ["a", "i", "o"],
  neura: ["l", "s"],
  neuro: ["l", "m", "n", "p", "s", "t"],
  neut: ["e", "r"],
  neutr: ["a", "i", "o"],
  nev: ["a", "e", "u"],
  new: ["b", "c", "e", "f", "i", "l", "n", "p", "s", "t"],
  newc: ["a", "o"],
  newf: ["a", "o"],
  news: ["a", "b", "c", "d", "g", "l", "m", "p", "r", "s", "v", "w", "y"],
  newsm: ["a", "o"],
  newsp: ["a", "r"],
  newsr: ["e", "o"],
  newss: ["h", "t"],
  newt: ["o"],
  nex: ["t", "u"],
  next: [],
  nf: ["d", "l"],
  nh: ["i", "s"],
  ni: [
    "a",
    "b",
    "c",
    "e",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "x",
  ],
  nia: ["c", "g"],
  nib: ["b", "l", "s"],
  nic: ["a", "e", "h", "k", "o"],
  nice: ["l", "n", "t"],
  nich: ["e", "o"],
  nick: ["e", "i", "n"],
  nicke: ["l", "r"],
  nie: ["c", "l", "t"],
  nif: ["f", "t"],
  nig: ["e", "g", "h", "r"],
  nigg: ["a", "e", "l"],
  niggl: ["e", "i"],
  nigh: ["t"],
  night: ["c", "d", "f", "g", "h", "i", "j", "l", "m", "r", "s", "t", "w"],
  nil: ["e", "o"],
  nim: ["b", "r"],
  nimb: ["i", "l", "o", "u"],
  nimbl: ["e", "y"],
  nin: ["c", "e", "n", "o", "t"],
  nine: ["f", "p", "t"],
  ninet: ["e", "i", "y"],
  nip: ["p"],
  nipp: ["e", "i", "l", "o", "y"],
  nis: ["e", "i", "s"],
  nit: ["e", "p", "r", "t", "w"],
  nitr: ["a", "e", "i", "o"],
  nitri: ["c", "d", "f", "t"],
  nitro: ["c", "g", "u"],
  nix: ["i", "o"],
  nn: ["e", "w"],
  no: [
    "r",
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "z",
  ],
  nob: ["b", "e", "i", "l", "o"],
  nobl: ["e", "y"],
  noble: ["m", "s", "w"],
  noc: ["k", "t"],
  nod: ["a", "d", "e", "u"],
  nodd: ["i", "l", "y"],
  noe: ["l", "t"],
  nog: ["g"],
  nol: ["l", "o"],
  nom: ["a", "e", "i", "o"],
  non: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "h",
    "i",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
  ],
  nona: ["g", "l", "s"],
  nonag: ["e", "g", "o"],
  nonc: ["e", "h", "o"],
  nonce: [],
  nonco: ["m", "n", "o"],
  nond: ["a", "e"],
  none: ["l", "n", "s", "t", "v", "x"],
  nones: ["s", "u"],
  nonf: ["i", "l", "o"],
  nonm: ["a", "e"],
  nonme: ["m", "t"],
  nonp: ["a", "e", "l", "r"],
  nonpa: ["r", "y"],
  nonr: ["e", "i"],
  nonre: ["c", "p", "s"],
  nons: ["c", "e", "k", "m", "t", "u"],
  nonse: ["c", "n", "x"],
  nonsk: ["e", "i"],
  nonst: ["a", "i"],
  nonv: ["e", "i", "o"],
  noo: ["d", "k", "n", "s"],
  noon: ["d", "t"],
  nop: ["a", "e"],
  nor: ["a", "d", "m", "s", "t", "w"],
  norm: ["a"],
  norma: ["l", "n", "t"],
  norw: ["a", "e"],
  nos: ["e", "h", "i", "o", "t", "y"],
  nose: ["b", "c", "d", "g", "y"],
  noseb: ["a", "l"],
  nosed: ["i"],
  nosh: ["e"],
  noso: ["c", "l"],
  nost: ["a", "r"],
  nostr: ["i", "u"],
  nosy: [],
  not: ["a", "c", "e", "h", "i", "o", "r", "w"],
  nota: ["b", "r", "t"],
  notab: ["i", "l"],
  notar: ["i", "y"],
  notat: ["e", "i"],
  note: ["b", "c", "d", "p", "w"],
  noti: ["c", "f", "o"],
  notif: ["i", "y"],
  nou: ["g", "n", "r", "s", "v"],
  noug: ["a", "h"],
  nov: ["a", "e", "i", "o"],
  nova: [],
  nove: ["l", "m", "n"],
  novel: ["e", "i", "l", "t"],
  novi: ["c", "t"],
  novic: ["e", "i"],
  now: ["a", "h", "i"],
  nowa: ["d", "y"],
  noway: ["s"],
  nr: ["a", "c"],
  ns: ["a", "c", "f"],
  nt: ["h"],
  nu: ["a", "b", "c", "d", "g", "i", "k", "l", "m", "n", "p", "r", "t", "z"],
  nub: ["b", "i"],
  nubi: ["a", "l"],
  nuc: ["h", "l"],
  nud: ["e", "g", "i"],
  nudi: ["b", "s", "t"],
  nudis: ["m", "t"],
  nug: ["a", "g"],
  num: ["b", "e", "i", "s"],
  numb: ["e", "n", "s"],
  nun: ["c", "n"],
  nur: ["s", "t"],
  nurs: ["e", "i", "l"],
  nurse: ["l", "m", "r"],
  nut: ["c", "h", "m", "p", "r", "s", "t"],
  nuth: ["a", "o"],
  nuts: ["h"],
  nutt: ["e", "i", "y"],
  nv: [],
  ny: ["l", "m", "s"],
  o: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "h",
    "k",
    "m",
    "p",
    "r",
    "t",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  oa: ["p", "s", "u", "f", "k", "r", "s", "t"],
  oaf: ["i"],
  oak: ["e", "l", "u", "y"],
  oar: ["l", "s"],
  oas: ["i", "t"],
  oat: ["c", "h", "m"],
  ob: ["b", "d", "e", "f", "i", "j", "l", "n", "o", "s", "t", "v"],
  obe: ["a", "d", "i", "l", "s", "y"],
  obes: ["e", "i"],
  obi: ["t"],
  obit: ["e", "u"],
  obj: ["e", "u"],
  obje: ["c", "t"],
  obl: ["a", "i", "o"],
  obli: ["g", "q", "t", "v"],
  oblig: ["a", "e", "i"],
  oblo: ["n", "q"],
  obo: ["e", "i"],
  obs: ["c", "e", "i", "o", "t"],
  obsc: ["e", "u"],
  obse: ["q", "r", "s"],
  obst: ["a", "e", "i", "r"],
  obstr: ["e", "u"],
  obt: ["a", "r", "u"],
  obv: ["e", "i"],
  obvi: ["a", "o"],
  oc: ["a", "d", "r", "s", "a", "c", "e", "h", "t", "u"],
  oca: ["s", "r"],
  occ: ["a", "i", "l", "u"],
  occi: ["d", "p"],
  occu: ["l", "p", "r"],
  occup: ["a", "i", "y"],
  occur: ["r"],
  oce: ["a", "l"],
  och: ["e", "r"],
  oct: ["a", "e", "o"],
  octa: ["g", "n", "v"],
  octav: ["e", "o"],
  octo: ["b", "g", "p", "r", "s"],
  od: ["a", "d", "e", "i", "o", "y"],
  odd: ["b", "i", "l", "m", "n", "s"],
  odds: [],
  ode: ["s"],
  odi: ["n", "o", "u"],
  odo: ["m", "r", "u"],
  odor: ["a", "i", "l", "o"],
  oe: ["c", "o", "c", "d", "s", "u"],
  oec: ["d", "u"],
  oed: ["e", "i"],
  oes: ["o", "t"],
  of: ["f", "t"],
  off: ["a", "b", "e", "h", "i", "l", "p", "s"],
  offe: ["n", "r"],
  offen: ["c", "d", "s"],
  offer: ["i", "t"],
  offi: ["c", "n", "s"],
  offic: ["e", "i"],
  offl: ["i", "o"],
  offs: ["e", "h", "i", "p", "t"],
  oft: ["e", "t"],
  og: ["l", "r"],
  oh: ["i", "m", "o"],
  ohm: ["m"],
  oi: ["l", "n"],
  oil: ["c", "e", "f", "i", "m", "r", "s", "y"],
  oilc: ["a", "l"],
  oilca: ["k", "n"],
  oile: ["d", "r"],
  oils: ["e", "k"],
  oin: ["k", "t"],
  ok: ["a", "e", "l", "r"],
  oka: ["p", "y"],
  oke: ["y"],
  ol: ["d", "e", "f", "i", "y"],
  old: ["e", "i", "n", "s", "y"],
  olde: ["n"],
  oldi: ["e", "s"],
  ole: ["a", "o"],
  olea: ["g", "n"],
  oleo: ["g", "m"],
  oli: ["g", "o", "v"],
  olig: ["a", "o"],
  oliv: ["a", "e", "i"],
  olive: [],
  om: ["a", "b", "e", "i", "n"],
  ome: ["g", "l", "n"],
  omi: ["c", "n", "s", "t"],
  on: ["a", "c", "e", "g", "i", "l", "o", "r", "s", "t", "u", "w", "y"],
  onc: ["e", "o"],
  once: [],
  one: ["n", "r", "s"],
  onl: ["i", "o", "y"],
  ons: ["e", "h", "i", "l", "t"],
  ont: ["a", "o"],
  onto: ["g", "l"],
  oo: ["d", "f", "l", "m", "p", "z"],
  oom: ["i", "p"],
  ooz: ["e", "y"],
  op: ["e", "a", "e", "h", "i", "o", "p", "s", "t", "u"],
  opa: ["c", "l", "q"],
  opal: ["e", "i"],
  ope: ["c", "n", "r"],
  open: ["c", "e", "h", "i", "l", "m", "n", "w"],
  openh: ["a", "e"],
  oper: ["a", "e"],
  opera: ["b", "n", "t"],
  opi: ["a", "n", "u"],
  opin: ["e", "i"],
  opini: ["n", "o"],
  opp: ["o", "r"],
  oppo: ["n", "r", "s"],
  oppos: ["a", "e", "i"],
  oppr: ["e", "o"],
  opt: ["a", "i", "o"],
  opti: ["c", "m", "o"],
  optic: ["a", "i", "s"],
  optim: ["a", "i", "u"],
  opu: ["l", "s"],
  or: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "t",
    "y",
  ],
  ora: ["c", "l", "n", "t"],
  orac: ["l", "u"],
  oral: ["l"],
  orat: ["e", "i", "o"],
  orb: ["i"],
  orc: ["h"],
  orch: ["a", "e", "i"],
  ord: ["a", "e", "i", "n", "o", "u"],
  orde: ["a", "r"],
  order: ["e", "i", "l"],
  ore: ["g"],
  oreg: ["a", "o"],
  org: ["a", "i", "y"],
  orga: ["n", "s"],
  organ: ["d", "i", "o", "z"],
  orgi: ["a", "e"],
  ori: ["e", "f", "g", "o", "s"],
  orie: ["l", "n"],
  orig: ["i"],
  orio: ["l", "n"],
  orn: ["a", "e", "i"],
  orna: ["m", "t"],
  oro: ["g", "t"],
  orog: ["e", "r"],
  orr: ["e", "i"],
  ort: ["h", "o"],
  os: ["c", "i", "l", "m", "p", "s", "t", "w"],
  osc: ["a", "i", "u"],
  osm: ["i", "o"],
  osmo: ["s", "t"],
  oss: ["e", "i", "u"],
  ost: ["e", "i", "l", "m", "r"],
  oste: ["n", "o"],
  osten: ["s", "t"],
  osteo: ["a", "g", "m", "p", "s", "t"],
  ostr: ["a", "i"],
  ot: ["a", "h", "i", "o", "t"],
  oti: ["c", "o", "t"],
  oto: ["l", "r", "s"],
  otol: ["a", "i"],
  ott: ["a", "e", "o"],
  ou: ["b", "c", "g", "i", "n", "r", "s", "t", "z"],
  our: ["s"],
  ours: ["e"],
  ous: ["e", "t"],
  oust: ["e"],
  out: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
    "w",
  ],
  outb: ["a", "i", "o", "r", "u"],
  outba: ["c", "l"],
  outbo: ["a", "u"],
  outbr: ["a", "e"],
  outbu: ["i", "r"],
  outc: ["a", "l", "o", "r"],
  outcr: ["o", "y"],
  outd: ["a", "i", "o"],
  outdi: ["d", "s"],
  outdo: ["n", "o"],
  outf: ["a", "i", "l", "o"],
  outfa: ["c", "l"],
  outfi: ["e", "g", "t"],
  outfl: ["a", "o"],
  outg: ["e", "o", "r", "u"],
  outgo: ["i", "n"],
  outgr: ["e", "o"],
  outl: ["a", "e", "i", "o", "y"],
  outla: ["n", "s", "w", "y"],
  outli: ["e", "n", "v"],
  outm: ["a", "o"],
  outma: ["n", "r", "t"],
  outmo: ["d", "s"],
  outp: ["a", "l", "o", "u"],
  outpa: ["c", "t"],
  outpo: ["i", "s", "u"],
  outr: ["a", "e", "i", "o", "u"],
  outra: ["g", "n"],
  outre: ["a"],
  outri: ["d", "g", "v"],
  outs: ["e", "h", "i", "k", "m", "o", "p", "t"],
  outse: ["l", "t"],
  outsh: ["i", "o"],
  outsi: ["d", "z"],
  outsp: ["e", "o", "r"],
  outst: ["a", "r"],
  outw: ["a", "e", "i", "o"],
  outwe: ["a", "i", "n"],
  ouz: ["e", "o"],
  ov: ["a", "e", "i", "o", "u"],
  ova: ["l", "r", "t"],
  oval: [],
  ovar: ["i", "y"],
  ovat: ["e", "i"],
  ove: ["n", "r"],
  oven: ["w"],
  over: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "z",
  ],
  overa: ["c", "g", "l", "n", "r", "t", "w"],
  overb: ["a", "e", "i", "l", "o", "u"],
  overc: ["a", "h", "l", "o", "r"],
  overd: ["e", "o", "r", "u"],
  overe: ["a", "s", "x"],
  overf: ["e", "i", "l", "u"],
  overg: ["l", "r"],
  overh: ["a", "e", "u"],
  overl: ["a", "e", "i", "o", "y"],
  overm: ["a", "i", "u"],
  overp: ["a", "l", "o", "r"],
  overr: ["a", "e", "i", "u"],
  overs: ["e", "h", "i", "l", "p", "t", "u"],
  overt: ["a", "h", "i", "l", "o", "r", "u"],
  overv: ["a", "i"],
  overw: ["a", "e", "h", "o", "r"],
  ovi: ["d", "p"],
  ovid: ["i", "u"],
  ovip: ["a", "o"],
  ovo: ["i", "v"],
  ovu: ["l", "m"],
  ovul: ["a", "e"],
  ow: ["e", "i", "l", "n"],
  owl: ["e", "i"],
  own: ["e"],
  ox: ["a", "b", "c", "e", "f", "i", "o", "t", "y"],
  oxb: ["l", "o", "r"],
  oxe: ["n", "y"],
  oxy: ["a", "g", "m"],
  oy: ["e", "s"],
  oz: ["o", "s"],
  p: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "l",
    "m",
    "o",
    "r",
    "s",
    "t",
    "v",
    "w",
    "x",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  pa: [
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  pac: ["e", "h", "i", "k", "t"],
  pace: ["m", "r", "s"],
  pack: ["a", "e", "h", "i", "r", "s", "t"],
  packe: ["d", "r", "t"],
  pad: ["d", "l", "r"],
  padd: ["i", "l", "o", "y"],
  paddl: ["e", "i"],
  paddy: [],
  pae: ["a", "d", "o"],
  paed: ["e", "i"],
  pag: ["a", "e", "i", "o"],
  page: ["a", "b"],
  pai: ["d", "l", "n", "r", "s"],
  paid: [],
  pail: ["f", "l"],
  pain: ["e", "f", "k", "l", "s", "t"],
  pains: ["t"],
  paint: ["b", "e", "i", "l", "s", "w"],
  pair: ["i"],
  pal: ["a", "e", "f", "i", "l", "m", "p", "s", "t"],
  pala: ["c", "i", "n", "t", "v"],
  palan: ["k", "q"],
  palat: ["a", "e", "i"],
  pale: ["d", "f", "l", "n", "o", "s", "t"],
  paleo: ["g", "l", "n", "z"],
  pali: ["m", "n", "s"],
  palin: ["d", "g"],
  palis: ["a", "h"],
  pall: ["a", "b", "e", "i", "o", "y"],
  palla: ["d", "s"],
  palli: ["a", "d"],
  palm: ["a", "e", "i", "y"],
  palme: ["d", "r", "t"],
  palp: ["a", "i"],
  palpa: ["b", "t"],
  pals: ["i", "y"],
  palsy: [],
  palt: ["e", "r"],
  pam: ["p"],
  pamp: ["a", "e", "h"],
  pan: ["a", "c", "d", "e", "g", "h", "i", "j", "n", "o", "p", "s", "t", "z"],
  pana: ["c", "m"],
  panac: ["e", "h"],
  panc: ["a", "h", "r"],
  pand: ["a", "e", "i", "o"],
  panda: [],
  pande: ["m", "r"],
  pane: ["d", "g", "l"],
  panel: ["i", "l"],
  pang: ["o"],
  pano: ["p", "r"],
  panop: ["l", "t"],
  pant: ["a", "e", "h", "i", "o", "r", "s", "y"],
  panti: ["e", "l", "n"],
  panto: ["g", "m"],
  pants: ["u"],
  panty: ["h", "w"],
  pap: ["a", "e", "i", "o", "p", "r", "u", "y"],
  papa: ["c", "d", "l", "w", "y"],
  papi: ["e", "l", "s"],
  papu: ["a", "l"],
  par: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "q",
    "r",
    "s",
    "t",
    "v",
  ],
  para: ["b", "c", "d", "f", "g", "k", "l", "m", "n", "p", "s", "t"],
  parab: ["l", "o"],
  parac: ["h", "l"],
  parad: ["e", "i", "o"],
  parag: ["o", "r", "u"],
  paral: ["l", "y"],
  param: ["a", "e", "i", "o"],
  parap: ["e", "h", "l", "r", "s"],
  paras: ["i", "o", "y"],
  parat: ["a", "h", "r", "y"],
  parc: ["e", "h"],
  parch: ["i", "m"],
  pard: ["o"],
  pare: ["g", "n", "r", "s", "t", "v"],
  paren: ["c", "t"],
  pari: ["a", "e", "n", "s", "t"],
  paris: ["h", "i"],
  park: ["a", "i", "l", "w", "y"],
  parl: ["a", "e", "i", "o"],
  parla: ["n", "y"],
  parlo: ["r", "u"],
  paro: ["c", "d", "l", "t", "x"],
  parod: ["i", "y"],
  parr: ["a", "i", "o", "y"],
  pars: ["e", "i", "l", "n", "o"],
  part: ["a", "e", "h", "i", "l", "n", "o", "r", "u", "w", "y"],
  parte: ["d", "r"],
  parth: ["e", "i"],
  parti: ["a", "b", "c", "n", "s", "t", "z"],
  party: [],
  pas: ["c", "h", "s", "t"],
  pass: ["a", "b", "e", "i", "k", "o", "p", "w"],
  passa: ["b", "g"],
  passe: ["l", "n", "r"],
  passi: ["m", "n", "o", "v"],
  past: ["a", "e", "i", "o", "r", "u", "y"],
  paste: ["b", "l", "r", "u"],
  pasti: ["c", "l", "m", "n"],
  pastr: ["a", "y"],
  pasty: [],
  pat: ["a", "c", "e", "h", "i", "o", "r", "s", "t"],
  pate: ["l", "n", "r"],
  paten: ["c", "t"],
  pater: ["f", "n"],
  path: ["a", "e", "f", "l", "o", "w"],
  patho: ["g", "l", "s"],
  pati: ["e", "n", "o", "s"],
  patr: ["i", "o"],
  patri: ["a", "c", "m", "o", "s"],
  patro: ["l", "n"],
  patt: ["e", "y"],
  patte: ["n", "r"],
  pau: ["c", "l", "n", "p", "s"],
  paul: [],
  pav: ["a", "e", "i"],
  pave: ["d", "m", "r"],
  pavi: ["l", "n"],
  paw: ["k", "l", "n", "p"],
  pawn: ["b", "s"],
  pay: ["a", "c", "d", "e", "l", "m", "n", "o", "p", "r", "t"],
  paye: ["e", "r"],
  paym: ["a", "e"],
  payo: ["f", "l"],
  pb: ["x"],
  pc: ["b", "p", "t"],
  pd: ["t"],
  pe: [
    "n",
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "w",
    "y",
  ],
  pea: ["c", "f", "h", "k", "l", "n", "r", "s", "t"],
  peac: ["e", "h", "o"],
  peace: ["a", "f", "k", "m", "t"],
  peach: ["i", "y"],
  peak: ["e", "i", "y"],
  pear: ["l"],
  pearl: ["y"],
  peas: ["a", "e", "h"],
  peat: ["y"],
  pec: ["a", "c", "k", "t", "u"],
  peck: ["e", "i"],
  pecki: ["n", "s"],
  pect: ["i", "o"],
  pecti: ["c", "n"],
  pecu: ["l", "n"],
  pecul: ["a", "i"],
  ped: ["a", "d", "e", "i", "l", "o", "u"],
  peda: ["g", "l", "n"],
  pede: ["r", "s"],
  pedi: ["a", "c", "g", "m"],
  pedic: ["a", "e", "l", "u"],
  pee: ["k", "l", "n", "p", "r", "v", "w"],
  peek: ["a"],
  peel: ["e", "i"],
  peep: ["e", "h", "i", "s", "u"],
  peer: ["a", "e", "l"],
  peev: ["e", "i"],
  peew: ["e", "i"],
  peg: ["a", "b", "g", "m"],
  pek: ["i", "o"],
  pel: ["a", "f", "i", "l", "m", "o", "t", "v"],
  pell: ["a", "e", "m", "u"],
  pelo: ["p", "t"],
  pelt: ["i"],
  pelv: ["e", "i"],
  pelvi: ["c", "s"],
  pem: ["i", "m"],
  pen: ["a", "c", "d", "e", "g", "i", "k", "l", "m", "n", "o", "s", "t", "u"],
  pena: ["l", "n", "t"],
  penal: ["i", "t"],
  penc: ["e", "h", "i"],
  pend: ["a", "e", "i", "u"],
  pene: ["p", "t"],
  peni: ["c", "n", "s", "t"],
  penn: ["a", "e", "i", "o", "s", "y"],
  penna: ["m", "n"],
  penno: ["n", "r"],
  penny: ["r", "w"],
  pens: ["i", "t"],
  pensi: ["o", "v"],
  pent: ["a", "e", "h"],
  penta: ["c", "g", "m", "t"],
  penu: ["c", "l", "m", "r"],
  penur: ["i", "y"],
  peo: ["n", "p"],
  peon: ["a", "y"],
  pep: ["l", "p", "s", "t"],
  per: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "s",
    "t",
    "u",
    "v",
  ],
  pera: ["d", "m", "n"],
  perc: ["a", "e", "h", "i", "o", "u"],
  perce: ["i", "n", "p"],
  perch: ["a", "l"],
  perd: ["i", "u"],
  pere: ["g", "m", "n"],
  perf: ["e", "i", "o", "u"],
  perfu: ["m", "n", "s"],
  perh: ["a"],
  peri: ["a", "c", "d", "g", "h", "l", "m", "n", "o", "p", "s", "t", "v", "w"],
  peric: ["a", "l"],
  peril: ["o", "u", "y"],
  perio: ["d", "s"],
  perip: ["a", "h"],
  peris: ["c", "h", "t"],
  perk: ["i", "y"],
  perki: ["l", "n"],
  perl: ["i"],
  perm: ["a", "e", "i", "u"],
  perma: ["f", "n"],
  permi: ["s", "t"],
  pero: ["n", "r", "x"],
  perp: ["e", "l"],
  perpe: ["n", "t"],
  pers: ["e", "i", "n", "o", "p", "u"],
  perse: ["c", "v"],
  persi: ["a", "f", "m", "s"],
  persp: ["e", "i"],
  pert: ["a", "i", "l", "u"],
  pertu: ["r", "s"],
  peru: ["k", "s", "v"],
  perus: ["a", "e"],
  perv: ["a", "e", "i"],
  perva: ["d", "s"],
  pes: ["e", "k", "o", "s", "t"],
  pess: ["a", "i"],
  pest: ["e", "i", "l"],
  pesti: ["c", "f", "l"],
  pet: ["a", "e", "i", "n", "r", "t", "u"],
  peta: ["l", "r"],
  petal: ["e", "l"],
  peti: ["o", "t"],
  petit: ["e", "i"],
  petr: ["e", "i", "o"],
  petri: ["f", "n"],
  petro: ["c", "g", "l", "u"],
  pett: ["i", "y"],
  petti: ["c", "f", "n", "s"],
  petty: [],
  petu: ["l", "n"],
  pew: ["e", "i", "t"],
  pf: ["c", "d", "e"],
  pg: [],
  ph: ["p", "s", "a", "e", "i", "l", "n", "o", "r", "t", "u", "y"],
  pha: ["e", "g", "l", "n", "r", "s"],
  phag: ["e", "o"],
  phal: ["a", "l"],
  phala: ["n", "r"],
  phall: ["i", "u"],
  phar: ["a", "i", "m", "o", "y"],
  pharm: ["a"],
  phas: ["e", "i"],
  phe: ["a", "n", "w"],
  phen: ["a", "o"],
  pheno: ["b", "l", "m", "t"],
  phi: ["a", "l", "z"],
  phil: ["a", "h", "i", "o", "t"],
  phila: ["d", "n", "t"],
  philh: ["a", "e"],
  phili: ["p", "s"],
  philo: ["l", "s"],
  philt: ["e", "r"],
  phl: ["e", "o"],
  phle: ["b", "g"],
  phleb: ["i", "o"],
  phlo: ["e", "g", "x"],
  pho: ["b", "e", "n", "o", "s", "t"],
  phoe: ["b", "n"],
  phon: ["a", "e", "i", "o", "y"],
  phone: ["m", "t", "y"],
  phono: ["g", "l", "v"],
  phr: ["a", "e", "s", "y"],
  phy: ["l", "s", "t"],
  phyl: ["a", "l", "o", "u"],
  pi: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
    "x",
    "z",
  ],
  pia: ["n", "s", "z"],
  pian: ["i", "o"],
  piano: ["f", "l"],
  pic: ["a", "c", "e", "k", "n", "o", "r", "t"],
  pica: ["d", "r", "s", "y"],
  picc: ["a", "o"],
  picca: ["d", "l", "n"],
  pick: ["a", "e", "i", "l", "p", "u", "y"],
  picka: ["b", "n", "x"],
  picke: ["d", "r", "t"],
  pict: ["i", "o", "u"],
  picto: ["g", "r"],
  pid: ["d", "g"],
  pie: ["b", "c", "d", "r", "t", "z"],
  piec: ["e", "r"],
  piece: ["m", "w"],
  pied: [],
  pier: ["c"],
  pierc: ["e", "i"],
  piet: ["a", "i", "y"],
  pig: ["e", "g", "h", "l", "m", "n", "p", "s", "t", "w"],
  pigg: ["e", "i", "y"],
  piggy: ["b"],
  pigm: ["e", "y"],
  pigs: ["k", "t", "w"],
  pil: ["a", "c", "e", "f", "g", "i", "l", "o", "s"],
  pila: ["f", "s", "t", "u"],
  pilaf: ["f"],
  pile: ["a", "d", "s", "u"],
  pill: ["a", "b", "i", "o"],
  pilla: ["g", "r"],
  pillo: ["r", "w"],
  pim: ["e", "i", "p"],
  pimp: ["e", "i", "l"],
  pimpl: ["e", "y"],
  pin: [
    "a",
    "b",
    "c",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "n",
    "o",
    "p",
    "s",
    "t",
    "u",
    "w",
    "x",
    "y",
  ],
  pina: ["f", "t"],
  pinc: ["e", "h", "u"],
  pince: ["r"],
  pinch: ["b", "e", "i", "p"],
  pine: ["a", "c", "w", "y"],
  pinea: ["l", "p"],
  ping: [],
  pinh: ["e", "o"],
  pini: ["n", "o"],
  pink: ["e", "i", "n", "o", "y"],
  pinki: ["e", "n", "s"],
  pinn: ["a", "i", "y"],
  pinna: ["c", "t"],
  pino: ["c", "l", "n"],
  pinp: ["o", "r"],
  pins: ["e", "p", "t"],
  pint: ["a", "o", "s"],
  pinta: ["b", "i"],
  pinto: ["s"],
  pinw: ["h", "o"],
  piny: ["o"],
  pio: ["n", "u"],
  pion: ["e"],
  pip: ["a", "e", "i", "p", "s"],
  pipa: ["l"],
  pipe: ["d", "f", "l", "r", "t"],
  piped: ["r"],
  pipi: ["n", "t"],
  pir: ["a", "o"],
  pira: ["c", "n", "t"],
  pirat: ["e", "i"],
  piro: ["g", "u"],
  pis: ["c", "m", "s", "t"],
  pisc: ["a", "e", "i"],
  piss: ["e"],
  pist: ["a", "e", "i", "o"],
  pisto: ["l", "n"],
  pit: ["a", "c", "e", "f", "h", "i", "m", "o", "t", "u", "y"],
  pith: ["e", "i", "y"],
  pithe: ["a", "c"],
  piti: ["a", "f", "l"],
  pito: ["n", "t"],
  pitt: ["a", "e", "s"],
  pitte: ["d", "r"],
  pity: ["i"],
  pix: ["i", "y"],
  pixi: ["e", "l"],
  piz: ["a", "z"],
  pizz: ["a", "e", "i"],
  pizza: ["z"],
  pk: ["g", "w"],
  pl: ["o", "a", "e", "i", "o", "u", "y"],
  pla: ["c", "g", "i", "n", "q", "s", "t", "u", "y", "z"],
  plac: ["a", "e", "i", "k"],
  placa: ["r", "t"],
  place: ["b", "d", "k", "l", "m", "n", "r"],
  plag: ["i", "u"],
  plai: ["c", "d", "n", "t"],
  plain: ["c", "l", "n", "s", "t"],
  plait: ["e"],
  plan: ["a", "c", "e", "g", "i", "k", "n", "t"],
  plane: ["l", "r", "t"],
  plani: ["n", "s"],
  plank: ["i", "t"],
  plant: ["a", "e", "i"],
  plas: ["h", "m", "t"],
  plasm: ["a", "o"],
  plast: ["e", "i", "r"],
  plat: ["e", "f", "i", "o", "t", "y"],
  plate: ["a", "f", "l", "n"],
  plati: ["n", "t"],
  plato: ["n", "o"],
  platy: ["p"],
  plau: ["d", "s"],
  play: [
    "a",
    "b",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "w",
  ],
  playa: ["b", "c"],
  playb: ["i", "o"],
  playf: ["e", "u"],
  playg: ["i", "o", "r"],
  playt: ["h", "i"],
  ple: ["a", "b", "c", "d", "i", "n", "o", "s", "t", "u", "x"],
  plea: ["c", "d", "s", "t"],
  plead: ["e", "i"],
  pleas: ["a", "e", "i", "u"],
  pleb: ["e", "i", "s"],
  plebe: ["i"],
  pled: ["g"],
  plen: ["a", "i", "t", "u"],
  plena: ["r"],
  pleni: ["p", "t"],
  plent: ["e", "i", "y"],
  plex: ["i", "u"],
  pli: ["a", "e", "g", "m", "n", "o"],
  plia: ["b", "n"],
  pliab: ["i", "l"],
  plian: ["c", "t"],
  plie: ["d", "r"],
  plo: ["d", "n", "p", "s", "t", "u", "v", "w", "y"],
  plod: ["d"],
  plodd: ["e", "i"],
  plot: ["t"],
  plow: ["b", "m", "s"],
  plu: ["c", "g", "m", "n", "p", "r", "s", "t", "v"],
  plug: ["g", "h"],
  plugg: ["e", "i"],
  plum: ["a", "b", "e", "m", "p"],
  plumb: ["a", "e", "i"],
  plumm: ["e", "y"],
  plump: ["e", "n"],
  plun: ["d", "g", "k"],
  plus: ["h"],
  plush: ["y"],
  plut: ["a", "o"],
  pluto: ["c", "n"],
  ply: ["m", "w"],
  pm: ["k"],
  po: [
    "b",
    "c",
    "d",
    "e",
    "w",
    "a",
    "c",
    "d",
    "e",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
  ],
  poc: ["k", "o"],
  pock: ["e", "m"],
  pocke: ["d", "t"],
  poco: [],
  pod: ["g", "i"],
  podi: ["a", "u"],
  poe: ["m", "s", "t"],
  poet: ["a", "e", "i", "r"],
  pog: ["o", "r"],
  poi: ["g", "l", "n", "s"],
  poin: ["c", "s", "t"],
  point: ["b", "e", "i", "l", "s"],
  pois: ["e", "o"],
  poise: ["d"],
  pok: ["e", "i", "y"],
  poke: ["r", "y"],
  poker: [],
  pol: ["a", "d", "e", "i", "k", "l", "o", "t", "y"],
  pola: ["c", "n", "r"],
  polar: ["i", "o"],
  pole: ["a", "c", "m", "n", "s"],
  poli: ["c", "o", "s", "t"],
  polic: ["e", "y"],
  polio: ["m"],
  polit: ["b", "e", "i", "y"],
  poll: ["a", "e", "i", "s", "u", "y"],
  polla: ["c", "r"],
  polle: ["d", "n"],
  polli: ["n", "w"],
  polly: ["a", "w"],
  polo: ["n"],
  polon: ["a", "i", "y"],
  polt: ["e", "r"],
  poly: ["a", "c", "e", "g", "h", "m", "n", "p", "s", "t", "u", "v"],
  polya: ["n", "r"],
  polyc: ["h", "l"],
  polye: ["s", "t"],
  polyg: ["a", "l", "o", "r", "y"],
  polym: ["a", "e", "o"],
  polyn: ["e", "o"],
  polyp: ["h", "o"],
  polys: ["t", "y"],
  polyt: ["e", "h"],
  polyv: ["a", "i"],
  pom: ["a", "e", "m", "p"],
  poma: ["c", "d", "n"],
  pome: ["g", "l", "r"],
  pomp: ["a", "e", "o"],
  pompa: ["d", "n"],
  pompo: ["m", "n", "s", "u"],
  pon: ["c", "d", "e", "g", "i", "t", "y"],
  pond: ["e", "w"],
  pont: ["i", "o"],
  pony: ["t"],
  poo: ["c", "d", "h", "l", "n", "p", "r"],
  pooh: [],
  pool: ["i", "r"],
  poop: ["e", "i"],
  poor: ["h", "l", "m"],
  pop: ["c", "e", "g", "i", "l", "o", "p", "s", "u"],
  pope: ["r", "y"],
  popi: ["n", "s"],
  popl: ["a", "i"],
  popli: ["n", "t"],
  popp: ["a", "e", "i", "y"],
  poppe: ["r", "t"],
  poppy: ["c"],
  popu: ["l", "p"],
  popul: ["a", "i", "o"],
  por: ["c", "e", "g", "k", "n", "o", "p", "r", "t"],
  porc: ["e", "h", "i", "u"],
  pork: ["e", "p", "y"],
  porn: ["o"],
  porno: ["g"],
  poro: ["s", "u"],
  porp: ["h", "o"],
  port: ["a", "c", "e", "f", "h", "i", "l", "m", "r", "s", "u"],
  porta: ["b", "g", "l"],
  porte: ["d", "n", "r"],
  porti: ["c", "o"],
  portl: ["a", "i", "y"],
  portu: ["g", "l"],
  pos: ["e", "h", "i", "s", "t", "y"],
  pose: ["d", "i", "r", "u"],
  poss: ["e", "i", "u"],
  posse: ["s", "t"],
  post: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "u",
    "w",
  ],
  posta: ["g", "l"],
  postb: ["a", "o"],
  postc: ["a", "o"],
  poste: ["r"],
  posth: ["a", "u"],
  posti: ["l", "n"],
  postm: ["a", "i", "o"],
  postn: ["a", "u"],
  postp: ["a", "o", "r"],
  postu: ["l", "r"],
  pot: ["a", "b", "e", "f", "h", "i", "l", "o", "p", "s", "t"],
  pota: ["b", "g", "s", "t"],
  potas: ["h", "s"],
  potat: ["i", "o"],
  potb: ["e", "o"],
  potbo: ["i", "u"],
  poth: ["e", "o", "u"],
  pothe: ["a", "r"],
  potho: ["l", "o", "u"],
  potl: ["a", "u"],
  potp: ["i", "o"],
  pott: ["a", "e", "i", "y"],
  potte: ["d", "r"],
  potty: [],
  pou: ["c", "f", "l", "n", "r", "t"],
  pouf: ["f"],
  poun: ["c", "d"],
  pounc: ["e", "i"],
  pound: ["a", "e", "i"],
  pout: ["e", "i"],
  pow: ["d", "e", "w"],
  pp: ["d", "m", "r", "t"],
  pr: ["o", "a", "e", "i", "o", "u", "y"],
  pra: ["c", "e", "g", "i", "l", "m", "n", "s", "t", "w", "x", "y"],
  prae: ["s", "t"],
  prag: ["m", "u"],
  prai: ["r", "s"],
  pran: ["c", "k"],
  prank: ["i", "s"],
  prat: ["e", "f", "i", "t"],
  pray: ["e", "i"],
  pre: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "w",
    "y",
  ],
  prea: ["c", "d", "m", "r"],
  pream: ["b", "p"],
  prec: ["a", "e", "i", "l", "o", "u"],
  preca: ["m", "n", "r", "s", "u"],
  prece: ["d", "n", "p", "s"],
  preci: ["n", "o", "p", "s"],
  preco: ["c", "g", "n", "r"],
  pred: ["a", "e", "i", "o"],
  preda: ["c", "t"],
  prede: ["c", "l", "s", "t"],
  predi: ["c", "g", "l", "s"],
  pree: ["l", "m", "n", "x"],
  preem: ["i", "p"],
  pref: ["a", "e", "i", "o", "r"],
  prefa: ["b", "c", "t"],
  prefe: ["c", "r"],
  prefi: ["g", "x"],
  preh: ["e", "i"],
  prehe: ["a", "n"],
  prel: ["a", "i", "u"],
  prela: ["c", "t"],
  preli: ["m", "t"],
  prem: ["a", "e", "i", "o"],
  prema: ["r", "t"],
  premi: ["e", "l", "s", "u"],
  premo: ["l", "n"],
  preo: ["c", "p", "r"],
  prep: ["a", "o", "u"],
  prepa: ["c", "i", "r", "y"],
  prepo: ["n", "s"],
  prer: ["e", "o"],
  prere: ["c", "q"],
  pres: ["a", "b", "c", "e", "h", "i", "o", "s", "t", "u"],
  presc: ["h", "i", "r"],
  prese: ["n", "r", "t"],
  presh: ["o", "r"],
  press: ["e", "g", "i", "m", "u"],
  prest: ["i", "o", "r"],
  presu: ["m", "p"],
  pret: ["e", "o", "t", "z"],
  prete: ["e", "n", "r", "s", "x"],
  prett: ["i", "y"],
  prev: ["a", "e", "i", "u"],
  preva: ["i", "l", "r"],
  previ: ["e", "o", "s"],
  pri: ["a", "c", "d", "e", "g", "m", "n", "o", "s", "t", "v", "z"],
  pric: ["e", "k", "y"],
  price: ["d", "l", "y"],
  prick: ["i", "l"],
  prie: ["d", "r", "s"],
  prig: ["g"],
  prim: ["a", "e", "i", "l", "n", "o", "p", "r", "u"],
  prima: ["c", "e", "l", "r", "t"],
  prime: ["r", "t", "v"],
  primi: ["n", "t"],
  primo: ["g", "r"],
  primu: ["l", "s"],
  prin: ["c", "k", "t"],
  princ: ["e", "i"],
  print: ["a", "e", "i", "o"],
  pris: ["e", "m", "o", "s", "t"],
  prism: ["a"],
  priss: ["i", "y"],
  priv: ["a", "e", "i", "y"],
  priva: ["c", "t"],
  privi: ["l", "t"],
  privy: [],
  priz: ["e", "i"],
  prize: ["f", "m", "w"],
  pro: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
  ],
  prob: ["a", "e", "i", "l", "o"],
  proba: ["b", "t"],
  proc: ["a", "e", "l", "o", "r", "t", "u"],
  proca: ["i", "t"],
  proce: ["d", "e", "s"],
  procl: ["a", "i"],
  procr: ["a", "e", "u"],
  prod: ["i", "u"],
  prof: ["a", "e", "f", "i", "l", "o", "u"],
  profi: ["c", "l", "t"],
  profu: ["n", "s"],
  prog: ["e", "n", "r"],
  proge: ["n", "s"],
  progn: ["a", "o"],
  progr: ["a", "e"],
  prol: ["a", "e", "i", "o"],
  prola: ["p", "t"],
  prole: ["g", "p", "t"],
  proli: ["f", "x"],
  prolo: ["g", "n"],
  prom: ["e", "i", "o", "p", "u"],
  prome: ["n", "t"],
  promi: ["n", "s"],
  promo: ["n", "t"],
  pron: ["a", "e", "g", "o", "t", "u"],
  prone: ["n"],
  prong: ["e", "h"],
  prono: ["m", "u"],
  prop: ["a", "e", "h", "i", "j", "o", "r", "s", "u", "y"],
  propa: ["g", "n"],
  prope: ["l", "n", "r"],
  proph: ["e", "y"],
  propi: ["n", "o", "t"],
  propo: ["l", "n", "r", "s", "u"],
  pror: ["a", "o"],
  pros: ["a", "c", "e", "l", "o", "p", "t", "y"],
  prosc: ["e", "r"],
  prose: ["c", "l"],
  proso: ["d", "p"],
  prost: ["a", "h", "i", "r"],
  prot: ["a", "e", "h", "o", "r", "u"],
  prota: ["c", "g"],
  prote: ["a", "c", "g", "i", "o", "r", "s"],
  proto: ["c", "n", "p", "t", "z"],
  protr: ["a", "u"],
  prov: ["a", "e", "i", "o"],
  prove: ["n", "r"],
  provi: ["d", "n", "s"],
  provo: ["c", "k", "l", "s"],
  prow: ["e", "l"],
  prowl: ["e", "i"],
  prox: ["i", "y"],
  pru: ["d", "n", "r", "s"],
  prud: ["e", "i"],
  prude: ["n", "r"],
  prun: ["e", "i"],
  prune: ["r"],
  pry: ["e", "i"],
  ps: ["t", "a", "e", "f", "h", "i", "o", "s", "y"],
  psa: ["l"],
  psal: ["m", "t"],
  psalm: ["i", "o", "s"],
  pse: ["p", "u"],
  psi: ["t"],
  pso: ["a", "r"],
  pss: ["s", "t"],
  pt: ["a", "v", "a", "e", "o", "y"],
  pta: ["r"],
  pto: ["l", "m"],
  pu: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "z",
  ],
  pub: ["e", "i", "l"],
  pube: ["r", "s"],
  pubes: ["c"],
  pubi: ["c", "s"],
  publ: ["i"],
  publi: ["c", "s"],
  puc: ["e", "k"],
  puck: ["e", "i"],
  pud: ["d", "e", "g"],
  pudd: ["i", "l"],
  puddl: ["e", "i"],
  pudg: ["i", "y"],
  pue: ["b", "r"],
  puer: ["i", "p", "t"],
  pug: ["i", "n"],
  pul: ["c", "e", "i", "l", "m", "p", "s", "v"],
  pull: ["b", "e", "m", "o", "u"],
  pulle: ["d", "r", "t", "y"],
  pullo: ["u", "v"],
  pulp: ["i", "w", "y"],
  puls: ["a", "e"],
  pulsa: ["r", "t"],
  pum: ["a", "i", "m", "p"],
  pump: ["e", "i", "k"],
  pun: ["c", "d", "g", "i", "j", "k", "n", "s", "t", "y"],
  punc: ["h", "t"],
  punch: ["b", "e", "i", "y"],
  punct: ["i", "u"],
  puni: ["c", "s", "t"],
  punk: ["a"],
  punt: ["e"],
  pup: ["a", "i", "p"],
  pupa: ["l", "t"],
  pupat: ["e", "i"],
  pupp: ["e", "y"],
  puppy: [],
  pur: ["b", "c", "d", "e", "g", "i", "l", "p", "r", "s", "u", "v"],
  pure: ["b", "d", "e", "l", "n"],
  pureb: ["l", "r"],
  purg: ["a", "e", "i"],
  puri: ["f", "m", "s", "t"],
  purif: ["i", "y"],
  puris: ["m", "t"],
  purit: ["a", "y"],
  purl: ["e", "i", "o"],
  purli: ["e", "n"],
  purp: ["l", "o"],
  purpl: ["e", "i"],
  purpo: ["r", "s"],
  purs: ["e", "l", "u"],
  purse: ["r"],
  pursu: ["a", "e", "i"],
  purv: ["e", "i"],
  pus: ["h", "i", "s", "t"],
  push: ["b", "c", "e", "i", "o", "p", "u", "y"],
  pushb: ["i", "u"],
  pushc: ["a", "h"],
  pushe: ["d", "r"],
  pushi: ["l", "n"],
  puss: ["y"],
  pussy: ["c"],
  put: ["a", "d", "o", "r", "s", "t"],
  putr: ["e", "i"],
  putre: ["f", "s"],
  putt: ["e", "i", "y"],
  putte: ["e", "r"],
  pv: ["c", "t"],
  pw: ["t"],
  py: ["g", "j", "l", "o", "r", "t", "x"],
  pyo: ["n", "r"],
  pyr: ["a", "e", "i", "o", "r"],
  pyre: ["n", "t", "x"],
  pyrex: ["i"],
  pyri: ["d", "f", "t"],
  pyro: ["l", "m", "t"],
  pyrom: ["a", "e"],
  q: ["m", "q", "r", "t", "u", "y"],
  qm: ["c", "g"],
  qt: ["o", "y"],
  qu: ["a", "e", "i", "o", "r"],
  qua: ["c", "d", "f", "g", "h", "i", "k", "l", "n", "r", "s", "t", "v", "y"],
  quad: ["r"],
  quadr: ["a", "e", "i", "o", "u"],
  quag: ["g", "m"],
  quai: ["l", "n"],
  quak: ["e", "i"],
  quake: ["r"],
  qual: ["i", "m"],
  quali: ["f", "t"],
  quan: ["d", "t"],
  quant: ["a", "i", "u"],
  quar: ["a", "k", "r", "t"],
  quarr: ["e", "i", "y"],
  quart: ["e", "i", "o", "z"],
  quas: ["a", "h", "i"],
  quasi: [],
  quat: ["e", "r", "t"],
  quatr: ["a", "e"],
  que: ["a", "b", "e", "l", "n", "r", "s", "t", "u"],
  quea: ["n", "s"],
  queas: ["i", "y"],
  quee: ["n", "r"],
  queen: ["d", "l"],
  queer: ["l", "n"],
  quen: ["c", "e"],
  quer: ["u", "y"],
  ques: ["t"],
  quest: ["i"],
  qui: ["b", "c", "d", "e", "f", "l", "n", "p", "r", "s", "t", "v", "x", "z"],
  quic: ["h", "k"],
  quick: ["e", "i", "l", "n", "s"],
  quid: [],
  quie: ["s", "t"],
  quiet: ["e", "i", "l", "n", "u"],
  quil: ["l", "t"],
  quill: ["e"],
  quilt: ["e", "i"],
  quin: ["c", "i", "o", "q", "s", "t"],
  quint: ["a", "e", "i", "u"],
  quir: ["e", "k", "t"],
  quirk: ["e", "y"],
  quit: ["c", "e", "o", "s", "t"],
  quitt: ["a", "e"],
  quiz: ["m", "z"],
  quo: ["d", "i", "n", "r", "t"],
  quoi: ["n", "t"],
  quon: ["d", "s"],
  quot: ["a", "e", "h", "i"],
  quota: ["b", "t"],
  quoti: ["d", "e"],
  r: [
    "a",
    "c",
    "e",
    "f",
    "i",
    "n",
    "o",
    "r",
    "s",
    "t",
    "v",
    "a",
    "b",
    "c",
    "d",
    "e",
    "h",
    "i",
    "m",
    "n",
    "o",
    "p",
    "t",
    "u",
    "w",
    "x",
    "y",
  ],
  ra: [
    "c",
    "f",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  rab: ["b", "e", "i"],
  rabb: ["e", "i", "l"],
  rabbi: ["n", "t"],
  rabi: ["d", "e"],
  rabid: ["i", "l"],
  rac: ["c", "e", "h", "i", "k", "o", "q", "y"],
  race: ["c", "h", "m", "r", "t", "w"],
  racem: ["e", "o"],
  raci: ["a", "l", "n", "s"],
  racin: ["e", "g"],
  racis: ["m", "t"],
  rack: ["e", "i"],
  raco: ["n", "o"],
  rad: ["a", "i", "m", "o"],
  radi: ["a", "c", "i", "o", "s", "u"],
  radia: ["l", "n", "t"],
  radic: ["a", "l"],
  radio: ["a", "c", "g", "i", "l", "m", "p", "s", "t"],
  radiu: ["m", "s"],
  rado: ["m", "n"],
  raf: ["f", "t"],
  raff: ["i", "l"],
  raffi: ["a", "s"],
  raft: ["e", "i", "s"],
  rag: ["a", "b", "e", "g", "i", "l", "o", "p", "s", "t", "w"],
  raga: ["m"],
  rage: ["f"],
  ragg: ["e", "y"],
  ragt: ["a", "i"],
  rai: ["d", "l", "m", "n", "s"],
  raid: ["e"],
  rail: ["c", "h", "i", "l", "r", "w"],
  rain: ["b", "c", "d", "f", "l", "m", "p", "s", "w", "y"],
  rainf: ["a", "o"],
  rais: ["e", "i", "o"],
  raise: ["d", "r"],
  raj: ["a"],
  raja: ["h"],
  rak: ["e", "i"],
  rake: ["r"],
  raki: ["n", "s"],
  ram: ["a", "b", "e", "i", "j", "p", "r", "s"],
  ramb: ["l", "u"],
  rambl: ["e", "i"],
  rami: ["e", "f"],
  ramif: ["i", "y"],
  ramp: ["a"],
  rampa: ["g", "n", "r"],
  ran: ["c", "d", "e", "g", "i", "k", "s", "t", "u"],
  ranc: ["h", "i", "o"],
  ranch: ["e", "m"],
  ranco: ["r", "u"],
  rand: ["o", "y"],
  rang: ["e", "o", "y"],
  range: ["f", "r"],
  rank: ["e", "i", "l"],
  rans: ["a", "o"],
  rant: ["e", "i"],
  rap: ["a", "e", "h", "i", "p", "s", "t"],
  rape: ["r"],
  raph: ["a", "e"],
  rapi: ["d", "e", "n", "s"],
  rapid: ["i", "l"],
  rapp: ["e", "o", "r"],
  rappe: ["d", "l", "r"],
  rapt: ["o", "u"],
  rar: ["e", "i"],
  rare: ["b", "f", "l", "n"],
  raref: ["a", "i", "y"],
  rari: ["n", "t"],
  ras: ["c", "h", "p", "t"],
  rash: ["e", "l", "n"],
  rasp: ["b", "e", "i", "y"],
  rat: ["a", "c", "e", "h", "i", "l", "s", "t"],
  rata: ["b", "n"],
  rate: ["a", "p", "r"],
  rath: ["e", "s"],
  rati: ["f", "n", "o"],
  ratif: ["i", "y"],
  ratio: ["c", "n"],
  rats: ["b"],
  ratt: ["a", "e", "i", "l", "r", "y"],
  rattl: ["e", "i"],
  rau: ["c", "n"],
  rav: ["a", "e", "i"],
  rave: ["l", "n", "r"],
  raven: ["i", "o"],
  ravi: ["n", "o", "s"],
  ravin: ["e", "g"],
  raw: ["b", "h", "n"],
  ray: ["o"],
  raz: ["e", "o", "z"],
  raze: ["d"],
  razz: ["l"],
  rc: ["a", "m", "d", "p", "t"],
  re: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
  ],
  rea: ["b", "c", "d", "f", "g", "l", "m", "n", "p", "r", "s", "t"],
  reac: ["h", "t"],
  reach: ["a"],
  react: ["a", "i", "o"],
  read: ["a", "d", "e", "i", "j", "m", "o", "y"],
  readi: ["l", "n"],
  ready: ["m"],
  real: ["i", "l", "m", "n", "p", "t"],
  reali: ["g", "s", "t", "z"],
  realt: ["o", "y"],
  ream: ["e"],
  reap: ["e", "i", "p"],
  reapp: ["e", "l", "o", "r"],
  rear: ["e", "g", "m", "r", "w"],
  rearm: ["a", "o"],
  reas: ["o", "s"],
  reass: ["e", "i", "u"],
  reb: ["a", "e", "i", "l", "o", "u"],
  reba: ["r", "t"],
  rebo: ["r", "u"],
  rebu: ["f", "i", "k", "r", "s", "t"],
  rebut: ["t"],
  rec: ["a", "d", "e", "h", "i", "k", "l", "o", "r", "t", "u", "y"],
  reca: ["l", "n", "p", "s"],
  recal: ["c", "l"],
  recap: ["i", "p", "t"],
  rece: ["d", "i", "n", "p", "s"],
  recei: ["p", "v"],
  recen: ["c", "t"],
  rech: ["a", "e"],
  reci: ["d", "p", "s", "t"],
  recip: ["e", "i", "r"],
  recit: ["a", "e"],
  reck: ["l", "o"],
  recl: ["a", "i", "u"],
  recla: ["i", "m"],
  reco: ["g", "i", "l", "m", "n", "r", "u", "v"],
  recol: ["l", "o"],
  recom: ["b", "m", "p"],
  recon: ["c", "d", "f", "n", "q", "s", "v"],
  recou: ["n", "p", "r"],
  recr: ["e", "i", "o", "u"],
  recru: ["d", "i"],
  rect: ["a", "i", "o", "u"],
  recta: ["l", "n"],
  recti: ["f", "l", "t"],
  recto: ["r"],
  recu: ["m", "p", "r", "s"],
  recur: ["r", "s", "v"],
  recus: ["a", "e"],
  red: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "h",
    "i",
    "l",
    "n",
    "o",
    "r",
    "s",
    "u",
    "w",
  ],
  redb: ["r", "u"],
  redc: ["a", "o", "u"],
  redd: ["e", "i"],
  rede: ["c", "e", "m", "p", "v", "y"],
  redi: ["r", "s"],
  redis: ["c", "t"],
  redo: ["l", "u"],
  redou: ["b", "n"],
  redr: ["a", "e"],
  reds: ["k", "t"],
  redu: ["c", "n", "p"],
  reduc: ["e", "i", "t"],
  redw: ["i", "o"],
  ree: ["c", "d", "f", "k", "l", "m", "n", "s", "v", "x"],
  reed: ["i", "u", "y"],
  reef: ["e"],
  reel: ["e"],
  reen: ["a", "f", "g", "l", "t"],
  reent: ["e", "r"],
  reex: ["a", "p"],
  ref: ["a", "e", "i", "l", "o", "r", "t", "u"],
  refa: ["c", "s"],
  refas: ["h", "t"],
  refe: ["c", "r"],
  refer: ["e", "r"],
  refi: ["g", "l", "n", "t"],
  refl: ["a", "e", "o", "u"],
  refle: ["c", "x"],
  refo: ["o", "r"],
  refor: ["e", "m"],
  refr: ["a", "e", "i"],
  refra: ["c", "i", "m"],
  refu: ["e", "g", "l", "n", "r", "s", "t"],
  refus: ["a", "e"],
  refut: ["a", "e"],
  reg: ["a", "e", "g", "i", "n", "r", "u"],
  rega: ["i", "l", "r", "t"],
  regal: ["e", "i", "l"],
  regi: ["c", "m", "o", "s", "u"],
  regr: ["e", "o"],
  regre: ["s", "t"],
  regro: ["u", "w"],
  regu: ["l", "r"],
  regul: ["a", "o"],
  reh: ["a", "e", "i", "o"],
  reha: ["b", "s"],
  rei: ["c", "f", "g", "m", "n", "s", "t"],
  reif: ["i", "y"],
  reim: ["b", "p"],
  rein: ["c", "d", "f", "s", "t", "v"],
  reinc: ["a", "o"],
  reins: ["e", "t", "u"],
  reint: ["e", "r"],
  reinv: ["e", "i"],
  rej: ["e", "o", "u"],
  rel: ["a", "e", "i", "o", "u", "y"],
  rela: ["p", "t", "x", "y"],
  relat: ["e", "i"],
  relax: ["a", "i"],
  relay: [],
  rele: ["a", "g", "n", "v"],
  reli: ["a", "c", "e", "g", "n", "q", "s", "v"],
  relia: ["b", "n"],
  relic: ["t"],
  relie: ["f", "v"],
  relig: ["h", "i"],
  relin: ["e", "q"],
  relo: ["a", "c"],
  rem: ["a", "b", "e", "i", "n", "o", "u"],
  rema: ["d", "i", "k", "n", "r"],
  remar: ["k", "r"],
  reme: ["d", "m"],
  remed: ["i", "y"],
  remi: ["l", "n", "s", "t", "x"],
  remin: ["d", "i"],
  remit: ["t"],
  remo: ["d", "l", "n", "r", "t", "u", "v"],
  remor: ["a", "s"],
  remou: ["l", "n"],
  remov: ["a", "e"],
  ren: ["a", "d", "e", "n", "o", "t", "u"],
  rena: ["i", "l", "m", "s"],
  rend: ["e", "i"],
  rende: ["r", "z"],
  rene: ["g", "w"],
  reneg: ["a", "e"],
  renew: ["a"],
  renn: ["e", "i"],
  reno: ["u", "v", "w"],
  rent: ["a", "e", "i"],
  renta: ["b", "l"],
  reo: ["c", "p", "r"],
  reor: ["d", "g", "i"],
  rep: ["a", "e", "i", "l", "o", "r", "t", "u"],
  repa: ["c", "i", "r", "s", "t", "y"],
  repai: ["d", "n", "r"],
  repar: ["a", "t"],
  repay: ["a", "m"],
  repe: ["a", "l", "n", "r", "t"],
  repea: ["l", "t"],
  repel: ["l"],
  reper: ["c", "t"],
  repl: ["a", "e", "i", "y"],
  repla: ["c", "n", "y"],
  reple: ["n", "t"],
  reply: [],
  repo: ["i", "p", "r", "s", "t"],
  repos: ["e", "i", "s"],
  repr: ["e", "i", "o"],
  repre: ["h", "s"],
  repri: ["e", "m", "n", "s"],
  repro: ["a", "b", "d", "g", "o", "v"],
  rept: ["i"],
  repu: ["b", "d", "g", "l", "r", "t"],
  repub: ["l"],
  reput: ["a", "e"],
  rer: ["e", "u"],
  res: ["a", "c", "e", "h", "i", "o", "p", "t", "u"],
  resc: ["i", "r", "u"],
  resci: ["n", "s"],
  rese: ["a", "c", "l", "m", "n", "r", "t"],
  resea: ["r", "t"],
  reset: ["t"],
  resh: ["a", "u"],
  resi: ["d", "g", "l", "n", "s"],
  resid: ["e", "u"],
  resin: ["a", "o"],
  reso: ["l", "n", "r", "u"],
  resol: ["e", "u", "v"],
  resor: ["p", "t"],
  resou: ["n", "r"],
  resp: ["e", "i", "l", "o"],
  respi: ["r", "t"],
  rest: ["a", "f", "i", "l", "o", "r"],
  resta: ["g", "t", "u"],
  resti: ["n", "t", "v"],
  resto: ["c", "r"],
  restr: ["a", "i", "o", "u"],
  resu: ["l", "m", "r", "s"],
  resum: ["e", "p"],
  resur: ["f", "g", "r", "v"],
  ret: ["a", "c", "d", "e", "h", "i", "o", "r", "u"],
  reta: ["i", "k", "l", "r"],
  retai: ["l", "n"],
  rete: ["l", "n"],
  reti: ["c", "n", "r"],
  retic: ["e", "l", "u"],
  retin: ["a", "i", "o", "u"],
  retir: ["e", "i"],
  reto: ["l", "r", "u"],
  retr: ["a", "e", "i", "o", "y"],
  retre: ["a", "n"],
  retri: ["a", "b", "e"],
  retro: ["a", "f", "g", "r", "s", "u", "v"],
  reu: ["n", "s", "t"],
  rev: ["a", "e", "i", "o", "u"],
  reva: ["l", "m"],
  reve: ["a", "i", "l", "n", "r", "t"],
  revel: ["a", "e", "l", "r"],
  reven: ["g", "u"],
  rever: ["b", "e", "i", "s", "t", "y"],
  revi: ["e", "l", "s", "t", "v"],
  revis: ["e", "i"],
  reviv: ["a", "e", "i"],
  revo: ["c", "k", "l"],
  revol: ["t", "u", "v"],
  revu: ["e", "l"],
  rew: ["a", "i", "o", "r"],
  rf: ["d"],
  rh: ["a", "e", "i", "o", "u", "y"],
  rhe: ["a", "n", "o", "s", "t", "u"],
  rheo: ["l", "m", "s"],
  rhet: ["o"],
  rhi: ["n", "z"],
  rhin: ["e", "i", "o"],
  rhine: ["s"],
  rhino: ["c", "p", "s"],
  rho: ["d", "m", "n"],
  rhod: ["e", "i", "o"],
  rhode: ["s"],
  rhodo: ["d", "p"],
  rhy: ["m", "o", "t"],
  rhym: ["e", "i"],
  rhyme: ["d", "s"],
  ri: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "t",
    "v",
    "y",
  ],
  rib: ["a", "b", "c", "o"],
  riba: ["l", "n"],
  ribb: ["e", "i", "o"],
  ribo: ["f", "n", "s"],
  ribos: ["e", "o"],
  ric: ["e", "h", "k", "o", "r", "t"],
  rice: ["r"],
  rich: ["e", "l", "m", "n", "t"],
  rick: ["e", "r", "s"],
  rid: ["d", "e", "g", "i"],
  ridd: ["a", "e", "l"],
  riddl: ["e", "i"],
  ride: ["r"],
  rider: ["l", "s"],
  ridi: ["c", "n"],
  rif: ["e", "f", "l", "t"],
  riff: ["l", "r"],
  rifl: ["e", "i"],
  rifle: ["m"],
  rift: [],
  rig: ["g", "h", "i", "m", "o"],
  rigg: ["e", "i"],
  rigo: ["r", "u"],
  rigor: ["o"],
  ril: ["e", "l"],
  rill: ["e"],
  rim: ["e"],
  rin: ["d", "g", "k", "s"],
  rind: ["e"],
  ring: ["e", "i", "l", "m", "s", "w"],
  ringe: ["d", "r"],
  rink: ["y"],
  rio: ["t"],
  riot: ["e", "o"],
  rip: ["a", "e", "o", "p", "r", "s", "t"],
  ripe: ["n"],
  ripen: ["e"],
  ripp: ["e", "l"],
  ris: ["e", "i", "k", "o", "q"],
  rise: ["n", "r"],
  risi: ["b", "n"],
  risib: ["i", "l"],
  risk: ["i", "y"],
  riski: ["l", "n"],
  rit: ["e", "u", "z"],
  riv: ["a", "e", "i", "u"],
  rive: ["n", "r", "t"],
  river: ["b", "s"],
  rivet: ["e", "i"],
  rn: ["a", "d"],
  ro: [
    "g",
    "k",
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  roa: ["c", "d", "m", "n", "r", "s"],
  road: ["a", "b", "h", "l", "m", "r", "s", "w"],
  roadb: ["e", "l"],
  roads: ["i", "t"],
  roadw: ["a", "o"],
  roar: ["i"],
  rob: ["b", "e", "i", "o", "u"],
  roc: ["h", "k", "o"],
  rock: ["b", "e", "f", "i", "y"],
  rocke: ["f", "r", "t"],
  rocki: ["e", "n"],
  rocky: [],
  rod: ["e", "i", "o"],
  rode: ["n", "o"],
  roe: ["b", "n"],
  rog: ["a", "e", "u"],
  rogu: ["e", "i"],
  rogue: ["r", "s"],
  roi: ["l", "s"],
  roil: ["y"],
  rol: ["e", "l", "y"],
  roll: ["b", "e", "i", "o", "s", "t"],
  rolle: ["d", "r"],
  rolli: ["c", "n"],
  rom: ["a", "e", "i", "p", "u"],
  roma: ["i", "n"],
  roman: ["c", "e", "i", "t", "y"],
  romp: ["e", "i"],
  ron: ["d", "e", "t"],
  rond: ["e", "o"],
  roo: ["d", "f", "k", "m", "s", "t"],
  rood: ["s"],
  roof: ["e", "i", "l", "t"],
  roofe: ["d", "r"],
  rooft: ["o", "r"],
  rook: ["e", "i"],
  room: ["e", "f", "i", "m", "y"],
  roome: ["d", "r", "t"],
  roos: ["e", "t"],
  roost: ["e"],
  root: ["e", "l", "s", "y"],
  rop: ["e", "y"],
  rope: ["d", "r", "w"],
  ros: ["a", "e", "h", "i", "t", "y"],
  rose: ["a", "b", "l", "m", "t", "w"],
  rosew: ["a", "o"],
  rosi: ["c", "e", "l", "n"],
  rosin: ["e"],
  rost: ["e", "r"],
  rot: ["a", "e", "g", "i", "o", "t", "u"],
  rota: ["r", "t"],
  rotat: ["e", "i", "o"],
  roto: ["g", "r"],
  rou: ["b", "e", "g", "l", "m", "n", "s", "t", "x"],
  roug: ["e", "h"],
  rough: ["a", "c", "d", "e", "h", "l", "n", "r", "s"],
  roul: ["a", "e"],
  rous: ["e", "i", "s", "t"],
  roust: ["a"],
  rout: ["e", "i"],
  route: ["r"],
  rov: ["e", "i"],
  rove: ["r"],
  row: ["a", "b", "d", "e", "i", "l"],
  rowd: ["i", "y"],
  rowdi: ["l", "n"],
  rowdy: ["i"],
  rowe: ["d", "l", "r"],
  rp: ["m", "s", "t"],
  rs: ["m", "v"],
  rt: ["e"],
  ru: [
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
  ],
  rub: ["a", "b", "d", "e", "i", "l", "r", "y"],
  rubb: ["e", "i", "l"],
  rubbi: ["n", "s"],
  rubbl: ["e", "y"],
  rube: ["l"],
  rubi: ["c", "d"],
  rubic: ["o", "u"],
  ruc: ["h", "k", "t"],
  ruck: ["s", "u"],
  rud: ["d", "e", "i"],
  rudd: ["e", "i", "l", "y"],
  ruddi: ["l", "n"],
  rude: ["l", "n"],
  rue: ["f"],
  ruf: ["f", "o"],
  ruff: ["e", "i", "l"],
  rug: ["b", "g"],
  rul: ["e", "i"],
  rule: ["b", "r"],
  rum: ["a", "b", "e", "i", "m", "o", "p", "r"],
  rumb: ["l", "u"],
  rumbl: ["e", "i"],
  rumm: ["a", "y"],
  rumo: ["r", "u"],
  rumor: ["e", "m"],
  rump: ["l", "u"],
  run: ["a", "d", "e", "g", "i", "n", "o", "t", "w"],
  runa: ["b", "r", "w"],
  runn: ["e", "i", "y"],
  runne: ["l", "r"],
  runt: ["y"],
  rup: ["e", "i", "t"],
  rus: ["e", "h", "k", "s", "t"],
  rush: ["e", "l", "y"],
  rushe: ["d", "r"],
  russ: ["e", "i"],
  russe: ["l", "t"],
  rust: ["i", "l", "p", "y"],
  rusti: ["c", "n"],
  rustl: ["e", "i"],
  rut: ["a", "h", "t"],
  ruth: ["e", "l"],
  rutt: ["i", "y"],
  rw: ["a", "y"],
  ry: ["a", "e"],
  rye: [],
  s: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "j",
    "l",
    "o",
    "p",
    "r",
    "s",
    "t",
    "w",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  sa: [
    "l",
    "m",
    "t",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
  ],
  sab: ["b", "e", "i", "l", "o", "r"],
  sabr: ["a", "e"],
  sabre: ["r"],
  sac: ["c", "e", "h", "k", "r", "s"],
  sacc: ["h", "u"],
  sack: ["b", "c", "e", "f", "i"],
  sacr: ["a", "e", "i", "o", "u"],
  sacra: ["l", "m"],
  sacri: ["f", "l", "s"],
  sad: ["d", "h", "i", "l", "n", "o"],
  sadd: ["e", "l", "u"],
  sadde: ["n", "r"],
  sadi: ["r", "s"],
  sadis: ["m", "t"],
  saf: ["a", "e", "f"],
  safe: ["b", "c", "g", "k", "l", "t"],
  saff: ["l", "r"],
  sag: ["a", "e", "g", "h", "i", "o", "u"],
  saga: ["c"],
  sage: ["b", "l"],
  sagg: ["i", "y"],
  sago: [],
  sah: ["a", "i"],
  sai: ["d", "g", "l", "n", "p", "t"],
  sail: ["b", "c", "f", "i", "o", "p"],
  sak: ["e", "h"],
  sal: ["t", "a", "e", "i", "k", "l", "m", "o", "s", "t", "u", "v"],
  sala: ["a", "b", "c", "d", "m", "r"],
  salab: ["i", "l"],
  salad: [],
  salam: ["a", "i"],
  salar: ["i", "y"],
  sale: ["a", "r", "s"],
  sales: ["c", "g", "l", "m", "p", "r", "w"],
  sali: ["c", "e", "f", "n", "s", "v"],
  salif: ["e", "y"],
  salin: ["a", "e", "i", "o"],
  sall: ["o", "y"],
  salo: ["m", "n", "o"],
  salon: [],
  salt: ["b", "c", "e", "i", "l", "p", "s", "w", "y"],
  saltb: ["o", "u"],
  salti: ["e", "n", "r"],
  saltp: ["a", "e"],
  salu: ["b", "t"],
  salut: ["a", "e"],
  salv: ["a", "e", "i", "o"],
  salva: ["g", "t"],
  salve: ["r"],
  salvi: ["a", "f"],
  sam: ["a", "b", "e", "i", "o", "p", "s", "u"],
  same: ["n"],
  samo: ["a", "v"],
  samoa: ["n"],
  samp: ["a", "l"],
  san: ["a", "c", "d", "e", "f", "g", "i", "k", "s", "t"],
  sand: ["a", "b", "c", "e", "g", "h", "l", "m", "p", "s", "w", "y"],
  sandb: ["a", "l", "o"],
  sande: ["d", "r"],
  sandp: ["a", "i"],
  sands: ["h", "t"],
  sane: ["l"],
  sang: ["f", "r", "u"],
  sans: ["k"],
  sant: ["a", "i", "o"],
  sap: ["i", "l", "o", "p", "r", "s", "w"],
  sapl: ["e", "i"],
  sapp: ["e", "h", "y"],
  sar: ["a", "c", "d", "g", "i", "k", "o", "s", "t"],
  sara: ["b", "c", "n", "p", "w"],
  sarc: ["a", "o"],
  sarco: ["m", "p"],
  sard: ["i", "o"],
  sarg: ["a", "e"],
  sas: ["h", "s"],
  sash: ["a"],
  sass: ["a", "y"],
  sat: ["a", "c", "e", "i", "r", "u", "y"],
  sate: ["e", "l"],
  sati: ["a", "e", "n", "r", "s"],
  satia: ["b", "t"],
  satin: ["w", "y"],
  satir: ["e", "i"],
  sau: ["c", "d", "e", "l", "n", "r", "s", "t"],
  sauc: ["e", "y"],
  sauce: ["b", "p", "r"],
  saun: ["a", "t"],
  sav: ["a", "e", "i", "o", "v"],
  sava: ["g", "n"],
  savan: ["n", "t"],
  save: ["l", "r"],
  savi: ["n", "o"],
  savin: ["g"],
  savio: ["r", "u"],
  savo: ["i", "r", "u", "y"],
  savor: ["y"],
  saw: ["b", "d", "e", "f", "h", "m", "n", "p", "t", "y"],
  sawb: ["o", "u"],
  sawf: ["i", "l"],
  sawn: [],
  sax: ["i", "o"],
  saxo: ["n", "p"],
  say: ["i", "s"],
  sb: ["a"],
  sc: ["a", "e", "h", "i", "l", "o", "r", "u", "y"],
  sca: ["b", "d", "f", "l", "m", "n", "p", "r", "t", "u", "v"],
  scab: ["b", "i", "r"],
  scabb: ["a", "e", "y"],
  scabi: ["e", "o"],
  scal: ["a", "d", "e", "i", "l", "p", "y"],
  scala: ["b", "r", "w"],
  scald: ["i"],
  scale: ["d", "n"],
  scall: ["i", "o"],
  scalp: ["e", "i"],
  scam: ["p"],
  scamp: ["e", "i"],
  scan: ["d", "n", "s", "t"],
  scand: ["a", "i"],
  scant: ["i", "l", "y"],
  scap: ["e", "u"],
  scape: ["g"],
  scar: ["a", "c", "e", "f", "i", "l", "p", "r", "y"],
  scarc: ["e", "i"],
  scare: ["c", "m"],
  scarp: ["e"],
  scat: ["h", "o", "t"],
  scath: ["e", "i"],
  scatt: ["e", "y"],
  sce: ["n", "p"],
  scen: ["a", "e", "i", "o", "t"],
  scene: ["r", "s"],
  scent: ["l"],
  sch: ["e", "i", "l", "m", "n", "o", "u", "w"],
  sche: ["d", "m", "r"],
  schem: ["a", "e", "i"],
  schi: ["c", "l", "s", "z"],
  schis: ["m", "t"],
  schl: ["e", "o"],
  schm: ["a", "o"],
  schn: ["a", "i", "o"],
  schna: ["p", "u"],
  schno: ["o", "r"],
  scho: ["l", "o"],
  schoo: ["l", "n"],
  schu: ["b", "m", "s"],
  schw: ["a", "e"],
  sci: ["a", "e", "f", "l", "m", "n", "o", "s"],
  scil: ["i"],
  sco: ["f", "l", "n", "o", "p", "r", "t", "u", "w"],
  scol: ["d", "i", "l"],
  scold: ["e", "i"],
  scon: ["c", "e"],
  scoo: ["p", "t"],
  scoop: ["f"],
  scoot: ["e"],
  scop: ["e", "o"],
  scor: ["b", "c", "e", "i", "n", "p"],
  score: ["b", "c", "k", "l", "r"],
  scorn: ["f"],
  scot: ["c", "f", "l", "o", "s", "t"],
  scots: ["m", "w"],
  scott: ["i"],
  scou: ["n", "r", "t"],
  scour: ["e", "g", "i"],
  scout: ["i", "m"],
  scow: ["l"],
  scr: ["a", "e", "i", "o", "u"],
  scra: ["b", "g", "m", "p", "t", "w"],
  scrag: ["g"],
  scram: ["b"],
  scrap: ["b", "e", "h", "i", "p"],
  scraw: ["l", "n"],
  scre: ["a", "e", "w"],
  scree: ["c", "d", "n"],
  screw: ["b", "d", "i", "y"],
  scri: ["b", "m", "p", "v"],
  scrib: ["b", "e"],
  scrim: ["m", "p", "s"],
  scrip: ["t"],
  scro: ["d", "f", "l", "o", "t", "u"],
  scrot: ["a", "u"],
  scru: ["b", "f", "m", "n", "p", "t"],
  scrub: ["b"],
  scrum: ["c", "h", "m", "p"],
  scrup: ["l", "u"],
  scu: ["b", "d", "f", "l", "m", "p", "r", "t"],
  scul: ["l", "p"],
  scull: ["e", "i"],
  sculp: ["i", "t"],
  scum: ["b", "m"],
  scumb: ["a", "l"],
  scur: ["f", "r", "v"],
  scurr: ["i", "y"],
  scurv: ["i", "y"],
  scut: ["c", "t", "u"],
  scy: ["l", "t"],
  se: [
    "c",
    "n",
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
    "w",
    "x",
  ],
  sea: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "w",
  ],
  seab: ["e", "i", "o"],
  seabo: ["a", "r"],
  seac: ["h", "o"],
  seaf: ["a", "o", "r"],
  seag: ["i", "o", "u"],
  seal: ["a", "e", "i", "s", "y"],
  seale: ["d", "r", "v"],
  seam: ["a", "e", "i", "l", "s", "y"],
  seap: ["l", "o"],
  sear: ["c", "e", "i"],
  seas: ["c", "h", "i", "o"],
  seash: ["e", "o"],
  seasi: ["c", "d"],
  seat: ["b", "i", "t"],
  seaw: ["a", "e", "o"],
  seawa: ["l", "r", "t", "y"],
  sec: ["a", "e", "l", "o", "r", "t", "u", "y"],
  seca: ["n", "t"],
  sece: ["d", "s"],
  sect: ["a", "i", "o"],
  secti: ["l", "o"],
  secu: ["l", "r"],
  secur: ["e", "i"],
  secy: [],
  sed: ["a", "e", "g", "i", "u"],
  seda: ["n", "t"],
  sedan: [],
  sedat: ["e", "i"],
  sede: ["n", "r"],
  sedg: ["e", "y"],
  sedi: ["m", "t"],
  sedu: ["c", "l"],
  seduc: ["e", "i", "t"],
  see: ["d", "i", "k", "m", "n", "p", "r", "s", "t"],
  seed: ["b", "c", "i", "l", "s", "y"],
  seedi: ["l", "n"],
  seedl: ["e", "i"],
  seek: ["e"],
  seem: ["i", "l"],
  seeml: ["i", "y"],
  seep: ["a"],
  seer: ["e", "s"],
  seg: ["m", "o", "r"],
  sei: ["g", "n", "s", "z"],
  sein: ["e", "i"],
  seiz: ["e", "i", "u"],
  sel: ["d", "e", "f", "l", "t", "v"],
  sele: ["c", "n"],
  selen: ["i", "o"],
  self: ["h", "i", "l", "r", "s", "w"],
  selfs: ["a", "e", "t"],
  sell: ["e", "i", "o"],
  sello: ["t", "u"],
  selv: ["a", "e"],
  selve: ["d", "s"],
  sem: ["a", "b", "e", "i", "o", "p"],
  sema: ["n", "p"],
  seme: ["i", "n", "s"],
  semi: ["a", "b", "c", "d", "f", "m", "n", "o", "p", "q", "s", "t", "v", "w"],
  semia: ["n", "u"],
  semic: ["i", "o"],
  semid: ["e", "o"],
  semin: ["a", "u"],
  semio: ["f", "l", "t"],
  semip: ["e", "r"],
  semis: ["k", "o"],
  semit: ["e", "i", "o", "r"],
  sen: ["a", "d", "e", "i", "n", "o", "s", "t"],
  send: ["e", "o", "u"],
  sene: ["c", "g", "s"],
  seni: ["l", "o"],
  senil: ["e", "i"],
  sens: ["a", "e", "i", "o", "u"],
  sense: ["l"],
  sensi: ["b", "t"],
  sensu: ["a", "o"],
  sent: ["e", "i", "r"],
  senti: ["e", "m", "n"],
  sep: ["a", "h", "i", "n", "o", "s", "t", "u"],
  sepa: ["l", "r"],
  sept: ["a", "e", "i", "u"],
  septe: ["m", "t"],
  septu: ["a", "m", "p"],
  seq: ["q", "u"],
  sequ: ["e", "i", "o"],
  seque: ["l", "n", "s"],
  ser: ["a", "b", "e", "f", "g", "i", "j", "m", "o", "p", "r", "u", "v"],
  sera: ["g", "p"],
  serap: ["e", "h"],
  serb: ["i"],
  sere: ["n"],
  seren: ["a", "d", "e", "i"],
  serf: ["d"],
  serg: ["e", "t"],
  serge: ["a"],
  seri: ["a", "c", "e", "f", "g", "n", "o"],
  seria: ["l", "t"],
  serio: ["c", "u"],
  sero: ["l", "u"],
  serr: ["a", "i"],
  serv: ["a", "e", "i", "o"],
  serve: ["r"],
  servi: ["c", "e", "l", "n", "t"],
  servo: ["m"],
  ses: ["a", "q", "s"],
  set: ["b", "s", "t", "u"],
  sets: ["c", "q"],
  sett: ["e", "i", "l"],
  sette: ["e", "r"],
  settl: ["e", "i"],
  sew: ["a", "e", "i", "n"],
  sex: ["a", "e", "i", "l", "p", "t", "u", "y"],
  sexe: ["d", "n"],
  sexi: ["l", "n", "s"],
  sexis: ["m", "t"],
  sext: ["a", "e", "o", "u"],
  sf: ["c", "o"],
  sg: ["r", "t"],
  sh: ["a", "e", "h", "i", "o", "r", "t", "u", "y"],
  sha: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "t",
    "v",
    "w",
    "y",
  ],
  shad: ["e", "i", "o", "y"],
  shade: ["d", "l"],
  shag: ["b", "g", "r"],
  shagg: ["e", "i", "y"],
  shak: ["e", "i", "o", "y"],
  shake: ["d", "n", "r", "s", "u"],
  shaki: ["l", "n"],
  shal: ["e", "l", "o", "t"],
  shale: [],
  shall: ["o"],
  sham: ["a", "b", "e", "m", "p", "r"],
  shame: ["f", "l"],
  shan: ["d", "g", "k", "t"],
  shang: ["h", "r"],
  shank: ["e"],
  shant: ["e", "u", "y"],
  shar: ["d", "e", "k", "p"],
  share: ["c", "h", "o", "r"],
  shark: ["i", "s"],
  sharp: ["e", "i", "l", "n", "s", "y"],
  shav: ["e", "i"],
  shave: ["d", "n", "r"],
  shaw: ["l"],
  she: ["a", "b", "d", "e", "i", "k", "l", "n", "p", "r", "t", "w"],
  shea: ["f", "r", "t", "v"],
  shear: ["e", "i", "l", "s"],
  sheb: ["a", "e"],
  shed: ["d"],
  shee: ["n", "p", "r", "t"],
  sheep: ["d", "f", "h", "i", "s"],
  sheer: ["l"],
  sheet: ["i"],
  shei: ["k", "l"],
  sheik: ["d", "h"],
  shel: ["f", "l", "t", "v"],
  shelf: [],
  shell: ["a", "e", "f", "i", "s"],
  shelv: ["e", "i"],
  sher: ["a", "b", "d", "i", "p", "r"],
  shi: ["b", "e", "f", "i", "l", "m", "n", "p", "r", "s", "t", "v"],
  shie: ["d", "l"],
  shim: ["m"],
  shimm: ["e", "y"],
  shin: ["b", "d", "e", "g", "i", "n", "y"],
  shind: ["i", "y"],
  shine: ["r"],
  ship: ["b", "l", "m", "p", "s", "t", "w", "y"],
  shipb: ["o", "r", "u"],
  shipm: ["a", "e"],
  shipp: ["e", "i"],
  shipw: ["o", "r"],
  shir: ["e", "k", "r", "t"],
  shire: [],
  shirk: ["e"],
  shirr: ["e", "i"],
  shirt: ["f", "i", "l", "s", "t", "w", "y"],
  shit: ["k", "t"],
  shiv: ["a", "e"],
  sho: ["a", "c", "d", "e", "g", "n", "o", "p", "r", "t", "u", "v", "w"],
  shoa: ["l", "t"],
  shoal: ["i"],
  shod: ["d"],
  shodd: ["i", "y"],
  shoe: ["b", "h", "l", "m", "s", "t"],
  shoel: ["a", "e"],
  shoes: ["h", "t"],
  shoo: ["k", "t"],
  shoot: ["e", "i", "o"],
  shop: ["g", "k", "l", "p", "s", "w"],
  shopp: ["e", "i"],
  shopw: ["i", "o"],
  shor: ["e", "i", "n", "t"],
  shore: ["b", "l", "w"],
  short: ["a", "b", "c", "e", "f", "h", "i", "l", "n", "s", "w", "y"],
  shot: ["e", "g", "s"],
  shou: ["l", "t"],
  shout: ["i"],
  show: ["b", "c", "d", "e", "g", "i", "m", "n", "o", "p", "r", "y"],
  showi: ["l", "n"],
  showp: ["i", "l"],
  shr: ["a", "e", "i", "o", "u"],
  shra: ["n", "p"],
  shre: ["d", "w"],
  shred: ["d"],
  shrew: ["d", "i"],
  shri: ["e", "f", "k", "l", "m", "n", "v"],
  shrin: ["e", "k"],
  shro: ["u", "v"],
  shru: ["b", "g", "n"],
  shrub: ["b"],
  sht: ["i"],
  shti: ["c", "k"],
  shu: ["c", "d", "f", "n", "s", "t"],
  shuf: ["f", "t"],
  shun: ["p", "t"],
  shunt: ["e", "i"],
  shut: ["d", "e", "o", "t"],
  shutt: ["e", "l"],
  shy: ["l", "n", "s"],
  shyl: ["o", "y"],
  si: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "x",
    "z",
  ],
  sib: ["e", "i", "l", "y"],
  sic: ["i", "k"],
  sick: ["b", "e", "l", "n", "o", "r"],
  sickb: ["a", "e"],
  sicke: ["n", "r"],
  sickl: ["e", "y"],
  sid: ["e", "i", "l"],
  side: ["a", "b", "c", "d", "h", "k", "l", "m", "p", "r", "s", "t", "w"],
  sideb: ["o", "u"],
  sidel: ["i", "o"],
  sider: ["e", "i"],
  sides: ["a", "h", "l", "m", "p", "t", "w"],
  sidew: ["a", "i"],
  sie: ["g", "n", "r", "s", "v"],
  sig: ["h", "i", "m", "n"],
  sigh: ["i", "t"],
  sight: ["e", "i", "l", "r", "s"],
  sigm: ["a", "o"],
  sign: ["a", "b", "e", "i", "o", "p"],
  signa: ["l", "t"],
  signe: ["r", "t"],
  sil: ["a", "e", "h", "i", "k", "l", "o", "t", "u", "v"],
  sile: ["n", "s"],
  silen: ["c", "t"],
  silk: ["e", "i", "s", "w", "y"],
  sill: ["a", "i", "y"],
  silt: ["y"],
  silv: ["a", "e", "i"],
  sim: ["i", "m", "o", "p", "u"],
  simi: ["a", "l"],
  simil: ["a", "e", "i"],
  simp: ["a", "e", "l"],
  simpl: ["e", "i", "y"],
  sin: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "k", "l", "n", "o", "u"],
  sine: ["c", "w"],
  sinew: ["y"],
  sing: ["a", "e", "h", "i", "l", "s", "u"],
  singe: ["r"],
  singl: ["e", "y"],
  sini: ["c", "s"],
  sink: ["a", "e", "h", "i"],
  sinu: ["o", "s"],
  sinuo: ["s", "u"],
  sinus: ["i", "o"],
  sio: ["n", "u"],
  siou: ["a", "x"],
  sip: ["h", "p"],
  sir: ["e", "i", "l", "o", "r", "u"],
  sire: ["n"],
  sis: ["a", "s", "t", "y"],
  siss: ["i", "y"],
  sit: ["a", "c", "e", "t", "u", "z"],
  site: ["d"],
  sitt: ["e", "i"],
  situ: ["a", "s"],
  six: ["f", "p", "t"],
  sixp: ["a", "e"],
  sixt: ["e", "h", "i", "y"],
  sixth: [],
  sixty: [],
  siz: ["a", "e", "i", "z"],
  size: ["a", "d"],
  sk: ["a", "e", "i", "o", "t", "u", "y"],
  ska: ["g", "l", "t"],
  skat: ["e", "i"],
  skate: ["b", "r"],
  ske: ["d", "e", "i", "l", "p", "t", "w"],
  skew: ["b", "e"],
  ski: ["b", "d", "e", "f", "i", "l", "m", "n", "p", "r", "t", "v"],
  skid: ["l", "p"],
  skie: ["d", "r"],
  skil: ["f", "l"],
  skill: ["e", "f"],
  skim: ["m", "p"],
  skimm: ["e", "i"],
  skimp: ["i", "y"],
  skin: ["f", "h", "k", "l", "n", "t"],
  skinf: ["l", "u"],
  skinn: ["e", "i", "y"],
  skint: ["i"],
  skip: ["j", "p"],
  skipp: ["e", "i"],
  skir: ["l", "m", "t"],
  skirt: ["i"],
  skit: ["t"],
  skitt: ["e", "i", "l"],
  skiv: ["e", "v"],
  sku: ["a", "l", "n"],
  skul: ["d", "k", "l"],
  skulk: ["e"],
  skull: ["c", "d"],
  sky: ["c", "d", "h", "j", "l", "r", "s", "w"],
  skyl: ["a", "i"],
  skyla: ["b", "r"],
  skyli: ["g", "n"],
  skyw: ["a", "r"],
  sl: ["r", "a", "d", "e", "i", "o", "u", "y"],
  sla: [
    "b",
    "c",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
  ],
  slam: [],
  slan: ["d", "g", "t"],
  slang: ["i", "y"],
  slant: ["i", "w"],
  slap: ["d", "h", "j", "p", "s"],
  slat: ["e", "h", "i", "t", "y"],
  slate: [],
  slav: ["e", "i", "o"],
  slave: ["h", "r"],
  slavi: ["c", "s"],
  slay: ["e"],
  sle: ["a", "d", "e", "i", "n", "p", "u", "w"],
  sled: ["d", "g"],
  slee: ["k", "p", "t", "v"],
  sleek: ["l", "n"],
  sleep: ["e", "i", "l", "w", "y"],
  sleet: ["y"],
  slew: ["e"],
  sli: ["c", "d", "g", "m", "n", "p", "t", "v"],
  slic: ["e", "k"],
  slice: ["r"],
  slick: ["e", "i", "n"],
  slid: ["e", "i"],
  slide: ["r"],
  slim: ["e", "n", "y"],
  slin: ["g", "k"],
  sling: ["e", "s"],
  slink: ["y"],
  slip: ["c", "k", "o", "p", "s", "u", "w"],
  slipp: ["a", "e", "y"],
  slips: ["h", "t"],
  slit: ["h", "t"],
  slo: ["b", "e", "g", "o", "p", "s", "t", "u", "v", "w"],
  slob: ["b"],
  sloe: [],
  slog: ["a"],
  slop: ["e", "i", "p"],
  slopp: ["i", "y"],
  slot: ["h", "t"],
  sloth: ["f"],
  slott: ["e", "i"],
  slou: ["c", "g"],
  slov: ["a", "e"],
  slow: ["c", "d", "l", "n", "p", "s", "w"],
  sloww: ["i", "o"],
  slu: ["d", "e", "g", "i", "m", "n", "r", "s", "t"],
  slug: ["g", "s"],
  slugg: ["a", "e", "i"],
  slum: ["b", "l", "m", "p"],
  slumb: ["e", "r"],
  slumm: ["i", "y"],
  slun: ["g", "k"],
  slur: ["p", "r"],
  slurr: ["e", "y"],
  slut: ["t"],
  sly: ["l", "n"],
  sm: ["a", "e", "i", "o", "u"],
  sma: ["c", "l", "r", "s", "t"],
  smar: ["m", "t"],
  smart: ["e", "l", "n", "w"],
  sme: ["a", "l"],
  smel: ["l", "t"],
  smell: ["i", "y"],
  smelt: ["e", "i"],
  smi: ["d", "l", "r", "t"],
  smil: ["a", "e", "i"],
  smir: ["c", "k"],
  smirk: ["y"],
  smit: ["e", "h", "t"],
  smith: ["e", "i", "y"],
  smo: ["c", "g", "k", "l", "o", "r", "t", "u"],
  smok: ["a", "e", "i", "y"],
  smoke: ["h", "l", "r", "s", "y"],
  smoo: ["c", "t"],
  smot: ["e", "h"],
  smu: ["d", "g", "t"],
  smug: ["g", "l", "n"],
  smut: ["t"],
  smutt: ["i", "y"],
  sn: ["a", "e", "i", "o", "r", "u"],
  sna: ["c", "f", "g", "i", "k", "p", "r", "t", "z"],
  snag: ["g"],
  snak: ["e", "y"],
  snake: ["b", "h", "s"],
  snap: ["d", "p", "s"],
  snapp: ["e", "i", "y"],
  snar: ["e", "l"],
  snare: [],
  snarl: ["i"],
  sne: ["a", "e"],
  snee: ["r", "z"],
  sneer: ["e", "i"],
  sneez: ["e", "i"],
  sni: ["c", "d", "f", "g", "p", "t", "v"],
  snif: ["f", "t"],
  sniff: ["e", "i", "l", "y"],
  snip: ["e", "p", "s"],
  snipe: ["r"],
  snipp: ["e", "y"],
  sno: ["b", "g", "o", "r", "t", "u", "w"],
  snob: ["b"],
  snobb: ["e", "i", "y"],
  snoo: ["d", "k", "p", "t", "z"],
  snook: ["e"],
  snoop: ["y"],
  snoot: ["i", "y"],
  snor: ["e", "i", "k", "t"],
  snore: ["r"],
  snort: ["e"],
  snot: ["t"],
  snow: ["b", "c", "d", "f", "l", "m", "p", "s", "y"],
  snowb: ["a", "e", "l", "o"],
  snowc: ["a", "l"],
  snowf: ["a", "i", "l"],
  snowl: ["e", "i"],
  snowm: ["a", "o"],
  snows: ["h", "l", "t"],
  snu: ["b", "c", "f", "g"],
  snub: [],
  snug: ["g", "l", "n"],
  so: [
    "h",
    "p",
    "s",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  soa: ["k", "p", "r"],
  soak: ["e", "i"],
  soake: ["d", "r"],
  soap: ["b", "e", "i", "s", "y"],
  soaps: ["t", "u"],
  soar: ["i"],
  sob: ["b", "e", "r"],
  soc: ["c", "i", "k", "l", "r"],
  soci: ["a", "e", "o"],
  socia: ["b", "l"],
  socio: ["l", "p"],
  sock: ["e", "l"],
  sod: ["a", "d", "i", "o"],
  soda: ["l"],
  sof: ["a", "f", "i", "t"],
  soft: ["b", "e", "h", "i", "l", "n", "w", "y"],
  softb: ["a", "o"],
  softw: ["a", "o"],
  soi: ["g", "l", "r"],
  soil: ["l"],
  sol: ["a", "d", "e", "f", "i", "o", "s", "u", "v"],
  sola: ["c", "n", "r"],
  solar: ["i"],
  sold: ["e", "i"],
  sole: ["c", "l", "m", "n"],
  soli: ["c", "d", "l", "p", "t"],
  solid: ["a", "i", "l", "n", "u"],
  solit: ["a", "u"],
  solo: ["i", "m", "n"],
  solu: ["b", "t"],
  solub: ["i", "l"],
  solut: ["e", "i"],
  solv: ["a", "e"],
  solve: ["n", "r"],
  som: ["a", "b", "e", "m", "n"],
  soma: ["l", "t"],
  somb: ["e", "r"],
  some: ["b", "d", "h", "o", "p", "r", "t", "w"],
  somet: ["h", "i"],
  somew: ["a", "h"],
  somn: ["a", "o"],
  son: ["a", "g", "i", "n", "o", "s"],
  sona: ["r", "t"],
  song: ["b", "c", "f", "s"],
  songb: ["i", "o"],
  songf: ["e", "u"],
  sonn: ["e", "y"],
  sons: ["h", "y"],
  soo: ["n", "t"],
  soon: ["e"],
  soot: ["h", "y"],
  sooth: ["e", "i", "s"],
  sop: ["h", "o", "p", "r"],
  soph: ["i", "o"],
  sopho: ["c", "m"],
  sopp: ["i", "y"],
  sor: ["b", "c", "d", "e", "g", "o", "p", "r", "t"],
  sore: ["h", "l", "n"],
  sorr: ["e", "i", "o", "y"],
  sort: ["e", "i"],
  sot: ["e", "t"],
  sott: ["i", "o"],
  sou: ["b", "f", "g", "l", "n", "p", "r", "s", "t", "v"],
  soul: ["f", "l"],
  soup: ["c", "s", "y"],
  sour: ["b", "c", "d", "i", "l", "n", "p", "s", "w"],
  sous: ["a", "e"],
  souse: ["d"],
  sov: ["e", "i"],
  sow: ["e", "n"],
  soy: ["a", "b"],
  sp: ["a", "e", "h", "i", "l", "o", "r", "u", "y"],
  spa: ["c", "d", "g", "i", "k", "m", "n", "r", "s", "t", "v", "w", "y"],
  spac: ["e", "i"],
  space: ["c", "m", "s", "w"],
  spaci: ["n", "o"],
  span: ["g", "i", "k", "n"],
  spani: ["a", "e", "s"],
  spank: ["e", "i"],
  spar: ["e", "i", "k", "r", "s", "t"],
  spare: ["l", "n", "r"],
  spark: ["i", "l", "p"],
  spars: ["e", "i"],
  spas: ["m", "t"],
  spasm: ["o"],
  spat: ["c", "e", "h", "i", "t", "u"],
  spe: ["a", "c", "d", "e", "l", "n", "r", "w"],
  spea: ["k", "r"],
  speak: ["e", "i"],
  spear: ["f", "h", "m"],
  spec: ["i", "k", "s", "t", "u"],
  speci: ["a", "e", "f", "m", "o"],
  speck: ["l"],
  spect: ["a", "r"],
  spee: ["c", "d"],
  speed: ["b", "e", "i", "o", "u", "w", "y"],
  spel: ["e", "l", "t"],
  spell: ["b", "d", "e", "i"],
  spen: ["d", "t"],
  spend: ["e", "i", "t"],
  sph: ["a", "e", "i", "y"],
  sphe: ["n", "r"],
  spher: ["e", "i", "o"],
  spi: ["c", "d", "e", "f", "g", "k", "l", "n", "r", "t"],
  spic: ["e", "i", "k", "u", "y"],
  spici: ["l", "n"],
  spie: ["d", "l"],
  spik: ["e", "y"],
  spike: ["d", "n"],
  spil: ["l", "t"],
  spill: ["e", "o", "w"],
  spin: ["a", "d", "e", "n", "o", "s", "y"],
  spina: ["c", "l"],
  spind: ["l", "r"],
  spine: ["l", "t"],
  spinn: ["a", "e", "i"],
  spir: ["a", "e", "i", "o", "t"],
  spira: ["c", "l", "t"],
  spiro: ["c", "m"],
  spit: ["b", "e", "f", "t"],
  spite: ["f"],
  spitt: ["e", "i", "l", "o"],
  spl: ["a", "e", "i", "o", "u"],
  spla: ["n", "s", "t", "y"],
  splat: ["t"],
  splay: ["f"],
  sple: ["e", "n"],
  splen: ["d", "e", "i"],
  spli: ["c", "n", "t"],
  splin: ["e", "t"],
  split: ["t"],
  splu: ["r", "t"],
  spo: ["i", "k", "l", "n", "o", "r", "t", "u"],
  spon: ["d", "g", "s", "t"],
  spond: ["a", "e"],
  spong: ["e", "i", "y"],
  spoo: ["f", "k", "l", "n", "r"],
  spook: ["y"],
  spoon: ["b", "d", "f"],
  spor: ["a", "e", "k", "r", "t"],
  spora: ["d", "n"],
  spore: [],
  sport: ["i", "s", "y"],
  spot: ["l", "t"],
  spotl: ["e", "i"],
  spott: ["e", "y"],
  spou: ["s", "t"],
  spous: ["a", "e"],
  spr: ["a", "e", "i", "o", "u", "y"],
  spra: ["i", "n", "t", "w", "y"],
  spray: ["e"],
  spre: ["a", "e"],
  spri: ["g", "n", "t"],
  sprig: ["g", "h"],
  sprin: ["g", "k", "t"],
  sprit: ["e"],
  spro: ["c", "u"],
  spru: ["c", "e", "n"],
  spu: ["d", "m", "n", "r", "t"],
  spun: ["k"],
  spunk: ["y"],
  spur: ["g", "i", "n", "r", "t"],
  sput: ["n", "t", "u"],
  spy: ["g"],
  sq: ["q", "u"],
  squ: ["a", "e", "i"],
  squa: ["b", "d", "l", "m", "n", "r", "s", "t", "w"],
  squab: ["b"],
  squad: ["r"],
  squal: ["i", "l", "o"],
  squar: ["e", "i"],
  squat: ["t"],
  squaw: ["k"],
  sque: ["a", "e", "l"],
  squea: ["k", "l", "m"],
  squee: ["g", "z"],
  squi: ["b", "d", "f", "g", "n", "r", "s"],
  squid: ["g"],
  squir: ["a", "e", "m", "r", "t"],
  sr: ["i"],
  sri: [],
  ss: ["e", "r", "s", "t", "w", "h"],
  st: ["d", "o", "p", "a", "d", "e", "g", "i", "k", "o", "r", "u", "y"],
  sta: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "y",
  ],
  stab: ["b", "i", "l"],
  stabl: ["e", "i", "y"],
  stac: ["c", "k"],
  stack: ["e", "i"],
  stag: ["e", "f", "g", "i", "n", "y"],
  stage: ["c", "r"],
  stai: ["d", "n", "r"],
  stain: ["e", "l"],
  stair: ["c", "w"],
  stal: ["a", "e", "i", "k", "l", "w"],
  stala: ["c", "g"],
  stale: ["m", "n"],
  stalk: ["e", "i", "y"],
  stall: ["e", "h", "i"],
  stam: ["e", "i", "m", "p"],
  stamp: ["e", "i"],
  stan: ["c", "d", "k", "n", "z"],
  stanc: ["e", "h"],
  stand: ["a", "b", "e", "i", "o", "p", "s", "u"],
  stann: ["i", "o"],
  stap: ["e", "h", "l"],
  stape: ["d", "s"],
  staph: ["y"],
  star: ["b", "c", "d", "e", "f", "g", "i", "k", "l", "r", "s", "t", "v"],
  starc: ["h", "r"],
  stard: ["o", "u"],
  stark: ["e", "l", "n"],
  starl: ["e", "i"],
  starr: ["e", "y"],
  start: ["e", "i", "l"],
  starv: ["a", "e"],
  stas: ["h", "i"],
  stat: ["e", "i", "o", "u"],
  state: ["c", "d", "h", "l", "m", "n", "r", "s"],
  stati: ["c", "n", "o", "s", "v"],
  statu: ["a", "e", "r", "s", "t"],
  stav: ["e", "i"],
  stave: ["s"],
  stay: ["e", "i", "s"],
  staye: ["d", "r"],
  std: [],
  ste: ["a", "e", "g", "i", "l", "m", "n", "p", "r", "t", "v", "w"],
  stea: ["d", "k", "l", "m", "r", "t"],
  stead: ["f", "i", "y"],
  steal: ["e", "i", "t"],
  steam: ["b", "e", "f", "r", "s", "y"],
  stee: ["d", "l", "n", "p", "r"],
  steel: ["h", "i", "w", "y"],
  steep: ["e", "l", "n"],
  steer: ["a", "e", "i", "s"],
  stel: ["a", "e", "l"],
  stem: ["l", "m", "w"],
  sten: ["c", "o", "t"],
  stenc: ["h", "i"],
  steno: ["g", "s"],
  stent: ["i", "o"],
  step: ["b", "c", "d", "f", "l", "m", "p", "s"],
  stepp: ["a", "e", "i"],
  steps: ["i", "o"],
  ster: ["e", "i", "l", "n", "o", "t"],
  stere: ["o"],
  stern: ["a", "e", "l", "n", "u", "w"],
  stet: ["h", "s"],
  stew: ["a", "e", "p"],
  sti: ["b", "c", "f", "g", "l", "m", "n", "p", "r", "t"],
  stif: ["f", "l"],
  stiff: ["e", "l", "n"],
  stifl: ["e", "i"],
  stil: ["e", "l", "t"],
  stile: ["t"],
  still: ["b", "e", "i", "n", "r", "y"],
  stilt: ["e", "o"],
  stin: ["g", "k", "t"],
  sting: ["e", "i", "l", "o", "r", "y"],
  stink: ["e", "i"],
  stip: ["e", "p", "u"],
  stir: ["r"],
  stirr: ["e", "i", "u"],
  sto: [
    "l",
    "a",
    "c",
    "d",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "u",
    "v",
    "w",
  ],
  stoc: ["h", "k"],
  stock: ["a", "b", "c", "e", "f", "h", "i", "j", "m", "p", "r", "t", "y"],
  stol: ["e", "i"],
  stole: ["n"],
  stom: ["a", "p"],
  stoma: ["c", "t"],
  ston: ["e", "i", "y"],
  stone: ["b", "c", "d", "m", "r", "w"],
  stony: [],
  stoo: ["d", "g", "l", "p"],
  stool: ["p"],
  stoop: ["i"],
  stop: ["c", "g", "l", "o", "p", "w"],
  stopg: ["a", "o"],
  stopp: ["a", "e", "i", "l", "r"],
  stor: ["a", "e", "i", "k", "m", "y"],
  store: ["d", "f", "h", "k", "r", "y"],
  storm: ["b", "i", "y"],
  story: ["b", "l", "t"],
  stou: ["p", "t"],
  stout: ["h", "l"],
  stow: ["a", "i"],
  stowa: ["g", "w"],
  str: ["a", "e", "i", "o", "u", "y"],
  stra: ["b", "d", "f", "g", "i", "n", "p", "t", "u", "w", "y"],
  strad: ["d", "i"],
  strai: ["g", "n", "t"],
  stran: ["d", "g"],
  strap: ["h", "l", "p", "w"],
  strat: ["a", "e", "f", "i", "o", "u"],
  straw: ["b", "c"],
  stre: ["a", "e", "n", "p", "s", "t", "w"],
  strea: ["k", "m"],
  stren: ["g", "u"],
  strep: ["t"],
  strew: ["i", "n", "t"],
  stri: ["a", "c", "d", "f", "k", "n", "p", "v"],
  stria: ["t"],
  stric: ["k", "t"],
  strid: ["d", "e", "o", "u"],
  strik: ["e", "i"],
  strip: ["e", "l", "p", "t", "y"],
  striv: ["e", "i"],
  stro: ["b", "d", "k", "l", "m", "n", "p", "v"],
  strob: ["e", "o"],
  strok: ["e", "i"],
  stron: ["g", "t"],
  strop: ["h"],
  stru: ["c", "d", "g", "m", "n", "t"],
  struc: ["k", "t"],
  strum: ["p"],
  strut: ["t"],
  stu: ["a", "b", "c", "d", "f", "l", "m", "n", "p", "r", "t"],
  stub: ["b"],
  stubb: ["e", "l", "o", "y"],
  stuc: ["c", "k"],
  stuck: [],
  stud: ["b", "d", "e", "i", "y"],
  studi: ["e", "o"],
  stum: ["b", "p"],
  stump: ["a", "e", "y"],
  stun: ["g", "k", "n", "t"],
  stunn: ["e", "i"],
  stunt: ["e", "m"],
  stup: ["a", "e", "i", "o"],
  stupe: ["f", "n"],
  stur: ["d", "g"],
  sturd: ["i", "y"],
  sty: ["e", "g", "l", "m", "p", "r", "x"],
  styl: ["e", "i", "o", "u"],
  style: ["r"],
  styli: ["s", "z"],
  stylo: ["i", "m"],
  styr: ["e", "o"],
  su: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "z",
  ],
  sua: ["s", "v"],
  suav: ["e", "i"],
  sub: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "t",
    "u",
    "v",
    "w",
    "z",
  ],
  suba: ["c", "g", "l", "r", "t"],
  subal: ["p", "t"],
  subar: ["a", "c"],
  subc: ["l", "o", "u"],
  subco: ["m", "n"],
  subcu: ["l", "t"],
  subd: ["e", "i", "o", "u"],
  subdu: ["c", "e", "r"],
  subg: ["l", "r"],
  subgl: ["a", "o"],
  subh: ["e", "u"],
  subj: ["e", "o", "u"],
  subju: ["g", "n"],
  subl: ["e", "i", "u"],
  suble: ["a", "t"],
  subli: ["e", "m", "n"],
  sublu: ["n", "x"],
  subm: ["a", "e", "i"],
  subma: ["c", "r"],
  subme: ["n", "r"],
  submi: ["c", "n", "s", "t"],
  subp: ["e", "l", "o"],
  subpe: ["n", "r"],
  subs: ["c", "e", "i", "o", "p", "t", "u"],
  subse: ["q", "r", "t"],
  subsi: ["d", "s"],
  subso: ["i", "n"],
  subst: ["a", "i", "r"],
  subt: ["e", "i", "l", "o", "r"],
  subte: ["e", "n", "r"],
  subtl: ["e", "y"],
  subto: ["p", "t"],
  subtr: ["a", "i", "o"],
  suc: ["c", "h", "k", "r", "t"],
  succ: ["e", "i", "o", "u"],
  succe: ["e", "s"],
  succo: ["r", "t", "u"],
  succu: ["b", "l", "m"],
  such: ["l"],
  suck: ["e", "i", "l"],
  suckl: ["e", "i"],
  sud: ["a", "d", "s"],
  sue: ["d", "t", "z"],
  suf: ["f", "i"],
  suff: ["e", "i", "o", "r", "u"],
  suffi: ["c", "x"],
  sug: ["a", "g"],
  sui: ["c", "n", "t"],
  suit: ["a", "c", "e", "i", "o"],
  sul: ["c", "f", "k", "l", "p", "t"],
  sulf: ["a", "i", "u"],
  sulfa: ["t"],
  sulfi: ["d", "t"],
  sulk: ["i", "s", "y"],
  sulki: ["l", "n"],
  sull: ["e", "y"],
  sult: ["a", "r"],
  sultr: ["i", "y"],
  sum: ["a", "e", "m", "p"],
  suma: ["c", "t"],
  sumac: ["h"],
  summ: ["a", "e", "i", "o"],
  summa: ["r", "t"],
  summi: ["n", "t"],
  sump: ["t"],
  sumpt: ["e", "u"],
  sun: ["b", "d", "f", "g", "k", "l", "n", "r", "s", "t", "u", "w"],
  sunb: ["a", "e", "i", "l", "o", "u"],
  sunba: ["k", "t"],
  sunbe: ["a", "l"],
  sund: ["a", "e", "i", "o", "r"],
  sunda: ["e", "y"],
  sunde: ["c", "r", "w"],
  sundr: ["e", "y"],
  sunf: ["i", "l"],
  sung: ["l"],
  sunk: ["e"],
  sunl: ["a", "e", "i"],
  sunli: ["g", "k", "t"],
  sunn: ["i", "y"],
  sunni: ["l", "n"],
  sunny: [],
  sunr: ["a", "i", "o"],
  suns: ["e", "h", "p", "t"],
  sunsh: ["a", "i"],
  sunt: ["a", "r"],
  sup: ["e", "i", "p", "r", "t", "v"],
  supp: ["e", "i", "l", "o", "r", "u"],
  suppl: ["a", "e", "i", "y"],
  suppo: ["r", "s"],
  supr: ["a", "e"],
  supra: ["c", "n"],
  sur: ["a", "c", "d", "e", "f", "g", "i", "l", "m", "n", "p", "r", "t", "v"],
  surc: ["e", "h", "i", "o"],
  sure: ["f", "l", "n", "t"],
  suref: ["i", "o"],
  surf: ["a", "b", "e", "i"],
  surfe: ["i", "r"],
  surg: ["e", "i"],
  surge: ["o", "r"],
  surl: ["i", "y"],
  surli: ["l", "n"],
  surm: ["i", "o"],
  surp: ["a", "l", "r"],
  surpl: ["i", "u"],
  surr: ["e", "o"],
  surre: ["a", "n", "p", "y"],
  surro: ["g", "u"],
  surt: ["a", "o"],
  surv: ["e", "i"],
  surve: ["i", "y"],
  sus: ["c", "p", "s", "t", "u"],
  susp: ["e", "i"],
  suspe: ["c", "n"],
  sust: ["a", "e"],
  susu: ["r"],
  sut: ["l", "r", "t", "u"],
  sv: ["c", "e", "g"],
  svc: ["e"],
  sw: ["a", "e", "i", "o", "u"],
  swa: ["b", "d", "g", "h", "i", "l", "m", "n", "p", "r", "s", "t", "y", "z"],
  swag: ["e", "g"],
  swal: ["e", "l"],
  swam: ["i", "p"],
  swamp: ["y"],
  swan: ["k", "s"],
  swank: ["y"],
  swar: ["d", "f", "m", "t"],
  swas: ["h", "t"],
  swash: ["b"],
  swat: ["c", "h", "t"],
  swath: ["e"],
  sway: ["b", "e", "i"],
  swe: ["a", "d", "e", "l", "p", "r"],
  swea: ["r", "t"],
  swear: ["e", "i", "w"],
  sweat: ["b", "e", "i", "s", "y"],
  swed: ["e", "i"],
  swede: ["n"],
  swee: ["p", "t"],
  sweep: ["e", "i", "s"],
  sweet: ["b", "e", "h", "i", "l", "m", "n"],
  swel: ["l", "t"],
  swell: ["e", "h", "i"],
  swi: ["f", "g", "l", "m", "n", "p", "r", "s", "t", "v", "z"],
  swim: ["m", "s"],
  swimm: ["e", "i"],
  swin: ["d", "e", "g", "i"],
  swine: ["h"],
  swing: ["e", "i"],
  swis: ["h", "s"],
  swiss: [],
  swit: ["c", "z"],
  switz: ["e"],
  swiz: ["z"],
  swo: ["b", "l", "o", "p", "r", "t"],
  swoo: ["n", "p"],
  swoon: ["i"],
  swor: ["d", "e", "n"],
  sword: ["f", "p", "s"],
  swu: ["m", "n"],
  sy: ["b", "c", "d", "l", "m", "n", "p", "r", "s"],
  syc: ["a", "o"],
  syl: ["l", "p", "v"],
  syll: ["a", "o"],
  sym: ["b", "m", "p"],
  symb: ["i", "o"],
  symp: ["a", "h", "o", "t"],
  symph: ["o", "y"],
  syn: ["a", "c", "d", "e", "o", "t"],
  syna: ["g", "p"],
  sync: ["h", "o", "r"],
  synch: ["r"],
  synd: ["i", "r"],
  syne: ["c", "r"],
  syno: ["d", "n", "p", "v"],
  synod: ["a", "i"],
  synop: ["s", "t"],
  synt: ["a", "h"],
  synta: ["c", "x"],
  syr: ["i", "u"],
  syri: ["a", "n"],
  syria: ["n"],
  syrin: ["g", "x"],
  t: [
    "b",
    "c",
    "d",
    "h",
    "k",
    "m",
    "n",
    "t",
    "v",
    "w",
    "x",
    "a",
    "b",
    "c",
    "e",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "u",
    "w",
    "y",
    "z",
  ],
  ta: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
  ],
  tab: ["a", "b", "e", "l", "o", "u"],
  taba: ["r", "s"],
  tabl: ["a", "e", "i", "o"],
  table: ["a", "c", "h", "l", "m", "s", "t", "w"],
  tabo: ["o", "r"],
  tabor: ["e"],
  tabu: ["l"],
  tac: ["h", "i", "k", "o", "t"],
  tach: ["o", "y"],
  tack: ["i", "l", "y"],
  tackl: ["e", "i"],
  taco: ["m", "n"],
  tact: ["f", "i", "l", "u"],
  tacti: ["c", "l"],
  tad: ["p"],
  taf: ["f", "t"],
  taff: ["e", "r", "y"],
  tag: ["a"],
  tai: ["g", "l", "n", "p", "w"],
  tail: ["b", "c", "e", "g", "i", "l", "o", "p", "s", "w"],
  taill: ["e", "i"],
  tak: ["e", "i"],
  take: ["a", "n", "o", "r"],
  takeo: ["f", "v"],
  tal: ["c", "e", "i", "k", "l", "m", "o", "u"],
  talc: ["u"],
  tale: ["b", "n", "s", "t"],
  tali: ["s"],
  talk: ["a", "e", "i"],
  talki: ["e", "n"],
  tall: ["a", "b", "i", "n", "o", "y"],
  tally: ["h", "m"],
  tam: ["a", "b", "e", "i", "m", "p"],
  tama: ["b", "l", "r"],
  tamar: ["a", "i"],
  tame: ["a", "l", "n", "r"],
  tamp: ["a", "e", "i", "o"],
  tan: ["a", "b", "d", "g", "k", "n", "s", "t", "z"],
  tang: ["a", "e", "i", "l", "o", "y"],
  tange: ["l", "n", "r"],
  tank: ["a", "e"],
  tanka: ["r"],
  tanke: ["d", "r"],
  tann: ["e", "i", "o"],
  tanni: ["c", "n"],
  tant: ["a", "r"],
  tanta: ["l", "m"],
  tantr: ["a", "u"],
  tap: ["a", "e", "i", "r"],
  tape: ["r", "s", "t", "w"],
  taper: ["e", "i"],
  tapi: ["o", "r"],
  tar: ["a", "b", "d", "e", "g", "i", "m", "n", "o", "p", "r", "s", "t"],
  tard: ["i", "y"],
  tardi: ["l", "n"],
  tarn: ["i"],
  taro: ["t"],
  tarp: ["a", "o"],
  tarr: ["a", "y"],
  tars: ["a", "i", "u"],
  tart: ["a", "l", "n"],
  tarta: ["n", "r"],
  tartl: ["e", "y"],
  tas: ["k", "m", "s", "t"],
  task: ["f", "m"],
  tast: ["e", "i", "y"],
  taste: ["f", "l", "r"],
  tat: ["a", "t"],
  tata: ["r"],
  tatt: ["e", "i", "l", "o", "y"],
  tattl: ["e", "i"],
  tau: ["g", "n", "p", "r", "t"],
  taur: ["i", "u"],
  taut: ["o"],
  taw: ["d", "n"],
  tax: ["a", "i", "o", "p"],
  taxa: ["b", "t"],
  taxi: ["c", "d", "m", "s"],
  tb: ["s"],
  tbs: ["p"],
  tc: ["h"],
  te: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "x",
  ],
  tea: ["c", "g", "h", "k", "l", "m", "p", "r", "s", "t", "z"],
  teac: ["a", "h", "u"],
  teach: ["a", "e", "i"],
  teak: ["e"],
  teal: ["e"],
  team: ["e", "i", "m", "s", "w"],
  tear: ["d", "f", "g", "j", "l", "o", "y"],
  teas: ["e", "p"],
  tease: ["l", "r"],
  teat: ["a", "i"],
  teaz: ["e", "l"],
  tec: ["h", "t"],
  tech: ["n", "y"],
  techn: ["e", "i", "o"],
  ted: ["d", "i"],
  tedi: ["o", "u"],
  tee: ["m", "n", "p", "t"],
  teem: ["i"],
  teen: ["a", "s", "y"],
  teeny: ["b"],
  teet: ["e", "h", "o"],
  teeth: ["e", "i"],
  teg: ["m", "u"],
  teh: ["e", "r"],
  tel: ["e", "f", "l", "p", "s"],
  tele: ["c", "g", "k", "m", "o", "p", "s", "t", "v", "x"],
  telec: ["a", "o"],
  teleg: ["e", "r"],
  telep: ["a", "h", "l", "r"],
  telet: ["h", "y"],
  tell: ["e", "i", "t", "u", "y"],
  tem: ["b", "e", "p"],
  temp: ["e", "l", "o", "s", "t"],
  tempe: ["r", "s"],
  templ: ["a", "e"],
  tempo: ["r"],
  tempt: ["a", "e", "i", "r"],
  ten: ["a", "d", "e", "f", "n", "o", "p", "s", "t", "u"],
  tena: ["b", "c", "n"],
  tenan: ["c", "t"],
  tend: ["e", "o", "r"],
  tende: ["n", "r"],
  tene: ["b", "m", "t"],
  tenn: ["e", "i", "y"],
  tenne: ["r", "s"],
  teno: ["n", "r"],
  tens: ["e", "i", "o"],
  tense: ["d"],
  tensi: ["l", "o", "t"],
  tent: ["a", "e", "h"],
  tenta: ["c", "t"],
  tente: ["d", "r"],
  tenu: ["i", "o", "r"],
  tep: ["e", "i"],
  ter: ["b", "c", "e", "m", "n", "p", "r", "s", "t"],
  term: ["a", "i", "s"],
  termi: ["n", "t"],
  tern: ["a"],
  terr: ["a", "e", "i", "o", "y"],
  terra: ["c", "i", "m", "p", "r", "z"],
  terri: ["b", "e", "f", "n", "t"],
  tes: ["s", "t"],
  test: ["a", "e", "i", "y"],
  testa: ["b", "m", "t"],
  teste: ["r", "s"],
  testi: ["c", "f", "l", "m", "n", "s"],
  tet: ["a", "c", "e", "h", "r"],
  tetr: ["a", "o"],
  tetra: ["h", "l", "m", "p"],
  tex: ["a", "t"],
  text: ["b", "i", "u"],
  textu: ["a", "r"],
  th: ["c", "i", "a", "e", "i", "o", "r", "u", "w", "y"],
  tha: ["i", "l", "m", "n", "t", "w"],
  thai: ["l"],
  thal: ["a", "i", "l"],
  thall: ["i", "o"],
  than: ["e", "k"],
  thank: ["f", "l", "s"],
  that: ["c"],
  the: [
    "a",
    "b",
    "c",
    "e",
    "f",
    "g",
    "i",
    "m",
    "n",
    "o",
    "r",
    "s",
    "t",
    "w",
    "y",
  ],
  theb: ["a", "e"],
  thei: ["r", "s"],
  their: ["s"],
  theis: ["m", "t"],
  them: ["a", "e", "s"],
  theme: [],
  then: ["c"],
  theo: ["b", "c", "d", "l", "p", "r", "s"],
  theod: ["i", "o"],
  theol: ["o"],
  theor: ["e", "i", "y"],
  ther: ["a", "e", "m"],
  there: ["a", "b", "f", "i", "o", "t", "u", "w"],
  therm: ["a", "i", "o"],
  thes: ["a", "e", "i", "p"],
  these: ["s"],
  they: [],
  thi: ["a", "b", "c", "e", "g", "m", "n", "r", "s", "t"],
  thie: ["f", "v"],
  thiev: ["e", "i"],
  thim: ["b", "p"],
  thimb: ["l", "u"],
  thin: ["e", "g", "k", "l", "n"],
  thing: ["a", "u"],
  think: ["a", "e", "i"],
  thir: ["d", "s", "t"],
  third: ["l"],
  thirt: ["e", "i", "y"],
  this: ["t"],
  tho: ["l", "m", "n", "r", "s", "u"],
  thor: ["a", "i", "n", "o"],
  thora: ["c", "x"],
  thorn: ["l", "y"],
  thou: ["g", "s"],
  thr: ["a", "e", "i", "o", "u"],
  thra: ["c", "l", "s"],
  thral: ["d", "l"],
  thre: ["a", "e", "n", "s", "w"],
  threa: ["d", "t"],
  three: ["f", "p", "s"],
  thri: ["c", "f", "l", "p", "v"],
  thriv: ["e", "i"],
  thro: ["a", "b", "e", "m", "n", "s", "t", "u", "v", "w"],
  thron: ["e", "g"],
  throw: ["a", "b", "e", "i", "n"],
  thru: ["m", "s", "w"],
  thrus: ["h", "t"],
  thu: ["d", "g", "l", "m", "n", "r", "s"],
  thug: ["g"],
  thum: ["b", "p"],
  thumb: ["e", "n", "s", "t"],
  thump: ["e", "i"],
  thur: ["i", "s"],
  thurs: ["d"],
  thy: ["m", "r", "s"],
  thym: ["e", "i", "u"],
  thymi: ["c", "n"],
  ti: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "z",
  ],
  tib: ["e", "i"],
  tibe: ["r", "t"],
  tibet: ["a"],
  tic: ["k"],
  tick: ["e", "i", "l", "t"],
  ticke: ["r", "t"],
  tickl: ["e", "i"],
  tid: ["a", "b", "d", "e", "i", "y"],
  tide: ["l", "m", "w"],
  tidi: ["l", "n"],
  tidin: ["e", "g"],
  tie: ["b", "p", "r"],
  tieb: ["a", "r"],
  tig: ["e", "h", "l", "r"],
  tigr: ["e", "i"],
  til: ["d", "e", "i", "l", "t"],
  till: ["a", "e"],
  tilla: ["b", "g"],
  tilt: ["h", "i"],
  tim: ["b", "e", "i", "o", "p"],
  timb: ["a", "e", "r"],
  time: ["c", "f", "k", "l", "o", "p", "r", "s", "t", "w"],
  timel: ["e", "i", "y"],
  times: ["a", "e", "h"],
  timi: ["d", "n"],
  timid: ["i", "l", "n"],
  timo: ["r", "t"],
  tin: ["c", "d", "e", "f", "g", "k", "n", "p", "s", "t", "w", "y"],
  tine: ["a"],
  ting: ["e", "l"],
  tink: ["e", "l"],
  tinkl: ["e", "i"],
  tinn: ["e", "i", "y"],
  tinni: ["n", "t"],
  tins: ["e", "m"],
  tint: ["a", "e", "i", "y"],
  tip: ["p", "s", "t"],
  tipp: ["e", "i", "l"],
  tippe: ["r", "t"],
  tips: ["i", "t", "y"],
  tipsi: ["l", "n"],
  tipst: ["a", "e"],
  tir: ["a", "e", "o"],
  tire: ["d", "l", "s"],
  tired: ["l", "n"],
  tis: ["a", "s"],
  tit: ["a", "b", "h", "i", "l", "m", "r", "t", "u"],
  tith: ["e", "i"],
  titi: ["a", "l", "v"],
  titl: ["e", "i"],
  title: ["d", "h"],
  titt: ["e", "i", "l", "y"],
  tk: ["o", "t"],
  tn: ["t", "g", "p"],
  to: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "i",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "w",
    "x",
    "y",
  ],
  toa: ["d", "s"],
  toad: ["s", "y"],
  tob: ["a", "o", "y"],
  toba: ["c", "g"],
  toc: ["c", "s"],
  tod: ["a", "d", "o"],
  todd: ["l", "y"],
  toe: ["c", "d", "h", "n"],
  tog: ["a", "e", "g", "o"],
  toi: ["l", "n"],
  toil: ["e", "s"],
  toile: ["r", "t"],
  tol: ["d", "e", "l", "s", "t", "u"],
  tole: ["r"],
  toll: ["b", "g", "h"],
  tom: ["a", "b", "c", "e", "f", "m", "o", "t"],
  toma: ["h", "t"],
  tomb: ["o", "s"],
  tombo: ["l", "y"],
  ton: ["a", "e", "g", "i", "n", "s"],
  tone: ["d", "l"],
  tong: ["a", "s", "u"],
  toni: ["c", "g", "t"],
  tonic: [],
  tonn: ["a", "e"],
  tonne: ["a"],
  tons: ["i", "o", "u"],
  too: ["k", "l", "n", "t"],
  tool: ["b", "i"],
  toot: ["h", "l", "s"],
  tooth: ["a", "b", "c", "e", "l", "p", "s", "y"],
  top: ["a", "c", "d", "e", "f", "g", "i", "k", "l", "m", "n", "o", "p", "s"],
  tope: ["e", "k"],
  topi: ["a", "c"],
  topic: ["a"],
  topm: ["a", "o"],
  topo: ["g", "l"],
  topog: ["r"],
  topp: ["e", "i", "l"],
  tops: ["a", "i", "o", "p", "y"],
  tor: ["a", "c", "e", "m", "n", "o", "p", "q", "r", "s", "t", "u", "y"],
  tore: ["a"],
  torn: ["a"],
  torp: ["e", "i", "o"],
  torr: ["e", "i"],
  tors: ["i", "o"],
  tort: ["i", "o", "u"],
  torti: ["l", "o"],
  torto: ["i", "n"],
  tortu: ["o", "r"],
  tot: ["a", "e", "t"],
  tote: ["m"],
  totem: ["i"],
  tou: ["c", "g", "p", "r", "s", "t"],
  touc: ["a", "h"],
  touch: ["a", "b", "d", "e", "i", "l", "s", "y"],
  tour: ["i", "m", "n"],
  tourn: ["a", "e", "i"],
  tout: [],
  tow: ["a", "b", "e", "h", "l", "n", "p", "r"],
  towe: ["l", "r"],
  towel: ["i", "l"],
  tower: ["e", "i"],
  town: ["h", "s"],
  towns: ["c", "f", "h", "m", "p", "w"],
  tox: ["a", "e", "i", "o"],
  toxi: ["c", "n"],
  toxic: ["a", "i", "o"],
  toy: ["s"],
  tp: ["k"],
  tr: ["a", "e", "i", "o", "u", "y"],
  tra: [
    "b",
    "c",
    "d",
    "f",
    "g",
    "i",
    "j",
    "m",
    "n",
    "p",
    "s",
    "u",
    "v",
    "w",
    "y",
  ],
  trac: ["e", "h", "i", "k", "t"],
  trace: ["a", "r"],
  trach: ["e", "o"],
  track: ["a", "e", "i", "l", "s"],
  tract: ["a", "i", "o"],
  trad: ["e", "i", "u"],
  trade: ["d", "m", "o", "r", "s"],
  tradi: ["n", "t"],
  traf: ["a", "f"],
  trag: ["e", "i"],
  trai: ["l", "n", "p", "t"],
  trail: ["b", "e", "i"],
  train: ["a", "b", "e", "i", "m"],
  trait: ["o", "r"],
  tram: ["l", "m", "p", "w"],
  tramp: ["l", "o"],
  tran: ["c", "n", "q", "s"],
  trans: [
    "a",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "l",
    "m",
    "n",
    "o",
    "p",
    "s",
    "u",
    "v",
  ],
  trap: ["d", "e", "p", "s"],
  trape: ["s", "z"],
  trapp: ["e", "i"],
  traps: ["h"],
  trav: ["a", "e", "o"],
  trave: ["l", "r", "s"],
  tray: ["s"],
  tre: ["a", "b", "c", "e", "f", "k", "l", "m", "n", "p", "s", "w", "y"],
  trea: ["c", "d", "s", "t"],
  treac: ["h", "l"],
  tread: ["l", "m"],
  treas: ["o", "u"],
  treat: ["a", "e", "i", "m", "y"],
  treb: ["l", "u"],
  tree: ["l", "t"],
  trek: ["k"],
  trem: ["a", "b", "e", "o", "u"],
  tremo: ["l", "r"],
  tren: ["c", "d", "t"],
  trend: ["s", "y"],
  trep: ["a", "h", "i"],
  tres: ["p", "s", "t"],
  tri: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "v",
  ],
  tria: ["d", "g", "l", "n", "s"],
  triad: ["i"],
  trial: [],
  trib: ["a", "e", "u"],
  tribe: ["s"],
  tribu: ["l", "n", "t"],
  tric: ["e", "h", "k", "o", "u", "y"],
  trice: ["n", "p", "r"],
  trick: ["e", "i", "l", "s", "y"],
  trico: ["l", "t"],
  trie: ["d", "n", "r"],
  trif: ["l", "o"],
  trifl: ["e", "i"],
  trifo: ["c", "l"],
  trig: ["e", "g", "l", "o"],
  tril: ["a", "b", "i", "l", "o"],
  trill: ["i"],
  trilo: ["b", "g"],
  trim: ["a", "e", "m", "o"],
  trimm: ["e", "i"],
  trin: ["e", "i", "k", "o"],
  trini: ["d", "t"],
  trio: ["d", "x"],
  trip: ["a", "e", "l", "o", "p", "t", "w"],
  tripl: ["e", "i", "y"],
  tripo: ["d", "l", "s"],
  tripp: ["e", "i"],
  tris: ["e", "k", "m"],
  trit: ["e", "i", "o", "u"],
  triu: ["m", "n"],
  trium: ["p", "v"],
  triv: ["a", "e", "i"],
  trivi: ["a", "u"],
  tro: ["c", "d", "g", "i", "j", "l", "m", "o", "p", "t", "u", "v", "w", "y"],
  trod: ["d"],
  trom: ["b", "m", "p"],
  trop: ["e", "h", "i", "o"],
  troph: ["i", "y"],
  tropi: ["c", "s"],
  tropo: ["l", "p", "s"],
  trot: ["h", "s", "t"],
  trou: ["b", "g", "n", "p", "s", "t"],
  troub: ["a", "l"],
  trous: ["e", "s"],
  trow: ["e"],
  troy: [],
  tru: ["a", "c", "d", "e", "f", "g", "i", "l", "m", "n", "s", "t"],
  truc: ["e", "k", "u"],
  truck: ["a", "e", "i", "l", "m"],
  true: ["b", "h", "l"],
  trum: ["a", "p"],
  trump: ["e"],
  trun: ["c", "d", "k"],
  trunc: ["a", "h"],
  trunk: ["f"],
  trus: ["s", "t"],
  trust: ["e", "f", "i", "w", "y"],
  try: ["i", "o", "p", "s"],
  ts: ["a", "e", "p"],
  tu: [
    "b",
    "c",
    "e",
    "f",
    "g",
    "i",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "x",
  ],
  tub: ["a", "b", "e", "f", "i", "u"],
  tuba: ["l"],
  tube: ["l", "r"],
  tuber: ["c", "o"],
  tuf: ["a", "f", "t"],
  tuft: ["e"],
  tug: ["b"],
  tul: ["i", "l"],
  tum: ["b", "e", "i", "m", "o", "u"],
  tumb: ["l", "r"],
  tumbl: ["e", "i"],
  tumbr: ["e", "i"],
  tumo: ["r", "u"],
  tun: ["a", "d", "e", "g", "i", "n"],
  tuna: ["b"],
  tune: ["f", "l", "r"],
  tung: ["s"],
  tuni: ["c", "n", "s"],
  tunis: ["i"],
  tunn: ["e", "y"],
  tup: ["p"],
  tur: ["b", "d", "e", "f", "g", "i", "k", "m", "n", "p", "q", "r", "t"],
  turb: ["a", "i", "o", "u"],
  turbi: ["d", "n"],
  turbo: ["f", "j", "p", "t"],
  turf: [],
  turk: ["e", "i"],
  turki: ["c", "s"],
  turm: ["e", "o"],
  turn: ["a", "b", "c", "d", "e", "i", "k", "o", "p", "s", "t", "u"],
  turna: ["b", "r"],
  turni: ["n", "p"],
  turno: ["f", "u", "v"],
  turp: ["e", "i"],
  tus: ["c", "h", "k", "s"],
  tusk: ["e"],
  tuss: ["l", "o"],
  tut: ["a", "e", "o", "t", "u"],
  tux: ["e"],
  tv: ["a"],
  tw: ["x", "a", "e", "i", "o", "p"],
  twa: ["d", "i", "n", "t"],
  twe: ["a", "e", "l", "n", "r"],
  twee: ["d", "t", "z"],
  tweed: ["y"],
  tweet: ["e"],
  twel: ["f", "v"],
  twi: ["c", "d", "g", "l", "n", "r", "s", "t"],
  twig: ["g"],
  twil: ["i", "l"],
  twin: ["e", "g", "i", "k", "n"],
  twini: ["g", "n"],
  twinn: ["e", "i"],
  twir: ["l", "p"],
  twirl: ["e"],
  twit: ["c", "t"],
  two: ["f", "p", "s"],
  twof: ["a", "e", "o"],
  ty: ["c", "g", "i", "k", "m", "p", "r"],
  typ: ["e", "h", "i", "o"],
  type: ["c", "f", "s", "w"],
  types: ["c", "e"],
  typh: ["o", "u"],
  typho: ["i", "o"],
  typi: ["c", "f", "n", "s"],
  typo: ["g", "l"],
  typog: ["r"],
  tyr: ["a", "e", "o"],
  tz: ["a", "e"],
  u: [
    "a",
    "f",
    "h",
    "k",
    "m",
    "n",
    "p",
    "s",
    "t",
    "v",
    "b",
    "d",
    "g",
    "h",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
    "x",
  ],
  ug: ["a", "h", "l"],
  ugl: ["i", "y"],
  ugli: ["l", "n"],
  ugly: [],
  uh: ["f", "f", "h", "u"],
  uk: ["a", "r", "u"],
  ul: ["c", "e", "l", "n", "s", "t", "u", "y"],
  ult: ["e", "i", "r"],
  um: ["t", "b", "i", "l", "p"],
  umb: ["e", "i", "r"],
  umbe: ["l", "r"],
  umbr: ["a", "e"],
  umbra: ["g"],
  ump: ["i", "t"],
  un: [
    "c",
    "e",
    "i",
    "o",
    "r",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "z",
  ],
  una: ["b", "c", "d", "f", "i", "l", "n", "p", "r", "s", "t", "v", "w"],
  unab: ["a", "l", "r"],
  unaba: ["s", "t"],
  unac: ["c", "q"],
  unacc: ["e", "o", "u"],
  unad: ["o", "u", "v"],
  unado: ["p", "r"],
  unal: ["i", "l", "t"],
  unan: ["i", "n", "s"],
  unas: ["k", "s"],
  unass: ["a", "u"],
  unav: ["a", "o"],
  unb: ["a", "e", "i", "l", "o", "r", "u"],
  unba: ["l", "r"],
  unbe: ["a", "c", "k", "l", "n"],
  unbea: ["r", "t"],
  unben: ["d", "t"],
  unbi: ["a", "d", "n"],
  unbl: ["e", "u"],
  unble: ["m", "s"],
  unbo: ["l", "r", "s", "u", "w"],
  unbr: ["e", "i", "o"],
  unbu: ["c", "r", "t"],
  unc: ["t", "a", "e", "h", "i", "l", "o", "r", "t", "u"],
  unca: ["l", "n", "p", "r", "u"],
  unce: ["a", "r"],
  uncer: ["e", "t"],
  unch: ["a", "e", "r"],
  uncha: ["i", "n", "r", "s"],
  unci: ["a", "r", "v"],
  uncl: ["a", "e", "o"],
  uncla: ["d", "s"],
  uncle: ["a", "n"],
  unclo: ["a", "g", "s", "t", "u"],
  unco: ["i", "l", "m", "n", "r", "u", "v"],
  uncom: ["f", "m", "p"],
  uncon: ["c", "d", "n", "q", "s", "t", "v"],
  uncou: ["n", "p", "t"],
  uncr: ["e", "i", "o", "u"],
  uncro: ["s", "w"],
  unct: ["a", "i", "u"],
  uncu: ["r", "t"],
  und: ["a", "e", "i", "o", "r", "u", "y"],
  unda: ["t", "u"],
  unde: ["c", "f", "m", "n", "r", "s", "t", "v"],
  undec: ["e", "i", "l"],
  under: [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
    "w",
  ],
  undes: ["e", "i"],
  undi: ["d", "e", "f", "g", "r", "s", "v"],
  undig: ["e", "n"],
  undis: ["c", "g", "p", "t"],
  undo: ["i", "m", "n", "u"],
  undu: ["e", "l"],
  undul: ["a", "y"],
  une: ["s", "a", "c", "d", "m", "n", "q", "r", "s", "v", "x"],
  unea: ["r", "s"],
  unear: ["n", "t"],
  uneas: ["e", "i", "y"],
  unem: ["b", "p"],
  unen: ["d", "l", "v"],
  unes: ["c", "s"],
  unex: ["a", "c", "p"],
  unexp: ["e", "l"],
  unf: ["a", "e", "i", "l", "o", "r", "u"],
  unfa: ["i", "l", "m", "s", "t", "v"],
  unfai: ["l", "r", "t"],
  unfe: ["e", "i", "t"],
  unfi: ["n", "t", "x"],
  unfl: ["a", "e", "i"],
  unfla: ["g", "p"],
  unfo: ["l", "r", "u"],
  unfor: ["e", "g", "m", "t"],
  unfr: ["e", "i", "o", "u"],
  unfre: ["e", "q"],
  ung: ["a", "e", "l", "o", "r", "u"],
  ungo: ["d", "v"],
  ungr: ["a", "u"],
  ungra: ["c", "m", "t"],
  ungu: ["a", "e", "l"],
  unh: ["a", "e", "i", "o", "u"],
  unha: ["l", "n", "p", "r"],
  unhar: ["m", "n"],
  unhe: ["a", "e"],
  unhea: ["l", "r"],
  unhi: ["n", "t"],
  unho: ["l", "o", "p", "r"],
  unhol: ["i", "y"],
  uni: ["c", "a", "c", "d", "f", "l", "m", "n", "o", "p", "q", "s", "t", "v"],
  unic: ["e", "a", "e", "i", "o", "y"],
  unice: ["f", "l"],
  unid: ["e", "i"],
  unidi: ["m", "r"],
  unif: ["i", "o", "y"],
  unifi: ["c", "e"],
  unim: ["a", "p"],
  unimp: ["e", "o", "r"],
  unin: ["f", "h", "t", "v"],
  uninh: ["a", "i"],
  unis: ["e", "o"],
  unit: ["a", "e", "i", "y"],
  unite: ["d", "r"],
  uniti: ["s", "v", "z"],
  univ: ["a", "e", "o"],
  unk: ["e", "i", "n"],
  unl: ["a", "e", "i", "o", "u"],
  unla: ["c", "t", "w"],
  unle: ["a", "s", "t"],
  unlea: ["r", "s", "v"],
  unli: ["k", "m", "s", "v"],
  unlim: ["b", "i"],
  unlo: ["a", "c", "o", "v"],
  unloo: ["k", "s"],
  unm: ["a", "e", "i", "o"],
  unma: ["d", "k", "n", "r", "s", "t"],
  unman: ["l", "n"],
  unmar: ["k", "r"],
  unme: ["a", "n", "r"],
  unmea: ["n", "s"],
  unmi: ["n", "s", "t"],
  unmo: ["l", "r", "v"],
  unmov: ["a", "e"],
  unn: ["a", "e", "o", "u"],
  unna: ["m", "t"],
  unne: ["c", "r"],
  uno: ["b", "c", "f", "p", "r", "w"],
  unob: ["s", "t"],
  unor: ["g", "t"],
  unp: ["a", "e", "i", "l", "o", "r"],
  unpa: ["c", "i", "r", "v"],
  unpai: ["d", "r"],
  unpar: ["a", "d", "l"],
  unpe: ["e", "r"],
  unpi: ["c", "n"],
  unpl: ["a", "e", "u"],
  unpla: ["c", "y"],
  unpr: ["a", "e", "i", "o"],
  unpre: ["c", "d", "j", "m", "p", "t"],
  unpro: ["f", "m", "t", "v"],
  unr: ["w", "a", "e", "i", "o", "u"],
  unre: ["a", "c", "d", "g", "l", "m", "q", "s"],
  unrea: ["d", "l", "s"],
  unrel: ["a", "e", "i"],
  unres: ["e", "p", "t"],
  unri: ["g", "p", "v"],
  unrip: ["e"],
  unro: ["l", "o"],
  unru: ["f", "l"],
  unrul: ["i", "y"],
  uns: ["a", "c", "e", "h", "i", "k", "n", "o", "p", "t", "u", "w", "y"],
  unsa: ["d", "f", "i", "l", "n", "t", "v", "y"],
  unsal: ["a", "t"],
  unsat: ["i", "u"],
  unsc: ["a", "h", "i", "r"],
  unscr: ["a", "e", "i", "u"],
  unse: ["a", "e", "l", "r", "t", "x"],
  unsea: ["l", "s", "t"],
  unsee: ["i", "m", "n"],
  unsex: ["e"],
  unsh: ["a", "e", "o"],
  unsha: ["c", "k", "p", "v"],
  unshe: ["a", "d"],
  unsho: ["d", "r"],
  unso: ["c", "l", "p", "r", "u"],
  unsp: ["a", "e", "o"],
  unspe: ["a", "c"],
  unspo: ["i", "k", "t"],
  unst: ["a", "e", "i", "o", "r", "u"],
  unstr: ["e", "u"],
  unstu: ["c", "d"],
  unsu: ["b", "c", "i", "l", "n", "p", "r", "s"],
  unsur: ["e", "p"],
  unt: ["a", "e", "h", "i", "o", "r", "u", "w"],
  unta: ["m", "n", "p", "u"],
  unth: ["i", "o"],
  unti: ["d", "e", "l", "m", "n", "r", "t"],
  untid: ["i", "y"],
  unto: ["l", "u", "w"],
  untr: ["a", "e", "i", "o", "u"],
  untra: ["i", "m", "v"],
  untru: ["e", "t"],
  untu: ["r", "t"],
  unu: ["s", "t"],
  unus: ["e", "u"],
  unv: ["a", "e", "o"],
  unve: ["i", "r"],
  unw: ["a", "e", "h", "i", "o", "r"],
  unwa: ["n", "r", "s"],
  unwar: ["r", "y"],
  unwe: ["d", "l"],
  unwel: ["c", "l"],
  unwi: ["e", "l", "n", "s", "t"],
  unwo: ["n", "r", "u"],
  unwor: ["l", "t"],
  unwr: ["a", "i"],
  up: [
    "c",
    "i",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "k",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "w",
  ],
  upb: ["e", "r"],
  upbr: ["a", "i"],
  upc: ["h", "o"],
  upco: ["m", "u"],
  upd: ["a", "r"],
  uph: ["e", "i", "o"],
  uphe: ["a", "l"],
  upl: ["a", "i"],
  upp: ["e", "i"],
  uppi: ["s", "t"],
  upr: ["a", "i", "o"],
  upri: ["g", "s"],
  upro: ["a", "o"],
  ups: ["c", "e", "h", "i", "t", "u", "w"],
  upsh: ["i", "o"],
  upsi: ["d", "l"],
  upst: ["a", "r"],
  upsta: ["g", "i", "n", "r", "t"],
  upstr: ["e", "o"],
  upt: ["a", "i", "o", "u"],
  upti: ["c", "g"],
  upw: ["a", "i"],
  ur: ["a", "b", "c", "d", "e", "g", "i", "n", "o", "s", "t", "u"],
  ura: ["c", "e", "l", "n"],
  uran: ["i", "u"],
  urani: ["a", "c", "u"],
  urb: ["a"],
  ure: ["a", "m", "t"],
  uret: ["e", "h"],
  ureth: ["a", "r"],
  uri: ["c", "n"],
  urin: ["a", "e"],
  urina: ["l", "r", "t"],
  uro: ["g", "l"],
  urs: ["a", "i"],
  us: ["a", "c", "d", "e", "i", "m", "n", "s", "a", "e", "h", "u"],
  usa: ["f", "b", "g"],
  use: ["s", "a", "d", "f", "l", "r"],
  usm: ["c"],
  usu: ["a", "f", "r"],
  usur: ["e", "i", "p", "y"],
  usurp: ["a", "e"],
  ut: ["a", "e", "i", "m", "o", "r", "t"],
  ute: ["n", "r"],
  uter: ["i", "u"],
  uv: ["u"],
  ux: ["o"],
  v: [
    "a",
    "c",
    "d",
    "h",
    "i",
    "l",
    "o",
    "s",
    "t",
    "a",
    "b",
    "e",
    "h",
    "i",
    "l",
    "o",
    "s",
    "t",
    "u",
    "y",
  ],
  va: [
    "d",
    "t",
    "c",
    "d",
    "g",
    "i",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
  ],
  vac: ["a", "c", "i", "u"],
  vaca: ["n", "t"],
  vacan: ["c", "t"],
  vacat: ["e", "i"],
  vacu: ["i", "o", "u"],
  vacuo: ["l", "u"],
  vad: ["m", "e"],
  vag: ["a", "i", "r", "u"],
  vaga: ["b", "l", "r"],
  vagr: ["a", "o"],
  vagu: ["e", "s"],
  vague: ["l", "n"],
  vai: ["l", "n"],
  vain: ["g", "l"],
  val: ["a", "e", "h", "i", "l", "o", "s", "u", "v"],
  vale: ["d", "n", "r", "t"],
  valen: ["c", "t"],
  valet: ["u"],
  vali: ["a", "d", "s"],
  valid: ["a", "i", "l"],
  valo: ["r", "u"],
  valor: ["i", "o"],
  valu: ["a", "e"],
  valua: ["b", "t"],
  value: ["d", "l", "r"],
  valv: ["e", "u"],
  vam: ["o", "p"],
  vamp: ["i"],
  van: ["a", "c", "d", "e", "g", "i", "q", "t"],
  vand: ["a", "y"],
  vani: ["l", "s", "t"],
  vap: ["i", "o"],
  vapo: ["r", "u"],
  vapor: ["i", "o"],
  var: ["i", "l", "m", "n", "s", "y"],
  vari: ["a", "c", "e", "f", "o"],
  varia: ["b", "n", "t"],
  varic: ["e", "o"],
  varie: ["d", "g", "t"],
  vario: ["r", "u"],
  vary: ["i"],
  vas: ["c", "e", "o", "s", "t"],
  vase: ["c", "l"],
  vaso: ["c", "d", "m"],
  vast: ["l", "n", "y"],
  vat: ["i"],
  vau: ["d", "l", "n"],
  ve: ["a", "c", "d", "e", "g", "h", "i", "l", "n", "r", "s", "t", "x"],
  vee: ["p", "r"],
  veer: ["i", "y"],
  veg: ["a", "e"],
  vega: ["n"],
  veh: ["e", "i"],
  vei: ["l", "n"],
  veil: ["e", "i"],
  vein: ["e", "i", "y"],
  vel: ["a", "c", "d", "l", "o", "u", "v"],
  veld: ["t"],
  vell: ["e", "u"],
  velo: ["c", "u"],
  ven: ["a", "d", "e", "g", "i", "o", "t", "u"],
  vena: ["l", "t"],
  venal: ["i"],
  vend: ["e", "i", "o"],
  vende: ["e", "r", "t"],
  vene: ["e", "r", "t", "z"],
  vener: ["a", "e"],
  veni: ["a", "c", "p", "r", "s"],
  veno: ["m", "u"],
  venom: ["o"],
  vent: ["i", "r", "u"],
  ventr: ["a", "i"],
  venu: ["e", "s"],
  venus: ["i"],
  ver: ["a", "b", "d", "g", "i", "m", "n", "o", "r", "s", "t", "v", "y"],
  vera: ["c", "n"],
  verb: ["a", "e", "i", "o"],
  verba: ["l", "t"],
  verbo: ["s", "t"],
  verd: ["a", "i", "u"],
  verdi: ["c", "g"],
  verg: ["e", "i"],
  verge: ["r"],
  veri: ["d", "e", "f", "l", "s", "t"],
  verif: ["i", "y"],
  verit: ["a", "y"],
  verm: ["e", "i", "o"],
  vermi: ["c", "f", "l", "n"],
  vermo: ["n", "u"],
  vern: ["a", "i"],
  verna: ["c", "l"],
  vers: ["a", "e", "i", "o", "u"],
  versa: ["i", "t"],
  verse: ["d"],
  versi: ["c", "f", "o"],
  vert: ["e", "i"],
  verte: ["b", "x"],
  verti: ["c", "g"],
  verv: ["a", "e"],
  very: [],
  ves: ["i", "p", "s", "t", "u"],
  vesp: ["e", "u"],
  vest: ["a", "e", "i", "m", "r", "u"],
  vesta: ["l"],
  veste: ["d", "e"],
  vesti: ["b", "g", "n"],
  vet: ["c", "e", "o"],
  veto: ["e"],
  vex: ["a", "e"],
  vh: ["f", "f"],
  vi: [
    "p",
    "a",
    "b",
    "c",
    "d",
    "e",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "v",
    "x",
    "z",
  ],
  via: ["b", "d", "l", "n", "t"],
  viab: ["i", "l"],
  viabl: ["e", "y"],
  vib: ["e", "r", "u"],
  vibr: ["a", "i"],
  vibra: ["h", "n", "p", "t"],
  vic: ["a", "e", "h", "i", "t", "u"],
  vice: ["g", "l", "n", "r"],
  vicer: ["e", "o"],
  vici: ["n", "o", "s"],
  vicin: ["a", "i"],
  vict: ["i", "o", "u"],
  vid: ["e", "k"],
  vide: ["l", "o"],
  video: ["d", "t"],
  vie: ["n", "t", "w"],
  vien: ["n", "t"],
  vienn: ["a", "e"],
  viet: ["n"],
  view: ["e", "f", "l", "p"],
  vig: ["e", "i", "n", "o"],
  vigo: ["r", "u"],
  vigor: ["o"],
  vil: ["e", "i", "l"],
  vile: [],
  vill: ["a", "e", "u"],
  villa: ["g", "i"],
  vin: ["a", "c", "d", "e", "o", "t", "y"],
  vine: ["g", "r", "y"],
  vino: ["u"],
  vint: ["a", "n"],
  vip: ["e"],
  vir: ["a", "e", "g", "i", "o", "t", "u"],
  vira: ["g", "l"],
  virg: ["i", "o"],
  virgi: ["l", "n"],
  viru: ["l", "s"],
  vis: ["a", "c", "e", "i", "o", "t", "u"],
  visa: ["g"],
  visc: ["e", "i", "o"],
  visco: ["s", "u"],
  visi: ["b", "o", "t"],
  visib: ["i", "l"],
  visit: ["a", "i", "o"],
  vit: ["a", "i", "r", "u"],
  vita: ["l", "m"],
  vital: ["i", "l", "s"],
  viti: ["a", "c", "l"],
  vitr: ["e", "i"],
  vitri: ["f", "n", "o"],
  viv: ["a", "i"],
  viva: ["c", "r"],
  vivac: ["e", "i"],
  vivi: ["d", "f", "p", "s"],
  vivid: ["l", "n"],
  viz: ["i", "o"],
  vizi: ["e", "r"],
  vl: ["f", "f"],
  vo: [
    "a",
    "c",
    "d",
    "g",
    "i",
    "l",
    "m",
    "n",
    "o",
    "r",
    "t",
    "u",
    "w",
    "x",
    "y",
  ],
  voc: ["a", "i"],
  voca: ["b", "l", "t"],
  vocab: ["l", "u"],
  vocal: ["i", "l"],
  vocat: ["i"],
  voi: ["c", "d", "l"],
  void: ["e", "i"],
  vol: ["a", "c", "e", "g", "i", "l", "t", "u"],
  vola: ["r", "t"],
  volt: ["a", "e", "m"],
  volta: ["g", "i"],
  volu: ["b", "m", "n", "p", "t"],
  volub: ["i", "l"],
  volum: ["e", "i"],
  vor: ["a", "t"],
  vort: ["e", "i"],
  vot: ["a", "e", "i"],
  vote: ["r"],
  voti: ["n", "v"],
  vow: ["e"],
  voy: ["a", "e"],
  vs: ["t"],
  vt: ["o"],
  w: [
    "a",
    "c",
    "h",
    "i",
    "l",
    "n",
    "o",
    "p",
    "r",
    "s",
    "v",
    "w",
    "y",
    "a",
    "e",
    "h",
    "i",
    "k",
    "m",
    "o",
    "p",
    "r",
    "t",
    "u",
    "y",
  ],
  wa: [
    "c",
    "f",
    "s",
    "t",
    "v",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "k",
    "l",
    "m",
    "n",
    "p",
    "r",
    "s",
    "t",
    "v",
    "x",
    "y",
  ],
  wac: ["k"],
  wack: ["i", "y"],
  wad: ["d", "e", "g", "i", "y"],
  wadd: ["i", "l"],
  wade: ["r"],
  wadi: ["n"],
  waf: ["e", "f", "t"],
  wag: ["e", "g", "n", "o", "t"],
  wage: ["r", "s"],
  wager: ["i"],
  wages: [],
  wagg: ["e", "i", "l", "o"],
  wah: ["i", "o"],
  wai: ["f", "k", "l", "n", "s", "t", "v"],
  wail: ["f"],
  wain: ["s", "w"],
  wait: ["e", "i", "r", "s"],
  wak: ["a", "e", "i"],
  wake: ["f", "n"],
  waken: ["i"],
  wal: ["d", "e", "k", "l", "n", "r", "t"],
  wale: ["s"],
  walk: ["a", "e", "i", "o", "u", "w", "y"],
  walka: ["b", "w"],
  walki: ["e", "n"],
  walko: ["u", "v"],
  wall: ["a", "b", "e", "f", "i", "o", "p"],
  walla: ["b", "h"],
  walle: ["t", "y"],
  wallo: ["o", "p", "w"],
  wan: ["d", "e", "g", "i", "k", "l", "t"],
  wand: ["e"],
  wank: ["e"],
  wanke: ["l", "r"],
  want: ["i", "o"],
  war: ["b", "d", "e", "f", "h", "i", "l", "m", "n", "p", "r", "s", "t", "y"],
  ward: ["e", "r", "s"],
  warde: ["n", "r"],
  ware: ["h", "r", "s", "z"],
  warh: ["e", "o"],
  wari: ["l", "n"],
  warl: ["i", "o"],
  warlo: ["c", "r"],
  warm: ["e", "h", "i", "l", "n", "o", "t"],
  warme: ["d", "r"],
  warmi: ["n", "s"],
  warn: ["i"],
  warp: ["a", "i", "l"],
  warpa: ["g", "t"],
  warr: ["a", "e", "i"],
  wars: ["a", "h"],
  wart: ["h", "i", "y"],
  was: ["p", "h", "n", "p", "s", "t"],
  wash: ["a", "b", "c", "d", "e", "h", "i", "o", "r", "s", "t", "w", "y"],
  washb: ["a", "o"],
  washe: ["d", "r"],
  washr: ["a", "o"],
  wasp: ["i", "w", "y"],
  wast: ["a", "e", "i", "r"],
  waste: ["b", "f", "l", "p", "r"],
  wat: ["s", "c", "e", "t"],
  watt: ["a", "l"],
  wav: ["e", "e", "i", "y"],
  wave: ["s", "b", "d", "l", "r"],
  waver: ["e", "i"],
  wax: ["e", "w", "y"],
  waxe: ["d", "n"],
  waxw: ["i", "o"],
  way: ["b", "f", "l", "s", "w"],
  ways: ["i"],
  wayw: ["a", "o"],
  wc: ["t"],
  we: ["a", "b", "d", "e", "f", "i", "l", "n", "p", "r", "s", "t"],
  wea: ["k", "l", "n", "p", "r", "s", "t", "v"],
  weak: ["e", "f", "l", "n"],
  weake: ["n", "r"],
  weakl: ["i", "y"],
  weal: ["d", "t"],
  wear: ["a", "e", "i", "y"],
  weari: ["l", "n", "s"],
  weav: ["e", "i"],
  weave: ["r"],
  web: ["b", "s"],
  webb: ["e", "i", "y"],
  wed: ["d", "g", "l", "n"],
  wedd: ["e", "i"],
  wedg: ["e", "w"],
  wedge: ["d"],
  wee: ["d", "k", "n", "p", "v"],
  weed: ["i", "l", "y"],
  week: ["d", "e", "l", "n"],
  weep: ["e", "i", "y"],
  wei: ["g", "r"],
  weir: ["d"],
  weird: ["i", "n", "o"],
  wel: ["c", "d", "f", "k", "l", "s", "t"],
  welc: ["h", "o"],
  weld: ["e"],
  well: ["b", "h", "i", "s"],
  welt: ["a", "e"],
  wen: ["c", "d", "s", "t"],
  wend: ["s"],
  wer: ["e", "t", "w"],
  were: ["n", "w"],
  wes: ["k", "l", "s", "t"],
  west: ["p", "b", "e", "m", "w"],
  wet: ["b", "h", "n", "s", "t"],
  wh: ["o", "a", "e", "i", "o", "s", "y"],
  wha: ["c", "l", "m", "p", "r", "t"],
  whal: ["e", "i"],
  whale: ["b", "r"],
  wham: ["m"],
  whar: ["f", "v"],
  wharf: ["a", "i"],
  what: ["e", "n", "s"],
  whate: ["v"],
  whe: ["a", "e", "l", "n", "r", "t", "w", "y"],
  whea: ["l", "t"],
  wheat: ["e"],
  whee: ["d", "l", "z"],
  wheel: ["b", "c", "e", "h", "i", "l", "s", "w"],
  wheez: ["e", "i", "y"],
  whel: ["k", "m", "p"],
  when: ["c", "e"],
  whene: ["v"],
  wher: ["e", "r"],
  where: ["a", "b", "f", "i", "o", "s", "t", "u", "v", "w"],
  whet: ["h", "s"],
  whi: ["c", "f", "g", "l", "m", "n", "p", "r", "s", "t", "z"],
  whig: ["g"],
  whil: ["e", "o", "s"],
  whim: ["p", "s"],
  whims: ["e", "i", "y"],
  whin: ["e", "n"],
  whine: ["r"],
  whip: ["c", "l", "p", "s", "t"],
  whipp: ["e", "i", "l", "o", "y"],
  whir: ["l", "r"],
  whirl: ["i", "p", "w", "y"],
  whis: ["h", "k", "p", "t"],
  whisk: ["b", "e", "i", "y"],
  whist: ["l"],
  whit: ["e", "h", "i", "l", "m", "s", "t"],
  white: ["b", "c", "d", "f", "h", "n", "s", "t", "w", "y"],
  whiti: ["n", "s"],
  whiz: ["k", "z"],
  whizz: ["k"],
  who: ["a", "d", "e", "l", "m", "o", "p", "r", "s"],
  whoe: ["v"],
  whol: ["e", "l"],
  whole: ["h", "n", "s"],
  whom: ["e", "s"],
  whoo: ["p", "s"],
  whoop: ["e", "i", "s"],
  whop: ["p"],
  whopp: ["e", "i"],
  whor: ["e", "i", "l", "t"],
  whore: ["h", "m"],
  whorl: ["e"],
  whos: ["e", "o"],
  whose: ["v"],
  whoso: ["e"],
  whs: ["e", "l"],
  wi: ["c", "d", "e", "f", "g", "l", "m", "n", "p", "r", "s", "t", "v", "z"],
  wic: ["h", "k"],
  wick: ["e", "i"],
  wicke: ["d", "r", "t"],
  wicki: ["n", "u"],
  wid: ["e", "g", "o", "t"],
  wide: ["l", "n", "s"],
  widen: ["e"],
  wie: ["l", "n"],
  wig: ["e", "g", "h", "l", "w"],
  wigg: ["e", "i", "l"],
  wil: ["c", "d", "e", "f", "i", "l", "m", "s", "t", "y"],
  wild: ["c", "e", "f", "l", "n", "w"],
  wilde: ["b", "r"],
  wildf: ["i", "l", "o"],
  wildl: ["i", "y"],
  will: ["f", "i", "o", "p", "y"],
  willi: ["a", "e", "n", "w"],
  wilt: ["o"],
  wim: ["b", "p"],
  wimp: ["l", "y"],
  win: ["c", "d", "e", "g", "k", "n", "o", "s", "t", "y"],
  winc: ["e", "h", "i"],
  wince: ["y"],
  wind: [
    "a",
    "b",
    "c",
    "e",
    "f",
    "i",
    "j",
    "l",
    "m",
    "o",
    "p",
    "r",
    "s",
    "u",
    "w",
    "y",
  ],
  windb: ["a", "r", "u"],
  winde: ["d", "r"],
  windf: ["a", "l"],
  windi: ["l", "n"],
  windl: ["a", "e"],
  winds: ["c", "h", "o", "t", "w"],
  wine: ["b", "g", "p", "r", "s"],
  wing: ["d", "e", "l", "s"],
  winge: ["d", "r"],
  wink: ["e", "l"],
  winn: ["e", "i", "o"],
  winni: ["n", "p"],
  wint: ["e", "r"],
  wir: ["e", "i", "y"],
  wire: ["h", "l", "p", "t", "w"],
  wis: ["c", "d", "e", "h", "p", "t"],
  wisc: ["o"],
  wise: ["a", "c", "l"],
  wish: ["b", "f", "i", "y"],
  wisp: ["y"],
  wist: ["e", "f"],
  wit: ["c", "h", "l", "n", "t"],
  with: ["a", "d", "e", "h", "i", "o", "s", "y"],
  withe: ["r"],
  witt: ["i", "y"],
  witti: ["c", "l", "n"],
  wiz: ["a", "e"],
  wk: ["l"],
  wo: [
    "a",
    "b",
    "d",
    "e",
    "g",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "r",
    "t",
    "u",
    "v",
    "w",
  ],
  woe: ["b", "f"],
  wok: ["e"],
  woke: ["n"],
  wol: ["d", "f", "v"],
  wolf: ["h", "i", "r", "s"],
  wom: ["a", "b", "e"],
  womb: ["a"],
  won: ["d", "k", "t"],
  wond: ["e", "r"],
  wont: ["e", "o"],
  woo: ["d", "e", "f", "l", "z"],
  wood: ["b", "c", "e", "l", "m", "n", "p", "r", "s", "w", "y"],
  woodb: ["i", "l"],
  woodc: ["a", "h", "o", "r", "u"],
  woode: ["d", "n"],
  woodl: ["a", "o"],
  woodp: ["e", "i"],
  woods: ["h", "m", "y"],
  woodw: ["i", "o"],
  woof: ["e"],
  wool: ["e", "g", "l", "s", "y"],
  wooll: ["e", "i", "y"],
  wor: ["c", "d", "e", "k", "l", "m", "n", "r", "s", "t"],
  word: ["a", "b", "i", "l", "p", "s", "y"],
  wordi: ["l", "n"],
  work: [
    "a",
    "b",
    "d",
    "e",
    "f",
    "h",
    "i",
    "m",
    "o",
    "p",
    "r",
    "s",
    "t",
    "u",
    "w",
  ],
  worka: ["b", "d", "h", "r"],
  workb: ["a", "e", "o"],
  worke: ["d", "r"],
  workt: ["a", "o"],
  workw: ["e", "o"],
  worm: ["e", "h", "w", "y"],
  worn: [],
  worr: ["i", "y"],
  worri: ["e", "m", "s"],
  worry: ["i", "w"],
  wors: ["e", "h", "t"],
  worse: ["n"],
  worst: ["e"],
  wort: ["h"],
  worth: ["i", "l", "w", "y"],
  wou: ["l", "n"],
  wp: ["m", "n"],
  wr: ["a", "a", "e", "i", "n", "o", "u", "y"],
  wra: ["c", "c", "i", "n", "p", "s", "t"],
  wrac: ["k"],
  wrap: ["a", "p", "t"],
  wrapp: ["e", "i"],
  wre: ["a", "c", "n", "s", "t"],
  wrea: ["k", "t"],
  wren: ["c"],
  wri: ["g", "n", "s", "t"],
  wrig: ["g", "h"],
  wrin: ["g", "k"],
  wring: ["e", "i"],
  writ: ["e", "h", "i", "t"],
  write: ["r"],
  wro: ["n", "t", "u"],
  wrot: ["e", "h"],
  wry: ["n"],
  wu: ["h", "r"],
  wy: ["c", "o", "v"],
  wyo: ["m"],
  x: ["l", "a", "e", "i", "m", "y"],
  xa: ["n", "v"],
  xe: ["b", "n", "r"],
  xer: ["i", "o"],
  y: ["m", "w", "a", "b", "c", "d", "e", "i", "o", "r", "t", "u"],
  ya: ["c", "h", "k", "m", "n", "p", "r", "s", "w"],
  yah: ["o", "w"],
  yam: ["m"],
  yan: ["g", "k"],
  yang: ["t"],
  yank: ["e"],
  yar: ["d", "m", "n", "r"],
  yard: ["a", "m", "s"],
  yarda: ["g", "r"],
  yarm: ["e", "u"],
  yaw: ["l", "n", "s"],
  ye: ["a", "g", "l", "m", "n", "o", "p", "r", "s", "t", "w"],
  yea: ["h", "r", "s"],
  year: ["b", "e", "l", "n"],
  yearl: ["i", "o", "y"],
  yearn: ["i"],
  yel: ["l", "p"],
  yell: ["o"],
  yes: ["h", "t"],
  yet: ["i"],
  yi: ["d", "e", "n", "p"],
  yid: ["d"],
  yin: [],
  yip: ["p"],
  ym: ["c", "h"],
  yo: ["d", "g", "k", "l", "m", "n", "r", "s", "u", "w", "y"],
  yog: ["a", "h", "i", "u"],
  yon: ["d", "k"],
  yor: ["e", "k"],
  york: ["s"],
  you: ["n", "r", "t"],
  your: ["s"],
  yours: ["e"],
  yr: ["s"],
  yu: ["a", "c", "g", "k", "l", "m", "r"],
  yuc: ["a", "c", "k"],
  yw: ["c", "h"],
  z: ["p", "a", "e", "i", "l", "n", "o", "r", "u", "w", "y", "z"],
  za: ["i", "m", "n", "p"],
  zan: ["y", "z"],
  ze: ["a", "b", "d", "e", "i", "n", "o", "p", "r", "s", "t", "u"],
  zeb: ["r", "u"],
  zen: ["i"],
  zep: ["h", "p"],
  zi: ["g", "l", "m", "n", "o", "p", "r", "t", "z"],
  zig: ["g", "z"],
  zil: ["c", "l"],
  zin: ["c", "g", "n"],
  zinc: [],
  zip: ["c", "l", "p"],
  zipp: ["e", "y"],
  zo: ["d", "m", "n", "o", "r", "s"],
  zon: ["a", "e", "i", "k"],
  zone: ["d"],
  zoo: ["l", "m", "p"],
  zool: ["o"],
  zoom: ["o"],
  zoop: ["h", "l"],
  zu: ["c", "l", "r"],
};
