import { useState } from "react";
import { createContainer } from "unstated-next";
import firebase from "firebase";
import "@firebase/firestore";
import { firebaseConfig } from "./Firestore";

export function firebaseUserId() {
  const user = firebase.auth().currentUser;
  if (!user) {
    return "";
  }
  return user.uid;
}

export function firebaseLoginStatus() {
  const user = firebase.auth().currentUser;

  if (!user) {
    return false;
  }
  return true;
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const firestoreDB = firebase.firestore();

export async function getWordsFromFirestore() {
  if (firebase.auth().currentUser) {
    let tmpAllWords = [];
    const wordsRef = firestoreDB
      .collection("users")
      .doc(firebaseUserId())
      .collection("words")
      .orderBy("createdAt", "desc");
    console.log("getting saved words....");
    let allWords = await wordsRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let rowData = doc.data();
          tmpAllWords.push(rowData);
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
    return tmpAllWords;
  }
}

function useWords(initialState = []) {
  let [savedWords, setSavedWords] = useState(initialState);
  let updateWords = (words) => {
    setSavedWords(words);
  };
  return { savedWords, updateWords };
}

export let SavedWords = createContainer(useWords);
