import Constants from "expo-constants";

const { manifest } = Constants;
const apiKey = manifest.extra && manifest.extra.firebaseApiKey;
const projectId = manifest.extra && manifest.extra.firebaseProjectId;
const authDomain = manifest.extra && manifest.extra.firebaseAuthDomain;
const databaseURL = manifest.extra && manifest.extra.firebaseDatabaseURL;
const storageBucket = manifest.extra && manifest.extra.firebaseStorageBucket;
const appId = manifest.extra && manifest.extra.firebaseAppId;
const measurementId = manifest.extra && manifest.extra.firebaseMesurementId;
// Initialize Firebase
export const firebaseConfig = {
  apiKey: apiKey,
  projectId: projectId,
  authDomain: authDomain,
  databaseURL: databaseURL,
  storageBucket: storageBucket,
  appId: appId,
  measurementId: measurementId,
};
