import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import HomeScreen from "../screens/HomeScreen";
import LicenseScreen from "../screens/LicenseScreen";
import BrowserScreen from "../screens/BrowserScreen";
import SavedScreen from "../screens/SavedScreen";
import SettingsScreen from "../screens/SettingsScreen";
import {
  BottomTabParamList,
  HomeParamList,
  BrowserParamList,
  SavedParamList,
  SettingsParamList,
} from "../types";

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      tabBarOptions={{
        activeTintColor: Colors[colorScheme].tint,
        //        activeBackgroundColor: Colors[colorScheme].background,
        //        inactiveBackgroundColor: Colors[colorScheme].background,
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="book-outline" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Browser"
        component={BrowserNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="browsers-outline" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Saved"
        component={SavedNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="save-outline" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Settings"
        component={SettingsNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="settings-outline" color={color} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeParamList>();

function HomeNavigator() {
  const colorScheme = useColorScheme();
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          headerTitle: "Home",
          headerStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
        }}
      />
    </HomeStack.Navigator>
  );
}
const BrowserStack = createStackNavigator<BrowserParamList>();

function BrowserNavigator() {
  const colorScheme = useColorScheme();
  return (
    <BrowserStack.Navigator>
      <BrowserStack.Screen
        name="BrowserScreen"
        component={BrowserScreen}
        options={{
          headerTitle: "Browser",
          headerStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
        }}
      />
    </BrowserStack.Navigator>
  );
}
const SavedStack = createStackNavigator<SavedParamList>();

function SavedNavigator() {
  const colorScheme = useColorScheme();
  return (
    <SavedStack.Navigator>
      <SavedStack.Screen
        name="SavedScreen"
        component={SavedScreen}
        options={{
          headerTitle: "Saved",
          headerStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
        }}
      />
    </SavedStack.Navigator>
  );
}

const SettingsStack = createStackNavigator<SettingsParamList>();

function SettingsNavigator() {
  const colorScheme = useColorScheme();
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{
          headerTitle: "Settings",
          headerStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
        }}
      />
      <SettingsStack.Screen
        name="LicenseScreen"
        component={LicenseScreen}
        options={{
          headerTitle: "License",
          headerStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
        }}
      />
    </SettingsStack.Navigator>
  );
}
