import { StyleSheet, Platform } from "react-native";

const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#23282F",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};

const keyboradBorderTop = "#fbfbfb";
const wordListBackGround = "#fff";
const wordListBorderBottom = "#ddd";
const wordTitleInputted = "#2089dc";

export const homeScreenStyles = StyleSheet.create({
  scrollViewContainer: {
    height: "60%",
  },
  container: {
    flex: 1,
  },
  inputBox: {
    height: 60,
    fontSize: 25,
    // fontFamily: "Menlo-Regular",
    borderWidth: 2,
    borderColor: "#DDD",
    fontWeight: "bold",
    margin: 4,
    padding: 8,
    textAlignVertical: "top",
    borderRadius: 10,
  },
  keyboardContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: "center",
    paddingVertical: 10,
  },
  wordListContainer: {
    padding: 2,
    paddingLeft: 10,
    borderBottomWidth: 1,
  },
  keyboardLineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  keyboardKey: {
    // fontFamily: "Menlo-Regular",
    fontSize: 25,
    fontWeight: "bold",
    paddingTop: 0.5,
    paddingBottom: 0.5,
  },
  keyboardKeyContainer: {
    width: "9.2%",
    margin: 1.2,
  },
  rowFrontContainer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 5,
    paddingLeft: 10,
    borderBottomColor: wordListBorderBottom,
    borderBottomWidth: 1,
  },
  wordTitleContainer: {
    flexDirection: "row",
    paddingLeft: 2,
  },
  wordTitle: {
    fontWeight: "bold",
    fontSize: 25,
  },
  wordTitleInputted: {
    fontWeight: "bold",
    color: wordTitleInputted,
    fontSize: 25,
  },
  wordExample: {
    fontStyle: "italic",
  },
  wordFreq: {
    opacity: 0.5,
    fontSize: 14,
  },
});

export const browserScreenStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputBox: {
    height: 30,
    fontSize: 16,
    // fontFamily: "Menlo-Regular",
    borderWidth: 1,
    margin: 4,
    padding: 4,
    textAlignVertical: "top",
  },
});

export const savedScreenStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rowFrontContainer: {
    alignItems: "flex-start",
    padding: 2,
    paddingLeft: 10,
    borderBottomColor: wordListBorderBottom,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  wordTitleContainer: {
    flexDirection: "row",
  },
  wordTitle: {
    fontWeight: "bold",
    fontSize: 20,
  },
  wordMeanContainer: {
    justifyContent: "flex-start",
    height: 50,
  },
  wordExample: {
    fontStyle: "italic",
  },
  wordFreq: {
    opacity: 0.5,
    fontSize: 14,
  },
});

export const settingsScreenStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  subcontainer: {
    alignItems: "center",
    width: "100%",
    padding: 2,
    paddingLeft: 10,
    borderBottomColor: wordListBorderBottom,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  appleButtonContainer: {
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});
