import * as alasql from "alasql";
import { itemA } from "./SQLDataA";
import { itemB } from "./SQLDataB";
import { itemC } from "./SQLDataC";
import { itemD } from "./SQLDataD";
import { itemE } from "./SQLDataE";
import { itemF } from "./SQLDataF";
import { itemG } from "./SQLDataG";
import { itemH } from "./SQLDataH";
import { itemI } from "./SQLDataI";
import { itemJ } from "./SQLDataJ";
import { itemK } from "./SQLDataK";
import { itemL } from "./SQLDataL";
import { itemM } from "./SQLDataM";
import { itemN } from "./SQLDataN";
import { itemO } from "./SQLDataO";
import { itemP } from "./SQLDataP";
import { itemQ } from "./SQLDataQ";
import { itemR } from "./SQLDataR";
import { itemS } from "./SQLDataS";
import { itemT } from "./SQLDataT";
import { itemU } from "./SQLDataU";
import { itemV } from "./SQLDataV";
import { itemW } from "./SQLDataW";
import { itemX } from "./SQLDataX";
import { itemY } from "./SQLDataY";
import { itemZ } from "./SQLDataZ";
const aladb = new alasql.Database();

aladb.exec(
  "CREATE TABLE itemA (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemB (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemC (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemD (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemE (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemF (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemG (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemH (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemI (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemJ (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemK (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemL (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemM (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemN (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemO (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemP (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemQ (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemR (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemS (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemT (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemU (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemV (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemW (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemX (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemY (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.exec(
  "CREATE TABLE itemZ (word STRING, mean STRING, mean_en STRING, freq INT, coca_spok2 INT, examples STRING)"
);

aladb.tables.itemA.data = itemA;
aladb.tables.itemB.data = itemB;
aladb.tables.itemC.data = itemC;
aladb.tables.itemD.data = itemD;
aladb.tables.itemE.data = itemE;
aladb.tables.itemF.data = itemF;
aladb.tables.itemG.data = itemG;
aladb.tables.itemH.data = itemH;
aladb.tables.itemI.data = itemI;
aladb.tables.itemJ.data = itemJ;
aladb.tables.itemK.data = itemK;
aladb.tables.itemL.data = itemL;
aladb.tables.itemM.data = itemM;
aladb.tables.itemN.data = itemN;
aladb.tables.itemO.data = itemO;
aladb.tables.itemP.data = itemP;
aladb.tables.itemQ.data = itemQ;
aladb.tables.itemR.data = itemR;
aladb.tables.itemS.data = itemS;
aladb.tables.itemT.data = itemT;
aladb.tables.itemU.data = itemU;
aladb.tables.itemV.data = itemV;
aladb.tables.itemW.data = itemW;
aladb.tables.itemX.data = itemX;
aladb.tables.itemY.data = itemY;
aladb.tables.itemZ.data = itemZ;
const itemMap = {
  a: "itemA",
  b: "itemB",
  c: "itemC",
  d: "itemD",
  e: "itemE",
  f: "itemF",
  g: "itemG",
  h: "itemH",
  i: "itemI",
  j: "itemJ",
  k: "itemK",
  l: "itemL",
  m: "itemM",
  n: "itemN",
  o: "itemO",
  p: "itemP",
  q: "itemQ",
  r: "itemR",
  s: "itemS",
  t: "itemT",
  u: "itemU",
  v: "itemV",
  w: "itemW",
  x: "itemX",
  y: "itemY",
  z: "itemZ",
};
export const propForMedian = "cocaSpok2";
export const dbColumnForMedian = "coca_spok2";
export const defaultValueForMedian = 52;

export function builder(queryChar, median = defaultValueForMedian) {
  if (median === 0) {
    median = defaultValueForMedian;
  }
  // console.log("median:" + median);
  const firstChar = queryChar[0];
  const item = itemMap[firstChar];
  const queryStrings =
    "select * from " +
    item +
    " where word like '" +
    queryChar +
    "%' order by " +
    dbColumnForMedian +
    " IS NULL, ABS(" +
    dbColumnForMedian +
    " - " +
    median +
    ") asc limit 10;";
  // console.log(queryStrings);
  return queryStrings;
}

export function freqMedian(values) {
  let newValues = [];
  values.forEach((el) => {
    if (el[propForMedian] > 10) {
      newValues.push(el[propForMedian]);
    }
  });
  if (newValues.length === 0) return 0;
  // console.log(newValues);

  newValues.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(newValues.length / 2);

  if (newValues.length % 2) return parseInt(newValues[half]);

  return parseInt((newValues[half - 1] + newValues[half]) / 2.0);
}

export function executeSQL(sql) {
  const res = aladb.exec(sql);
  return res;
}
