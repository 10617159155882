import React, { useState } from "react";
import { Image, Switch, Platform } from "react-native";
import { Text, TextInput, View } from "../components/Themed";
import { Button } from "react-native-elements";
import * as Updates from "expo-updates";

import * as AppleAuthentication from "expo-apple-authentication";
import * as Google from "expo-google-app-auth";
import * as Colors from "../constants/Colors";
import firebase from "firebase";
import "@firebase/firestore";
import * as WebBrowser from "expo-web-browser";
import {
  makeRedirectUri,
  ResponseType,
  useAuthRequest,
  useAutoDiscovery,
  generateHexStringAsync,
} from "expo-auth-session";
import { firebaseConfig } from "../api/Firestore";
import * as Analytics from "expo-firebase-analytics";
import { firebaseLoginStatus, firebaseUserId } from "../api/SharedContainer";

WebBrowser.maybeCompleteAuthSession();

const useProxy = Platform.select({ web: false, default: true });

function nonceGen(length: number) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function useNonce() {
  const [nonce, setNonce] = React.useState(null);
  React.useEffect(() => {
    generateHexStringAsync(16).then((value) => setNonce(value));
  }, []);
  return nonce;
}

async function signInWithGoogleAsync() {
  try {
    const result = await Google.logInAsync({
      androidClientId:
        "616637341414-gaui0vhp57ugno4cbhfmu66cptr5r8ic.apps.googleusercontent.com",
      iosClientId:
        "616637341414-5j2ep444tfcl2g5h4s51ucg46ap3dfro.apps.googleusercontent.com",
      iosStandaloneAppClientId:
        "616637341414-1o4lai9q4rgsrcviguot6j5luh1e3ggf.apps.googleusercontent.com",
      androidStandaloneAppClientId:
        "616637341414-70ve7et5006abg88eq022v479vbsnq1n.apps.googleusercontent.com",
      scopes: ["profile", "email"],
    });

    if (result.type === "success") {
      //console.log(JSON.stringify(result));
      return result.idToken;
    } else {
      console.log("google sign in does not return success");
      return { cancelled: true };
    }
  } catch (e) {
    console.log("google sign in return error");
    return { error: true };
  }
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (firebaseLoginStatus()) {
    Analytics.setUserId(firebaseUserId());
  }
}
const firestoreDB = firebase.firestore();

export default function SettingsScreen({ navigation }) {
  const [signInStatus, setSignInStatus] = useState(false);
  const [isEmailEnabled, setIsEmailEnabled] = useState(true);
  const nonce = useNonce();
  // Endpoint
  const discovery = useAutoDiscovery("https://accounts.google.com");
  // Request
  const [request, response, promptAsync] = useAuthRequest(
    {
      responseType: ResponseType.IdToken,
      clientId:
        "616637341414-kh33o5ot2q0qil57232s90b1m5jk2v0v.apps.googleusercontent.com",
      redirectUri: makeRedirectUri({
        // For usage in bare and standalone
        native:
          "com.googleusercontent.apps.616637341414-kh33o5ot2q0qil57232s90b1m5jk2v0v:/redirect",
        useProxy,
      }),
      scopes: ["openid", "profile", "email"],
      usePKCE: false,
      extraParams: {
        nonce,
      },
    },
    discovery
  );

  React.useEffect(() => {
    if (response?.type === "success") {
      const { id_token } = response.params;

      const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
      firebase.auth().signInWithCredential(credential);
      setSignInStatus(true);
    }
  }, [response]);
  const emailSwitch = () => {
    if (firebaseLoginStatus()) {
      const userRef = firestoreDB.collection("users").doc(firebaseUserId());
      userRef.get().then((doc) => {
        setIsEmailEnabled(doc.data().receiveReviewEmail);
      });
    }
    return (
      <View>
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <View style={{ alignItems: "flex-start", paddingRight: 10 }}>
            <Text style={{ fontSize: 24 }}>Receive Review Mail</Text>
          </View>
          <Switch
            value={isEmailEnabled}
            onValueChange={(v) => {
              setIsEmailEnabled(v);
              if (firebaseLoginStatus()) {
                const userRef = firestoreDB
                  .collection("users")
                  .doc(firebaseUserId());
                userRef.update({ receiveReviewEmail: v });
              }
            }}
          />
        </View>
      </View>
    );
  };
  const appleSignInView = () => {
    const tmpSignInProvider = "Apple";
    const nonceString = nonceGen(32);
    return (
      <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
        cornerRadius={5}
        style={{ width: 200, height: 64, borderColor: "#fff", borderWidth: 1 }}
        onPress={() => {
          try {
            AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
              state: nonceString,
            }).then((result) => {
              let provider = new firebase.auth.OAuthProvider("apple.com");
              let credential = provider.credential({
                idToken: result.identityToken,
                rawNonce: nonceString,
              });
              console.log(result.identityToken);
              firebase
                .auth()
                .signInWithCredential(credential)
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.log("firebase auth failed with apple Sign In");
                  console.log(errorMessage);
                });
              setSignInStatus(true);
            });
          } catch (e) {
            if (e.code === "ERR_CANCELED") {
              // handle that the user canceled the sign-in flow
            } else {
              // handle other errors
            }
          }
        }}
      />
    );
  };
  const googleSignInView = () => {
    if (Platform.OS === "web") {
      return (
        <Button
          buttonStyle={{ height: 64, padding: 1 }}
          icon={
            <Image
              source={require("../assets/images/google-icon.png")}
              style={{ width: 40, height: 40 }}
            />
          }
          title="Sign In with Google"
          disabled={!request}
          onPress={() => {
            promptAsync({ useProxy });
          }}
        />
      );
    } else {
      return (
        <Button
          buttonStyle={{ height: 64, padding: 1 }}
          icon={
            <Image
              source={require("../assets/images/google-icon.png")}
              style={{ width: 40, height: 40 }}
            />
          }
          title="Sign In with Google"
          onPress={() => {
            signInWithGoogleAsync().then((idToken) => {
              const credential = firebase.auth.GoogleAuthProvider.credential(
                idToken
              );
              firebase
                .auth()
                .signInWithCredential(credential)
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.log("firebase auth failed with Google Sign In");
                  console.log(errorMessage);
                });
              setSignInStatus(true);
            });
          }}
        />
      );
    }
  };
  const singInSignOutView = () => {
    if (!firebaseLoginStatus() && !signInStatus) {
      return (
        <View>
          <View style={Colors.settingsScreenStyles.appleButtonContainer}>
            {appleSignInView()}
          </View>
          <View style={Colors.settingsScreenStyles.appleButtonContainer}>
            {googleSignInView()}
          </View>
        </View>
      );
    } else {
      return (
        <View>
          <View>
            <View style={Colors.settingsScreenStyles.appleButtonContainer}>
              {emailSwitch()}
            </View>
          </View>
          <View>
            <View style={Colors.settingsScreenStyles.appleButtonContainer}>
              <Button
                title="Sign Out"
                type="outline"
                onPress={() => {
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      setSignInStatus(false);
                      Updates.reloadAsync();
                      console.log("sign out called");
                    });
                }}
              />
            </View>
          </View>
        </View>
      );
    }
  };
  return (
    <View style={Colors.settingsScreenStyles.container}>
      <View style={Colors.settingsScreenStyles.subcontainer}>
        {singInSignOutView()}
        <View>
          <View style={Colors.settingsScreenStyles.appleButtonContainer}>
            <Text
              onPress={() => {
                navigation.navigate("LicenseScreen");
              }}
            >
              Dependent Software/Data Licence
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

SettingsScreen.navigationOptions = {
  header: null,
};
