import * as React from "react";
import { StyleSheet } from "react-native";

import { Text, View } from "../components/Themed";
export default function LicenseScreen() {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Dependent Software/Data Licence</Text>
      </View>
      <View>
        <Text>
          WordNet 3.0 license: (Download) WordNet Release 3.0 This software and
          database is being provided to you, the LICENSEE, by Princeton
          University under the following license. By obtaining, using and/or
          copying this software and database, you agree that you have read,
          understood, and will comply with these terms and conditions.:
          Permission to use, copy, modify and distribute this software and
          database and its documentation for any purpose and without fee or
          royalty is hereby granted, provided that you agree to comply with the
          following copyright notice and statements, including the disclaimer,
          and that the same appear on ALL copies of the software, database and
          documentation, including modifications that you make for internal use
          or for distribution. WordNet 3.0 Copyright 2006 by Princeton
          University. All rights reserved. THIS SOFTWARE AND DATABASE IS
          PROVIDED "AS IS" AND PRINCETON UNIVERSITY MAKES NO REPRESENTATIONS OR
          WARRANTIES, EXPRESS OR IMPLIED. BY WAY OF EXAMPLE, BUT NOT LIMITATION,
          PRINCETON UNIVERSITY MAKES NO REPRESENTATIONS OR WARRANTIES OF
          MERCHANT- ABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR THAT THE
          USE OF THE LICENSED SOFTWARE, DATABASE OR DOCUMENTATION WILL NOT
          INFRINGE ANY THIRD PARTY PATENTS, COPYRIGHTS, TRADEMARKS OR OTHER
          RIGHTS. The name of Princeton University or Princeton may not be used
          in advertising or publicity pertaining to distribution of the software
          and/or database. Title to copyright in this software, database and any
          associated documentation shall at all times remain with Princeton
          University and LICENSEE agrees to preserve same.
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
